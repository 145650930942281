import {
  convertCurrentDate,
  convertCurrentDateWithFormat,
} from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import React from "react";
import DashboardMoreOptions from "../../components/dashboard-more-options";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { Eye } from "lucide-react";

function KPIRankingsComponents(props: any) {
  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="dashboard-card-heading"
        onClick={() => props.toggleChartDetails(props)}
      >
        {capitalizeFirstLetter(getValue(props, `name`, ""))}
        <Eye size={16} className="dashboard-card-heading-eye" />
      </p>
      <table className="w-100">
        {/* <thead>
          <th></th>
          <th></th>
          <th></th>
        </thead> */}
        {getValue(props, `graph`, []).map((item: object, i: number) => {
          return (
            <tr
              className={`w-100 ${
                i !== getValue(props, `graph`, []).length - 1
                  ? "border-bottom"
                  : ""
              }`}
            >
              <td className="w-10 py-2">{i + 1}.</td>
              <td className="w-50 py-2">
                {typeof getValue(item, `key`, "") === "string"
                  ? convertCurrentDateWithFormat(
                      getValue(item, `key`, ""),
                      "DD-MM-YYYY"
                    )
                  : convertCurrentDateWithFormat(
                      getValue(item, `key.created_at`, ""),
                      "DD-MM-YYYY"
                    )}
              </td>
              <td className="w-20 py-2 text-center">
                {getValue(item, `count`, "")}
              </td>
              <td className="w-20 py-2">
                {getValue(item, `percentageToCurrent`, "")}
              </td>
            </tr>
          );
        })}
      </table>
      <DashboardMoreOptions
        props={props}
        id={getValue(props, "id", "")}
        confirmDeleteAnalyticsComponent={props.confirmDeleteAnalyticsComponent}
        handleEditChart={props.handleEditChart}
      />
    </div>
  );
}

export default KPIRankingsComponents;
