import { ExternalLink } from "lucide-react";
import React from "react";
import AIAgentLayout from "../../Layout";

const AIAgentShare = () => {
  return (
    <AIAgentLayout>
      <a
        href="/2c65a4b45ff1/chatbot/share/cm4tknmzf06pkhrx7yb0i8pt1"
        className="header_blue_text__18 cursor-pointer"
        target="_blank"
      >
        Bots Share page <ExternalLink />
      </a>
    </AIAgentLayout>
  );
};

export default AIAgentShare;
