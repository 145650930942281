import { handleTextChange } from "@common/handleChange";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import InputRuleForm from "@components/InputRuleForm/form";
import ConditionManagerHoc from "@pages/Private/Settings/SLAPolicy/CreateSLAPolicy/common/ConditionManagerHoc";
import { getValue } from "@utils/lodash";
import { Radio } from "antd";

const SkillsGeneralSettings = ({
  request,
  setRequest,
  validator,
  editId,
  conditionList,
  isLoading1,
}: any) => {
  return (
    <div>
      <div className="w-50">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Eg: Handling VIP Customers"
          required
          label="Skill Name (For Own Reference)"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
          validator={validator}
          validLeft
        />
      </div>
      <FormDescription
        request={request}
        textClassname="my-3"
        inputClassname="w-50 my-2"
        input={
          <InputRuleForm
            inputType="TEXTAREA"
            name="description"
            value={getValue(request, `description`, "")}
            placeholder="Enter Description"
            label="Description"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
          />
        }
      />
      <div className="mt-5">
        <h6 className="header_text__18">Conditions</h6>
        <Radio.Group
          onChange={(e) =>
            setRequest((prev: any) => ({
              ...prev,
              match_type: e.target.value,
            }))
          }
          value={getValue(request, `match_type`, "")}
          className="my-2"
        >
          <Radio value="any">
            Match <b>Any</b> of the below
          </Radio>
          <Radio value="all">
            Match <b>All</b> of the below
          </Radio>
        </Radio.Group>
        <ConditionManagerHoc
          editId={editId}
          request={request}
          setRequest={setRequest}
          conditionList={conditionList}
          isLoading1={isLoading1}
          validator={validator}
          array="skill_conditions"
          isSkills
          matchType={
            getValue(request, "match_type", "") === "any" ? "OR" : "AND"
          }
        />
      </div>
    </div>
  );
};

export default SkillsGeneralSettings;
