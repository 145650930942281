import classnames from "classnames";
import { getValue } from "@utils/lodash";
import PenSvgComponent from "assets/svg/pen";
import ArrowDownSvgComponent from "@assets/svg/arrow-down";
import EditSvgComponent from "@assets/svg/edit";
import { useState } from "react";
import { formatString } from "@common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";

function SettingModuleNavbar(props: any) {
  const [tabs] = useState([
    {
      value: "Fields",
      name: "fields",
    },
    {
      value: "Status",
      name: "stages",
    },
  ]);

  const [tasktabs] = useState([
    {
      value: "Fields",
      name: "fields",
    },
  ]);
  return (
    <div className="position-relative">
      {/* <Nav tabs className="w-100">
        <NavItem>
          <NavLink
            className={classnames({
              active: getValue(props, `tab`, "") === "fields",
            })}
            onClick={() => {
              props.handleNavigate("fields");
            }}
          >
            Fields
          </NavLink>
        </NavItem>
        {getValue(props, `params.code`, "") !== "tasks" && (
          <NavItem>
            <NavLink
              className={classnames({
                active: getValue(props, `tab`, "") === "stages",
              })}
              onClick={() => {
                props.handleNavigate("stages");
              }}
            >
              Status
            </NavLink>
          </NavItem>
        )}
      </Nav> */}
      <ul
        className="d-flex align-items-center justify-content-between mt-4"
        style={{ borderBottom: "1px solid #efefef" }}
      >
        <div className="d-flex align-items-center justify-content-start ms-3">
          {(getValue(props, `code`, "") === "tickets" ||
          getValue(props, `code`, "") === "tasks" ||
          getValue(props, `code`, "") === "calls" ||
          getValue(props, `code`, "") === "meetings"
            ? tasktabs
            : tabs
          ).map((linkObj: object, index: number) => {
            return (
              <li
                key={`pending-${index}`}
                className={`${
                  getValue(props, `tab`, "") == getValue(linkObj, `name`, "")
                    ? "activebar__main-body-list_active"
                    : "activebar__main-body-list"
                }`}
                onClick={() => {
                  props.handleNavigate(getValue(linkObj, `name`, ""));
                }}
              >
                <a className={"activebar__main-body-link p-3 "}>
                  {formatString(getValue(linkObj, `value`, ""))}
                </a>
              </li>
            );
          })}
        </div>
      </ul>
      {/* <div className="absolute_right_icon" onClick={props.toggle}>
        <ButtonComponent
          buttonType={"primary"}
          buttonText={"Edit Info"}
          onClickHandler={() => {
            props.toggle();
          }}
        />
      </div> */}
    </div>
  );
}

export default SettingModuleNavbar;
