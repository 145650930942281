import InfoSvgComponent from "@assets/svg/info";
import { emojisList } from "@common/emojis";
import { handleTextArrayChange } from "@common/handleChange";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Button, Dropdown, Menu, MenuProps, Space, Tooltip } from "antd";
import AIStars from "@components/common/Loader/ai-stars";
import { getStringContentLength } from "@common/markdown/html-to-text-parser";
import { DownOutlined } from "@ant-design/icons";
import { List, Settings } from "lucide-react";
import MarkdownEditor from "@components/MarkdownEditor/Editor";

const TemplateBody = (props: any) => {
  const {
    request,
    setRequest,
    handleModal,
    handleChangeEditor,
    toggleAiModal,
    aiRephraseMethods,
    isRephraseLoading,
    handleAddCustomTags,
    handleCursorMovement,
    parentChage,
    setParentChage,
  } = props;

  const items: MenuProps["items"] = aiRephraseMethods.map(
    (method: any, index: any) => ({
      key: `${index + 1}`,
      label: method,
      onClick: () => toggleAiModal(method),
      disabled: isRephraseLoading === true,
    })
  );

  const variableItems = (
    <Menu
      items={[
        {
          label: "Show Variables",
          key: "1",
          icon: <List size={16} />,
          onClick: () => {
            handleModal();
          },
        },
        // {
        //   label: "Custom",
        //   key: "2",
        //   icon: <Settings size={16} />,
        //   onClick: () => {
        //     handleAddCustomTags();
        //   },
        // },
      ]}
    />
  );

  return (
    <>
      <div className="border-bottom">
        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex align-items-center gap-2">
            <h6 className="">
              Message Body <span style={{ color: "red" }}>*</span>
            </h6>
            <Tooltip
              title="Make your messages personal using variables like [[name]] and get more replies."
              className="mb-1"
            >
              <span>
                <InfoSvgComponent color="#676767" size="16" />
              </span>
            </Tooltip>
          </div>
          {/* <Dropdown overlay={variableItems}>
            <Button type="primary">
              <Space>Add variable</Space>
            </Button>
          </Dropdown> */}
          <Button type="primary" onClick={() => handleModal()}>
            Add variable
          </Button>
        </div>
        <div className="w-100 mt-1 input-with-length">
          <div className="py-3">
            {/* <DraftJSRefEditor
              editorState={getValue(request, `body`, "")}
              handleChangeEditor={handleChangeEditor}
              name="content"
              placeholder={`Hi [[name]]!🖐\n\nThe awesome products 😍 from [[brand_name]] are waiting to be yours!\n Get your products here👇`}
              editorStyle={{
                background: "white",
                paddingLeft: "20px",
                paddingTop: "20px",
                // height: "30vh",
              }}
              focusColor={"#3fd1a9"}
              toolbar={{
                options: ["inline", "emoji"],
                inline: {
                  options: ["bold", "italic"],
                },
                emoji: {
                  emojis: emojisList,
                },
              }}
              focus
              // autoFocus
              hideFocus
              editorClassName={"broadcast-editorClassName"}
            /> */}
            <MarkdownEditor
              handleChangeEditor={handleChangeEditor}
              handleCursorMovement={handleCursorMovement}
              value={getValue(request, `body`, "")}
              parentChage={parentChage}
              setParentChage={setParentChage}
              style={{
                minHeight: '40vh', 
                overflowY: 'auto',
                resize: 'vertical',
              }}
              removeEnterAction
            />
            <p className="small_text__12 mt-1 text-end">
              {getStringContentLength(getValue(request, `body`, ""))}/1024
            </p>
          </div>
          <div className="d-flex justify-content-end align-items-center gap-3">
            <p className="small_text__14">
              Let me know if you want me to modify.
            </p>
            <Tooltip
              title={
                getValue(request, "body", "").length < 12
                  ? "Add message body to let AI help you"
                  : ""
              }
            >
              <Dropdown
                menu={{ items }}
                disabled={getValue(request, "body", "").length < 12}
              >
                <div onClick={(e) => e.preventDefault()} className="w-auto">
                  <div className="ai-button-wrapper">
                    <button className="ai-button" type="button">
                      <div className="heart-beat-anim">
                        <AIStars />
                      </div>
                      AI Rewrite
                    </button>
                    <div className="ai-button-bg"></div>
                  </div>
                </div>
              </Dropdown>
            </Tooltip>
          </div>
        </div>
      </div>
      {getValue(request, `customTags.length`, 0) > 0 && (
        <div className="border-bottom">
          <h6 className="mt-3">Sample column</h6>
          <p className="my-2 greyed-description">
            Your fields will appear here once an attribute is added
          </p>
          <div className="input-with-length">
            {getValue(request, `customTags`, []).map(
              (item: any, index: number) => {
                return (
                  <InputRuleForm
                    inputType="TEXT"
                    placeholder={`Enter sample for ${getValue(
                      item,
                      `placeholder`,
                      ""
                    )}`}
                    name="sample"
                    onChange={(e: any) =>
                      handleTextArrayChange(index, e, "customTags", setRequest)
                    }
                    value={getValue(item, `sample`, "")}
                  />
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateBody;
