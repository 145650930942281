import { UploadOutlined } from "@ant-design/icons";
import { handleNumberChange, handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import Loader from "@components/common/Loader/loading";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";
import { Button, ColorPicker, Select, Switch, Upload } from "antd";
import { config } from "../../../../../../env";
import { useState } from "react";

function SettingsChatInterface(props: any) {
  const { request, setRequest, isLoading } = props;

  const handleKeyDown = (e: any, name: any) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent space character
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      const currentValue = e.target.value;
      const cursorPosition = e.target.selectionStart;
      const textBeforeCursor = currentValue.substring(0, cursorPosition);
      const textAfterCursor = currentValue.substring(cursorPosition);

      setRequest({
        ...request,
        [name]: textBeforeCursor + "\n" + textAfterCursor,
      });

      // Move cursor to the new line
      setTimeout(() => {
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  const handleChange = (e: any, name: any) => {
    const value = e.target.value;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  function rgbToHex(r: number, g: number, b: number) {
    return "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");
  }

  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUpload = async (file: any, name: any) => {
    setUploadLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append("is_public", "true");
    formData.append("collection", "public-images");
    try {
      setUploadLoading(true);
      let resp = await mediaUpload(formData);
      if (resp) {
        if (name === "profile_picture_id") {
          setRequest((prevState: any) => ({
            ...prevState,
            profile_picture_id: getValue(resp, `data`, {}),
          }));
        }
        if (name === "chat_icon_id") {
          setRequest((prevState: any) => ({
            ...prevState,
            chat_icon_id: getValue(resp, `data`, {}),
          }));
        }
        setUploadLoading(false);
      } else {
        setUploadLoading(false);
      }
    } catch (error) {
      setUploadLoading(false);
    }
  };

  const beforeUpload = (file: any) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      //   message.error("You can only upload image files!");
    }
    return isImage;
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div
      className="border rounded-3 p-4"
      style={{ height: "calc(100vh - 260px)", overflowY: "auto" }}
    >
      <h6 className="header_text__18">Chat Interface</h6>
      <div className="my-3">
        <div className="space-y-2">
          <InputRuleForm
            inputType="TEXTAREA"
            id="domains"
            value={getValue(request, `initial_messages`, "")}
            onChange={(e: any) => handleChange(e, "initial_messages")}
            onKeyDown={(e: any) => handleKeyDown(e, "initial_messages")}
            className="w-full min-h-32 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter domains here..."
          />
        </div>

        <p className="small_text__14">Enter each message in a new line.</p>
      </div>
      <div className="my-3">
        <InputRuleForm
          inputType="TEXTAREA"
          name="name"
          placeholder="What is example.com"
          label="Suggested Messages"
          value={getValue(request, `suggested_messages`, "")}
          onChange={(e: any) => handleChange(e, "suggested_messages")}
          onKeyDown={(e: any) => handleKeyDown(e, "suggested_messages")}
        />
        <p className="small_text__14">Enter each message in a new line.</p>
      </div>
      <div className="my-3">
        <InputRuleForm
          inputType="TEXT"
          name="message_placeholder"
          placeholder="Message..."
          label="Message Placeholder"
          value={getValue(request, `message_placeholder`, "")}
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
        />
      </div>
      <div className="my-3">
        <label>Collect User Feedback</label>
        <Switch
          size="small"
          className="ms-2"
          checked={getValue(request, `collect_user_feedback`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              required: !getValue(request, `collect_user_feedback`, false),
            })
          }
        />
      </div>
      <div className="my-3">
        <label>Regenerate Messages</label>
        <Switch
          size="small"
          className="ms-2"
          checked={getValue(request, `regenerate_messages`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              required: !getValue(request, `regenerate_messages`, false),
            })
          }
        />
      </div>
      <div className="my-3">
        <InputRuleForm
          inputType="TEXTAREA"
          name="footer"
          label="Footer"
          value={getValue(request, `footer`, "")}
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
        />
        <p className="small_text__12 text-end">0/200 characters</p>
        <p className="small_text__14">
          You can use this to add a disclaimer or a link to your privacy policy.
        </p>
      </div>
      <div className="my-3">
        <label className="form-label">Theme</label>
        <Select
          value={getValue(request, `theme`, "")}
          style={{ width: 120 }}
          options={[
            { value: "light", label: "Dark" },
            { value: "dark", label: "Light" },
          ]}
          onChange={(e: any) => {
            setRequest({
              ...request,
              theme: e,
            });
          }}
        />
      </div>
      <div className="my-3">
        <InputRuleForm
          inputType="TEXT"
          name="display_name"
          placeholder="name..."
          label="Display name"
          value={getValue(request, `display_name`, "")}
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
        />
      </div>
      <div className="my-3">
        <Upload
          name="file"
          listType="picture-circle"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={({ file }) => handleUpload(file, "profile_picture_id")}
          beforeUpload={beforeUpload}
        >
          {getValue(request, `profile_picture_id.key`, "") ? (
            <img
              src={`https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                request,
                `profile_picture_id.key`,
                ""
              )}`}
              alt="avatar"
              style={{ height: "100px", width: "100px", borderRadius: "50%" }}
              className="rounded-full object-cover"
            />
          ) : (
            "uploadButton"
          )}
        </Upload>
        <label className="form-label">Profile Picture</label>
      </div>
      <div className="my-3">
        <Upload
          name="file"
          listType="picture-circle"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={({ file }) => handleUpload(file, "chat_icon_id")}
          beforeUpload={beforeUpload}
        >
          {getValue(request, `chat_icon_id.key`, "") ? (
            <img
              src={`https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                request,
                `chat_icon_id.key`,
                ""
              )}`}
              alt="avatar"
              style={{ height: "100px", width: "100px", borderRadius: "50%" }}
              className="rounded-full object-cover"
            />
          ) : (
            "uploadButton"
          )}
        </Upload>
        <label className="form-label">Chat Icon</label>
      </div>
      <div className="my-3">
        <ColorPicker
          value={getValue(request, "user_message_color", "")}
          onChange={(e: any) => {
            const { r, g, b } = e.metaColor;
            const hexColor = rgbToHex(r, g, b);
            setRequest({
              ...request,
              user_message_color: hexColor,
            });
          }}
        />
        <label className="form-label">User Message Color</label>

        {/* <Checkbox>Sync User Message Color with Chatbot Header</Checkbox> */}
      </div>
      <div className="my-3">
        <ColorPicker
          value={getValue(request, "button_color", "")}
          onChange={(e: any) => {
            const { r, g, b } = e.metaColor;
            const hexColor = rgbToHex(r, g, b);
            setRequest({
              ...request,
              button_color: hexColor,
            });
          }}
        />
        <label className="form-label">Chat Bubble Button Color</label>
      </div>
      <div className="my-3">
        <label className="form-label">Align Chat Bubble Button</label>
        <Select
          value={getValue(request, "align_chat_button", "")}
          style={{ width: 120 }}
          options={[
            { value: "right", label: "Right" },
            { value: "left", label: "Left" },
          ]}
          onChange={(e: any) => {
            setRequest({
              ...request,
              align_chat_button: e,
            });
          }}
        />
      </div>
      <div className="my-3">
        <InputRuleForm
          inputType="NUMBER"
          name="auto_open_chat_window_after"
          placeholder="name..."
          label="Auto show initial messages pop-ups after"
          value={getValue(request, `auto_open_chat_window_after`, 0)}
          onChange={(e: any) => handleNumberChange(e, request, setRequest)}
        />
        <p>seconds(negative to disable)</p>
      </div>
    </div>
  );
}

export default SettingsChatInterface;
