import { handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import LoaderMain from "@components/common/Loader/loading1";
import { updateFormAgentAction } from "@services/agent-actions.service";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import { useState } from "react";
import { toast } from "sonner";

const FAGenral = (props: any) => {
  const { request, setRequest, isLoading, getActionData } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSave = async () => {
    try {
      setSubmitLoading(true);
      let payload = {
        instruction: getValue(request, `instruction`, ""),
      };
      let resp;
      if (getValue(UrlParams, `action_id`, "")) {
        resp = await updateFormAgentAction(
          getValue(UrlParams, `action_id`, ""),
          payload
        );
      }
      if (resp) {
        toast.success(`Action updated successfully`);
        getActionData();
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return isLoading ? (
    <LoaderMain />
  ) : (
    <>
      <div className="px-4 pb-4 d-grid gap-3">
        <h6 className="header_text__16">When to use</h6>
        <p className="small_text__14">
          Explain when the AI Agent should use this action. Include a
          description of what this action does, the data it provides, and any
          updates it makes. Include example queries that should trigger this
          action.
        </p>

        <div>
          <InputRuleForm
            inputType="TEXTAREA"
            placeholder="Example: Use this action to retireve the user's invoice history. example queries: 'Show me my invoice history', 'What are my invoices?'..."
            label="When to Use"
            name="instruction"
            value={getValue(request, "instruction", "")}
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
            validLeft
          />
        </div>
      </div>
      <Button
        onClick={() => handleSave()}
        size="large"
        type="primary"
        disabled={submitLoading}
      >
        Save and Continue
      </Button>
    </>
  );
};

export default FAGenral;
