import EditSvgComponent from "@assets/svg/edit";
import { getValue } from "@utils/lodash";
import { config } from "../../../../env";
import { useNavigate, useParams } from "react-router-dom";
import AddSvgComponent from "@assets/svg/add";
import { capitalizeFirstLetterOfEachWord } from "@common/text-helpers";

const propertyList = [
  {
    name: "Verde Vista",
    image: "/property/1.jpg",
  },
  {
    name: "Ridge Retreat",
    image: "/property/2.webp",
  },
  {
    name: "Coastal Charm Resort",
    image: "/property/3.webp",
  },
  {
    name: "Windy Woods",
    image: "/property/4.jpg",
  },
  {
    name: "Kstamusu",
    image: "/property/5.webp",
  },
];

const PropertiesList = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <div className="property-list-wrapper p-5 ">
      <div
        onClick={() =>
          navigate(`/${getValue(params, `orgId`, "")}/property/create`)
        }
        className="property-card-add "
      >
        <AddSvgComponent />
        <h6 className="header_text__20 ">Add property</h6>
      </div>
      {getValue(props, `list`, []).map((property: object, index: number) => (
        <button
          key={index}
          className="property-card position-relative"
          onClick={() =>
            navigate(
              `/${getValue(params, "orgId", "")}/property/details/${getValue(
                property,
                `id`,
                ""
              )}/reservations`
            )
          }
          // onClick={() =>
          //   navigate(
          //     `/${getValue(params, "orgId", "")}/property/details/${getValue(
          //       property,
          //       `id`,
          //       ""
          //     )}/summary`
          //   )
          // }
        >
          <div
            onClick={(e: any) => {
              e.stopPropagation();
              navigate(
                `/${getValue(params, "orgId", "")}/property/edit/${getValue(
                  property,
                  `id`,
                  ""
                )}`
              );
            }}
            className="property-card-edit"
          >
            <EditSvgComponent />
          </div>
          <img
            src={
              getValue(property, `property_images.length`, "") > 0
                ? `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                    property,
                    `property_images[${0}].file_upload.key`,
                    ""
                  )} `
                : "/property/dummy-image.jpg"
            }
            className="property-image"
          />
          <div className="p-3">
            <p className="header_text__20">
              {capitalizeFirstLetterOfEachWord(getValue(property, `name`, ""))}
            </p>
          </div>
        </button>
      ))}
    </div>
  );
};

export default PropertiesList;
