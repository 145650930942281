import HomeHeader from "@components/common/Header/HomeHeader/Header";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import { getValue } from "@utils/lodash";
import { Collapse, CollapseProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import CaGeneral from "./CaGeneral";
import CaApi from "./CaAPI";
import CaTestResponse from "./CaTestResponse";
import CaDataAccess from "./CaDataAccess";
import { useEffect, useState } from "react";
import { getSpecificAgent } from "@services/agent-actions.service";
import { caCustomActionRequest } from "../action-request-helper";

const CustomAction = () => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(UrlParams, `action_id`, "")) {
      getActionData();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [request, setRequest] = useState(caCustomActionRequest);
  const [info, setInfo] = useState({});
  const reset = () => {
    setRequest(caCustomActionRequest);
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const getActionData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificAgent(getValue(UrlParams, `action_id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setIsLoading(false);
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          description: getValue(resp, `data.description`, ""),
          chatbot_id: getValue(resp, `data.chatbot_id`, ""),
          account_id: getValue(resp, `data.account_id`, ""),
          instruction: getValue(resp, `data.instruction`, ""),
          type: getValue(resp, `data.type`, ""),
          config: {
            inputs:
              getValue(resp, `data.actions_config.inputs.length`, 0) > 0
                ? getValue(resp, `data.actions_config.inputs`, [])
                : [],
            headers:
              getValue(resp, `data.actions_config.headers.length`, 0) > 0
                ? getValue(resp, `data.actions_config.headers`, [])
                : [],
            parameters:
              getValue(resp, `data.actions_config.parameters.length`, 0) > 0
                ? getValue(resp, `data.actions_config.parameters`, [])
                : [],
            return_fields:
              getValue(resp, `data.actions_config.return_fields.length`, 0) > 0
                ? getValue(resp, `data.actions_config.return_fields`, [])
                : [],
            body: getValue(resp, `data.actions_config.body`, ""),
            wait_for_response: getValue(
              resp,
              `data.actions_config.wait_for_response`,
              false
            ),
            is_client: getValue(resp, `data.actions_config.is_client`, false),
            url: getValue(resp, `data.actions_config.url`, ""),
            method: getValue(resp, `data.actions_config.method`, "")
              ? getValue(resp, `data.actions_config.method`, "")
              : "get",
            is_live_response: getValue(
              resp,
              `data.actions_config.is_live_response`,
              false
            ),
          },
        });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const onChange = (key: string | string[]) => {};

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "General",
      children: (
        <CaGeneral
          request={request}
          setRequest={setRequest}
          isLoading={isLoading}
          getActionData={getActionData}
        />
      ),
    },
    {
      key: "2",
      label: "API",
      children: (
        <CaApi
          request={request}
          setRequest={setRequest}
          getActionData={getActionData}
        />
      ),
    },
    {
      key: "3",
      label: "Test Response",
      children: (
        <CaTestResponse
          request={request}
          setRequest={setRequest}
          getActionData={getActionData}
        />
      ),
    },
    {
      key: "4",
      label: "Data Access",
      children: (
        <CaDataAccess
          request={request}
          setRequest={setRequest}
          getActionData={getActionData}
        />
      ),
    },
  ];
  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle={`Custom Action`}
        showBack
        handleNavigateBack={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/chatbot/${getValue(
              params,
              "chatbotId",
              ""
            )}/ai/detail/actions`
          )
        }
        // addButton={
        //   <Button
        //     // onClick={() =>
        //     //   navigate(
        //     //     `/${getValue(params, "orgId", "")}/solutions/article/create`
        //     //   )
        //     // }
        //     size="large"
        //     type="primary"
        //     className="justify-content-end"
        //   >
        //     Create Custom Action
        //   </Button>
        // }
      />
      <div className="custom-action-wrapper">
        <div className="custom-action-left-side">
          <Collapse
            accordion
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </div>
        <div className="custom-action-right-side">Chat demo</div>
      </div>
    </div>
  );
};

export default CustomAction;
