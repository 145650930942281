import { useEffect } from "react";
import { useStateContext } from "./profileProvider";
import { useSocketContext } from "./socketContext";

const useDynamicTitle = (screenTitle) => {
  const { totalInboxCount } = useSocketContext();

  useEffect(() => {
    const inboxCount =
      typeof totalInboxCount === "function"
        ? totalInboxCount()
        : totalInboxCount;

    const inboxPart = inboxCount ? `(${inboxCount}) ` : "";
    document.title = `${inboxPart}${screenTitle}`;
  }, [totalInboxCount, screenTitle]);
};

export default useDynamicTitle;
