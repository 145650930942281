// import { TabContent, TabPane } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "common/query-request-helper";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";

import {
  deleteTask,
  getAllTasks,
  tasksBulkUpdate,
  updateTask,
} from "@services/tasks.service";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import Filter from "@components/Pages/Filter/filter";
import { useStateContext } from "context/profileProvider";
import {
  intialPipelineState,
  intitalFilterViewState,
  sortPipelineFieldList,
  sortTaskFieldList,
} from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import NavViews from "@components/Pages/Pipeline/NavViews";
import NotificationBar from "@components/Pages/Filter/NotificationBar";
import CommonBulkEditPopup from "@components/Pages/Pipeline/EditPopup";
import CommonAddPipeline from "@components/Pages/Pipeline/AddPipeline";
import CommonAddSubPipeline from "@components/Pages/Pipeline/AddSubPipeline";
import CommonAddFilterView from "@components/Pages/Pipeline/AddFilterViews";
import EditFieldsPopup from "@components/Pages/Pipeline/EditFieldsPopup";
import PaginationPage from "@components/common/Pagination/pagination";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import { convertDate } from "@common/date-helpers";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import { handleNavigateURL } from "@common/url-navigate";
import { deleteFilterView } from "@services/filter-views.service";
import CustomTaskDataTable from "@components/custom/Datatable/tasks_table";
import { listAllModules } from "@services/modules.service";
import {
  getAllModuleFields,
  getModuleColumns,
} from "@services/module-fields.service";
import {
  deleteMeeting,
  getAllMeetings,
  meetingsBulkUpdate,
  meetingsSheetUpdate,
  updateMeeting,
} from "@services/meetings.service";
import CustomDataTable from "@components/custom/Datatable";
import { concatString, removeDuplicates } from "@common/text-helpers";
import useDynamicTitle from "@context/useDynamicTitle";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import { Button } from "antd";
import { motion } from "framer-motion";
import CustomSheetTable from "@components/custom/SheetTable";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICallsProps {}

const ActivityMeetings: React.FunctionComponent<ICallsProps> = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const { userId, getUserIDCallback, selectedModuleId, allModules } =
    useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                               Validation Section                           */
  /* -------------------------------------------------------------------------- */

  const simpleValidator1 = useRef(new SimpleReactValidator());
  const simpleValidator2 = useRef(new SimpleReactValidator());

  const [, forceUpdate1] = useState(0);
  const [, forceUpdate2] = useState(0);

  // const headers = [
  //   "owner_id",
  //   "title",
  //   "description",
  //   "priority",
  //   "due_datetime",
  //   "reminder_datetime",
  //   "created_at",
  //   "updated_at",
  //   "created_by",
  //   "updated_by",
  // ];

  useEffect(() => {
    getAllModules();
  }, []);

  // const [headers, setHeaders] = useState([]);
  // const [actualHeaders, setActualHeaders] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const getAllModules = async () => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        getTasks();
        let task_modules = getValue(resp, `data`, []).find(
          (item: object) => getValue(item, `api_name`, "") === "meetings"
        );
        setModuleId(getValue(task_modules, `id`, ""));
        let payload = {
          module_id: getValue(task_modules, `id`, ""),
        };
        let columnsList = await getModuleColumns(
          getValue(task_modules, `id`, "")
        );
        setHeaders(getValue(columnsList, `data`, []));

        let queryRequest = QueryRequestHelper(payload);
        let fields = await getAllModuleFields(getValue(task_modules, `id`, ""));
        if (fields) {
          let list = sortTaskFieldList(getValue(fields, `data`, []));
          for (const item of list) {
            if (getValue(item, `input_type`, "") !== "select_multiple") {
              if (
                typeof getValue(item, `default_value`, null) === "string" ||
                typeof getValue(item, `default_value`, null) === "boolean"
              ) {
                item.value = getValue(item, `default_value`, null);
              }
            } else {
              item.value = [getValue(item, `default_value`, null)];
            }
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          const aDict = list.reduce((dict: any, item: any) => {
            dict[item.name] = item;
            return dict;
          }, {});
          const ReorderedList: any = [];
          // Add elements from b in the order they appear in a
          for (const name of getValue(fields, `data`, [])) {
            if (aDict[name]) {
              ReorderedList.push(aDict[name]);
            }
          }
          // Add elements from a that are not in b to the end
          for (const item of list) {
            if (!getValue(fields, `data`, []).includes(item.name)) {
              ReorderedList.push(item);
            }
          }
          setSelectedFields(removeDuplicates(ReorderedList));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [sheetRequest, setSheetRequest] = useState({});
  const getTasks = async () => {
    try {
      let payload = {
        filter_owner_id: getValue(UrlParams, `filter_owner_id`, ""),
        filter_pipeline_stage_id: getValue(
          UrlParams,
          `filter_pipeline_stage_id`,
          ""
        ),
        module_id: await selectedModuleId("meetings"),
        filter_closing_date: getValue(UrlParams, `filter_closing_date`, ""),
        filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      setListLoading(true);
      let resp = await getAllMeetings(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.meetings`, []));
        setSheetRequest(getValue(resp, `data.meetings`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setListLoading(false);
      } else {
        setList([]);
        setListLoading(false);
      }
    } catch (error) {
      setList([]);
      setListLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  // useEffect(() => {
  //   if (Object.keys(UrlParams).length === 0) {
  //     getAllPipelines();
  //     getUserIDCallback();
  //   }
  // }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      getUserIDCallback();
      if (getValue(UrlParams, `search`, "")) {
        setSearchInput(UrlParams.search);
        setShowSearchTextbox(true);
      }
      if (getValue(UrlParams, `filter_view_id`, "")) {
        setSelectedFilterView(UrlParams.filter_view_id);
      }
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getAllModules();
      // getAllPipelines();
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = React.useState({
    pipeline_id: "",
    stage: "",
  });
  const [stageList, setStageList] = React.useState<any>([]);
  const [selectedIds, setSelectedIds] = useState([]);
  /**
   *
   * Get all Module pipelines
   * @requires
   *
   */

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const getAllPipelines = async () => {
    try {
      setIsLoading(true);
      let payload = {
        module_name: "meetings",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(await selectedModuleId(), queryRequest);
      if (resp) {
        // setPipelineList(
        //   getValue(resp, `data`, []).map((item: object) => ({
        //     id: getValue(item, `id`, ""),
        //     module_name: getValue(item, `module_name`, ""),
        //     module_id: getValue(item, `module_id`, ""),
        //     name: getValue(item, `name`, ""),
        //     label: getValue(item, `name`, ""),
        //     value: getValue(item, `id`, ""),
        //     org_id: getValue(item, `org_id`, ""),
        //   }))
        // );
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `api_name`, ""),
              }))
            : [];
        //**----------------------- creating pipeline options---------------- */
        setOptions(list);
        //**----------------------- Status & filterview section ---------------- */
        if (getValue(UrlParams, `pipeline`, "")) {
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            pipeline_id: getValue(UrlParams, "pipeline", ""),
          }));
          let defaultFilterView =
            getValue(resp, `data.length`, 0) > 0 &&
            getValue(resp, `data`, []).filter(
              (item: any) =>
                getValue(UrlParams, `pipeline`, "") === getValue(item, `id`, "")
            );
          getFilterViews(
            getValue(UrlParams, `pipeline`, ""),
            getValue(defaultFilterView, `[${0}].plural_label`, "")
          );
        } else {
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            pipeline_id: getValue(list, `[${0}].id`, ""),
          }));
          getFilterViews(
            getValue(list, `[${0}].id`, {}),
            getValue(list, `[${0}].plural_label`, {})
          );
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setListLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setListLoading(false);
    }
  };
  /**
   *
   * Get all Filter View
   * @requires
   *
   */
  const [filterViews, setFilterViews] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [selectedFilterView, setSelectedFilterView] = useState("");
  const getFilterViews = async (pipeline_id: string, plural_label: string) => {
    setListLoading(false);
    setSelectedFilterView(getValue(UrlParams, `filter_view_id`, ""));
    getListData(pipeline_id, getValue(UrlParams, `filter_view_id`, ""));

    // try {
    //   let payload = {
    //     pipeline_id: pipeline_id,
    //   };
    //   setFilterLoading(true);
    //   let queryRequest = QueryRequestHelper(payload);
    //   let resp = await getAllFilterViews(queryRequest);
    //   if (resp) {
    //     let obj = [
    //       plural_label
    //         ? {
    //             id: "",
    //             value: plural_label,
    //             name: `All ${formatString(plural_label)}`,
    //           }
    //         : null,
    //     ];
    //     let views = getValue(resp, `data`, []);
    //     const concatenatedArray: any = obj.concat(views);
    //     if (
    //       getValue(views, `length`, 0) + 1 ===
    //       getValue(concatenatedArray, `length`, 0)
    //     ) {
    //       setFilterViews(concatenatedArray);
    //       setFilterLoading(false);
    //       if (getValue(UrlParams, `filter_view_id`, "")) {
    //         setSelectedFilterView(getValue(UrlParams, `filter_view_id`, ""));
    //         getListData(pipeline_id, getValue(UrlParams, `filter_view_id`, ""));
    //       } else {
    //         setSelectedFilterView(getValue(resp, `data[${0}].id`, ""));
    //         getListData(pipeline_id, getValue(resp, `data[${0}].id`, ""));
    //       }
    //     }
    //   } else {
    //     setFilterLoading(false);
    //     setListLoading(false);
    //   }
    // } catch (error) {
    //   setFilterLoading(false);
    //   setListLoading(false);
    // }
  };

  /**
   *
   * Get selected pipeline list
   * @requires id
   *
   */

  const [listLoading, setListLoading] = useState(true);
  const [list, setList] = useState([]);
  const getListData = async (id: string, filter_view_id: string) => {
    if (typeof id === "string") {
      try {
        let payload = {
          pipeline_id: id,
          filter_owner_id: getValue(UrlParams, `filter_owner_id`, ""),
          filter_pipeline_stage_id: getValue(
            UrlParams,
            `filter_pipeline_stage_id`,
            ""
          ),
          filter_closing_date: getValue(UrlParams, `filter_closing_date`, ""),
          filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
          page_size: getValue(UrlParams, `limit`, "")
            ? getValue(UrlParams, `limit`, "")
            : limit,
          page_no: getValue(UrlParams, `page_no`, "")
            ? getValue(UrlParams, `page_no`, "")
            : page_no,
          search: getValue(UrlParams, `search`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        setListLoading(true);
        let resp = await getAllMeetings(queryRequest);
        if (resp) {
          setList(getValue(resp, `data.meetings`, []));
          setTotalCount(getValue(resp, `data.pagination.total`, 0));
          setListLoading(false);
        } else {
          setList([]);
          setListLoading(false);
        }
      } catch (error) {
        setList([]);
        setListLoading(false);
      }
    }
  };

  const handleChangePipeline = (e: any) => {
    delete UrlParams.pipeline;
    let payload = {
      ...UrlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleClickPipeline = (option: any) => {
    delete UrlParams.filter_pipeline_stage_id;
    let payload = {
      ...UrlParams,
      pipeline: getValue(option, `id`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                     Helper Functions Section                               */
  /* -------------------------------------------------------------------------- */

  const findSelectedPipeline = (id: string) => {
    let list =
      getValue(options, `length`, 0) > 0
        ? options.filter((item: object) => getValue(item, `id`, "") === id)
        : [];
    return getValue(list, `length`, 0) > 0 ? getValue(list, `[${0}]`, "") : {};
  };
  const getSelectedPipeline = useMemo(
    () => findSelectedPipeline(getValue(staticFieldRequest, `pipeline_id`, "")),
    [getValue(staticFieldRequest, `pipeline_id`, "")]
  );

  /* -------------------------------------------------------------------------- */
  /*                     Other Functions Section                                */
  /* -------------------------------------------------------------------------- */

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const filterNavigationArray: any = [];
  const subMenuArray: any = [];

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (UrlParams.search) {
      delete UrlParams.search;
      let payload: any = { ...UrlParams };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      // getData();
    }
  };
  const handleChangeSearch = async (e: any) => {
    const regex = /^[a-zA-Z0-9@.]*$/;
    if (regex.test(e.target.value)) {
      setSearchInput(e.target.value);
      let payload: any = {
        ...UrlParams,
        search: e.target.value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      if (!e.target.value) {
        // getData();
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Edit section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const openEditPopup = () => {
    setIsOpenEditPopup(!isOpenEditPopup);
  };
  const [selectedEditOption, setSelectedEditOption] = useState("");
  const [editRequest, setEditRequest] = useState<any>({
    stage: "",
    owner_id: "",
  });
  const [editLoading, setEditLoading] = useState(false);
  const handleOpenFilter = (value: string) => {
    setSelectedEditOption(value);
    openEditPopup();
  };
  const handleSubmitEdit = async () => {
    try {
      setEditLoading(true);
      let resp = await meetingsBulkUpdate({
        ids: selectedIds,
        data: {
          [selectedEditOption]: editRequest[selectedEditOption],
        },
      });
      if (resp) {
        toast.success("Updated Succssfully");
        setSelectedIds([]);
        openEditPopup();
        getListData(
          getValue(staticFieldRequest, `pipeline_id`, ""),
          getValue(UrlParams, `filter_view_id`, "")
        );
        setSelectedEditOption("");
        setEditRequest({
          ...editRequest,
          stage: "",
          owner_id: "",
        });
        setEditLoading(false);
      } else {
        setEditLoading(false);
      }
    } catch (error) {
      setEditLoading(false);
    }
  };

  /* ----------------------------- Delete View Section  ------------------------- */
  const [deleteId1, setDeleteId1] = useState("");
  const [deleteTableValue, setDeleteTableValue] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleDeleteTable = (id: string) => {
    setDeleteId1(id);
    handleModal1();
  };

  const handleDeleteTableRow = async () => {
    try {
      let resp = await deleteMeeting(deleteId1);
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        getListData(
          getValue(staticFieldRequest, `pipeline_id`, ""),
          getValue(UrlParams, `filter_view_id`, "")
        );
        setSelectedIds([]);
        handleModal1();
      }
    } catch (error) {}
  };

  const handleUpdateStatus = async (id: string, status: boolean) => {
    try {
      let resp = await updateMeeting(id, { is_completed: status });
      if (resp) {
        toast.success("Updated successfully");
        getListData(
          getValue(staticFieldRequest, `pipeline_id`, ""),
          getValue(UrlParams, `filter_view_id`, "")
        );
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                          Create Pipline Section                            */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isOpenPipeline, setIsOpenPipeline] = useState(false);
  const togglePipeline = () => {
    setIsOpenPipeline(!isOpenPipeline);
  };
  const [pipelineRequest, setPipelineRequest] = useState(intialPipelineState);

  /* -------------------------------------------------------------------------- */
  /*                       Create SubPipline Section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenSubPipeline, setIsOpenSubPipeline] = useState(false);
  const toggleOpenSubpipeline = () => {
    setIsOpenSubPipeline(!isOpenSubPipeline);
  };
  const [selectedFields, setSelectedFields] = useState([]);
  const [optionLoading, setOptionLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [headers, setHeaders] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [createViewStep, setCreateViewStep] = useState(1);
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const [filterRequest, setFilterRequest] = useState(intitalFilterViewState);
  const [filterViewId, setFilterId] = useState("");
  const handleOpenFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
    setFilterRequest(intitalFilterViewState);
    setFilterId("");
    setCreateViewStep(1);
  };
  const handleNaviagteView = (name: string, value: string) => {
    if (!value) {
      delete UrlParams[name];
      getAllModules();
    }
    navigate(handleNavigateURL(name, value));
  };
  const handleEditFitlerView = (id: string) => {
    setFilterId(id);
    let filters =
      getValue(filterViews, `length`, 0) > 0
        ? filterViews.filter((item: any) => getValue(item, `id`, "") === id)
        : [];
    if (getValue(filters, `length`, 0) > 0) {
      let filterKey = Object.keys(getValue(filters, `[${0}].filter_query`, ""));
      let selectedField: any = selectedFields.filter(
        (item: any) => item.name === getValue(filterKey, `[${0}]`, "")
      );
      setFilterRequest({
        ...filterRequest,
        name: getValue(filters, `[${0}].name`, ""),
        description: getValue(filters, `[${0}].description`, ""),
        is_private: getValue(filters, `[${0}].is_private`, ""),
        filter_name: getValue(selectedField, `[${0}].name`, ""),
        filter_value: getValue(
          filters,
          `[${0}].filter_query[${filterKey[0]}]`,
          ""
        ),
        filter_obj:
          filterKey.length > 0
            ? {
                ...getValue(selectedField, `[${0}]`, ""),
                name: getValue(selectedField, `[${0}].name`, ""),
                lookup_api: getValue(selectedField, `[${0}].lookup_api`, ""),
                type: getValue(selectedField, `[${0}].type`, ""),
                sub_type: getValue(selectedField, `[${0}].sub_type`, ""),
                picklist_options: getValue(
                  selectedField,
                  `[${0}].picklist_options`,
                  ""
                ),
                [getValue(selectedField, `[${0}].name`, "")]: getValue(
                  filters,
                  `[${0}].filter_query[${filterKey[0]}]`,
                  ""
                ),
              }
            : {},
      });
    }
    setIsOpenFilterView(true);
  };
  //-------------------- delete filter view -------------------------//
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteFilterView = async () => {
    try {
      let resp = await deleteFilterView(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        delete UrlParams.filter_view_id;
        let payload = {
          ...UrlParams,
        };
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
        handleModal();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [editFieldsOpen, setEditFieldsOpen] = useState(false);
  const handleEditFieldsModal = () => {
    setEditFieldsOpen(!editFieldsOpen);
  };
  /* -------------------------------------------------------------------------- */
  /*                  Hide Body Scroll When Modal Open                          */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (isOpenFilterView || isOpenSubPipeline) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpenFilterView, isOpenSubPipeline]);
  /* -------------------------------------------------------------------------- */
  /*                                 Module Info                                */
  /* -------------------------------------------------------------------------- */
  const findModuleType = (id: string) => {
    let info =
      getValue(allModules, `length`, 0) > 0
        ? allModules.find((item: object) => getValue(item, `id`, "") == id)
        : [];
    return info;
  };
  const moduleInfo = useMemo(
    () => findModuleType(moduleId),
    [allModules, moduleId]
  );
  useDynamicTitle(
    getValue(moduleInfo, `linkText`, "")
      ? getValue(moduleInfo, `linkText`, "")
      : "Meetings"
  );

  /* -------------------------------------------------------------------------- */
  /*                                Sheet view                                  */
  /* -------------------------------------------------------------------------- */
  const [currentView, setCurrentView] = useState("list");
  const [patchRequest, setPatchRequest] = useState({ meetings: [] });

  const [sheetLoading, setSheetLoading] = useState(false);
  const handleSheetSubmit = async () => {
    try {
      setSheetLoading(true);
      const payload = {
        meetings: patchRequest.meetings,
      };
      let resp = await meetingsSheetUpdate(payload);
      if (resp) {
        toast.success("Updated Successfully");
        setSheetLoading(false);
        setPatchRequest({ meetings: [] });
        // navigate(`/${getValue(params, "orgId", "")}/crm/contacts`);
        getListData(
          getValue(staticFieldRequest, `pipeline_id`, ""),
          getValue(UrlParams, `filter_view_id`, "")
        );
      } else {
        setSheetLoading(false);
      }
    } catch (error) {
      setSheetLoading(false);
    }
  };

  const handleSheetCancel = () => {
    setPatchRequest({ meetings: [] });
  };

  return (
    <MainLayout {...props} isLoading={listLoading}>
      <PipelineSubHeader
        subHeaderListTitle="Meetings"
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        subMenuArray={subMenuArray}
        options={options}
        selectedPipeline={getSelectedPipeline}
        handleChangePipeline={handleChangePipeline}
        handleClickPipeline={handleClickPipeline}
        hideClose={!getValue(UrlParams, `pipeline`, "") ? true : false}
        isLoading={optionLoading}
        pipeline={false}
        handleOpenPopup={togglePipeline}
      />
      <div className="d-flex">
        <div className={`manageFieldRight w-100`}>
          <NavViews
            views={filterViews}
            isLoading={filterLoading}
            handleOpenFilterView={handleOpenFilterView}
            selectedFilterView={selectedFilterView}
            handleNaviagteView={handleNaviagteView}
            handleEditFitlerView={handleEditFitlerView}
            handleDeleteView={handleDeleteView}
            plural_label={"Meetings"}
          />
          {/* <TabContent activeTab={activeTab}>
            <TabPane tabId="1"> */}
          <div>
            <div className="px-5">
              <div
                className={`content ${
                  getValue(selectedIds, `length`, 0) > 0 ? "open" : "closed"
                }`}
              >
                <NotificationBar
                  openEditPopup={openEditPopup}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  handleOpenFilter={handleOpenFilter}
                  handleDeleteTable={handleDeleteTable}
                  hideStage
                />
              </div>
              <div
                className={`other-content subheader_filter_section ${
                  getValue(selectedIds, `length`, 0) === 0 ? "open" : "closed"
                }`}
              >
                <Filter
                  isStatusVisible={true}
                  filterNavigationArray={filterNavigationArray}
                  isFilterVisibile={true}
                  isCreateDealVisible={true}
                  setVisisbility={setIsOpenSubPipeline}
                  SubPiplineTitle={concatString(
                    getValue(moduleInfo, `label_singular`, ""),
                    20
                  )}
                  stageList={stageList}
                  //search section
                  searchInput={search}
                  showSearchTextbox={showSearchTextbox}
                  toggleSearch={toggleSearch}
                  toggleSearchClose={toggleSearchClose}
                  handleChangeSearch={handleChangeSearch}
                  module={"meetings"}
                  selectedPipeline={getSelectedPipeline}
                  getListData={getListData}
                  hideStage
                  handleEditFieldsModal={handleEditFieldsModal}
                  moduleId={moduleId}
                  hideUpload
                  // hideEditColumns
                  permissions={getValue(props, `permissions`, [])}
                  //current view
                  currentView={currentView}
                  setCurrentView={setCurrentView}
                />
              </div>
            </div>
            {!getValue(UrlParams, `sheet`, "") ? (
              <CustomDataTable
                headers={headers}
                data={list}
                // setHeaders={setHeaders}
                singular_label={getValue(selectedOption, `singular_label`, "")}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                stopRedirect
                isLoading={listLoading}
                module={"meetings"}
                handleUpdateStatus={handleUpdateStatus}
                add
                handleEditFieldsModal={handleEditFieldsModal}
              />
            ) : (
              <CustomSheetTable
                headers={headers}
                data={list}
                setHeaders={setHeaders}
                singular_label={getValue(selectedOption, `label`, "")}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                module="meetings"
                isLoading={listLoading}
                add
                stageList={stageList}
                selectedPipeline={getSelectedPipeline}
                getListData={getListData}
                sheetRequest={sheetRequest}
                setSheetRequest={setSheetRequest}
                patchRequest={patchRequest}
                setPatchRequest={setPatchRequest}
                fields={selectedFields}
                isOpenSubPipeline={isOpenSubPipeline}
              />
            )}
          </div>
          {/* </TabPane>
            <TabPane tabId="2"></TabPane>
          </TabContent> */}
          <div className={"table_footer_container"}>
            {totalCount > 10 && getValue(list, `length`, 0) > 0 ? (
              <PaginationPage
                totalCount={totalCount}
                limit={limit}
                pageNumber={page_no}
                handleChangePagination={handleChangePagination}
                handleChangeLimit={handleChangeLimit}
              />
            ) : (
              <p className="show-rows-count mt-3 d-flex">
                {/* Total Count:&nbsp;&nbsp;{" "}
                <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p> */}
              </p>
            )}
          </div>

          {/* ------------------------- Edit Table Section --------------------------------- */}

          <CommonBulkEditPopup
            isOpen={isOpenEditPopup}
            handleModal={openEditPopup}
            request={editRequest}
            setRequest={setEditRequest}
            stageList={stageList}
            selectedEditOption={selectedEditOption}
            setSelectedEditOption={setSelectedEditOption}
            isLoading={editLoading}
            handleSubmit={handleSubmitEdit}
            permissions={getValue(props, `permissions`, [])}
          />
          <DeleteModal
            isOpen={isOpen1}
            handleModal={handleModal1}
            handleSubmit={handleDeleteTableRow}
            deleteValue={deleteTableValue}
          />
          {/* ------------------------- Creating Pipelines --------------------------------- */}
          <CommonAddPipeline
            isOpen={isOpenPipeline}
            toggle={togglePipeline}
            title={"Create Meeting"}
            simpleValidator={simpleValidator}
            forceUpdate={forceUpdate}
            request={pipelineRequest}
            setRequest={setPipelineRequest}
            module_id={getValue(selectedOption, `module_id`, "")}
            module={"meetings"}
          />

          {/* ------------------------- Creating Sub Pipelines ------------------------------ */}
          <CommonAddSubPipeline
            isOpen={isOpenSubPipeline}
            toggle={toggleOpenSubpipeline}
            singular_label={"Meeting"}
            fields={selectedFields}
            setFields={setSelectedFields}
            simpleValidator={simpleValidator1}
            forceUpdate={forceUpdate1}
            module={"meetings"}
            staticFieldRequest={staticFieldRequest}
            setStaticFieldRequest={setStaticFieldRequest}
            width={"580px"}
            permissions={getValue(props, `permissions`, [])}
            getAllPipelines={getAllModules}
            pipeline_id={getValue(getSelectedPipeline, `id`, "")}
          />

          {/* ------------------------- Creating/Editing Filter View ------------------------- */}
          {isOpenFilterView && (
            <CommonAddFilterView
              isOpenFilterView={isOpenFilterView}
              module={"meetings"}
              handleOpenFilterView={handleOpenFilterView}
              selectedOption={selectedOption}
              getData={getAllPipelines}
              permissions={["create"]}
            />
          )}
          <DeleteModal
            isOpen={isOpen}
            handleModal={handleModal}
            handleSubmit={handleDeleteFilterView}
            deleteValue={deleteValue}
          />
          {/* ------------------------- Editing Table Fields ------------------------- */}
          <EditFieldsPopup
            isOpen={editFieldsOpen}
            toggle={handleEditFieldsModal}
            fields={selectedFields}
            setFields={setSelectedFields}
            id={getValue(selectedOption, `id`, "")}
            headers={headers}
            setHeaders={setHeaders}
            moduleId={moduleId}
            module={"meetings"}
            permissions={getValue(props, `permissions`, [])}
            // getData={getData}
          />
        </div>
      </div>
      {getValue(patchRequest, "meetings.length", "") !== 0 && (
        <motion.div
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          exit={{
            y: 50,
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" },
          }}
        >
          <div className={"sheet_footer_container"}>
            <div className={"button_container"}>
              <div className="d-flex align-items-center gap-2">
                <Button onClick={() => handleSheetCancel()}>Cancel</Button>

                {getValue(props, `permissions`, []).includes("update") && (
                  <Button
                    type="primary"
                    loading={sheetLoading}
                    onClick={() => handleSheetSubmit()}
                    disabled={
                      getValue(patchRequest, "meetings.length", "") === 0
                    }
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
            <p className={"text"}>You have unsaved changes</p>
          </div>
        </motion.div>
      )}
    </MainLayout>
  );
};

export default ActivityMeetings;
