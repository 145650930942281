import { AuthRoutes } from "./private/auth.routes";
import { DashboardRoutes } from "./private/dashboard.routes";
// import { DispatchRoutes } from "./private/dispatch.routes";
// import { MoreRoutes } from "./private/more.routes";
import { OrganizationRoutes } from "./private/orgnization.routes";
// import { TaskRoutes } from "./private/tasks.route";
import { MarketingRoutes } from "./private/marketing.routes";
import { ChatbotRoutes } from "./private/chatbot.routes";
import { PropertyRoutes } from "./private/property.routes";
import { SettingsAdminRoutes } from "./private/settings/admin.routes";
import { SettingsEmailTemplatesRoutes } from "./private/settings/email-templates.routes";
import { SettingsEmailsRoutes } from "./private/settings/emails.routes";
import { SettingsFormsRoutes } from "./private/settings/forms.routes";
import { SettingsModulesAndFieldsRoutes } from "./private/settings/modules-fields.routes";
import { SettingsRolesRoutes } from "./private/settings/roles.routes";
import { SettingsUsersRoutes } from "./private/settings/users.routes";
import { SettingsWorkflowsRoutes } from "./private/settings/workflows.routes";
import { CrmLeadsRoutes } from "./private/crm/leads.routes";
import { CrmContactsRoutes } from "./private/crm/contacts.routes";
import { CrmCompaniesRoutes } from "./private/crm/companies.routes";
import { CrmDelasRoutes } from "./private/crm/deals.routes";
import { CrmTaskRoutes } from "./private/crm/tasks.routes";
import { CrmMeetingsRoutes } from "./private/crm/meetings.routes";
import { CrmCallsRoutes } from "./private/crm/calls.routes";
import { SettingsAdsRoutes } from "./private/settings/ads.routes";
import { HomeRoutes } from "./private/home.routes";
import { SettingsCannedRoutes } from "./private/settings/canned-response.routes";
import { TicketRoutes } from "./private/ticket.routes";
import { SettingsBusinessHoursRoutes } from "./private/settings/business-hours.routes";
import { SettingsSLAPolicyRoutes } from "./private/settings/sla-policy.routes";
import { SettingsGroupsRoutes } from "./private/settings/groups.routes";
import { SettingsAgentShiftsRoutes } from "./private/settings/agent-shifts.routes";
import { SettingsProductsRoutes } from "./private/settings/products.routes";
import { SolutionsRoutes } from "./private/solutions.routes";
import { AiChatbotRoutes } from "./private/ai-chatbot.routes";
import { SettingsSkillsRoutes } from "./private/settings/skills.roues";
// import { CommonRoutes } from "./public/common.routes";

export const PrivateRoutes = [
  ...DashboardRoutes,
  // ...CommonRoutes,
  ...AiChatbotRoutes,
  ...HomeRoutes,
  ...OrganizationRoutes,
  ...AuthRoutes,
  ...MarketingRoutes,
  ...ChatbotRoutes,
  ...PropertyRoutes,
  ...TicketRoutes,
  ...SolutionsRoutes,

  // ------------  CRM routes ------------- //
  ...CrmLeadsRoutes,
  ...CrmContactsRoutes,
  ...CrmCompaniesRoutes,
  ...CrmDelasRoutes,
  ...CrmTaskRoutes,
  ...CrmMeetingsRoutes,
  ...CrmCallsRoutes,

  // ------------  settings routes --------- //
  ...SettingsAdminRoutes,
  ...SettingsAgentShiftsRoutes,
  ...SettingsBusinessHoursRoutes,
  ...SettingsCannedRoutes,
  ...SettingsEmailTemplatesRoutes,
  ...SettingsEmailsRoutes,
  ...SettingsFormsRoutes,
  ...SettingsGroupsRoutes,
  ...SettingsAdsRoutes,
  ...SettingsProductsRoutes,
  ...SettingsModulesAndFieldsRoutes,
  ...SettingsRolesRoutes,
  ...SettingsSLAPolicyRoutes,
  ...SettingsSkillsRoutes,
  ...SettingsUsersRoutes,
  ...SettingsWorkflowsRoutes,
];
