import { Button, Tabs, TabsProps } from "antd";
import { useState } from "react";
import FilesTab from "./components/FilesTab";
import TextTab from "./components/TextTab";
import WebsiteTab from "./components/WebsiteTab";
import QandATab from "./components/QandATab";
import KnowledgeBaseTab from "./components/KnowledgeBaseTab";
import SourcesCard from "./components/SourcesCard";
import AIAgentLayout from "../../Layout";

const AIAgentTrainingData = (props: any) => {
  const [request, setRequest] = useState<any>([]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Files ",
      children: (
        <div className="training-data-container">
          <FilesTab />
          <SourcesCard request={request} />
        </div>
      ),
    },
    {
      key: "2",
      label: "Text",
      children: (
        <div className="training-data-container">
          <TextTab />
          <SourcesCard request={request} />
        </div>
      ),
    },
    {
      key: "3",
      label: "Website",
      children: (
        <div className="training-data-container">
          <WebsiteTab request={request} setRequest={setRequest} />
          <SourcesCard request={request} />
        </div>
      ),
    },
    {
      key: "4",
      label: "Q&A",
      children: (
        <div className="training-data-container">
          <QandATab request={request} setRequest={setRequest} />
          <SourcesCard request={request} />
        </div>
      ),
    },
    {
      key: "5",
      label: "Knowledge Base",
      children: (
        <div className="training-data-container">
          <KnowledgeBaseTab />
          <SourcesCard request={request} />
        </div>
      ),
    },
  ];

  return (
    <AIAgentLayout>
      <Tabs defaultActiveKey="1" items={items} tabPosition="left" />
    </AIAgentLayout>
  );
};

export default AIAgentTrainingData;
