import { handleTextChange } from "@common/handleChange";
import { getValue } from "@utils/lodash";
import { Badge, Button, Divider, Input, Select, Tabs, TabsProps } from "antd";
import CaAPITable from "./components/table";
import CaAPIHeaderPair from "./components/headers";
import JsonEditor from "./components/jsoneditor";
import CaAPIParameterPair from "./components/parameters";
import { memo, useState } from "react";
import { updateAgent } from "@services/agent-actions.service";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
const methods = [
  {
    label: "GET",
    value: "get",
  },
  {
    label: "POST",
    value: "post",
  },
  {
    label: "PUT",
    value: "put",
  },
  {
    label: "DELETE",
    value: "delete",
  },
];

const CaApi = ({ request, setRequest,getActionData }: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const params = useParams();
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div className="d-flex align-items-center gap-2">
          Parameters
          <Badge
            className="site-badge-count-109"
            count={getValue(request, `config.parameters.length`, 0)}
            style={{ backgroundColor: "#000" }}
          />
        </div>
      ),
      children: (
        <CaAPIParameterPair request={request} setRequest={setRequest} />
      ),
    },
    {
      key: "2",
      label: (
        <div className="d-flex align-items-center gap-2">
          Headers
          <Badge
            className="site-badge-count-109"
            count={getValue(request, `config.headers.length`, 0)}
            style={{ backgroundColor: "#000" }}
          />
        </div>
      ),
      children: <CaAPIHeaderPair request={request} setRequest={setRequest} />,
    },
    {
      key: "3",
      label: "Body",
      children: <JsonEditor request={request} setRequest={setRequest} />,
      disabled:
        getValue(request, `config.method`, "") !== "post" ? true : false,
    },
  ];

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSaveAPI = async () => {
    if (getValue(UrlParams, `action_id`, "")) {
      try {
        setSubmitLoading(true);
        let reqPayload: any = JSON.parse(JSON.stringify(request));
        delete reqPayload["config"]["is_live_response"];
        let payload = {
          ...reqPayload,
          config: removeNullOrUndefinedProperties(
            getValue(reqPayload, `config`, {})
          ),
        };
        let resp = await updateAgent(
          getValue(UrlParams, `action_id`, ""),
          removeNullOrUndefinedProperties(payload)
        );
        if (resp) {
          toast.success("Action updated successfully");
          navigate(
            `/${getValue(params, "orgId", "")}/chatbot/${getValue(
              params,
              "chatbotId",
              ""
            )}/ai/detail/custom-action/create?action_id=${getValue(
              resp,
              `data.id`,
              ""
            )}`
          );
          getActionData()
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    } else {
      toast.error("Please save general config to continue!");
    }
  };
  return (
    <div className="p-4">
      <div className="d-flex justify-content-between align-items-center gap-4">
        <div>
          <label className="form-label mt-0">
            Collect data inputs from user (optional)
          </label>
          <p className="small_text__14">
            List any information the AI Agent needs from the user to perform the
            action.
          </p>
        </div>
      </div>
      <CaAPITable request={request} setRequest={setRequest} />
      <Divider />
      <div>
        <label className="form-label mt-0">API request</label>
        <p className="small_text__14">
          The API endpoint that should be called by the AI Agent to retrieve
          data or to send updates. You can include data inputs (variables)
          collected from the user in the URL or the request body.
        </p>
      </div>
      <div className="d-flex align-items-center gap-2 mt-3">
        <Select
          defaultValue="GET"
          style={{ width: 100 }}
          //   onChange={handleChange}
          options={methods}
          value={getValue(request, `config.method`, "")}
          onChange={(e: any) => {
            setRequest({
              ...request,
              config: {
                ...request.config,
                method: e,
              },
            });
          }}
        />
        <Input
          placeholder="https://api.example.com/endpoint/{{item_id}}"
          value={getValue(request, `config.url`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              config: {
                ...request.config,
                url: e.target.value,
              },
            })
          }
          name="url"
        />
        {/* <Button
            icon={<Plus size={16} />}
            // onClick={() => handleAddDataInput()}
          >
            Add Variable
          </Button> */}
      </div>
      <Tabs
        type="card"
        defaultActiveKey="1"
        items={items}
        // onChange={onChange}
        className="mt-4"
      />

      <Button
        onClick={() => handleSaveAPI()}
        size="large"
        type="primary"
        disabled={submitLoading}
      >
        Save and Continue
      </Button>
    </div>
  );
};

export default memo(CaApi);
