import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllProducts = (queryRequest: string) =>
  get(`${config.API_URL}/products?${queryRequest}`);

export const getSpecificProduct = (id: string) =>
  get(`${config.API_URL}/products/${id}`);

export const getAllProductsList = (queryRequest: string) =>
  get(`${config.API_URL}/products/list?${queryRequest}`);

export const createProduct = (payload: object) =>
  post(`${config.API_URL}/products`, payload);

export const updateProduct = (id: string, payload: object) =>
  patch(`${config.API_URL}/products/${id}`, payload);

export const deleteProduct = (id: string) =>
  Delete(`${config.API_URL}/products/${id}`);
