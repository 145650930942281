import { handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import LoaderMain from "@components/common/Loader/loading1";
import { updateWebSearchAgentAction } from "@services/agent-actions.service";
import { getValue } from "@utils/lodash";
import { Button, Switch } from "antd";
import { useEffect, useState } from "react";
import { toast } from "sonner";

const WSGeneral = (props: any) => {
  const { request, setRequest, isLoading, getActionData } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSave = async () => {
    try {
      setSubmitLoading(true);
      let payload = { ...request };
      // Convert the newline-separated string back to array before sending
      if (payload.config.included_domains) {
        payload.config.included_domains = payload.config.included_domains
          .split("\n")
          .map((domain: string) => domain.trim())
          .filter((domain: string) => domain.length > 0);
      }
      let resp;
      if (getValue(UrlParams, `action_id`, "")) {
        resp = await updateWebSearchAgentAction(
          getValue(UrlParams, `action_id`, ""),
          payload
        );
      }
      if (resp) {
        toast.success(`Action updated successfully`);
        getActionData();
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    // Convert array to newline-separated string when component mounts or domains update
    const domains = getValue(request, `config.included_domains`, []);
    if (Array.isArray(domains) && domains.length > 0) {
      setRequest({
        ...request,
        config: {
          ...request.config,
          included_domains: domains.join("\n"),
        },
      });
    }
  }, []); // Empty dependency array so it only runs on mount

  const handleKeyDown = (e: any) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent space character
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      const currentValue = e.target.value;
      const cursorPosition = e.target.selectionStart;
      const textBeforeCursor = currentValue.substring(0, cursorPosition);
      const textAfterCursor = currentValue.substring(cursorPosition);

      setRequest({
        ...request,
        config: {
          ...request.config,
          included_domains: textBeforeCursor + "\n" + textAfterCursor,
        },
      });

      // Move cursor to the new line
      setTimeout(() => {
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setRequest({
      ...request,
      config: {
        ...request.config,
        included_domains: value,
      },
    });
  };

  return isLoading ? (
    <LoaderMain />
  ) : (
    <>
      <div className="px-4 pb-4 d-grid gap-3">
        <p className="small_text__14">
          Explain when the AI Agent should use this action. Include a
          description of what this action does, the data it provides, and any
          updates it makes. Include example queries that should trigger this
          action.
        </p>
        <div>
          <InputRuleForm
            inputType="TEXTAREA"
            placeholder="Example: Use this action to retireve the user's invoice history. example queries: 'Show me my invoice history', 'What are my invoices?'..."
            label="When to Use"
            name="instruction"
            value={getValue(request, "instruction", "")}
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
            validLeft
          />
        </div>

        <div className="d-flex justify-content-between align-items-center mb-3 ">
          <div>
            <h6 className="header_text__16">Include Images</h6>
            <p className="small_text__14">
              Enable to include relevant images in the search results
            </p>
          </div>
          <Switch
            size="small"
            className="ms-2"
            checked={getValue(request, `config.images_enabled`, false)}
            onChange={(e: any) =>
              setRequest({
                ...request,
                config: {
                  ...request.config,
                  images_enabled: !getValue(
                    request,
                    `config.images_enabled`,
                    false
                  ),
                },
              })
            }
          />
        </div>

        <div className="space-y-2">
          <label htmlFor="domains">Enter Domains (press Enter to add)</label>
          <InputRuleForm
            inputType="TEXTAREA"
            id="domains"
            value={getValue(request, `config.included_domains`, "")}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className="w-full min-h-32 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter domains here..."
          />
        </div>
      </div>

      <Button
        onClick={() => handleSave()}
        size="large"
        type="primary"
        disabled={submitLoading}
      >
        Save
      </Button>
    </>
  );
};

export default WSGeneral;
