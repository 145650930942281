import { getValue } from "@utils/lodash";
import DocumentFormatPreviews from "./doc-formats-preview";
import DocumentSvgComponent from "@assets/svg/document";
import ArrowdownSvgComponent from "@assets/svg/arrowdown";
import DocumentFormatReplyPreviews from "./doc-formats-reply-preview";
import { AnimatePresence, motion } from "framer-motion";
import { handleDownloadOnClick } from "@common/downloadfile";
import { config } from "env";
import { Dropdown, MenuProps, Space } from "antd";
import { formatMarkdownText } from "@components/MarkdownEditor/helpers/textFormatter";

function ChatReceiverSection(props: any) {
  const { item, setSelectedReplyMessage } = props;

  const items: any = [
    {
      key: "1",
      label: (
        <div
          onClick={() => setSelectedReplyMessage(item)}
          className=" small_text__13 d-flex gap-2 align-items-center"
        >
          <img src="/images/icons/whatsapp-reply.svg" />
          Reply
        </div>
      ),
    },
    ...(item.type === "image" ||
    item.type === "video" ||
    item.type === "document"
      ? [
          {
            type: "divider",
          },
          {
            key: "2",
            label: (
              <div
                className="small_text__13 d-flex gap-2 align-items-center"
                onClick={() =>
                  handleDownloadOnClick(
                    `https://${config.URL}/${item.upload_file.key}`,
                    item.upload_file.filename
                  )
                }
              >
                <img
                  src="/images/icons/whatsapp-download.svg"
                  alt="Download"
                  className="download-icon"
                  width={16}
                  height={16}
                />
                Download
              </div>
            ),
          },
        ]
      : []),
  ];
  return (
    <div>
      <div className="reciever-details-wrapper">
        <div className="reciever-details">
          <div className="reciever-right-section">
            <p className="reciever-message ">
              <div className="arrow-container">
                <Dropdown menu={{ items }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <ArrowdownSvgComponent color={"gray"} class="arrow" />
                    </Space>
                  </a>
                </Dropdown>
              </div>
              {getValue(item, `type`, "") === "unsupported" && (
                <p className="small_text__13 px-2 py-2 color_gray">
                  Message format is not supported.
                </p>
              )}
              {getValue(item, `message`, "") && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: formatMarkdownText(getValue(item, `message`, "")),
                  }}
                  className="small_text__14 px-2 py-1"
                />
              )}
              {getValue(item, `replied_message.id`, "") && (
                <AnimatePresence mode="wait">
                  <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, minHeight: "56px" },
                      collapsed: { opacity: 0 },
                    }}
                    transition={{
                      duration: 0.8,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <div className="reply_message_container mb-2 reciever-replay w-100">
                      <div className="d-flex align-items-center ms-2 justify-content-between w-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <DocumentSvgComponent />
                          {/* <p className="small_text__14 px-2">
                            {getValue(item, `replied_message.media_caption`, "")
                              ? getValue(
                                  item,
                                  `replied_message.media_caption`,
                                  ""
                                )
                              : getValue(item, `replied_message.text_body`, "")}
                          </p> */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: formatMarkdownText(
                                getValue(item, `message`, "")
                                  ? getValue(item, `message`, "")
                                  : getValue(
                                      item,
                                      `replied_message.media_caption`,
                                      ""
                                    )
                                  ? getValue(
                                      item,
                                      `replied_message.media_caption`,
                                      ""
                                    )
                                  : getValue(
                                      item,
                                      `replied_message.text_body`,
                                      ""
                                    )
                              ),
                            }}
                            className="small_text__14 px-2 py-1"
                          >
                            {/* {getValue(
                              item,
                              `replied_message.media_caption`,
                              ""
                            ) ||
                              (getValue(
                                item,
                                `replied_message.text_body`,
                                ""
                              ) ? (
                                <>

                                
                                  {replaceDefaultPlaceholders(
                                    getValue(
                                      item,
                                      `replied_message.media_caption`,
                                      ""
                                    )
                                      ? getValue(
                                          item,
                                          `replied_message.media_caption`,
                                          ""
                                        )
                                      : getValue(
                                          item,
                                          `replied_message.text_body`,
                                          ""
                                        )
                                  )}
                                </>
                              ) : (
                                <>
                                  {getValue(
                                    item,
                                    `replied_message.type`,
                                    {}
                                  ) === "image" && "Image"}
                                  {getValue(
                                    item,
                                    `replied_message.type`,
                                    {}
                                  ) === "video" && "Video"}
                                  {getValue(
                                    item,
                                    `replied_message.type`,
                                    {}
                                  ) === "audio" && "Audio"}
                                  {getValue(
                                    item,
                                    `replied_message.type`,
                                    {}
                                  ) === "document" && "Document"}
                                </>
                              ))} */}
                          </p>
                        </div>
                        {getValue(
                          item,
                          `replied_message.upload_file.id`,
                          ""
                        ) && (
                          <DocumentFormatReplyPreviews
                            item={getValue(item, `replied_message`, {})}
                            upload_file={getValue(
                              item,
                              `replied_message.upload_file`,
                              ""
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </motion.section>
                </AnimatePresence>
              )}

              <DocumentFormatPreviews item={item} receiver />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatReceiverSection;
