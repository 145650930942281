import { handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import LoaderMain from "@components/common/Loader/loading1";
import { createAgent, updateAgent } from "@services/agent-actions.service";
import { getValue } from "@utils/lodash";
import { Button, Radio } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const CaGeneral = (props: any) => {
  const { request, setRequest, isLoading, getActionData } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const params = useParams();

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSaveGeneral = async () => {
    try {
      setSubmitLoading(true);
      let payload = {
        name: getValue(request, `name`, ""),
        description: getValue(request, `description`, ""),
        chatbot_id: getValue(request, `chatbot_id`, ""),
        instruction: getValue(request, `instruction`, ""),
      };
      let resp;
      if (getValue(UrlParams, `action_id`, "")) {
        resp = await updateAgent(getValue(UrlParams, `action_id`, ""), payload);
      } else {
        resp = await createAgent(payload);
      }
      if (resp) {
        toast.success(
          `Action ${
            getValue(UrlParams, `action_id`, "") ? "updated" : "created"
          } successfully`
        );
        navigate(
          `/${getValue(params, "orgId", "")}/chatbot/${getValue(
            params,
            "chatbotId",
            ""
          )}/ai/detail/custom-action/create?action_id=${getValue(
            resp,
            `data.id`,
            ""
          )}`
        );
        getActionData()
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  const snakeCase = (str: any) => {
    if (str) return str.replace(/\s+/g, "_").toLowerCase();
  };
  return isLoading ? (
    <LoaderMain />
  ) : (
    <>
      <div className="px-4 pb-4 d-grid gap-3">
        <InputRuleForm
          inputType="TEXT"
          placeholder="A descriptive name for this action. This will help the AI Agent know when to use it."
          required
          label="Action name"
          name="name"
          value={getValue(request, "name", "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              name: snakeCase(e.target.value),
            })
          }
          validLeft
        />
        <div>
          <InputRuleForm
            inputType="TEXTAREA"
            placeholder="Example: Use this action to retireve the user's invoice history. example queries: 'Show me my invoice history', 'What are my invoices?'..."
            label="When to Use"
            name="instruction"
            value={getValue(request, "instruction", "")}
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
            validLeft
          />
          <p className="small_text__14">
            Explain when the AI Agent should use this action. Include a
            description of what this action does, the data it provides, and any
            updates it makes. Include example queries that should trigger this
            action.
          </p>
        </div>
        <Radio.Group
          onChange={() =>
            setRequest({
              ...request,
              is_client: getValue(request, "is_client", false) ? false : true,
            })
          }
          value={getValue(request, "is_client", false)}
          className="d-grid gap-2"
        >
          <Radio value={false}>
            <h6 className="header_text__16">Server Action</h6>
            <p className="small_text__14">
              This action will be executed on the server. There is no need to
              write any client-side code.
            </p>
          </Radio>
          <Radio value={true}>
            <h6 className="header_text__16">Client Action</h6>
            <p className="small_text__14">
              This action will be executed on the client. You will need to write
              some client-side code.
            </p>
          </Radio>
        </Radio.Group>
      </div>
      <Button
        onClick={() => handleSaveGeneral()}
        size="large"
        type="primary"
        disabled={submitLoading}
      >
        Save and Continue
      </Button>
    </>
  );
};

export default CaGeneral;
