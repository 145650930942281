import HomeHeader from "@components/common/Header/HomeHeader/Header";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import { getValue } from "@utils/lodash";
import { Collapse, CollapseProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
// import CaGeneral from "./CaGeneral";
// import CaApi from "./CaAPI";
// import CaTestResponse from "./CaTestResponse";
// import CaDataAccess from "./CaDataAccess";
import { useEffect, useState } from "react";
import {
  getFormAgentAction,
  getSpecificAgent,
  getWebSearchAgentAction,
} from "@services/agent-actions.service";
import {
  caCustomActionRequest,
  caWebSearchActionRequest,
} from "../action-request-helper";
import WSGeneral from "./General";

const WebSearchAction = () => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(UrlParams, `action_id`, "")) {
      getActionData();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [request, setRequest] = useState(caWebSearchActionRequest);
  const [info, setInfo] = useState({});
  const reset = () => {
    setRequest(caWebSearchActionRequest);
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const getActionData = async () => {
    try {
      let resp = await getWebSearchAgentAction(
        getValue(UrlParams, `action_id`, "")
      );
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          description: getValue(resp, `data.description`, ""),
          instruction: getValue(resp, `data.instruction`, ""),
          status: getValue(resp, `data.status`, ""),
          config: {
            images_enabled: getValue(
              resp,
              `data.web_search_action_config.images_enabled`,
              false
            ),
            included_domains:
              getValue(
                resp,
                `data.web_search_action_config.included_domains.length`,
                0
              ) > 0
                ? getValue(
                    resp,
                    `data.web_search_action_config.included_domains`,
                    []
                  )
                    .join("\n")
                    .replace(/,/g, "\n")
                : "",
          },
        });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const onChange = (key: string | string[]) => {
    // console.log(key);
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "General",
      children: (
        <WSGeneral
          request={request}
          setRequest={setRequest}
          isLoading={isLoading}
        />
      ),
    },
  ];
  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle={`Web Search Action`}
        showBack
        handleNavigateBack={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/chatbot/${getValue(
              params,
              "chatbotId",
              ""
            )}/ai/detail/actions`
          )
        }
      />
      <div className="custom-action-wrapper">
        <div className="custom-action-left-side">
          <Collapse
            accordion
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </div>
        <div className="custom-action-right-side">Chat demo</div>
      </div>
    </div>
  );
};

export default WebSearchAction;
