import { config } from "../../env";
import { DeleteWithPayload, get, patch, post } from "../helpers/http-handler";

export const getPropertyBookingprice = (payload: object) =>
  post(`${config.API_URL}/property-booking/price`, payload);

export const createPropertyBooking = (payload: object) =>
  post(`${config.API_URL}/property-booking`, payload);

export const getPropertyBookingDetail = (
  booking_id: string,
  queryRequest: string
) =>
  get(
    `${config.API_URL}/property-booking/${booking_id}/detail?${queryRequest}`
  );

export const getPropertyBookingUsers = (queryRequest: string) =>
  get(`${config.API_URL}/property-booking/users?${queryRequest}`);

export const getSpecificPropertyBookingUsers = (id: string) =>
  get(`${config.API_URL}/property-booking/user/${id}`);

export const updatePropertyBookingUsers = (id: string, payload: object) =>
  patch(`${config.API_URL}/property-booking/user/${id}`, payload);

export const getPropertyBookingFolio = (id: string) =>
  get(`${config.API_URL}/property-booking/folio/${id}`);

//status change APIs

export const recordPayment = (id: string, payload: object) =>
  patch(`${config.API_URL}/property-booking/${id}/record/payment`, payload);

export const propertyBookingCheckin = (id: string) =>
  get(`${config.API_URL}/property-booking/${id}/process/checkin`);

export const propertyBookingCheckout = (id: string) =>
  get(`${config.API_URL}/property-booking/${id}/process/checkout`);

export const deletePropertyBooking = (
  booking_id: string,
  queryRequest: string
) =>
  DeleteWithPayload(
    `${config.API_URL}/property-booking/${booking_id}/detail?${queryRequest}`
  );
