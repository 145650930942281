import { getValue } from "@utils/lodash";
import { Link, useLocation, useParams } from "react-router-dom";
import CompanyProfileSvgComponent from "assets/svg/company-profile";
import AppBrandingSvgComponent from "assets/svg/app-branding";
import ModulesAndFieldsSvgComponent from "assets/svg/modules-fields";
import PersonSvgComponent from "assets/svg/person";
import { useStateContext } from "context/profileProvider";
import DataAdministrationSvgComponent from "@assets/svg/data";
import AdsSvgCompnent from "@assets/svg/ads";
import MoneySvgComponent from "@assets/svg/money";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import SearchEngineSvgComponent from "@assets/svg/search-engine";
import NotificationSvgComponent from "@assets/svg/notification";
import CalendarSvgComponent from "@assets/svg/calender";
import CalenderPlusSvgComponent from "@assets/svg/calender-plus";
import DocumentSvgComponent from "@assets/svg/document";

export default function PropertySettingsNavigation() {
  const params = useParams();
  const location = useLocation();
  let selectedURL = location.pathname.split("/").pop();
  let currentUrl = location.pathname;
  const CompanyNavigationListArray = [
    {
      title: "Property Config",
      linkArray: [
        {
          linkTitle: "Property details",
          linkUrl: `/${getValue(
            params,
            `orgId`,
            ""
          )}/property/details/${getValue(params, "id", "")}/settings`,
          path: "settings",
          image_url: "/images/company_profile.svg",
        },
        // {
        //   linkTitle: "Booking engine",
        //   linkUrl: `/${getValue(params, `orgId`, "")}/settings/branding`,
        //   path: "booking-engine",
        //   image_url: "/images/branding.svg",
        // },
      ],
    },

    // {
    //   title: "Policy",
    //   linkArray: [
    //     {
    //       linkTitle: "All policies",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/users`,
    //       path: "users",
    //       image_url: "/images/modules_fields.svg",
    //     },
    //   ],
    // },
    {
      title: "Tax and Fee ",
      linkArray: [
        {
          linkTitle: "Tax ",
          linkUrl: `/${getValue(
            params,
            `orgId`,
            ""
          )}/property/details/${getValue(params, `id`, "")}/settings/tax`,
          path: "tax",
          image_url: "/images/emails.svg",
        },
        {
          linkTitle: "Group Tax ",
          linkUrl: `/${getValue(
            params,
            `orgId`,
            ""
          )}/property/details/${getValue(params, `id`, "")}/settings/group-tax`,
          path: "group-tax",
          image_url: "/images/emails.svg",
        },
      ],
    },
    {
      title: "Revenue Management",
      linkArray: [
        {
          linkTitle: "Rate Plan",
          linkUrl: `/${getValue(
            params,
            `orgId`,
            ""
          )}/property/details/${getValue(params, `id`, "")}/settings/rate-plan`,
          path: "rate-plan",
          image_url: "/images/emails.svg",
        },
      ],
    },
    {
      title: "Flexible Controls",
      linkArray: [
        // {
        //   linkTitle: "Edit Slot Mode ",
        //   linkUrl: `/${getValue(
        //     params,
        //     `orgId`,
        //     ""
        //   )}/property/details/${getValue(params,`id`,'')}/settings/slot-mode`,
        //   path: "calender",
        //   image_url: "/images/users.svg",
        // },
        {
          linkTitle: "Edit Slot Time",
          linkUrl: `/${getValue(
            params,
            `orgId`,
            ""
          )}/property/details/${getValue(params, `id`, "")}/settings/slot-time`,
          path: "slot-time",
          image_url: "/images/users.svg",
        },
      ],
    },
    // {
    //   title: "Notification",
    //   linkArray: [
    //     {
    //       linkTitle: "Access",
    //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/ads`,
    //       path: "access",
    //       image_url: "/images/ads.svg",
    //     },
    //   ],
    // },
    // {
    //   title: "Configuration",
    //   linkArray: [
    // {
    //   linkTitle: "Folio Config",
    //   linkUrl: `/${getValue(
    //     params,
    //     `orgId`,
    //     ""
    //   )}/property/details/${getValue(params, `id`, "")}/settings/folio`,
    //   path: "folio",
    //   image_url: "/images/ads.svg",
    // },
    // {
    //   linkTitle: "Invoice Config ",
    //   linkUrl: `/${getValue(
    //     params,
    //     `orgId`,
    //     ""
    //   )}/property/details/${getValue(params, `id`, "")}/settings/invoice`,
    //   path: "invoice",
    //   image_url: "/images/ads.svg",
    // },
    // {
    //   linkTitle: "Hotel addons",
    //   linkUrl: `/${getValue(params, `orgId`, "")}/settings/ads`,
    //   path: "adons",
    //   image_url: "/images/ads.svg",
    // },
    // {
    //   linkTitle: "User defined booking sources",
    //   linkUrl: `/${getValue(params, `orgId`, "")}/settings/ads`,
    //   path: "source",
    //   image_url: "/images/ads.svg",
    // },
    // {
    //   linkTitle: "Channel mapping",
    //   linkUrl: `/${getValue(params, `orgId`, "")}/settings/ads`,
    //   path: "mapping",
    //   image_url: "/images/ads.svg",
    // },
    //   ],
    // },
  ];
  const getImages = (name: string, color: string) => {
    switch (name) {
      case "booking-engine":
        return <SearchEngineSvgComponent color={color} />;
      case "module-and-field":
        return <PersonSvgComponent color={color} />;
      case "users":
        return <ModulesAndFieldsSvgComponent color={color} />;
      case "source":
        return <DataAdministrationSvgComponent color={color} />;
      case "tax":
      case "group-tax":
        return <MoneySvgComponent color={color} />;
      case "adons":
        return <AddCircleSvgComponent color={color} />;
      case "access":
        return <NotificationSvgComponent color={color} />;
      case "mapping":
        return <AppBrandingSvgComponent color={color} />;
      case "flexibility-control":
        return <DragIndicatorSvgComponent color={color} />;
      case "folio":
        return <AdsSvgCompnent color={color} />;
      case "calender":
        return <CalendarSvgComponent color={color} />;
      case "slot-time":
        return <CalenderPlusSvgComponent color={color} />;
      case "invoice":
        return <DocumentSvgComponent color={color} />;
      default:
        return <CompanyProfileSvgComponent color={color} />;
    }
  };
  const { orgInfo } = useStateContext();
  return (
    <div className={"company-profile-details-wrapper__navigation"}>
      {CompanyNavigationListArray.map((navObj, index) => {
        return (
          <div
            key={`Company-nav-${index}`}
            className={
              "company-profile-details-wrapper__navigation-container pt-3"
            }
          >
            <div
              className={`d-flex align-items-center justify-content-between`}
            >
              <h6
                className={`company-profile-details-wrapper__navigation-title px-4`}
              >
                {navObj.title}
              </h6>
              <img
                src={`/images/icons/arrow-down.svg`}
                alt="arrow-drop"
                className="cursor-pointer img-fluid px-4"
              />
            </div>
            <ul>
              {navObj.linkArray.map((linkObj, index) => {
                return (
                  <li
                    key={`nav-linkObj-${index}`}
                    className={
                      linkObj.linkUrl === currentUrl
                        ? `company-profile-details-wrapper__navigation-list_active mt-1`
                        : `company-profile-details-wrapper__navigation-list mt-1`
                    }
                  >
                    <Link
                      to={linkObj.linkUrl}
                      className={`company-profile-details-wrapper__navigation-list-link d-flex align-items-center gap`}
                    >
                      {/* <img
                        src={getValue(
                          linkObj,
                          `image_url`,
                          "/images/icons/arrow-down.svg"
                        )}
                        alt="arrow-drop"
                        className="cursor-pointer img-fluid"
                      /> */}
                      {getImages(
                        getValue(linkObj, `path`, ""),
                        selectedURL !== getValue(linkObj, `path`, "")
                          ? "#414141"
                          : getValue(orgInfo, `theme`, "")
                          ? getValue(orgInfo, `theme`, "")
                          : "#D7242E"
                      )}
                      <h6
                        className={`company-profile-details-wrapper__navigation-link-title`}
                      >
                        {linkObj.linkTitle}
                      </h6>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
