import { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { Modal, Select, Switch } from "antd";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import InputRuleForm from "@components/InputRuleForm/form";
import {
  handleAntNestedSelectChange,
  handleTextArrayChange,
  handleTextChange,
} from "@common/handleChange";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import { Trash2 } from "lucide-react";
import "../products.scss";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import { toast } from "sonner";
import {
  createProduct,
  getSpecificProduct,
  updateProduct,
} from "@services/products.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getGroupsList } from "@services/groups.service";
import SimpleReactValidator from "simple-react-validator";

const CreateProducts = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [editId, seteditId] = useState("");
  const [request, setRequest] = useState({
    name: "",
    description: "",
    product_supports: [
      {
        email: "",
        is_primary: true,
        group_id: "",
      },
    ],
  });
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (getValue(params, "id", "")) {
      seteditId(getValue(params, "id", ""));
    }
  }, [params]);

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (editId) {
      getProduct(true);
    }
  }, [editId]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      toast.error(`Please fill all the mandatory fields`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        const resp = editId
          ? await updateProduct(editId, payload)
          : await createProduct(payload);

        if (resp) {
          toast.success(`${editId ? "Updated" : "Created"} Successfully`);

          setRequest({
            name: "",
            description: "",
            product_supports: [
              {
                email: "",
                is_primary: true,
                group_id: "",
              },
            ],
          });
          navigate(
            `/${getValue(params, "orgId", "")}/settings/helpdesk/products`
          );
        } else {
          toast.error(`Failed to ${editId ? "editing" : "creating"} products`);
        }
      } catch (error) {
        toast.error(`Error ${editId ? "editing" : "creating"} products`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getGroups = async () => {
    try {
      setIsLoading1(true);
      const payload = {
        page_size: 10,
        page_no: 1,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getGroupsList(queryRequest);
      if (resp) {
        const modifiedResp = getValue(resp, "data", []).map((item: any) => ({
          value: getValue(item, "id", ""),
          label: getValue(item, "name", ""),
        }));
        setGroups(modifiedResp);
      }
      setIsLoading1(false);
    } catch (error) {
      toast.error("Failed to load groups");
      setIsLoading1(false);
    } finally {
      setIsLoading1(false);
    }
  };

  const getProduct = async (status: boolean) => {
    try {
      setIsLoading2(status);
      const resp = await getSpecificProduct(editId);
      if (resp) {
        const updatedSupports = getValue(resp, "data.product_supports", []).map(
          (support: any) => ({
            ...support,
            group_id: support.group?.id || "",
          })
        );

        setRequest({
          name: getValue(resp, "data.name", ""),
          description: getValue(resp, "data.description", ""),
          product_supports: updatedSupports,
        });
        setIsLoading2(false);
      }
    } catch (error) {
      toast.error("Failed to fetch product.");
      setIsLoading2(false);
    } finally {
      setIsLoading2(false);
    }
  };

  const handleDeleteModal = (index: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this email?",
      content: "Once deleted, this email cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: {
        disabled: isLoading1,
      },
      onOk: () => {
        setRequest((prev) => ({
          ...prev,
          product_supports: prev.product_supports.filter(
            (_, idx) => idx !== index
          ),
        }));
      },
    });
  };

  const handleAddEmail = () => {
    setRequest((prev) => ({
      ...prev,
      product_supports: [
        ...prev.product_supports,
        {
          email: "",
          is_primary: false,
          group_id: "",
        },
      ],
    }));
  };

  const handleSupportEmail = (value: boolean, index: number) => {
    setRequest((prev) => ({
      ...prev,
      product_supports: prev.product_supports.map((item, idx) => ({
        ...item,
        is_primary: idx === index ? value : false,
      })),
    }));
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        backButton
        showSubmit
        titleLoading={isLoading2}
        title={`${editId ? "Edit" : "New"} Product`}
        submitText={`${editId ? "Update" : "Create"} Product`}
        handleSubmit={() => handleSubmit()}
        isLoading={isLoading}
        showCancel
        handleCancel={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/settings/helpdesk/products`
          )
        }
      />
      <div className="manageFieldRightSettings overflow-y-auto p-4">
        <div className="w-50">
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="Enter Product Name"
            required
            label="Product Name (For Own Reference)"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
            validator={simpleValidator}
            validLeft
          />
        </div>
        <FormDescription
          request={request}
          textClassname="my-3"
          inputClassname="w-50 my-2"
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter Description"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        />
        <div>
          <label className="form-label">Product Support Emails</label>
          <div className="w-50 mt-2 d-grid gap-2">
            {getValue(request, "product_supports", []).map(
              (item: any, index: number) => (
                <div className="products-support-card border" key={index}>
                  <div className="w-100 d-grid gap-3 mb-2">
                    <div>
                      <InputRuleForm
                        inputType="EMAIL"
                        name="email"
                        value={getValue(item, "email", "")}
                        placeholder="Enter email"
                        required
                        label="Support Email"
                        onChange={(e: any) =>
                          handleTextArrayChange(
                            index,
                            e,
                            "product_supports",
                            setRequest
                          )
                        }
                        hideFormLabel
                        validator={simpleValidator}
                        validLeft
                      />
                    </div>
                    <label className="form-label d-flex align-items-center gap-3">
                      Primary Support Email{" "}
                      <Switch
                        size="small"
                        checked={getValue(item, "is_primary", false)}
                        onChange={(checked) =>
                          handleSupportEmail(checked, index)
                        }
                        disabled={
                          getValue(request, "product_supports.length", 0) === 1
                        }
                      />
                    </label>
                    <div>
                      <label className="form-label mt-0">Assign to Group</label>
                      <Select
                        value={getValue(item, "group_id", "")}
                        size="large"
                        onChange={(value) =>
                          handleAntNestedSelectChange(
                            value,
                            "group_id",
                            index,
                            "product_supports",
                            setRequest
                          )
                        }
                        allowClear
                        showSearch
                        className="w-100"
                        options={groups}
                      />
                    </div>
                  </div>

                  <button
                    className="reverted-button"
                    disabled={getValue(item, "is_primary", false)}
                    onClick={() => handleDeleteModal(index)}
                  >
                    <Trash2 size={18} />
                  </button>
                </div>
              )
            )}
            <div
              className="d-flex align-items-center mb-3 mt-1 cursor-pointer"
              onClick={handleAddEmail}
            >
              <AddCircleSvgComponent color={"#1a73e8"} />
              <h6 className="header_blue_text__16 ms-2">Add Another Email</h6>
            </div>
          </div>
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default CreateProducts;
