import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";

const FilesTab = ({ props }: any) => {
  return (
    <div className="training-data-cotnent-container">
      <h6 className="header_text__18 mb-3">Files</h6>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Drag & drop files here, or click to select files
        </p>
        <p className="ant-upload-hint">
          Supported File Types: .pdf, .doc, .docx, .txt
        </p>
      </Dragger>
      <p className="small_text__14 pt-3 text-center">
        If you are uploading a PDF, make sure you can select/highlight the text.
      </p>
    </div>
  );
};

export default FilesTab;
