import { Info, Pencil } from "lucide-react";
import React from "react";
import ContentEditor from "../../Articles/components/ContentEditor";
import { Badge, Empty, Tooltip } from "antd";
import { getValue } from "@utils/lodash";

const CategoryDetailsRightSide = (props: any) => {
  const {
    params,
    navigate,
    articleDetails,
    selectedArticle,
    request,
    setRequest,
    isDataFetched,
    handleGetArticleVersions,
  } = props;
  return (
    <div className="w-70 p-4">
      {Object.keys(articleDetails).length > 0 ? (
        <>
          <div className="d-flex align-items-center justify-content-between gap-5 mb-3">
            <div>
              <span className="small_text__14">Tags: </span>
              {getValue(articleDetails, "tags", []).map(
                (tag: string, index: number) => (
                  <span key={index}>{tag}, </span>
                )
              )}
            </div>
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center gap-2">
                <Badge
                  status={
                    getValue(articleDetails, "is_active", false)
                      ? "success"
                      : "error"
                  }
                />
                {getValue(articleDetails, "is_active", false)
                  ? "Active"
                  : "Inactive"}
              </div>
              <div className="d-flex align-items-center gap-2">
                <Badge
                  status={
                    getValue(articleDetails, "is_published", false)
                      ? "success"
                      : "error"
                  }
                />
                {getValue(articleDetails, "is_published", false)
                  ? "Published"
                  : "Unpublished"}
              </div>
              <Tooltip title="Edit">
                <Pencil
                  size={16}
                  onClick={() =>
                    navigate(
                      `/${getValue(
                        params,
                        "orgId",
                        ""
                      )}/solutions/article/edit/${selectedArticle}`
                    )
                  }
                  className="cursor-pointer"
                />
              </Tooltip>
              <Tooltip title="Versions">
                <Info
                  size={18}
                  onClick={() =>
                    handleGetArticleVersions(getValue(articleDetails, "id", ""))
                  }
                  className="cursor-pointer"
                />
              </Tooltip>
            </div>
          </div>
          <ContentEditor
            request={request}
            setRequest={setRequest}
            isDataFetched={isDataFetched}
          />
        </>
      ) : (
        <Empty
          description="Selected an article to continue"
          className="rounded-2 px-4 py-2"
        />
      )}
    </div>
  );
};

export default CategoryDetailsRightSide;
