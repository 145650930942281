import { handleTextChange } from "@common/handleChange";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import InputRuleForm from "@components/InputRuleForm/form";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import { getValue } from "@utils/lodash";
import {
  Alert,
  Button,
  Cascader,
  Divider,
  Input,
  InputRef,
  Select,
  Space,
  Switch,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentEditor from "./components/ContentEditor";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import {
  createArticle,
  getCategories,
  getSpecificArticle,
  updateArticle,
} from "@services/solutions.service";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import { PlusOutlined } from "@ant-design/icons";

let index = 0;

const SolutionsDetail = () => {
  const params = useParams();
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [editId, setEditId] = useState("");
  const [request, setRequest] = useState<any>({
    title: "",
    description: {},
    status: "draft",
    folder_id: "",
    tags: [],
    attachments: [],
  });
  const [folderList, setFolderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [cascaderValue, setCascaderValue] = useState<any>("");

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const [isDataFetched, setDataFetched] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getCategoriesList(true);
    }
  }, []);

  useEffect(() => {
    if (getValue(params, "id", "")) {
      setEditId(getValue(params, "id", ""));
    }
    if (getValue(params, "folder", "")) {
      setRequest((prev: any) => ({
        ...prev,
        folder_id: getValue(params, "folder", ""),
      }));
    }
  }, [params]);

  useEffect(() => {
    if (editId) {
      getArticleDetails(true);
    }
  }, [editId]);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getCategoriesList(true);
    }
  }, [window.location.href]);

  useEffect(() => {
    const findPath = (options: any[], id: string): string[] => {
      for (const option of options) {
        if (option.value === id) return [option.value];
        if (option.children) {
          const childPath = findPath(option.children, id);
          if (childPath.length) return [option.value, ...childPath];
        }
      }
      return [];
    };

    if (request.folder_id) {
      setCascaderValue(findPath(folderList, request.folder_id));
    } else {
      setCascaderValue([]);
    }
  }, [request.folder_id, folderList]);

  /* -------------------------------------------------------------------------- */
  /*                                    API Section                             */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      // toast.error(`Please fill all the mandatory fields`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        const resp = editId
          ? await updateArticle(editId, payload)
          : await createArticle(payload);
        if (resp) {
          toast.success(`${editId ? "Updated" : "Created"} Successfully`);
          setRequest({
            title: "",
            description: {},
            status: "",
            folder_id: "",
            tags: [],
            attachments: [],
          });
          forceUpdate(0);
          simpleValidator.current.hideMessages();
          setIsLoading(false);
          if (editId) {
            window.history.back();
          } else {
            navigate(`/${getValue(params, "orgId", "")}/solutions`);
          }
        } else {
          toast.error(`Failed ${editId ? "updating" : "creating"} Article`);
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(`Error ${editId ? "updating" : "creating"} Article`);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getCategoriesList = async (status: boolean) => {
    try {
      setIsLoading1(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getCategories(queryRequest);
      if (resp) {
        const modifiedList = resp.data.categories
          .filter((item: any) => getValue(item, "folders", []).length > 0)
          .map((item: any) => {
            const folders = getValue(item, "folders", []);
            return {
              value: getValue(item, "id", ""),
              label: getValue(item, "name", ""),
              children: folders.map((folder: any) => ({
                value: getValue(folder, "id", ""),
                label: getValue(folder, "name", ""),
              })),
            };
          });
        setFolderList(modifiedList);
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setIsLoading1(false);
      }
    } catch (error) {
      toast.error("Failed to load folders.");
      setIsLoading1(false);
    } finally {
      setIsLoading1(false);
    }
  };

  const getArticleDetails = async (status: boolean) => {
    try {
      setIsLoading2(status);
      const resp = await getSpecificArticle(getValue(params, "id", ""));
      if (resp) {
        // setCategoriesDetails(getValue(resp, "data", {}));
        setRequest({
          title: getValue(resp, "data.title", ""),
          description: getValue(resp, "data.description", {}),
          status: getValue(resp, "data.status", ""),
          folder_id: getValue(resp, "data.folder_id", ""),
          tags: getValue(resp, "data.tags", ""),
          attachments: getValue(resp, "data.attachments", []),
        });
        setIsLoading2(false);
        setDataFetched(true);
      }
    } catch (error) {
      toast.error("Failed to load category.");
      setIsLoading2(false);
    } finally {
      setIsLoading2(false);
    }
  };

  const subMenuArray = [
    {
      linkText: `Articles`,
      linkUrl: "",
    },
    // {
    //   linkText: getValue(categoryDetails, "name", ""),
    //   linkUrl: window.location.pathname,
    // },
  ];

  const displayRender = (labels: string[]) => {
    return labels.length > 0 ? labels[labels.length - 1] : "";
  };

  const handleFolderSelection = (e: string[]) => {
    const selectedValue = e && e.length > 1 ? e[1] : "";

    setRequest((prev: any) => ({
      ...prev,
      folder_id: selectedValue,
    }));
  };

  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    if (name && !request.tags.includes(name)) {
      setRequest((prev: any) => ({
        ...prev,
        tags: [...prev.tags, name],
      }));
      setName("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleTagsChange = (selectedTags: string[]) => {
    setRequest((prev: any) => ({
      ...prev,
      tags: selectedTags,
    }));
  };

  const handleNavigateBack = () => {
    if (editId || getValue(params, "folder", "")) {
      window.history.back();
    } else {
      navigate(`/${getValue(params, "orgId", "")}/solutions`);
    }
  };

  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle={`Knowledge Base (Support)`}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        subMenuArray={subMenuArray}
        showBack
        handleNavigateBack={handleNavigateBack}
        addButton={
          <div className="d-flex justify-content-end align-items-center gap-2">
            <Button
              size="large"
              onClick={() =>
                navigate(`/${getValue(params, "orgId", "")}/solutions`)
              }
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              size="large"
              type="primary"
              className="justify-content-end"
            >
              {editId ? "Update" : "Create"} Article
            </Button>
          </div>
        }
      />
      <div className={`d-flex w-100`}>
        <div className="manageFieldRight w-100 d-flex">
          <div className="w-70 px-5 py-4">
            <InputRuleForm
              inputType="TEXT"
              name="title"
              value={getValue(request, "title", "")}
              placeholder="Enter Title"
              required
              label="Title"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
              validator={simpleValidator}
              validLeft
            />
            <div>
              <label className="form-label">
                Content<span className=" small_text__16 text-danger">*</span>
              </label>

              <ContentEditor
                request={request}
                setRequest={setRequest}
                isDataFetched={isDataFetched}
              />
              {simpleValidator && (
                <p className={`error-text`}>
                  {simpleValidator.current.message(
                    "Content",
                    getValue(request, "description", []),
                    "required"
                  )}
                </p>
              )}
            </div>
          </div>
          <div className="w-30 p-4 d-flex flex-column gap-3">
            <div>
              <label className="form-label">Status</label>
              <div className="d-flex align-items-center gap-3 mt-1">
                <Switch
                  // size="small"
                  value={
                    getValue(request, "status", "") === "published"
                      ? true
                      : false
                  }
                  onChange={(checked) =>
                    setRequest({
                      ...request,
                      status: checked ? "published" : "draft",
                    })
                  }
                />
                {capitalizeFirstLetter(getValue(request, "status", ""))}
              </div>
            </div>
            <div className="">
              <label className="form-label">Folder</label>
              <Cascader
                size="large"
                placeholder="Select Folder"
                options={folderList}
                expandTrigger="hover"
                displayRender={displayRender}
                onChange={(e) => handleFolderSelection(e)}
                className="w-100"
                value={cascaderValue}
              />
              {simpleValidator && (
                <p className={`error-text mb-2`}>
                  {simpleValidator.current.message(
                    "Folder",
                    getValue(request, "folder_id", ""),
                    "required"
                  )}
                </p>
              )}
              <Alert
                showIcon
                type="info"
                message="Only categories with a folder are displayed."
              />
            </div>
            <div className="">
              <label className="form-label">Tags</label>
              <Select
                size="large"
                mode="multiple"
                className="w-100"
                placeholder="Select or add tags"
                value={request.tags}
                onChange={handleTagsChange}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Input
                        placeholder="Add a new tag"
                        ref={inputRef}
                        value={name}
                        onChange={onNameChange}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addItem}
                      >
                        Add Item
                      </Button>
                    </Space>
                  </>
                )}
                options={request.tags.map((tag: any) => ({
                  label: tag,
                  value: tag,
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsDetail;
