import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";
import DashboardMoreOptions from "../../components/dashboard-more-options";
import { Eye } from "lucide-react";

function KPIGrowthIndexComponent(props: any) {
  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="dashboard-card-heading"
        onClick={() => props.toggleChartDetails(props)}
      >
        {capitalizeFirstLetter(getValue(props, `name`, ""))}
        <Eye size={16} className="dashboard-card-heading-eye" />
      </p>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center ">
          <h6
            className={`standard-highlight-percentage ${
              getValue(props, `compare_objective`, "") !== "positive" //dummy color
                ? "db_color_success"
                : "db_color_negative"
            }`}
          >
            {parseInt(getValue(props, `graph.percentage`, ""))}%
          </h6>
        </div>
        <h6 className="header_text__20 ms-3">
          {getValue(props, `graph.current`, "")}
        </h6>
      </div>
      <div className="d-flex align-items-center">
        <p className="small_text__14 mt-2">
          {capitalizeFirstLetter(getValue(props, `graph.previous_label`, ""))} :{" "}
          {getValue(props, `graph.previous`, "")}
        </p>
      </div>
      <DashboardMoreOptions
        props={props}
        id={getValue(props, "id", "")}
        confirmDeleteAnalyticsComponent={props.confirmDeleteAnalyticsComponent}
        handleEditChart={props.handleEditChart}
      />
    </div>
  );
}

export default KPIGrowthIndexComponent;
