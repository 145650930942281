import ConditionManagerHoc from "../common/ConditionManagerHoc";

const SLATicketCondition = ({
  editId,
  request,
  setRequest,
  conditionList,
  isLoading1,
  validator,
}: any) => {
  return (
    <div className="mt-5">
      <h6 className="header_text__18">
        When a ticket satisfies these conditions:
      </h6>
      <ConditionManagerHoc
        editId={editId}
        request={request}
        setRequest={setRequest}
        conditionList={conditionList}
        isLoading1={isLoading1}
        validator={validator}
        array="sla_conditions"
      />
    </div>
  );
};

export default SLATicketCondition;
