import AccountCircleSvgComponent from "@assets/svg/account-circle";
import { formatString } from "@common/text-helpers";
import MultiLevelSearchToolTip from "@components/custom/Dropdown/MultiLevelSearchTooltip";
import {
  assignConversation,
  assignInboxChatbotConversation,
  resolveConversation,
} from "@services/fb.service";
import { updateWebchatInfo } from "@services/inbox.service";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";

function ChatRightHeader(props: any) {
  const {
    handleToggleWidth,
    selectedContact,
    selectedConversationInfo,
    chatbotList,
    orgUsers,
    setCurrentlyManagedBy,
  } = props;

  const handleSelectMultiLevel = async (level: any, value: any, e: any) => {
    if (getValue(selectedConversationInfo, `source`, "") === "webchat") {
      // handleUpdateStatus("resolved");
      let payload = {
        is_active: true,
        status:
          level === "team"
            ? "un-assigned"
            : level === "user"
            ? "assigned"
            : getValue(selectedConversationInfo, `status`, ""),
        chat_bot_id:
          level === "chat-bot"
            ? value
            : getValue(selectedConversationInfo, `chat_bot_id`, ""),
        org_user_id:
          level === "user"
            ? value
            : level === "team"
            ? ""
            : getValue(selectedConversationInfo, `org_user_id`, ""),
        contact_id: getValue(selectedConversationInfo, `contact_id`, ""),
        name: getValue(selectedConversationInfo, `name`, ""),
        phone: getValue(selectedConversationInfo, `phone`, ""),
        email: getValue(selectedConversationInfo, `email`, ""),
        is_guest: getValue(selectedConversationInfo, `is_guest`, false),
      };
      try {
        let resp = await updateWebchatInfo(
          getValue(selectedConversationInfo, `id`, ""),
          payload
        );
        if (resp) {
          toast.success("Assigned successfully");
          props.getData(
            false,
            getValue(selectedConversationInfo, `id`, ""),
            payload
          );
        }
      } catch (error) {}
    } else {
      let payload = {};
      if (level === "team") {
        payload = {
          managedBy: "team",
          conversationId: getValue(selectedConversationInfo, `id`, ""),
          orgUserId: "",
        };
      } else if (level === "bot") {
        payload = {
          managedBy: "chat-bot",
          conversationId: getValue(selectedConversationInfo, `id`, ""),
          chatBotId: value,
        };
      } else {
        payload = {
          conversationId: getValue(selectedConversationInfo, `id`, ""),
          managedBy: "team",
          orgUserId: value,
        };
      }

      let resp = await assignInboxChatbotConversation(payload);
      if (resp) {
        toast.success("Assigned successfully");
        props.getData(
          false,
          getValue(selectedConversationInfo, `id`, ""),
          payload
        );
      }
    }
  };

  const handleSelectConvesation = async (userId: string) => {
    try {
      let resp = await assignConversation({
        conversationId: getValue(selectedConversationInfo, `id`, ""),
        orgUserId: userId,
      });
      if (resp) {
        toast.success("Assigned successfully");
        props.handleClickFilter("assigned");
      }
    } catch (error) {}
  };

  const handleResolveConvesation = async (userId: string) => {
    try {
      let resp = await resolveConversation({
        conversationId: getValue(selectedConversationInfo, `id`, ""),
        orgUserId: userId,
      });
      if (resp) {
        toast.success("Resolved successfully");
        props.handleClickFilter("resolved");
      }
    } catch (error) {}
  };

  const handleSelectChange = (value: any, option: any) => {
    const key = option.key;
    handleSelectMultiLevel(key, value, option);
  };

  const reopenTeam = async () => {
    let payload = {
      managedBy: "team",
      conversationId: getValue(selectedConversationInfo, `id`, ""),
      orgUserId: "",
    };
    try {
      let resp = await assignInboxChatbotConversation(payload);
      if (resp) {
        toast.success("Assigned successfully");
        props.getData(
          false,
          getValue(selectedConversationInfo, `id`, ""),
          payload
        );
      }
    } catch (error) {}
  };

  const handleUpdateStatus = async (status: string) => {
    let payload = {
      is_active: true,
      status: status,
      chat_bot_id: getValue(selectedConversationInfo, `chat_bot_id`, ""),
      org_user_id:
        status === "un-assigned"
          ? null
          : getValue(selectedConversationInfo, `org_user_id`, ""),
      contact_id: getValue(selectedConversationInfo, `contact_id`, ""),
      name: getValue(selectedConversationInfo, `name`, ""),
      phone: getValue(selectedConversationInfo, `phone`, ""),
      email: getValue(selectedConversationInfo, `email`, ""),
      is_guest: true,
    };
    try {
      let resp = await updateWebchatInfo(
        getValue(selectedConversationInfo, `id`, ""),
        payload
      );
      if (resp) {
        toast.success("Assigned successfully");
        props.getData(
          false,
          getValue(selectedConversationInfo, `id`, ""),
          payload
        );
      }
    } catch (error) {}
  };
  return (
    <div
      className="conversation-header  cursor-pointer"
      onClick={handleToggleWidth}
    >
      <div className="header-right-section">
        <button className="header-name">
          <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
          <div>
            {getValue(selectedContact, `contact.first_name`, "") ||
            getValue(selectedContact, `contact.last_name`, "") ||
            getValue(selectedConversationInfo, `name`, "") ? (
              <p
                className="small_text__14 color_black"
                style={{ textAlign: "left" }}
              >
                {formatString(
                  getValue(selectedContact, `contact.first_name`, "")
                )}{" "}
                {formatString(
                  getValue(selectedContact, `contact.last_name`, "")
                )}{" "}
                {formatString(getValue(selectedConversationInfo, `name`, ""))}
              </p>
            ) : (
              <p
                className="small_text__14 color_black"
                style={{ textAlign: "left" }}
              >
                {formatString(
                  getValue(selectedConversationInfo, `wa_profile_name`, "")
                )}
              </p>
            )}

            <p className="small_text__12 mt-1 color_desc">
              Click here for more contact info
            </p>
          </div>
        </button>
      </div>
      <div className="d-flex align-items-center">
        {getValue(selectedConversationInfo, `status`, "") === "assigned" ? (
          <button
            className="inbox_button"
            onClick={(e: any) => {
              e.stopPropagation();
              if (
                getValue(selectedConversationInfo, `source`, "") === "webchat"
              ) {
                handleUpdateStatus("resolved");
              } else {
                handleResolveConvesation(
                  getValue(selectedConversationInfo, `org_user_id`, "")
                );
              }
            }}
          >
            Mark as Resolved
          </button>
        ) : getValue(selectedConversationInfo, `status`, "") === "resolved" ? (
          <button
            className="inbox_button"
            onClick={(e: any) => {
              e.stopPropagation();
              if (
                getValue(selectedConversationInfo, `source`, "") === "webchat"
              ) {
                handleUpdateStatus("un-assigned");
              } else {
                if (getValue(selectedConversationInfo, `org_user_id`, "")) {
                  handleSelectConvesation(
                    getValue(selectedConversationInfo, `org_user_id`, "")
                  );
                } else {
                  reopenTeam();
                }
              }
            }}
          >
            Reopen
          </button>
        ) : (
          <button
            className="inbox_button"
            onClick={(e: any) => {
              e.stopPropagation();
              if (
                getValue(selectedConversationInfo, `source`, "") === "webchat"
              ) {
                handleUpdateStatus("resolved");
              } else {
                handleResolveConvesation(
                  getValue(selectedConversationInfo, `org_user_id`, "")
                );
              }
            }}
          >
            Mark as Resolved
          </button>
        )}
        <div className="bg-white">
          <MultiLevelSearchToolTip
            label={"label"}
            width={"300px"}
            data1={{
              title: "Assign to Team",
              description:
                "This will move the conversation to unassigned status",
              key: "team",
              data: [{ value: "team", label: "Team", id: "team" }],
            }}
            showAsButton
            data2={{ title: "Assign to User", key: "user", data: orgUsers }}
            data3={{ title: "Assign to Bot", key: "bot", data: chatbotList }}
            value={
              getValue(selectedConversationInfo, `managed_by`, "") === "team" &&
              !getValue(selectedConversationInfo, `org_user_id`, "")
                ? "team"
                : getValue(selectedConversationInfo, `managed_by`, "") ===
                  "chat-bot"
                ? getValue(selectedConversationInfo, `chat_bot_id`, "")
                : getValue(selectedConversationInfo, `org_user_id`, "")
                ? getValue(selectedConversationInfo, `org_user_id`, "")
                : !getValue(selectedConversationInfo, `managed_by`, "")
                ? "team"
                : ""
            }
            selectKey={"value"}
            onChange={(e: object, key: string) =>
              handleSelectMultiLevel(key, getValue(e, `id`, ""), e)
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ChatRightHeader;
