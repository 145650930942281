import { convertCurrentDate } from "@common/date-helpers";
import { capitalizeFirstLetter, formatString } from "@common/text-helpers";
import ListLoader from "@components/common/Loader";
import AdNoDataPage from "@components/common/NoData/ad-nodata";
import { getValue } from "@utils/lodash";
import { Badge, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";

function FacebookList(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: any, record: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() =>
            navigate(
              `/${getValue(params, `orgId`, "")}/settings/crm/ads/${getValue(
                record,
                `id`,
                ""
              )}`
            )
          }
        >
          {capitalizeFirstLetter(name)}
        </div>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "PROVIDER",
      dataIndex: "provider",
      key: "provider",
      render: (provider: any) => formatString(provider),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any, text: any) => (
        <div className="d-flex gap-2">
          <Badge status={status === "ACTIVE" ? "success" : "error"} />
          {formatString(status)}
        </div>
      ),
    },
    {
      title: "ADDED ON",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any, record: any) => convertCurrentDate(created_at),
    },
  ];
  return (
    <>
      {isLoading ? (
        <ListLoader />
      ) : (
        <>
          {list.length > 0 ? (
            <Table
              size="middle"
              bordered
              columns={columns}
              dataSource={list}
              rowKey="id"
              pagination={{
                showTotal: (total, range) =>
                  `${total} ${total >= 2 ? "items" : "item"}`,
              }}
              scroll={{
                y: "calc(100vh - 190px)",
              }}
            />
          ) : (
            <div className="pt-4">
              <AdNoDataPage />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FacebookList;
