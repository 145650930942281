import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllAvatar = (payload: object) =>
  post(`${config.API_URL}/chatbot/avatar/seed`, payload);

//AI chatbot
export const createAIChatbot = (payload: object) =>
  post(`${config.API_URL}/ai-chatbot`, payload);

export const getAllCombinedChatbots = (queryRequest: string) =>
  get(`${config.API_URL}/common/route/combine/bot?${queryRequest}`);

//chatbot
export const getAllChatbots = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot?${queryRequest}`);

export const getAllChatbotsLibrary = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/library?${queryRequest}`);

export const cloneChatbotsLibrary = (id: string) =>
  get(`${config.API_URL}/chatbot/${id}/clone/library`);

export const getSpecificChatbot = (id: string) =>
  get(`${config.API_URL}/chatbot/${id}`);

export const createChatbot = (payload: object) =>
  post(`${config.API_URL}/chatbot`, payload);

export const updateChatbot = (id: string, payload: object) =>
  patch(`${config.API_URL}/chatbot/${id}`, payload);

export const deleteChatbot = (id: string) =>
  Delete(`${config.API_URL}/chatbot/${id}`);

//chatbot group

export const getAllChatbotGroups = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/group?${queryRequest}`);

export const getSpecificChatbotGroup = (id: string) =>
  get(`${config.API_URL}/chatbot/group/${id}`);

export const createChatbotGroup = (payload: object) =>
  post(`${config.API_URL}/chatbot/group`, payload);

export const updateChatbotGroup = (id: string, payload: object) =>
  patch(`${config.API_URL}/chatbot/group/${id}`, payload);

export const deleteChatbotGroup = (id: string) =>
  Delete(`${config.API_URL}/chatbot/group/${id}`);

//chatbot flow

export const createChatbotSetup = (payload: object) =>
  post(`${config.API_URL}/chatbot/flow`, payload);

export const getAllChatbotSetup = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/flow?${queryRequest}`);

export const deleteChatbotSetup = (id: string) =>
  Delete(`${config.API_URL}/chatbot/flow/${id}`);

export const updateChatbotPosition = (payload: object) =>
  patch(`${config.API_URL}/chatbot/flow/position`, payload);

export const getChatbotFlowInvoke = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/flow/invoke?${queryRequest}`);

export const getChatbotFlowInput = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/flow/input?${queryRequest}`);

export const addChatbotFlowInput = (payload: object) =>
  post(`${config.API_URL}/chatbot/flow/input`, payload);

//chatbot log

export const createChatbotLog = (payload: object) =>
  post(`${config.API_URL}/chatbot/log`, payload);

export const getAllChatbotLogs = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/log?${queryRequest}`);

export const getAllChatbotLogStatus = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/log/status?${queryRequest}`);

//clone API

export const cloneChatbot = (id: string) =>
  get(`${config.API_URL}/chatbot/${id}/clone`);
