import { Button, Tabs, TabsProps } from "antd";
import AIAgentLayout from "../../Layout";
import SettingsGeneral from "./General";
import SettingsAI from "./AI";
import SettingsChatInterface from "./ChatInterface";
import SettingsLeads from "./Leads";
import { useEffect, useState } from "react";
import { SettingsRequest } from "./settings-request-helper";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { getSpecificAIChatbot } from "@services/AIBot/settings.service";
import { showAIChatbotInstructions } from "@services/AIBot/settings.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { convertObjectKeystoJSON } from "@common/text-helpers";
import SettingsSecurity from "./Security";
import SettingsNotifications from "./Notifications";
import { updateAIChatbot } from "@services/AIBot/settings.service";

const AIAgentSettings = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState(SettingsRequest);
  const reset = () => {
    setRequest(SettingsRequest);
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getActionData();
    getInstructions();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const getActionData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificAIChatbot(getValue(params, `chatbotId`, ""));
      if (resp) {
        console.log(resp);
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          description: getValue(resp, `data.description`, ""),
          enable_credits_limit: getValue(resp, `data.enable_credits_limit`, ""),
          credits_limit: getValue(resp, `data.credits_limit`, ""),
          model: getValue(resp, `data.model`, ""),
          instructions_type: getValue(resp, `data.instructions_type`, ""),
          instructions: getValue(resp, `data.instructions`, ""),
          temp: getValue(resp, `data.temp`, ""),
          retraining_interval: getValue(resp, `data.retraining_interval`, ""),
          initial_messages: getValue(resp, `data.initial_messages`, []).join(
            "\n"
          ),
          suggested_messages: getValue(
            resp,
            `data.suggested_messages`,
            []
          ).join("\n"),
          message_placeholder: getValue(resp, `data.message_placeholder`, ""),
          collect_user_feedback: getValue(
            resp,
            `data.collect_user_feedback`,
            false
          ),
          regenerate_messages: getValue(
            resp,
            `data.regenerate_messages`,
            false
          ),
          footer: getValue(resp, `data.footer`, ""),
          theme: getValue(resp, `data.theme`, ""),
          display_name: getValue(resp, `data.display_name`, ""),
          user_message_color: getValue(resp, `data.user_message_color`, ""),
          header_color: getValue(resp, `data.header_color`, ""),
          button_color: getValue(resp, `data.button_color`, ""),
          align_chat_button: getValue(resp, `data.align_chat_button`, ""),
          auto_open_chat_window_after: getValue(
            resp,
            `data.auto_open_chat_window_after`,
            0
          ),
          profile_picture_id: getValue(resp, `data.profile_picture`, {}),
          chat_icon_id: getValue(resp, `data.chat_icon`, {}),
          visibility: getValue(resp, `data.visibility`, ""),
          only_allow_on_added_domains: getValue(
            resp,
            `data.only_allow_on_added_domains`,
            false
          ),
          allowed_domains: getValue(resp, `data.allowed_domains`, []).join(
            "\n"
          ),
          ip_limit: getValue(resp, `data.ip_limit`, 0),
          ip_limit_timeframe: getValue(resp, `data.ip_limit_timeframe`, 0),
          ip_limit_message: getValue(resp, `data.ip_limit_message`, ""),
          daily_conversations_active: getValue(
            resp,
            `data.daily_conversations_active`,
            false
          ),
          daily_conversations_emails: getValue(
            resp,
            `data.daily_conversations_emails`,
            []
          ).join("\n"),
          daily_leads_collected_active: getValue(
            resp,
            `data.daily_leads_collected_active`,
            false
          ),
          daily_leads_collected_emails: getValue(
            resp,
            `data.daily_leads_collected_emails`,
            []
          ).join("\n"),
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [instructionList, setInstructionList] = useState<any>([]);
  const getInstructions = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await showAIChatbotInstructions(queryRequest);
      if (resp) {
        let list = convertObjectKeystoJSON(getValue(resp, `data`, {}));
        let mapList: any =
          getValue(list, `length`, 0) > 0
            ? list.map((item: object) => ({
                ...item,
                description: getValue(item, `value`, ""),
                value: getValue(item, `key`, ""),
              }))
            : [];
        setInstructionList(mapList);
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General",
      children: (
        <SettingsGeneral
          isLoading={isLoading}
          request={request}
          setRequest={setRequest}
        />
      ),
    },
    {
      key: "2",
      label: "AI",
      children: (
        <SettingsAI
          isLoading={isLoading}
          request={request}
          setRequest={setRequest}
          instructionList={instructionList}
        />
      ),
    },
    {
      key: "3",
      label: "Chat Interface",
      children: (
        <SettingsChatInterface
          isLoading={isLoading}
          request={request}
          setRequest={setRequest}
        />
      ),
    },
    {
      key: "4",
      label: "Security",
      children: (
        <SettingsSecurity
          isLoading={isLoading}
          request={request}
          setRequest={setRequest}
        />
      ),
    },
    {
      key: "5",
      label: "Notifications",
      children: (
        <SettingsNotifications
          isLoading={isLoading}
          request={request}
          setRequest={setRequest}
        />
      ),
    },
    // {
    //   key: "4",
    //   label: "Leads",
    //   children: (
    //     <SettingsLeads
    //       isLoading={isLoading}
    //       request={request}
    //       setRequest={setRequest}
    //     />
    //   ),
    // },
  ];
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSave = async () => {
    try {
      let payloadReq = JSON.parse(JSON.stringify(request));
      let payload = {
        ...request,
        profile_picture_id: getValue(payloadReq, `profile_picture_id.id`, ""),
        chat_icon_id: getValue(payloadReq, `chat_icon_id.id`, ""),
        credits_limit: parseInt(getValue(payloadReq, `credits_limit`, 0)),
        temp: parseInt(getValue(payloadReq, `temp`, 0)),
        ip_limit: parseInt(getValue(payloadReq, `ip_limit`, 0)),
        ip_limit_timeframe: parseInt(
          getValue(payloadReq, `ip_limit_timeframe`, 0)
        ),
        initial_messages: payloadReq.initial_messages
          .split("\n")
          .map((value: string) => value.trim())
          .filter((value: string) => value.length > 0),
        suggested_messages: payloadReq.suggested_messages
          .split("\n")
          .map((value: string) => value.trim())
          .filter((value: string) => value.length > 0),
        allowed_domains: payloadReq.allowed_domains
          .split("\n")
          .map((value: string) => value.trim())
          .filter((value: string) => value.length > 0),
        daily_conversations_emails: payloadReq.daily_conversations_emails
          .split("\n")
          .map((value: string) => value.trim())
          .filter((value: string) => value.length > 0),
        daily_leads_collected_emails: payloadReq.daily_leads_collected_emails
          .split("\n")
          .map((value: string) => value.trim())
          .filter((value: string) => value.length > 0),
      };
      setSubmitLoading(true);
      let resp = await updateAIChatbot(
        getValue(params, `chatbotId`, ""),
        payload
      );
      if (resp) {
        setSubmitLoading(false);
        getActionData();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <AIAgentLayout>
      <div className="d-flex justify-content-end my-2">
        <Button onClick={handleSave} disabled={submitLoading} color="primary">
          {submitLoading ? "Please wait..." : "Save"}
        </Button>
      </div>
      <Tabs tabPosition="left" defaultActiveKey="1" items={items} />
    </AIAgentLayout>
  );
};

export default AIAgentSettings;
