import React from "react";
import { Bold, Italic, Strikethrough, Type, Code } from "lucide-react";

interface FormatButton {
  icon: React.ElementType;
  format: string;
  label: string;
}

const formatButtons: FormatButton[] = [
  { icon: Bold, format: "*", label: "Bold" },
  { icon: Italic, format: "_", label: "Italic" },
  { icon: Strikethrough, format: "~", label: "Strikethrough" },
  // { icon: Code, format: "```", label: "Code" },
  // { icon: Type, format: "```", label: "Monospace" },
];

interface FormatToolbarProps {
  onFormat: (format: string) => void;
}

export default function FormatToolbar({ onFormat }: FormatToolbarProps) {
  return (
    <>
      <div className="format-toolbar">
        {formatButtons.map((button) => (
          <button
            key={button.label}
            onClick={() => onFormat(button.format)}
            title={button.label}
          >
            <button.icon className="icon" />
          </button>
        ))}
      </div>
    </>
  );
}
