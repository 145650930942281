import { getValue } from "@utils/lodash";
import DashboardMoreOptions from "../../components/dashboard-more-options";
import { Table } from "antd";
import { findLabelFromKeyItem } from "../../helpers/enum";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { Eye } from "lucide-react";

function TableChartComponent(props: any) {
  const columns = [
    {
      title: "Sl no",
      dataIndex: "index",
      key: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // render: (name: any, record: any, item: any) =>
      //   name,
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      render: (count: any) => <div className="text-center">{count}</div>,
    },
    // {
    //   title: "Created On",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (created_at: any) => convertCurrentDate(created_at),
    // },
  ];

  const data = getValue(props, "graph", []).map(
    (item: object, index: number) => ({
      // key: getValue(item, "key.id", `unique-${index}`),
      index,
      name: findLabelFromKeyItem(item),
      count: getValue(item, "count", ""),
      created_at: getValue(item, "key.created_at", ""),
    })
  );

  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="dashboard-card-heading"
        onClick={() => props.toggleChartDetails(props)}
      >
        {capitalizeFirstLetter(getValue(props, `name`, ""))}
        <Eye size={16} className="dashboard-card-heading-eye" />
      </p>
      <div className="cursor-pointer">
        <Table
          size="small"
          bordered
          columns={columns}
          dataSource={data}
          rowKey={getValue(props, "id", "")}
          pagination={false}
        />
      </div>
      {!props.hideOptions && (
        <DashboardMoreOptions
          props={props}
          id={getValue(props, "id", "")}
          confirmDeleteAnalyticsComponent={
            props.confirmDeleteAnalyticsComponent
          }
          handleEditChart={props.handleEditChart}
        />
      )}
    </div>
  );
}

export default TableChartComponent;
