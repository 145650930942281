// JSONEditorWithLineNumbers.jsx
import React, { useState } from "react";
import { CodeBlock, dracula } from "react-code-blocks";

const JSONEditorWithLineNumbers = ({ initialJSON }:any) => {
  // Convert initialJSON to string if it's an object
  const stringifiedJSON = typeof initialJSON === 'object' 
    ? JSON.stringify(initialJSON, null, 2) 
    : initialJSON;

  const [jsonValue, setJsonValue] = useState(stringifiedJSON);
  const [error, setError] = useState<any>(null);

  // Handle changes in the text area
  const handleChange = (e:any) => {
    const value = e.target.value;
    setJsonValue(value);

    try {
      JSON.parse(value);
      setError(null);
    } catch (err) {
      setError("Invalid JSON format.");
    }
  };

  // Handle JSON submission
  const handleSave = () => {
    try {
      const parsedJSON = JSON.parse(jsonValue);
    //   console.log("Saved JSON:", parsedJSON);
      alert("JSON saved successfully!");
    } catch (err) {
      setError("Cannot save due to invalid JSON.");
    }
  };

  return (
    <div className="json-editor">
      {/* Display JSON in a syntax-highlighted code block */}
      <div className="json-editor__code-container">
        <CodeBlock
          text={stringifiedJSON}
          language="json"
          showLineNumbers={true}
        //   theme={dracula}
          wrapLongLines={true}
        />
      </div>

      {/* Error message */}
      {error && (
        <div className="json-editor__error">
          {error}
        </div>
      )}

      {/* Optional: Editable textarea */}
      {/* <textarea
        value={jsonValue}
        onChange={handleChange}
        rows={20}
        className="json-editor__textarea"
      /> */}

      {/* Optional: Save button */}
      {/* <button
        onClick={handleSave}
        className="json-editor__save-button"
      >
        Save Changes
      </button> */}
    </div>
  );
};

export default JSONEditorWithLineNumbers;