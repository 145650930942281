import { handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import Loader from "@components/common/Loader/loading";
import { getValue } from "@utils/lodash";
import { Button, Select, Slider, Tag } from "antd";
import React from "react";

function SettingsAI(props: any) {
  const { request, setRequest, isLoading, instructionList } = props;
  return isLoading ? (
    <Loader />
  ) : (
    <div
      className="border rounded-3 p-4"
      style={{ height: "calc(100vh - 260px)", overflowY: "auto" }}
    >
      <h6 className="header_text__18">AI</h6>
      <div className="my-3">
        <h6>
          Model{" "}
          <Tag color="purple">Claude and Gemini models are now available</Tag>
        </h6>
        <Select
          defaultValue="gpt"
          options={[
            { value: "gpt", label: "GPT 4o mini" },
            { value: "gpt1", label: "GPT 4 Turbo 1 (coming soon...)" },
            { value: "gpt2", label: "GPT sample 2 (coming soon...)" },
            { value: "gemini", label: "Gemini sample (coming soon...)" },
            { value: "gemini 1", label: "Gemini sample 1 (coming soon...)" },
            { value: "gemini 2", label: "Gemini sample 2 (coming soon...)" },
          ]}
          className="w-100 my-2"
        />
      </div>
      <div className="mt-4">
        <div className="d-flex justify-content-between">
          <h6>Instructions</h6>
          <div className="d-flex gap-2 align-items-center">
            {/* <Button>Reset</Button> */}
            <Select
              style={{ width: "300px" }}
              defaultValue={getValue(request, `instructions_type`, "")}
              options={instructionList}
              onChange={(e: any) => {
                let info = instructionList.find(
                  (item: object) => getValue(item, `value`, "") === e
                );
                setRequest({
                  ...request,
                  instructions_type: e,
                  instructions: getValue(info, `description`, ""),
                });
              }}
              className="w-100 my-2 "
            />
          </div>
        </div>
        <InputRuleForm
          inputType="TEXTAREA"
          name="instructions"
          value={getValue(request, "instructions", "")}
          placeholder="Enter a Instructions"
          label=""
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
          disabled
        />
      </div>
      <p>
        The instructions allow you to customize your chatbot's personality and
        style. Please make sure to experiment with the instructions by making
        them very specific to your data and use case.
      </p>
      <div className="mt-5">
        <h6>Temperature</h6>
        <Slider
          value={getValue(request, `temp`, 0)}
          defaultValue={getValue(request, `temp`, 0)}
          min={0}
          max={1}
          step={0.1}
          onChange={(value: number) => {
            setRequest({
              ...request,
              temp: value,
            });
          }}
        />
        <div className="d-flex justify-content-between">
          <p>Reserved</p>
          <p>Creative</p>
        </div>
      </div>
      <div className="mt-5 border rounded-3 p-4">
        <h6>Training</h6>
        <p>Last trained at December 21, 2024 at 02:46 PM</p>
      </div>
      <Select
        style={{ width: "300px" }}
        defaultValue={getValue(request, `retraining_interval`, "")}
        options={[
          { label: "Never", value: null },
          { label: "every 24 hours", value: 24 },
        ]}
        onChange={(e: any) => {
          setRequest({
            ...request,
            retraining_interval: e,
          });
        }}
        className="w-100 my-2 "
      />
    </div>
  );
}

export default SettingsAI;
