import Solutions from "@pages/Private/Solutions";
import CategoryFolders from "@pages/Private/Solutions/CategoryFolders";
import SolutionsDetail from "@pages/Private/Solutions/Articles";

export const SolutionsRoutes = [
  {
    path: "/:orgId/solutions",
    name: "chatbot",
    component: Solutions,
  },
  {
    path: "/:orgId/solutions/article/create",
    name: "chatbot",
    component: SolutionsDetail,
  },
  {
    path: "/:orgId/solutions/article/create/:folder",
    name: "chatbot",
    component: SolutionsDetail,
  },
  {
    path: "/:orgId/solutions/article/edit/:id",
    name: "chatbot",
    component: SolutionsDetail,
  },
  {
    path: "/:orgId/solutions/:id",
    name: "chatbot",
    component: CategoryFolders,
  },
  {
    path: "/:orgId/solutions/:id/folders/:folderId",
    name: "chatbot",
    component: CategoryFolders,
  },
  {
    path: "/:orgId/solutions/:id/folders/:folderId/article/:articleId",
    name: "chatbot",
    component: CategoryFolders,
  },
  // {
  //   path: "/:orgId/solutions/:id/edit/:id",
  //   name: "chatbot",
  //   component: SolutionsDetail,
  // },
];
