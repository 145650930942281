import { getValue } from "@utils/lodash";
import { Drawer, Table } from "antd";
import GraphHeaderPage from "../components/graph-header";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { useNavigate, useParams } from "react-router-dom";

const ChartDetailsDrawer = (props: any) => {
  const {
    open,
    onClose,
    drawerGraphName,
    chartInfo,
    graphInfo,
    total_count,
    page_size,
    page_no,
    handleChangePagination,
    handleChangeLimit,
  } = props;
  const params = useParams();
  const navigate = useNavigate();
  const module = getValue(props, `graphInfo.module.api_name`, "");

  const formatName = (record: any) => {
    if (record.first_name || record.last_name || record.name) {
      const first = capitalizeFirstLetter(record.first_name || "");
      const last = capitalizeFirstLetter(record.last_name || "");
      const name = capitalizeFirstLetter(record.name || "");
      return name ? name : `${first} ${last}`.trim();
    }
    return capitalizeFirstLetter(record.name || "Unknown");
  };

  const dataSource = chartInfo.map((item: object, index: number) => {
    const baseData = {
      key: index,
      index: index + 1,
      name: formatName(item),
      email:
        module === "companies" || module === "deals"
          ? "N/A"
          : getValue(item, `email`, "") || "N/A",
      id: getValue(item, "id", ""),
    };

    return baseData;
  });

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text: string) => <span>{text}</span>,
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: any, _index: any) => {
        return (
          <a
            className="header_blue_text__14 cursor-pointer"
            href={`/${getValue(params, "orgId", "")}/crm/${getValue(
              props,
              `graphInfo.module.api_name`,
              ""
            )}/detail/${record.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getValue(record, `name`, "")}
          </a>
        );
      },
    },
    ...(module === "companies" || module === "deals"
      ? []
      : [
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (text: string) => <span>{text}</span>,
          },
        ]),
  ];

  const renderData = (module: string) => (
    <Table
      size="middle"
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{
        y: "calc(100vh - 200px)",
      }}
    />
  );

  return (
    <Drawer
      title={drawerGraphName}
      onClose={onClose}
      open={open}
      width={720}
      className="chart-details-table-drawer"
    >
      <div className="px-4 pt-4">
        <GraphHeaderPage graphInfo={graphInfo} />
      </div>
      <div>
        <div className="chart-details-table px-4">
          {getValue(chartInfo, `length`, 0) > 0 && renderData(module)}
        </div>

        {total_count > 10 && (
          <Pagination
            totalCount={total_count}
            limit={page_size}
            page_no={page_no}
            handleChangePagination={handleChangePagination}
            handleChangeLimit={handleChangeLimit}
          />
        )}
      </div>
    </Drawer>
  );
};

export default ChartDetailsDrawer;
