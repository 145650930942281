import InputRuleForm from "@components/InputRuleForm/form";
import Loader from "@components/common/Loader/loading";
import { getValue } from "@utils/lodash";
import { Switch } from "antd";
import React from "react";

function SettingsNotifications(props: any) {
  const { request, setRequest, isLoading } = props;

  const handleKeyDown = (e: any, name: any) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent space character
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      const currentValue = e.target.value;
      const cursorPosition = e.target.selectionStart;
      const textBeforeCursor = currentValue.substring(0, cursorPosition);
      const textAfterCursor = currentValue.substring(cursorPosition);
      setRequest({
        ...request,
        [name]: textBeforeCursor + "\n" + textAfterCursor,
      });
      // Move cursor to the new line
      setTimeout(() => {
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  const handleChange = (e: any, name: any) => {
    const value = e.target.value;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div style={{ height: "calc(100vh - 260px)", overflowY: "auto" }}>
      <div>
        <p>Receive email with daily leads</p>
        <Switch
          size="small"
          className="ms-2"
          checked={getValue(request, `daily_conversations_active`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              daily_conversations_active: !getValue(
                request,
                `daily_conversations_active`,
                false
              ),
            })
          }
        />
      </div>
      <div>
        <InputRuleForm
          inputType="TEXTAREA"
          name="name"
          placeholder="Enter Daily Conversation Emails"
          label="Daily Conversation Emails"
          value={getValue(request, `daily_conversations_emails`, "")}
          onChange={(e: any) => handleChange(e, "daily_conversations_emails")}
          onKeyDown={(e: any) => handleKeyDown(e, "daily_conversations_emails")}
        />
      </div>
      <div>
        <p>Receive email with daily conversations</p>
        <Switch
          size="small"
          className="ms-2"
          checked={getValue(request, `daily_leads_collected_active`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              daily_leads_collected_active: !getValue(
                request,
                `daily_leads_collected_active`,
                false
              ),
            })
          }
        />
      </div>
      <div>
        <InputRuleForm
          inputType="TEXTAREA"
          name="name"
          placeholder="Enter Daily Leads Collected Emails"
          label="Daily Leads Collected Emails"
          value={getValue(request, `daily_leads_collected_emails`, "")}
          onChange={(e: any) => handleChange(e, "daily_leads_collected_emails")}
          onKeyDown={(e: any) =>
            handleKeyDown(e, "daily_leads_collected_emails")
          }
        />
      </div>
    </div>
  );
}

export default SettingsNotifications;
