import {
  Boxes,
  Building2,
  CalendarRange,
  Clock4,
  Component,
  Eye,
  FolderInput,
  Hotel,
  Mail,
  Megaphone,
  Reply,
  Route,
  Star,
  TableOfContents,
  TextCursorInput,
  Tickets,
  UserRound,
  UserRoundCog,
  Workflow,
} from "lucide-react";
import AppBrandingSvgComponent from "@assets/svg/app-branding";
import ChatSvgComponent from "@assets/svg/chat";
import { WhatsAppOutlined } from "@ant-design/icons";
import BroadcastSvgComponent from "@assets/svg/broadcast";
import ChatbotSvgComponent from "@assets/svg/chatbot";
import CompanyProfileSvgComponent from "@assets/svg/company-profile";

export const renderIcons = (name, color, iconSize) => {
  const size = iconSize ? iconSize : 24;
  switch (name) {
    case "ads":
      return <Megaphone color={color} size={size} />;
    case "agent-shifts":
      return <Clock4 color={color} size={size} />;
    case "app-branding":
      return <AppBrandingSvgComponent color={color} />;
    case "apps":
      return <ChatbotSvgComponent color={color} />;
    case "branding":
      return <Hotel color={color} size={size} />;
    case "broadcast":
      return <BroadcastSvgComponent color={color} />;
    case "business-hours":
      return <CalendarRange color={color} size={size} />;
    case "canned":
      return <Reply color={color} size={size} />;
    case "company-profile":
      return <Building2 color={color} size={size} />;
    case "data-administration-exports":
      return <FolderInput color={color} size={size} />;
    case "emails":
      return <Mail color={color} size={size} />;
    case "eye":
      return <Eye color={color} size={size} />;
    case "fields":
      return <Tickets color={color} size={size} />;
    case "forms":
      return <TextCursorInput color={color} size={size} />;
    case "groups":
      return <Component color={color} size={size} />;
    case "messages":
      return <ChatSvgComponent color={color} />;
    case "module-and-field":
      return <TableOfContents color={color} size={size} />;
    case "products":
      return <Boxes color={color} size={size} />;
    case "roles":
      return <UserRoundCog color={color} size={size} />;
    case "sla-policy":
      return <Route color={color} size={size} />;
    case "skills":
      return <Star color={color} size={size} />;
    case "users":
      return <UserRound color={color} size={size} />;
    case "whatsapp":
      return <WhatsAppOutlined style={{ color, fontSize: "16px" }} />;
    case "workflows":
      return <Workflow color={color} size={size} />;
    default:
      return <CompanyProfileSvgComponent color={color} />;
  }
};
