import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllMeetings = (queryRequest: string) =>
  get(`${config.API_URL}/records/meetings?${queryRequest}`);

export const getSpecificMeeting = (id: string) =>
  get(`${config.API_URL}/records/meetings/${id}`);

export const createMeeting = (payload: object) =>
  post(`${config.API_URL}/records/meetings`, payload);

export const updateMeeting = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/meetings/${id}`, payload);

export const deleteMeeting = (id: string) =>
  Delete(`${config.API_URL}/records/meetings/${id}`);

export const meetingsBulkUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/meetings/bulk-update`, payload);

export const meetingsSheetUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/meetings/sheet-view`, payload);

//comments

export const getSpecificMeetingComment = (id: string) =>
  get(`${config.API_URL}/records/meetings/${id}/comments`);

export const createMeetingComment = (payload: object, id: string) =>
  post(`${config.API_URL}/records/meetings/${id}/comments`, payload);

export const updateMeetingComment = (
  id: string,
  payload: object,
  comment_id: string
) =>
  patch(
    `${config.API_URL}/records/meetings/${id}/comments/${comment_id}`,
    payload
  );

export const deleteMeetingComment = (id: string, comment_id: String) =>
  Delete(`${config.API_URL}/records/meetings/${id}/comments/${comment_id}`);
