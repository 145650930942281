import { getValue } from "@utils/lodash";
import SheetPhoneEdit from "./components/SheetPhoneEdit";
import SheetTextEditor from "./components/SheetTextEditor";
import SheetBooleanEditor from "./components/SheetBooleanEditor";
import SheetNumberEditor from "./components/SheetNumberEditor";
import SheetTextAreaEditor from "./components/SheetTextAreaEditor";
import SheetCheckboxEditor from "./components/SheetCheckboxEditor";
import SheetBooleanRadioEditor from "./components/SheetBooleanRadioEditor";
import SheetSelectMultiple from "./components/SheetSelectMultiple";
import SheetBooleanSelectEditor from "./components/SheetBooleanSelectEditor";
import SheetDateEditor from "./components/SheetDateEditor";
import SheetDateTimeEditor from "./components/SheetDateTimeEditor";
import SheetTimePicker from "./components/SheetTimePicker";
import SheetLookupDropdown from "./components/SheetLookupDropdown";
import React from "react";

const SheetEditor = ({
  col,
  row,
  field,
  findStageColor,
  handleDisplayItem,
  appendCustomData,
  handleChangeStage,
  stageList,
  orgInfo,
  tempPhoneNumbers,
  numberError,
  handlePhoneInputChange,
  handleCancel,
  handleUpdate,
  item,
  handleInputChange,
  handleCustomInputChange,
  idx,
}: any) => {
  const renderCustomContent = (fieldCol: any) => {
    const selectedField = fieldCol.input_type;
    switch (selectedField) {
      case "text":
        return (
          <SheetTextEditor
            col={col}
            row={row}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "boolean":
        return (
          <SheetBooleanEditor
            col={col}
            row={row}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "boolean_checkbox":
        return (
          <SheetCheckboxEditor
            col={col}
            row={row}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "boolean_radio":
        return (
          <SheetBooleanRadioEditor
            col={col}
            row={row}
            selectedField={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "boolean_select":
        return (
          <SheetBooleanSelectEditor
            col={col}
            row={row}
            field={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "decimal":
      case "currency":
      case "percent":
      case "integer":
      case "auto_number":
      case "autonumber":
      case "bigint":
        return (
          <SheetNumberEditor
            col={col}
            row={row}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "select":
      case "picklist":
        return (
          <SheetSelectMultiple
            col={col}
            row={row}
            field={selectedField}
            fieldCol={fieldCol}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "mobile_number":
      case "whatsapp_number":
      case "phone_number":
      case "home_phone_number":
      case "assistant_phone_number":
        return (
          <SheetPhoneEdit
            row={row}
            col={col}
            item={item}
            orgInfo={orgInfo}
            tempPhoneNumbers={tempPhoneNumbers}
            handlePhoneInputChange={handlePhoneInputChange}
            numberError={numberError}
            handleCancel={handleCancel}
            handleUpdate={handleUpdate}
            handleDisplayItem={handleDisplayItem}
          />
        );
      case "date":
        return (
          <SheetDateEditor
            col={col}
            row={row}
            field={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "datetime":
        return (
          <SheetDateTimeEditor
            col={col}
            row={row}
            field={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "time":
        return (
          <SheetTimePicker
            col={col}
            row={row}
            field={selectedField}
            // handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      // case "date":
      //   return (
      //     <SheetDateEditor
      //       col={col}
      //       row={row}
      //       field={selectedField}
      //       handleDisplayItem={handleDisplayItem}
      //       handleInputChange={handleCustomInputChange}
      //     />
      //   );
      default:
        return <input value={handleDisplayItem(col, appendCustomData(row))} />;
    }
  };

  const renderContent = (fieldCol: any) => {
    const selectedField = fieldCol.input_type;
    switch (selectedField) {
      case "text":
      case "email":
        return (
          <SheetTextEditor
            col={col}
            row={row}
            fieldCol={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleInputChange}
          />
        );
      case "tel":
        return (
          <SheetPhoneEdit
            row={row}
            col={col}
            item={item}
            orgInfo={orgInfo}
            tempPhoneNumbers={tempPhoneNumbers}
            handlePhoneInputChange={handlePhoneInputChange}
            numberError={numberError}
            handleCancel={handleCancel}
            handleUpdate={handleUpdate}
            handleDisplayItem={handleDisplayItem}
          />
        );
      case "textarea":
        return (
          <SheetTextAreaEditor
            col={col}
            row={row}
            fieldCol={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleInputChange}
          />
        );
      case "url":
        return (
          <SheetTextEditor
            col={col}
            row={row}
            fieldCol={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleInputChange}
          />
        );
      case "country":
        return <div className=""></div>;
      case "currency":
        return (
          <SheetNumberEditor
            col={col}
            row={row}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleInputChange}
          />
        );
      case "boolean_checkbox":
        return (
          <SheetCheckboxEditor
            col={col}
            row={row}
            fieldCol={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleInputChange}
          />
        );
      case "date_of_birth":
        return <div className=""></div>;
      case "message_opt_out":
        return (
          <SheetBooleanEditor
            col={col}
            row={row}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleInputChange}
          />
        );
      case "boolean_radio":
        return (
          <SheetBooleanRadioEditor
            col={col}
            row={row}
            fieldCol={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleInputChange}
          />
        );
      case "select":
        return (
          <SheetSelectMultiple
            col={col}
            row={row}
            field={selectedField}
            fieldCol={fieldCol}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleInputChange}
          />
        );
      case "lookup":
        return (
          <SheetLookupDropdown
            col={col}
            row={row}
            field={selectedField}
            fieldCol={fieldCol}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleInputChange}
          />
        );
      case "date":
        return (
          <SheetDateEditor
            col={col}
            row={row}
            field={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "datetime":
        return (
          <SheetDateTimeEditor
            col={col}
            row={row}
            field={selectedField}
            handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      case "time":
        return (
          <SheetTimePicker
            col={col}
            row={row}
            field={selectedField}
            // handleDisplayItem={handleDisplayItem}
            handleInputChange={handleCustomInputChange}
          />
        );
      // case "boolean_select":
      //   return (
      //     <SheetBooleanEditor
      //       col={col}
      //       row={row}
      //       handleDisplayItem={handleDisplayItem}
      //       handleInputChange={handleInputChange}
      //     />
      //   );
      default:
        return null;
      // <div>{handleDisplayItem(col, appendCustomData(row))}</div>;
    }
  };

  return (
    <div className="" key={idx}>
      {getValue(item, "custom", false) === false
        ? renderContent(field)
        : renderCustomContent(field)}
    </div>
  );
};

export default SheetEditor;
