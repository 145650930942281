import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllShifts = (queryRequest: string) =>
  get(`${config.API_URL}/shifts?${queryRequest}`);

export const getSpecificShift = (id: string) =>
  get(`${config.API_URL}/shifts/${id}`);

export const getSpecificShiftAgents = (id: string) =>
  get(`${config.API_URL}/shifts/${id}/agents`);

export const createAgentShift = (payload: object) =>
  post(`${config.API_URL}/shifts`, payload);

export const updateAgentShift = (id: string, payload: object) =>
  patch(`${config.API_URL}/shifts/${id}`, payload);

export const deleteAgentShift = (id: string) =>
  Delete(`${config.API_URL}/shifts/${id}`);
