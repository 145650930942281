import React, { createContext, useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import { config } from "../env";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getCombinedInboxChatListCount } from "@services/inbox.service";

const SocketContextRef = createContext();

// Define status mappings to normalize status keys
const STATUS_MAPPINGS = {
  'assigned_me': 'assignedMe',
  'un-assigned': 'unAssigned',
  'assigned': 'assigned',
  'resolved': 'resolved',
  'assigned-chatbot': 'assignedChatbot'
};

// Initial state for status counts
const INITIAL_STATUS_COUNTS = {
  assigned: 0,
  unAssigned: 0,
  resolved: 0,
  assignedChatbot: 0,
  assignedMe: 0
};

function SocketProvider({ children }) {
  const [newChatMessageInfo, setNewChatMessageInfo] = useState({});
  const [newChatStatusInfo, setNewChatStatusInfo] = useState({});
  const [newWebChatMessageInfo, setNewWebChatMessageInfo] = useState({});
  const [statusCounts, setStatusCounts] = useState(INITIAL_STATUS_COUNTS);

  useEffect(() => {
    getAllCount();
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    const orgID = path.split("/")[1];

    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });

    const whatsappChannel = pusher.subscribe(`whats-app-${orgID}`);
    const webchatChannel = pusher.subscribe(`webchat-${orgID}`);

    const handleNewMessage = (data) => {
      console.log("NEW MESSAGE EVENT RECEIVED :::", { data });
      getAllCount();
      setNewChatMessageInfo(data);
      
      if (getValue(data, 'conversation.status') !== 'assigned-chatbot') {
        playNotificationSound();
      }
    };

    const handleStatusChange = (data) => {
      console.log("NEW STATUS EVENT RECEIVED :::", { data });
      setNewChatStatusInfo(data);
    };

    const handleWebchatChange = (data) => {
      console.log("NEW WEBCHAT MESSAGE EVENT RECEIVED :::", { data });
      setNewWebChatMessageInfo(data);
      if (getValue(data, 'conversation.status') !== 'assigned-chatbot') {
        playNotificationSound();
      }
    };

    whatsappChannel.bind("whats-app-message-notification", handleNewMessage);
    whatsappChannel.bind("whats-app-message-status-notification", handleStatusChange);
    webchatChannel.bind("inbox-notification", handleWebchatChange);

    return () => {
      whatsappChannel.unbind_all();
      whatsappChannel.unsubscribe();
      webchatChannel.unbind_all();
      webchatChannel.unsubscribe();
      pusher.disconnect();
    };
  }, []);

  const playNotificationSound = () => {
    new Audio("/notification.mp3")
      .play()
      .catch(error => console.error("Failed to play notification sound:", error));
  };

  const getAllCount = async () => {
    try {
      const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
      const queryRequest = QueryRequestHelper({
        search: getValue(urlParams, 'searchText', ''),
        channel: 'all'
      });

      const response = await getCombinedInboxChatListCount(queryRequest);
      
      if (response?.data?.buckets) {
        const newCounts = response.data.buckets.reduce((acc, { status, count }) => {
          // Map the incoming status to our normalized status key
          const normalizedStatus = STATUS_MAPPINGS[status] || status;
          acc[normalizedStatus] = count;
          return acc;
        }, { ...INITIAL_STATUS_COUNTS }); // Start with initial state to ensure all properties exist

        setStatusCounts(newCounts);
      }
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };

  const totalInboxCount = () => Object.values(statusCounts).reduce((sum, count) => sum + (count || 0), 0);

  return (
    <SocketContextRef.Provider
      value={{
        newChatMessageInfo,
        setNewChatMessageInfo,
        newChatStatusInfo,
        setNewChatStatusInfo,
        newWebChatMessageInfo,
        setNewWebChatMessageInfo,
        totalInboxCount,
        getAllCount,
        statusCounts
      }}
    >
      {children}
    </SocketContextRef.Provider>
  );
}

export default SocketProvider;

export const useSocketContext = () => useContext(SocketContextRef);