import React from "react";
import { Button, Spin } from "antd";
import BackButton from "@components/custom/BrowserBack";
import "./CompanyProfile.scss";
import { renderIcons } from "@common/Data/icons-data";
import { LoadingOutlined } from "@ant-design/icons";

interface CompanyProfileSubHeaderProps {
  isLoading?: boolean;
  handleSubmit?: () => void;
  submitText?: string;
  submitIcon?: React.ReactNode;
  showCancel?: boolean;
  cancelText?: string;
  handleCancel?: () => void;
  addChildren?: React.ReactNode;
  disabled?: boolean;
  backButton?: boolean;
  route?: string;
  title?: string;
  addWorkflow?: boolean;
  addForm?: boolean;
  addRole?: boolean;
  toggle?: () => void;
  showSubmit?: boolean;
  settings?: boolean;
  icon?: any;
  titleLoading?: any;
  centeredContent?: any;
  handleBack?: () => void;
}

const CompanyProfileSubHeader: React.FC<CompanyProfileSubHeaderProps> = ({
  isLoading,
  handleSubmit,
  submitText = "Save",
  submitIcon,
  showCancel,
  cancelText = "Cancel",
  handleCancel,
  addChildren,
  disabled,
  backButton,
  route,
  title,
  addWorkflow,
  addForm,
  addRole,
  toggle,
  showSubmit,
  settings,
  icon,
  titleLoading,
  centeredContent,
  handleBack,
}) => {
  const renderButton = (
    label: string,
    onClick: () => void,
    type: "default" | "primary" = "default",
    submitIcon?: React.ReactNode,
    loading?: boolean,
    disabled?: boolean
  ) => (
    <Button
      size="large"
      type={type}
      onClick={onClick}
      icon={submitIcon}
      loading={loading}
      disabled={disabled}
    >
      {label}
    </Button>
  );

  return (
    <div
      className={`${
        settings
          ? "settings-header-wrapper"
          : "company-profile-subheader-wrapper"
      }`}
    >
      <div className="d-flex align-items-center justify-content-between w-100">
        <h6 className="header_text__18 d-flex align-items-center gap-3">
          {backButton ? (
            <BackButton className="" route={route} handleBack={handleBack} />
          ) : (
            renderIcons(icon ? icon : "", "#202124")
          )}
          {titleLoading ? (
            <Spin indicator={<LoadingOutlined spin />} size="small" />
          ) : (
            title
          )}
        </h6>
        {centeredContent && centeredContent}
        <div className="d-flex align-items-center gap-2">
          {addChildren}
          {showCancel && renderButton(cancelText, handleCancel || (() => {}))}
          {showSubmit &&
            renderButton(
              submitText,
              handleSubmit || (() => {}),
              "primary",
              submitIcon,
              isLoading,
              disabled
            )}
          {addWorkflow &&
            renderButton(
              "Create Workflow",
              toggle || (() => {}),
              "primary",
              submitIcon
            )}
          {addForm &&
            renderButton(
              "Create Form",
              toggle || (() => {}),
              "primary",
              submitIcon
            )}
          {addRole &&
            renderButton(
              "Create Role",
              toggle || (() => {}),
              "primary",
              submitIcon
            )}
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileSubHeader;
