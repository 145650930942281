import AppSettings from "@pages/Private/Settings/Apps";
import DataAdministrationExports from "@pages/Private/Settings/DataAdministration/exports";

export const SettingsEmailTemplatesRoutes = [
  //-------------------------------- Email Templates -------------------------------- //
  {
    path: "/:orgId/settings/crm/data-administration-exports",
    name: "settings_reports_bulk_download",
    component: DataAdministrationExports,
  },
  {
    path: "/:orgId/settings/crm/apps",
    name: "settings_reports_bulk_download",
    component: AppSettings,
  },

  //-------------------------------- Email Templates -------------------------------- //

  // {
  //   path: "/:orgId/settings/email-templates/service",
  //   name: "Dashboard",
  //   component: EmailTemplates,
  // },
  // {
  //   path: "/:orgId/settings/email-templates/sales",
  //   name: "Dashboard",
  //   component: EmailTemplatesSales,
  // },
  // {
  //   path: "/:orgId/settings/email-templates/tasks",
  //   name: "Dashboard",
  //   component: EmailTemplatesTasks,
  // },
  // {
  //   path: "/:orgId/settings/email-templates/notification/add",
  //   name: "Dashboard",
  //   component: EmailNotification,
  // },
  // {
  //   path: "/:orgId/settings/email-templates/notification/:id",
  //   name: "Dashboard",
  //   component: EmailNotification,
  // },
];
