import { Table, Switch, Tooltip } from "antd";
import ListLoader from "@components/common/Loader";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { LockKeyhole } from "lucide-react";

function RolesList(props: any) {
  const {
    loading,
    selectedIds,
    rolesList,
    handleChangeAllCheckbox,
    handleChangeCheckbox,
    updateUserStatus,
  } = props;
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = (id: string) => {
    navigate(`/${getValue(params, `orgId`, "")}/settings/general/roles/${id}`);
  };

  const columns = [
    // {
    //   title: (
    //     <Checkbox
    //       indeterminate={
    //         getValue(selectedIds, `length`, 0) > 0 &&
    //         getValue(selectedIds, `length`, 0) !==
    //           getValue(rolesList, `length`, 0)
    //       }
    //       checked={
    //         getValue(selectedIds, `length`, 0) ===
    //         getValue(rolesList, `length`, 0)
    //       }
    //       onChange={() =>
    //         handleChangeAllCheckbox(
    //           getValue(selectedIds, `length`, 0) ===
    //             getValue(rolesList, `length`, 0)
    //             ? "all_checked"
    //             : "all_not_checked"
    //         )
    //       }
    //     />
    //   ),
    //   dataIndex: "checkbox",
    //   render: (_: any, record: any) => (
    //     <Checkbox
    //       checked={
    //         getValue(selectedIds, `length`, 0) > 0 &&
    //         selectedIds.includes(getValue(record, `id`, ""))
    //       }
    //       onChange={() => handleChangeCheckbox(getValue(record, `id`, ""))}
    //     />
    //   ),
    //   width: 70,
    // },
    {
      title: "Name",
      dataIndex: "title",
      render: (text: string, record: any) => (
        <span
          onClick={() => handleNavigate(getValue(record, `id`, ""))}
          className="header_blue_text__14 cursor-pointer d-flex align-items-center gap-2"
        >
          {capitalizeFirstLetter(text)}
          {getValue(record, `is_sys_role`, false) && (
            <Tooltip title="Administrator">
              <LockKeyhole size={16} color="#212024" />
            </Tooltip>
          )}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text: string, record: any) => (
        <span onClick={() => handleNavigate(getValue(record, `id`, ""))}>
          {text}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      // render: (is_active: any) => (
      //   <div className="d-flex align-items-center gap-2">
      //     <Badge status={is_active === true ? "success" : "error"} />
      //     {is_active === true ? "Active" : "Inactive"}
      //   </div>
      // ),
      render: (_: any, record: any) => (
        <Switch
          size="small"
          checked={getValue(record, `is_active`, false)}
          onChange={() =>
            updateUserStatus(
              getValue(record, `id`, ""),
              !getValue(record, `is_active`, false)
            )
          }
        />
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <ListLoader />
      ) : getValue(rolesList, `length`, 0) > 0 ? (
        <Table
          rowKey="id"
          columns={columns}
          className=""
          dataSource={rolesList}
          pagination={false}
          scroll={{
            y: "calc(100vh - 216px);",
          }}
        />
      ) : (
        <FiltersNoDataPage module={"roles"} />
      )}
    </>
  );
}

export default RolesList;
