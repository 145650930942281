import AgentShifts from "@pages/Private/Settings/AgentShifts";
import CreateAgentShift from "@pages/Private/Settings/AgentShifts/CreateAgentShift";

export const SettingsAgentShiftsRoutes = [
  {
    path: "/:orgId/settings/helpdesk/agent-shifts",
    name: "settings_templates_business_hours",
    component: AgentShifts,
  },
  {
    path: "/:orgId/settings/helpdesk/agent-shifts/create",
    name: "settings_templates_business_hours",
    component: CreateAgentShift,
  },
  {
    path: "/:orgId/settings/helpdesk/agent-shifts/edit/:id",
    name: "settings_templates_business_hours",
    component: CreateAgentShift,
  },
];
