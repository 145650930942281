import { capitalizeFirstLetterOfEachWord } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React, { useRef } from "react";
import parse from "html-to-md";
import MarkdownPreview from "@uiw/react-markdown-preview";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import CallSvgComponent from "@assets/svg/phone";
import UrlSvgComponent from "@assets/svg/custom/url";
import { Swiper, SwiperSlide } from "swiper/react";
import { convertCurrentDate } from "@common/date-helpers";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import { config } from "env";

const EditTemplatePreview = ({ request }: any) => {
  const swiperRef = useRef(null);

  const replacePlaceholdersWithTag: any = () => {
    if (getValue(request, `variableConfig.body`, "")) {
      let replacedText = parse(getValue(request, `variableConfig.body`, ""));
      let escapedMatch = replacedText.replace(/\\/g, "");
      getValue(request, `variableConfig.templateTags`, []).forEach(
        (item: any) => {
          escapedMatch = escapedMatch.replace(
            `[[${item.actual_tag}]]`,
            `${
              getValue(item, `extractType`, "") === "manual"
                ? getValue(item, `value`, "")
                : `[[${item.tag}]]`
            }`
          );
        }
      );
      return escapedMatch;
    } else {
      return null;
    }
    // return replacedText;
  };

  return (
    <div className="p-4 border-start template-selected-preview position-sticky top-0">
      <div
        className="template-radio-wrapper-selected border"
        style={{ width: "304px" }}
      >
        <div>
          <div className="p-3 bg-white rounded-top-3 ">
            <h6 className="header_text__16 mb-2 position-relative pe-4">
              {capitalizeFirstLetterOfEachWord(getValue(request, "name", ""))}
            </h6>
          </div>
          <div className="template-message-container py-4">
            <div className="mx-4 bg-white p-3 template-message-body">
              <MarkdownPreview
                source={replacePlaceholdersWithTag()}
                className="mb-3"
              />
              {getValue(
                request,
                `variableConfig.headerLinkDetail.mime_type`,
                ""
              ).includes("image") &&
                getValue(
                  request,
                  `variableConfig.headerLinkDetail.url`,
                  ""
                ) && (
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <img
                      src={`https://${config.URL}/${getValue(
                        request,
                        `variableConfig.headerLinkDetail.url`,
                        ""
                      )}`}
                      alt="Selected"
                      className="w-100 object-fit-cover rounded-3"
                      height={120}
                    />
                  </div>
                )}
              {getValue(
                request,
                `variableConfig.headerLinkDetail.mime_type`,
                ""
              ).includes("video") &&
                getValue(
                  request,
                  `variableConfig.headerLinkDetail.url`,
                  ""
                ) && (
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <video
                      autoPlay
                      loop
                      controls
                      style={{ height: "100px", width: "100%" }}
                    >
                      <source
                        src={`https://${config.URL}/${getValue(
                          request,
                          `variableConfig.headerLinkDetail.url`,
                          ""
                        )}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                )}
              {getValue(
                request,
                `variableConfig.headerLinkDetail.mime_type`,
                ""
              ).includes("document") && (
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src="/images/icons/document-blue.svg"
                    className="dummy-image-icon"
                    alt=""
                  />
                </div>
              )}
              {getValue(request, `variableConfig.buttons`, []) &&
                getValue(request, "variableConfig.buttons", []).map(
                  (button: any, btnIndex: number) => (
                    <div key={btnIndex} className="">
                      <div className="d-flex justify-content-center align-items-center gap-2 broadcast_button py-2">
                        {getValue(button, "type", "") === "QUICK_REPLY" ? (
                          <ReplySvgComponent color="#408dfb" size={16} />
                        ) : getValue(button, "actionType", "") === "call" ? (
                          <CallSvgComponent color="#408dfb" size={14} />
                        ) : getValue(button, "actionType", "") === "url" ? (
                          <UrlSvgComponent color="#408dfb" size={14} />
                        ) : null}

                        <p className="small_text__13 text-black">
                          {getValue(button, "text", "")
                            ? getValue(button, "text", "")
                            : getValue(button, "value", "")}
                        </p>
                      </div>
                    </div>
                  )
                )}
            </div>
            {getValue(request, "variableConfig.carousels", "").length > 0 && (
              <>
                <div className="broadcast-carousel-swiper_wrapper mx-4 mt-2">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1.25}
                    onSlideChange={() => {}}
                    modules={[Navigation]}
                    navigation={{
                      prevEl: ".custom-button-prev",
                      nextEl: ".custom-button-next",
                    }}
                    ref={swiperRef}
                    className="position-relative"
                  >
                    {getValue(request, "variableConfig.carousels", "").map(
                      (carousel: any, index: number) => (
                        <SwiperSlide key={index}>
                          <div className="carousel-swiper-card">
                            {getValue(
                              carousel,
                              "headerLinkDetail.mime_type",
                              ""
                            ).includes("image") ? (
                              <img
                                src={`https://${config.URL}/${getValue(
                                  carousel,
                                  "headerLinkDetail.url",
                                  ""
                                )}`}
                                alt="Carousel Image"
                                className="broadcast-carousel-image"
                              />
                            ) : (
                              <video
                                autoPlay
                                className="broadcast-carousel-video"
                              >
                                <source
                                  src={`https://${config.URL}/${getValue(
                                    carousel,
                                    "headerLinkDetail.url",
                                    ""
                                  )}`}
                                  type="video/mp4"
                                />
                              </video>
                            )}
                            <p className="p-2 small_text__13 text-black">
                              {getValue(carousel, "body", "")}
                            </p>
                            <div className={`ms-2 d-flex flex-column gap-1`}>
                              {getValue(carousel, "buttons", []).map(
                                (button: any, btnIndex: number) => (
                                  <div key={btnIndex}>
                                    <div className="d-flex justify-content-center align-items-center broadcast_button py-1">
                                      {getValue(button, "type", "") ===
                                      "QUICK_REPLY" ? (
                                        <ReplySvgComponent
                                          color="#408dfb"
                                          size={16}
                                        />
                                      ) : getValue(button, "type", "") ===
                                        "CALL_TO_ACTION" ? (
                                        <CallSvgComponent
                                          color="#408dfb"
                                          size={14}
                                        />
                                      ) : getValue(button, "type", "") ===
                                        "URL" ? (
                                        <UrlSvgComponent
                                          color="#408dfb"
                                          size={14}
                                        />
                                      ) : null}

                                      <p className="ms-2 small_text__12 text-black">
                                        {getValue(button, "actionType", "") ===
                                          "call" ||
                                        getValue(button, "actionType", "") ===
                                          "url"
                                          ? getValue(button, "text", "")
                                          : getValue(button, "value", "")}
                                      </p>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTemplatePreview;
