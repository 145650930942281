import { getValue } from "@utils/lodash";

export const DashboardGraphTypeList = [
  {
    value: "column_chart",
    label: "Column Chart",
    image: "/images/dashboard/column_chart.png",
  },
  {
    value: "donut_chart",
    label: "Donut Chart",
    image: "/images/dashboard/donut_chart.png",
  },
  {
    value: "pie_chart",
    label: "Pie Chart",
    image: "/images/dashboard/pie_chart.png",
  },
  {
    value: "bar_chart",
    label: "Bar Chart",
    image: "/images/dashboard/bar_chart.png",
  },
  {
    value: "line_chart",
    label: "Line Chart",
    image: "/images/dashboard/line_chart.png",
  },
  {
    value: "table_chart",
    label: "Table Chart",
    image: "/images/dashboard/table_chart.png",
  },
  // {
  //   value: "funnel_chart",
  //   label: "Funnel Chart",
  //   image: "/images/dashboard/funnel_chart.png",
  // },
  {
    value: "area_chart",
    label: "Area Chart",
    image: "/images/dashboard/area_chart.png",
  },
  // {
  //   value: "heat_map",
  //   label: "Heat Map",
  //   image: "/images/dashboard/heat_map.png",
  // },
];
export const DashboardKPITypeList = [
  {
    value: "standard",
    label: "Standard",
    image: "/images/dashboard/standard.png",
  },
  {
    value: "growth_index",
    label: "Growth Index",
    image: "/images/dashboard/growth_index.png",
  },
  { value: "basic", label: "Basic", image: "/images/dashboard/basic.png" },
  {
    value: "scorecard",
    label: "Scorecard",
    image: "/images/dashboard/scorecard.png",
  },
  {
    value: "rankings",
    label: "Rankings",
    image: "/images/dashboard/rankings.png",
  },
];
export const StackedChartTypeList = [
  {
    value: "column_chart",
    label: "Stacked Column Chart",
    image: "/images/dashboard/stacked_column.png",
  },
  {
    value: "bar_chart",
    label: "Stacked Bar Chart",
    image: "/images/dashboard/stacked_bar.png",
  },
  // {
  //   value: "area_chart",
  //   label: "Stacked Area Chart",
  //   image: "/images/dashboard/stacked_area_chart.png",
  // },
];

export const DashboardComponentTypeList = [
  { value: "chart", label: "Chart" },
  { value: "kpi", label: "KPI" },
];
export const DashboardGroupingList = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "45", label: "45" },
  { value: "50", label: "50" },
  { value: "55", label: "55" },
  { value: "60", label: "60" },
  { value: "65", label: "65" },
  { value: "70", label: "70" },
  { value: "75", label: "75" },
];

export const DashboardModuleColumnList = [
  { value: "created_by", label: "Created By" },
  { value: "updated_by", label: "Updated By" },
  { value: "created_at", label: "Created At" },
  { value: "updated_at", label: "Updated At" },
  { value: "pipeline_stage", label: "Pipeline Stage" },
];

export const DashboardDateGranularityList = [
  { value: "day", label: "Day" },
  { value: "month", label: "Month" },
  { value: "year", label: "Year" },
];

export const DashboardMetricList = [
  { value: "SUM", label: "Sum" },
  { value: "MIN", label: "Minimum" },
  { value: "MAX", label: "Maximum" },
  { value: "AVG", label: "Average" },
];

export const DashboardDurationTypeList = [
  { value: "today", label: "Today" },
  { value: "this_week", label: "This Week" },
  { value: "this_month", label: "This Month" },
  { value: "this_year", label: "This Year" },
  { value: "last", label: "Last" },
  { value: "custom", label: "Custom" },
];

export const DashboardShowRankList = [
  { value: "top_5", label: "Top 5" },
  { value: "top_10", label: "Top 10" },
  { value: "top_15", label: "Top 15" },
  { value: "top_20", label: "Top 20" },
  { value: "bottom_5", label: "Bottom 5" },
  { value: "bottom_10", label: "Bottom 10" },
  { value: "bottom_15", label: "Bottom 15" },
  { value: "bottom_20", label: "Bottom 20" },
];

export const DashboardShowRankChartList = [
  { value: "none", label: "None" },
  { value: "label_ascending", label: "Label Ascending" },
  { value: "label_descending", label: "Label Descending" },
  { value: "value_ascending", label: "Value Ascending" },
  { value: "value_descending", label: "Value Descending" },
];

export const DashboardCompareToList = [
  { value: "previous_period", label: "Previous Period" },
  { value: "same_period_last_year", label: "Same Period Last Year" },
  { value: "custom", label: "Custom" },
];

export const DashboardCompareObjectList = [
  { value: "positive", label: "Positive" },
  { value: "negative", label: "negative" },
];

export const DashboardFilterCondition = [
  { value: "and", label: "AND" },
  { value: "or", label: "OR" },
];

export const DashboardKPIMetricCondition = [
  { value: "COUNT", label: "Count" },
  // { value: "SUM", label: "Sum" },
  // { value: "MIN", label: "Minimum" },
  // { value: "MAX", label: "Maximum" },
  // { value: "AVG", label: "Average" },
];

export const DashboardKPIMetricCountCondition = [
  { value: "COUNT", label: "Count" },
];

export const DashboardDateEnums = [
  { label: "Is", operator: "{IN}", valueLength: 1 },
  { label: "Isn't", operator: "{NOT_IN}", valueLength: 1 },
  { label: "Is Before", operator: "{BEFORE}", valueLength: 1 },
  { label: "Is After", operator: "{AFTER}", valueLength: 1 },
  // { label: "Between", operator: "BETWEEN", valueLength: 1 },
  // { label: "Not Between", operator: "NOT_BETWEEN", valueLength: 1 },
  { label: "Today", operator: "{TODAY}", valueLength: 1 },
  { label: "Tomorrow", operator: "{TOMORROW}", valueLength: 1 },
  {
    label: "Tomorrow Onwards",
    operator: "{TOMORROW_ONWARDS}",
    valueLength: 1,
  },
  { label: "Yesterday", operator: "{YESTERDAY}", valueLength: 1 },
  {
    label: "Till Yesterday",
    operator: "{TILL_YESTERDAY}",
    valueLength: 1,
  },
  { label: "Last Month", operator: "{LAST_MONTH}", valueLength: 1 },
  { label: "This Month", operator: "{THIS_MONTH}", valueLength: 1 },
  { label: "Next Month", operator: "{NEXT_MONTH}", valueLength: 1 },
  { label: "Last Week", operator: "{LAST_WEEK}", valueLength: 1 },
  { label: "This Week", operator: "{THIS_WEEK}", valueLength: 1 },
  { label: "Next Week", operator: "{NEXT_WEEK}", valueLength: 1 },
  { label: "Is Empty", operator: "{EMPTY}", valueLength: 1 },
  { label: "Is Not Empty", operator: "{NOT_EMPTY}", valueLength: 1 },
  { label: "Age In Days", operator: "{AGE_IN_DAYS}", valueLength: 1 },
  { label: "Due In Days", operator: "{DUE_IN_DAYS}", valueLength: 1 },
];

export const DashboardLookupEnums = [
  { label: "Is", operator: "{IN}", valueLength: 1 },
  { label: "Isn't", operator: "{NOT_IN}", valueLength: 1 },
];

export const findLabelFromKeyItem = (item: any) => {
  if (getValue(item, "key", "")) {
    return typeof getValue(item, "key", "") === "object"
      ? Object.keys(getValue(item, "key", "")).length > 0
        ? getValue(item, "key.label", "")
          ? getValue(item, "key.label", "")
          : `${getValue(item, "key.first_name", "")} ${getValue(
              item,
              "key.last_name",
              ""
            )}`
        : getValue(item, "key", "")
      : getValue(item, "key", "");
  }
};
