import "./filter.scss";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { concatString, formatString } from "common/text-helpers";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import { QueryRequestHelper } from "common/query-request-helper";
import { dataImport, mediaUpload } from "@services/upload.service";
import { toast } from "sonner";
import EditColumnSvgComponent from "assets/svg/edit-column";
import SearchHeader from "@components/custom/SearchHeader";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import DatePicker from "react-datepicker";
import { convertDate } from "@common/date-helpers";
import CloseMainSvgComponent from "@assets/svg/close-main";
import CommonImportPopoup from "@components/BulkImport/ImportPopoup";
import FilterSvgComponent from "@assets/svg/filter";
import SortPage from "./sort";
import { Button, Segmented, Switch, Tooltip } from "antd";
import {
  AppstoreOutlined,
  BarsOutlined,
  FileTextOutlined,
  FunnelPlotOutlined,
  InsertRowAboveOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import SortSvgComponent from "@assets/svg/sort";
import { DownloadCloud, Sheet } from "lucide-react";
export default function Filter(props: any) {
  const {
    SubPiplineTitlePrefix,
    handleOpenFilterView,
    showAdvancedFilter,
    showtaskFilter,
    fields,
    tourRef4,
    currentView,
    setCurrentView,
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const handleSelectFilter = (key: string, value: string, type: string) => {
    if (type === "custom") {
    } else {
      delete UrlParams[`${key}_custom`];
    }
    let payload = {
      ...UrlParams,
      page_no: 1,
      [key]: value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const generateFilterName = (module: string) => {
    switch (module) {
      case "contacts":
        return "Contact Owner";

      case "tasks":
        return "Assignee";

      case "companies":
        return "Company Owner";

      case "sales":
        return "Deal Owner";

      case "leads":
        return "Lead Owner";

      default:
        return "Deal Owner";
    }
  };

  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any, index: number) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          if (index === 0) {
            let response = await dataImport({
              pipeline_id: getValue(props, `selectedPipeline`, ""),
              file_id: getValue(resp, `data.id`, ""),
            });
            if (response) {
              setUploadLoading(false);
              toast.success("Imported successfully");
              props.getListData(
                props.selectedPipeline,
                getValue(UrlParams, `filter_view_id`, "")
              );
            } else {
              setUploadLoading(false);
            }
          }
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                            Date Filter section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(UrlParams, `filter_closing_date`, "")) {
      let dates = getValue(UrlParams, `filter_closing_date`, "").split(",");
      setStartDate(new Date(getValue(dates, `[${0}]`, "")));
      setEndDate(new Date(getValue(dates, `[${1}]`, "")));
    }
  }, [window.location.href]);

  const handleChangeCustomDate = (name: string, start: any, end: any) => {
    if (start && end) {
      handleSelectFilter(name, `${start},${end}`, "custom");
    }
  };

  const handleCloseFilter = (name: string) => {
    delete UrlParams[`${name}`];
    delete UrlParams[`${name}_custom`];
    let payload = {
      ...UrlParams,
      page_no: 1,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  /* --------------------------- Filter Closing Date -------------------------- */

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleChangeCustomDate(
      "filter_closing_date",
      convertDate(start),
      convertDate(end)
    );
  };

  /* --------------------------- Filter Closing Date -------------------------- */

  const [startDate1, setStartDate1] = useState<any>(null);
  const [endDate1, setEndDate1] = useState<any>(null);
  const onChange1 = (dates: any) => {
    const [start, end] = dates;
    setStartDate1(start);
    setEndDate1(end);
    handleChangeCustomDate(
      "filter_created_at",
      convertDate(start),
      convertDate(end)
    );
  };

  let smart_filters = getValue(localStorage, `sf:${props.module}`, "")
    ? JSON.parse(getValue(localStorage, `sf:${props.module}`, ""))
    : {};

  const handleRemoveSmartFilter = () => {
    localStorage.removeItem(`sf:${getValue(props, `module`, "")}`);
    delete UrlParams.smart_filter;
    let payload = {
      ...UrlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    props.getAllPipelines();
  };

  const handleRemoveSort = () => {
    localStorage.removeItem(`s:${getValue(props, `module`, "")}`);
    delete UrlParams.smart_filter;
    let payload = {
      ...UrlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    props.getListData();
  };
  const taskFilters = [
    { value: "all", label: "All" },
    { value: "active", label: "Completed" },
    { value: "Inactive", label: "Pending" },
  ];

  const handleNavigateSheetView = (value: string) => {
    if (value === "sheet") {
      let payload = {
        ...UrlParams,
        sheet: true,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    } else {
      delete UrlParams.sheet;
      let payload = {
        ...UrlParams,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };

  return (
    <div
      className={`filter-wrapper d-flex align-items-center justify-content-between `}
    >
      <div className="d-flex align-items-center">
        {/* {showtaskFilter && (
          <div>
            <Switch
              checkedChildren="Open"
              unCheckedChildren="Completed"
              onChange={props.handleChangeFilter}
              checked={
                !getValue(UrlParams, `filter_is_completed`, "") ? true : false
              }
            />
          </div>
        )} */}

        {getValue(props, `showtaskFilter`, false) && (
          <div className={"filter_module"}>
            <TextHeaderTooltip
              title={`Task Status`}
              data={taskFilters}
              label={`label`}
              value={
                getValue(UrlParams, `filter_is_completed`, "")
                  ? getValue(UrlParams, `filter_is_completed`, "")
                  : "Inactive"
              }
              onChange={(e: any) =>
                handleSelectFilter(
                  "filter_is_completed",
                  getValue(e, `value`, ""),
                  ""
                )
              }
              // selectFirst={
              //   getValue(UrlParams, `filter_is_completed`, "") ? false : true
              // }
              selectKey={"value"}
              width={"270px"}
              showActiveInline
            />
          </div>
        )}
        {!getValue(props, `hideOwner`, false) &&
          getValue(props, `permissions`, []).includes("view_all") &&
          (getValue(UrlParams, `filter_view_id`, "") == "NO_OWNER" ||
            !getValue(UrlParams, `filter_view_id`, "")) && (
            <div className={"filter_module"}>
              <TextHeaderTooltip
                title={generateFilterName(getValue(props, `module`, ""))}
                lookup_api={"org_users"}
                value={getValue(UrlParams, `filter_owner_id`, "")}
                onChange={(e: any) =>
                  handleSelectFilter(
                    "filter_owner_id",
                    getValue(e, `id`, ""),
                    ""
                  )
                }
                label={`label`}
                width={"270px"}
                showActiveInline
              />
            </div>
          )}
        {!getValue(props, `hideStage`, false) && (
          <div className={"filter_module"}>
            <TextHeaderTooltip
              title={`Status`}
              data={getValue(props, `stageList`, [])}
              label={`label`}
              value={getValue(UrlParams, `filter_pipeline_stage_id`, "")}
              onChange={(e: any) =>
                handleSelectFilter(
                  "filter_pipeline_stage_id",
                  getValue(e, `id`, ""),
                  ""
                )
              }
              selectKey={"id"}
              width={"270px"}
              showActiveInline
            />
          </div>
        )}
        {!getValue(props, `hideCreateDate`, false) && (
          <div className={"filter_module"}>
            {getValue(UrlParams, `filter_created_at_custom`, "") ? (
              <div className="position-relative">
                <div className="d-flex align-items-center inline_date_active px-2">
                  <DatePicker
                    className="custom-datepicker-wrapper"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    selected={startDate1}
                    onChange={onChange1}
                    startDate={startDate1}
                    endDate={endDate1}
                    selectsRange
                    dropdownMode="select"
                    placeholderText={"Enter Created Date"}
                    dateFormat="d MMM yyyy"
                    autoFocus={true}
                    focusSelectedMonth
                  />
                  <div
                    className="inline_close_image_container"
                    onClick={(e) => {
                      setStartDate1(null);
                      setEndDate1(null);
                      handleCloseFilter("filter_created_at");
                    }}
                  >
                    <CloseMainSvgComponent size={18} />
                  </div>
                </div>
              </div>
            ) : (
              <TextHeaderTooltip
                title={`Create Date`}
                data={dateFilters}
                label={`label`}
                value={getValue(
                  UrlParams,
                  `filter_created_at${
                    getValue(UrlParams, `filter_created_at_custom`, "") ===
                    "custom"
                      ? "_custom"
                      : ""
                  }`,
                  ""
                )}
                onChange={(e: any) =>
                  handleSelectFilter(
                    `filter_created_at${
                      getValue(e, `value`, "") === "custom" ? "_custom" : ""
                    }`,
                    getValue(e, `value`, ""),
                    ""
                  )
                }
                selectKey={"value"}
                width={"270px"}
                showActiveInline
              />
            )}
          </div>
        )}
        {!getValue(props, `hideCloseDate`, false) && (
          <div className={"filter_module"}>
            {getValue(UrlParams, `filter_closing_date_custom`, "") ? (
              <div className="position-relative">
                <div className="d-flex align-items-center inline_date_active px-2">
                  <DatePicker
                    className="custom-datepicker-wrapper"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    dropdownMode="select"
                    placeholderText={"Enter Close Date"}
                    dateFormat="d MMM yyyy"
                    autoFocus={true}
                    focusSelectedMonth
                  />
                  <div
                    className="inline_close_image_container"
                    onClick={(e) => {
                      setStartDate(null);
                      setEndDate(null);
                      handleCloseFilter("filter_closing_date");
                    }}
                  >
                    <CloseMainSvgComponent size={18} />
                  </div>
                </div>
              </div>
            ) : (
              <TextHeaderTooltip
                title={`Close Date`}
                data={dateFilters}
                label={`label`}
                value={getValue(
                  UrlParams,
                  `filter_closing_date${
                    getValue(UrlParams, `filter_closing_date_custom`, "") ===
                    "custom"
                      ? "_custom"
                      : ""
                  }`,
                  ""
                )}
                onChange={(e: any) =>
                  handleSelectFilter(
                    `filter_closing_date${
                      getValue(e, `value`, "") === "custom" ? "_custom" : ""
                    }`,
                    getValue(e, `value`, ""),
                    ""
                  )
                }
                selectKey={"value"}
                width={"270px"}
                showActiveInline
              />
            )}
          </div>
        )}

        {showAdvancedFilter && (
          <div
            className={`d-flex align-items-center cursor-pointer ${
              getValue(smart_filters, `length`, 0) > 0
                ? "inline_popup_active"
                : ""
            }`}
            ref={tourRef4}
          >
            <div
              onClick={handleOpenFilterView}
              className="d-flex align-items-center"
            >
              <FilterSvgComponent size={20} color={"#1a73e8"} />
              <p className="ms-1 touchable-text header_blue_text__16">
                Advanced Filters{" "}
                {getValue(smart_filters, `length`, 0) > 0 && (
                  <>({getValue(smart_filters, `length`, 0)})</>
                )}
              </p>
            </div>
            {getValue(smart_filters, `length`, 0) > 0 ? (
              <div
                onClick={handleRemoveSmartFilter}
                className="ms-2 inline_close_image_container"
              >
                <CloseMainSvgComponent size={18} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {getValue(localStorage, `s:${getValue(props, `module`, "")}`, "") && (
          <div
            className={`d-flex align-items-center cursor-pointer ms-3 ${
              getValue(localStorage, `s:${getValue(props, `module`, "")}`, "")
                ? "inline_popup_active"
                : ""
            }`}
          >
            <div
              onClick={handleOpenFilterView}
              className="d-flex align-items-center"
            >
              {/* <FunnelPlotOutlined
                style={{ color: "#408dfb", fontSize: "18px" }}
              /> */}
              <SortSvgComponent />
              <p className="ms-1 touchable-text header_blue_text__16">Sort</p>
            </div>
            {getValue(
              localStorage,
              `s:${getValue(props, `module`, "")}`,
              ""
            ) ? (
              <div
                onClick={handleRemoveSort}
                className="ms-2 inline_close_image_container"
              >
                <CloseMainSvgComponent size={18} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <div className="d-flex gap align-items-center">
        {(window.location.pathname.split("/").pop() === "leads" ||
          window.location.pathname.split("/").pop() === "contacts" ||
          window.location.pathname.split("/").pop() === "companies" ||
          window.location.pathname.split("/").pop() === "tasks" ||
          window.location.pathname.split("/").pop() === "deals") && (
          // || window.location.pathname.split("/").pop() === "tasks"
          <Segmented
            value={getValue(UrlParams, `sheet`, "") ? "sheet" : "list"}
            onChange={(value) => handleNavigateSheetView(value)}
            options={[
              { label: "List", value: "list", icon: <BarsOutlined /> },
              {
                label: "Sheet",
                value: "sheet",
                icon: <InsertRowAboveOutlined />,
              },
              // {
              //   label: "Kanban",
              //   value: "kanban",
              //   icon: <AppstoreOutlined />,
              // },
            ]}
          />
        )}
        {/* {getValue(props, `showDownload`, false) && (
          <div
            className="cursor-pointer"
            onClick={() =>
              navigate(
                `/${getValue(
                  params,
                  `orgId`,
                  ""
                )}/settings/crm/data-administration-exports`
              )
            }
          >
            <Tooltip title="Download">
              <DownloadCloud style={{ color: "#276ef1" }} />
            </Tooltip>
          </div>
        )} */}

        {getValue(props, `isSort`, false) && (
          <SortPage fields={fields} module={props.module} />
        )}
        {!getValue(props, `hideSearch`, false) && (
          <SearchHeader
            searchInput={props.searchInput}
            showSearchTextbox={props.showSearchTextbox}
            toggleSearch={props.toggleSearch}
            toggleSearchClose={props.toggleSearchClose}
            handleChangeSearch={props.handleChangeSearch}
          />
        )}
        {!props.hideUpload && (
          <CommonImportPopoup
            {...props}
            stages={getValue(props, `stageList`, [])}
            getListData={props.getListData}
            getImportLog={props.importLogs}
          />
        )}

        {/* {getValue(props, `importLogs`, false) && (
          <div
            className="cursor-pointer logs"
            onClick={() =>
              navigate(
                `/${getValue(params, `orgId`, "")}/${getValue(
                  props,
                  `module`,
                  ""
                )}/imports`
              )
            }
          >
            <FileSvgComponent color={"#408dfb"} />
          </div>
        )} */}

        {props.isCreateDealVisible &&
          getValue(props, `permissions`, []).includes("create") && (
            <Button
              size="large"
              type="primary"
              icon={<PlusOutlined />}
              onClick={props.setVisisbility}
            >
              {SubPiplineTitlePrefix ? SubPiplineTitlePrefix : "Create"}{" "}
              {formatString(
                concatString(getValue(props, `SubPiplineTitle`, ""), 20)
              )}
            </Button>
          )}

        {!props.hideEditColumns && (
          <Tooltip title="Edit Column">
            <div
              onClick={props.handleEditFieldsModal}
              className="cursor-pointer edit_columns"
            >
              <EditColumnSvgComponent
                size={26}
                // color={"#3c4043"}
                color={"#1a73e8"}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
