import { GlobalOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import { createAIChatbot } from "@services/chatbot.service";
import { getValue } from "@utils/lodash";
import { Badge, List, Modal, Radio, Space } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const BotTypeModal = (props: any) => {
  const {
    isModalOpen,
    handleChangeBotType,
    selectedBotType,
    handleCancel,
    handleSelectBotType,
  } = props;

  const params = useParams();
  const navigate = useNavigate();
  const [request, setRequest] = useState({
    name: "",
    type: "agents",
  });

  const handleRadioChange = (e: any) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      type: e.target.value,
    }));
  };

  const websiteList = [
    {
      title: "This can be added to your website or mobile site.",
    },
    {
      title: "You can build the flow and add script to your website",
    },
    {
      title: "Responses are captured here.",
    },
  ];

  const whatsappList = [
    {
      title: "You can create a bot for your Whatsapp.",
    },
    {
      title: "You need to have a facebook business account.",
    },
    {
      title: "You need to have dedicated mobile number to activate this bot.",
    },
  ];

  const handleCreateAIBot = async () => {
    try {
      let resp = await createAIChatbot({ name: getValue(request, `name`, "") });
      if (resp) {
        navigate(
          `/${getValue(params, "orgId", "")}/chatbot/${getValue(
            resp,
            "data.id",
            ""
          )}/ai/detail/responses`
        );
        toast.success("Create AI bot successfully");
      }
    } catch (error) {}
  };

  return (
    <Modal
      title="Create New Chatbot"
      okText="Create bot"
      open={isModalOpen}
      onOk={
        request.type === "chatbot" ? handleSelectBotType : handleCreateAIBot
      }
      onCancel={handleCancel}
      width={600}
    >
      <InputRuleForm
        inputType="TEXT"
        name="name"
        value={getValue(request, "name", "")}
        placeholder="Enter a Name"
        required
        label="Name"
        onChange={(e: any) => handleTextChange(e, request, setRequest)}
        validLeft
      />
      <div className="mt-3">
        <label className="form-label">What do you want to Create?</label>
        <Radio.Group onChange={handleRadioChange} value={request.type}>
          <Space direction="vertical">
            <Radio value="agents">
              AI Agents
              <p className="small_text__14">
                Train your AI agent with the right knowledge surces to respond
                to cusotmer queries- No manual flows required.
              </p>
            </Radio>
            <Radio value="chatbot">
              Chatbots
              <p className="small_text__14">
                Manually setup flows to guide your bot's actions and responses
                to customer queries
              </p>
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      {request.type === "chatbot" && (
        <Radio.Group
          defaultValue="a"
          size="large"
          className="w-100 d-grid grid-container custom-bot-type-selector my-4 gap-3"
          value={selectedBotType}
          onChange={handleChangeBotType}
        >
          <Radio.Button value="web" className="flex-grow-1 p-3">
            <div>
              <h6 className="header_text__14 text-center">Create Web Bot</h6>
              <div className="d-flex justify-content-center mt-2 mb-3">
                <GlobalOutlined
                  style={{ color: "#1DA1F2", fontSize: "30px" }}
                />
              </div>
              {websiteList.map((item: any) => (
                <Badge
                  status="processing"
                  text={item.title}
                  className="bot-type-badge"
                />
              ))}
            </div>
          </Radio.Button>

          <Radio.Button value="whatsapp" className="flex-grow-1 p-3">
            <div>
              <h6 className="header_text__14 text-center">
                Create Whatsapp Bot
              </h6>
              <div className="d-flex justify-content-center mt-2 mb-3">
                <WhatsAppOutlined
                  style={{ color: "#25D366", fontSize: "30px" }}
                />
              </div>
              {whatsappList.map((item: any) => (
                <Badge
                  status="processing"
                  text={item.title}
                  className="bot-type-badge"
                />
              ))}
            </div>
          </Radio.Button>
        </Radio.Group>
      )}
    </Modal>
  );
};

export default BotTypeModal;
