import NotificationSvgComponent from "@assets/svg/notification";
import {
  NotificationBell,
  NovuProvider,
  PopoverNotificationCenter,
} from "@novu/notification-center";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const NotificationCenter = ({ userId }: { userId: string }) => {
  const APPLICATION_IDENTIFIER = "VDG-TDK8mEKV";
  //   const Header = () => {
  //     return null;
  //   };
  const Footer = () => {
    return null;
  };
  const navigate = useNavigate();

  function onNotificationClick(message: any) {
    if (message?.cta?.data?.url) {
      navigate(message.cta.data.url);
    } else {
      toast.error("URL not found");
    }
  }
  const styles = {
    bellButton: {
      root: {
        // marginTop: "20px",
        svg: {
          color: "#fff",
          // fill: "white",
          // minWidth: "20px",
          // minHeight: "20px"
        },
      },
      dot: {
        rect: {
          fill: "transparent",
          strokeWidth: "0",
          width: "3px",
          height: "3px",
          x: 10,
          y: 2,
        },
      },
    },
  };

  useEffect(() => {
    const handlePushNotification = (event: any) => {
      // Handle Novu push notification data
      // const notificationData = event.detail;
    };
    // Subscribe to Novu push notifications
    document.addEventListener("novuPushNotification", handlePushNotification);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener(
        "novuPushNotification",
        handlePushNotification
      );
    };
  }, []);
  return (
    <NovuProvider
      subscriberId={userId}
      applicationIdentifier={APPLICATION_IDENTIFIER}
      styles={styles}
    >
      <PopoverNotificationCenter
        onNotificationClick={onNotificationClick}
        showUserPreferences={false}
        colorScheme={"light"}
        position="top"
        offset={10}
        // header={() => <Header />}
        footer={() => <Footer />}
      >
        {({ unseenCount }: any) => {
          return (
            <div className="notification-bell-container">
              <NotificationBell
                unseenCount={unseenCount}
                // colorScheme="light"
                // unseenBadgeBackgroundColor="#FFFFFF"
                // unseenBadgeColor="#fff"
              />
              {unseenCount > 0 && (
                <div
                  className={`unseen-dot ${unseenCount < 10 ? "p-1" : "p-2"}`}
                >
                  {unseenCount}
                </div>
              )}
            </div>
          );
        }}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
};
export default NotificationCenter;
