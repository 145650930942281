import React from "react";
import "./index.scss";
import { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import { useNavigate, useParams } from "react-router-dom";
import { getAllWebForms } from "@services/web-forms.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import FormCreatorTable from "./components/form-creator-table";
import FormCreatorInfoPopup from "./components/form-creator-info-popup";
import { updateWebForm } from "@services/web-forms.service";
import { toast } from "sonner";
import useDynamicTitle from "@context/useDynamicTitle";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Plus } from "lucide-react";

function SettingsFormCreator(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    navigate(`/${getValue(params, `orgId`, "")}/settings/crm/forms/create`);
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [formList, setFormList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllWebForms(queryRequest);
      if (resp) {
        setFormList(getValue(resp, `data.forms`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChangeStatus = async (id: string, status: boolean) => {
    try {
      let resp = await updateWebForm(id, { is_active: status });
      if (resp) {
        getData();
        toast.success("Updated successfully");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  useDynamicTitle("Forms");
  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        title="Web Forms"
        icon="forms"
        submitIcon={<Plus size={20} />}
        addForm
        toggle={toggle}
      />
      <div className="manageFieldRightSettings">
        <FormCreatorTable
          list={formList}
          isLoading={isLoading}
          handleChangeStatus={handleChangeStatus}
        />
      </div>
      <FormCreatorInfoPopup isOpen={isOpen} toggle={toggle} />
    </CompanyProfileDetailsLayout>
  );
}

export default SettingsFormCreator;
