// import React, { useState } from "react";
// import { createChatbot } from "@services/chatbot.service";
// import { toast } from "sonner";
// import { getValue } from "@utils/lodash";
// import { useNavigate, useParams } from "react-router-dom";
// import BackSvgComponent from "@assets/svg/back-link";
// import ButtonComponent from "@components/Form/Button/Button";
// import InputRuleForm from "@components/InputRuleForm/form";

// const BotBuilder = () => {
//   const params = useParams();
//   const navigate = useNavigate();
//   /* -------------------------------------------------------------------------- */
//   /*                               UseState Section                             */
//   /* -------------------------------------------------------------------------- */
//   const initialState = {
//     name: "",
//     description: "",
//   };
//   const [request, setRequest] = useState(initialState);
//   const [activeTab, setActiveTab] = useState(1);

//   /* -------------------------------------------------------------------------- */
//   /*                               API Section                                  */
//   /* -------------------------------------------------------------------------- */

//   const [createLoading, setCreateLoading] = useState(false);
//   const handleSubmit = async () => {
//     try {
//       setCreateLoading(true);
//       let resp = await createChatbot(request);
//       if (resp) {
//         toast.success("Created Successfully");
//         navigate(
//           `/${getValue(params, `orgId`, "")}/chatbot/group/${getValue(
//             resp,
//             `data.id`,
//             ""
//           )}`
//         );
//         setCreateLoading(false);
//       } else {
//         setCreateLoading(false);
//       }
//     } catch (error) {
//       setCreateLoading(false);
//     }
//   };
//   /* -------------------------------------------------------------------------- */
//   /*                               Onchange section                             */
//   /* -------------------------------------------------------------------------- */
//   return (
//     <div>
//       <div className={"company-profile-subheader-wrapper_bot"}>
//         <div className="d-flex align-items-center justify-content-between w-100">
//           <div className="d-flex align-items-center">
//             <div
//               onClick={() =>
//                 navigate(`/${getValue(params, `orgId`, "")}/chatbot`)
//               }
//             >
//               <BackSvgComponent />
//             </div>
//             <p className="small_text__16 ms-3">
//               {activeTab === 1 && "Bot builder"}
//               {activeTab === 2 && "Bot profile"}
//               {activeTab === 3 && "Configurations"}
//             </p>
//           </div>
//           <div className="d-flex align-items-center">
//             {activeTab === 1 && (
//               <ButtonComponent
//                 buttonType="primary"
//                 buttonText={"Submit"}
//                 onClickHandler={() => handleSubmit()}
//               />
//             )}
//           </div>
//         </div>
//       </div>
//       <div
//         className={` w-50  add-overflow-y  ${
//           activeTab !== 4 && "p-5 mb-5 manageFieldRight"
//         }`}
//       >
//         <>
//           <div className="">
//             <p className="form-label">Name your Bot</p>
//             <InputRuleForm
//               inputType="TEXT"
//               name="name"
//               value={getValue(request, `name`, "")}
//               placeholder="Enter a Name"
//               required
//               className="mb-2"
//               onChange={(e: any) =>
//                 setRequest({
//                   ...request,
//                   name: e.target.value,
//                 })
//               }
//             />
//           </div>
//           <div className="mt-3">
//             <p className="form-label">Describe your bot</p>
//             <InputRuleForm
//               inputType="TEXTAREA"
//               name="description"
//               value={getValue(request, `description`, "")}
//               placeholder="Enter a Description"
//               required
//               onChange={(e: any) =>
//                 setRequest({
//                   ...request,
//                   description: e.target.value,
//                 })
//               }
//             />
//           </div>
//         </>
//       </div>
//     </div>
//   );
// };

// export default BotBuilder;

import { useEffect, useState } from "react";
import BotBuilderHeader from "../components/bot-builder-header";
import BotBuilderStepOne from "../components/bot-builder-step-1";
import BotBuilderStepThree from "../components/bot-builder-step-3";
import { createChatbot } from "@services/chatbot.service";
import { toast } from "sonner";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { geChatbotAvatar } from "@services/fb.service";
import { chatbotAvatarmediaUpload } from "@services/upload.service";
import { createChatbotSetup } from "@services/chatbot.service";
import BotDemo from "../components/bot-demo";

const BotGroupBuilder = () => {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const initialState = {
    name: "",
    platform: getValue(params, "type", ""),
    description: "",
    position: "RIGHT_BOTTOM",
    channels: ["WEBSITE"],
    supported_languages: [],
    response_interval: 2,
    idle_chat: true,
    idle_chat_time: 5,
    idle_chat_response:
      "Sorry we did not hear from you, please connect here when you're available.",
    avatar: "",
    theme_color: "#4cc793",
    enable_email_notification: true,
    send_email_notification_to: "",
  };
  const [request, setRequest] = useState(initialState);
  const resetRequest = () => {
    setRequest(initialState);
  };

  const [activeTab, setActiveTab] = useState(1);
  const [selectedOption, setSelectedOption] = useState("Codeless");
  const [selectedBrand, setSelectedBrand] = useState("Randoo");
  const [selectedVisitor, setSelectedVisitor] = useState("All visitors");

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAvatarList();
  }, []);

  useEffect(() => {
    setRequest((prevState) => ({
      ...prevState,
      platform: getValue(params, "type", "") === "web" ? "WEB" : "WHATSAPP",
    }));
  }, [params]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [avatarList, setAvatarlist] = useState([]);
  const getAvatarList = async () => {
    try {
      let resp = await geChatbotAvatar("");
      if (resp) {
        setAvatarlist(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };

  const [createLoading, setCreateLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setCreateLoading(true);
      let payload = JSON.parse(JSON.stringify(request));
      payload["send_email_notification_to"] =
        getValue(payload, `send_email_notification_to.length`, 0) > 0
          ? getValue(request, `send_email_notification_to`, []).join(",")
          : "";
      let resp = await createChatbot(payload);
      if (resp) {
        toast.success("Created Successfully");

        let obj = {
          id: "",
          chatbot_id: getValue(resp, `data.id`, ""),
          action_id: getValue(resp, `data.id`, ""),
          flow_id: getValue(resp, `data.id`, ""),
          name: "Welcome Card",
          position: { x: 400, y: 100 },
          seq_num: 1,
          component: "reply",
          source: "",
          is_end_chat: false,
          type: "reply",
          rendering_config: {
            action: "reply",
            replies: [
              {
                text: "Hi, I'm Super Bot, your digital assistant. What would you like to do?",
                delay_config: {
                  enabled: false,
                  info_messages: [""],
                  time: null,
                  type: "random",
                },
              },
            ],
            _meta: { type: "reply" },
            behaviour: "goto_next_action",
          },
        };
        let response = await createChatbotSetup(obj);
        navigate(
          `/${getValue(
            params,
            `orgId`,
            ""
          )}/chatbot/builder/create-flow/${getValue(resp, `data.id`, "")}`
        );
        setCreateLoading(false);
      } else {
        setCreateLoading(false);
      }
    } catch (error) {
      setCreateLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           Upload Avatar section                            */
  /* -------------------------------------------------------------------------- */

  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    Array.from(e.target.files).forEach(async (item: any, index: number) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await chatbotAvatarmediaUpload(formData);
        if (resp) {
          getAvatarList();
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };
  return (
    <div>
      {/* <HomeHeader /> */}
      <BotBuilderHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSubmit={handleSubmit}
        isLoading={createLoading}
      />
      <div className="d-flex ">
        <div
          className={` w-50 d-flex flex-column  add-overflow-y  
           p-5 manageFieldRight`}
        >
          <BotBuilderStepOne
            selectedOption={selectedOption}
            request={request}
            setRequest={setRequest}
          />

          <div className=" mt-2">
            <BotBuilderStepThree
              selectedVisitor={selectedVisitor}
              request={request}
              setRequest={setRequest}
              avatarList={avatarList}
              handleUploadDocuments={handleUploadDocuments}
            />
          </div>
        </div>
        <BotDemo request={request} />
      </div>
    </div>
  );
};

export default BotGroupBuilder;
