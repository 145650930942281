import Broadcast from "@pages/Private/Broadcast";
import WhatsappCommunicationPages from "@pages/Private/Inbox";
import CreateTemplateMessage from "@pages/Private/Broadcast/CreateTemplateMessage";
import CreateBroadcast from "@pages/Private/Broadcast/createBroadcast";
import EditBroadcast from "@pages/Private/Broadcast/EditBroadcast";

export const MarketingRoutes = [
  {
    path: "/:orgId/broadcast",
    name: "campaigns_whatsapp_broadcast",
    component: Broadcast,
  },
  {
    path: "/:orgId/broadcast/create-broadcast",
    name: "campaigns_whatsapp_broadcast",
    component: CreateBroadcast,
  },
  {
    path: "/:orgId/broadcast/:id",
    name: "campaigns_whatsapp_broadcast",
    component: EditBroadcast,
  },
  // {
  //   path: "/:orgId/broadcast/:id",
  //   name: "campaigns_whatsapp_broadcast",
  //   component: CreateBroadcast,
  // },
  {
    path: "/:orgId/broadcast/template/:id",
    name: "campaigns_whatsapp_broadcast",
    component: CreateTemplateMessage,
  },
  {
    path: "/:orgId/broadcast/create-template",
    name: "campaigns_whatsapp_broadcast",
    component: CreateTemplateMessage,
  },
  {
    path: "/:orgId/live-chat",
    name: "campaigns_whatsapp_broadcast",
    component: WhatsappCommunicationPages,
  },
];
