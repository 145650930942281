import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Switch } from "antd";
import React from "react";

function FaFieldComponent(props: any) {
  const { request, setRequest, key_value, title } = props;
  console.log(props);
  return (
    <div>
      <h6 className="header_text__16">{title}</h6>
      <div className="d-flex justify-content-end align-items-center mb-3 px-4">
        <p>Enabled</p>
        <Switch
          size="small"
          className="ms-2"
          checked={getValue(request, `config.${key_value}.enabled`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              config: {
                ...request.config,
                [key_value]: {
                  ...request.config[key_value],
                  enabled: !getValue(
                    request,
                    `config.${key_value}.enabled`,
                    false
                  ),
                },
              },
            })
          }
        />
      </div>
      <div className="p-4 border border-radius__5">
        <InputRuleForm
          inputType="TEXT"
          placeholder="Enter Label"
          label="Label"
          name="display_name"
          value={getValue(request, `config.${key_value}.display_name`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              config: {
                ...request.config,
                [key_value]: {
                  ...request.config[key_value],
                  display_name: e.target.value,
                },
              },
            })
          }
          validLeft
        />
        <InputRuleForm
          inputType="TEXT"
          placeholder="Enter Placeholder"
          label="Placeholder"
          name="placeholder"
          value={getValue(request, `config.${key_value}.placeholder`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              config: {
                ...request.config,
                [key_value]: {
                  ...request.config[key_value],
                  placeholder: e.target.value,
                },
              },
            })
          }
          validLeft
        />
        <div className="d-flex justify-content-end align-items-center mt-3">
          <p>Required</p>
          <Switch
            size="small"
            className="ms-2"
            checked={getValue(request, `config.${key_value}.required`, false)}
            onChange={(e: any) =>
              setRequest({
                ...request,
                config: {
                  ...request.config,
                  [key_value]: {
                    ...request.config[key_value],
                    required: !getValue(
                      request,
                      `config.${key_value}.required`,
                      false
                    ),
                  },
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FaFieldComponent;
