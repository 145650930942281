import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import { getFbAdManagerLeadFormsByID } from "@services/admanager.service";
import { getValue } from "@utils/lodash";
import FacebookLeadList from "./pages";
import Navbar from "../../common/navbar";
import BackButton from "@components/custom/BrowserBack";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";

function AdsLeadInfo(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const views = [
    {
      id: "1",
      name: "Lead Forms",
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getFbAdManagerLeadFormsByID(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        backButton
        titleLoading={isLoading}
        title="Lead Forms"
      />
      <div className="w-100">
        {/* <div className="d-flex gap-1 align-items-center pt-4">
            <BackButton className="ms-4 mt-3" />
            <Navbar
              views={views}
              route={`/settings/crm/ads/lead-forms/${getValue(
                params,
                `id`,
                ""
              )}`}
            />
          </div> */}
        <FacebookLeadList isLoading={isLoading} list={info} />
      </div>
    </CompanyProfileDetailsLayout>
  );
}

export default AdsLeadInfo;
