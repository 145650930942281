import React, { useEffect } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import Quote from "@editorjs/quote";
import CodeTool from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import Marker from "@editorjs/marker";
import Warning from "@editorjs/warning";
import LinkTool from "@editorjs/link";
import RawTool from "@editorjs/raw";
import Paragraph from "@editorjs/paragraph";
import { getValue } from "@utils/lodash";
import { config } from "env";
import { mediaUpload } from "@services/upload.service";

const ContentEditor = ({ request, setRequest, isDataFetched }) => {
  useEffect(() => {
    const editor = new EditorJS({
      holder: "editorjs",
      tools: {
        header: {
          class: Header,
          inlineToolbar: ["link"],
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        embed: Embed,
        table: Table,
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile: async (file) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("filename", file.name);
                formData.append("is_public", "true");
                formData.append("collection", "public-images");

                try {
                  const response = await mediaUpload(formData);
                  const data = response?.data;

                  if (data?.key) {
                    return {
                      success: 1,
                      file: {
                        url: `https://${config.URL}/${data.key}`,
                      },
                    };
                  } else {
                    throw new Error("Invalid upload response");
                  }
                } catch (error) {
                  return {
                    success: 0,
                    message: "Upload failed. Please try again.",
                  };
                }
              },
              uploadByUrl: async (url) => {
                try {
                  if (!url || !/^https?:\/\/\S+$/.test(url)) {
                    throw new Error("Invalid URL");
                  }
                  return {
                    success: 1,
                    file: {
                      url,
                    },
                  };
                } catch (error) {
                  return {
                    success: 0,
                    message: "Invalid URL. Please provide a valid image URL.",
                  };
                }
              },
            },
          },
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          config: {
            quotePlaceholder: "Enter a quote",
            captionPlaceholder: "Quote's author",
          },
        },
        code: CodeTool,
        delimiter: Delimiter,
        inlineCode: InlineCode,
        marker: Marker,
        warning: {
          class: Warning,
          config: {
            titlePlaceholder: "Enter a title",
            messagePlaceholder: "Enter a message",
          },
        },
        raw: RawTool,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
      },
      onChange: async (api) => {
        const content = await api.saver.save();
        setRequest((prev) => ({
          ...prev,
          description: content,
        }));
      },
      data: getValue(request, "description", {}),
      placeholder: "Start writing your article here by typing /",
      disableInlineToolbar: true,
    });

    return () => {
      editor.isReady
        .then(() => {
          editor.destroy();
        })
        .catch((e) => console.error("ERROR editor cleanup", e));
    };
  }, [setRequest, isDataFetched]);

  return <div id="editorjs" className="article-content-editor border"></div>;
};

export default ContentEditor;
