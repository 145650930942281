import React from "react";
import { Input, Select, Checkbox, Button, Table } from "antd";
import { get } from "lodash";

const { Option } = Select;

interface InputConfig {
  name: string;
  type: string;
  array: boolean;
  description: string;
  key?: number;
}

interface Request {
  config: {
    inputs: InputConfig[];
  };
}

interface CaAPITableProps {
  request: Request;
  setRequest: React.Dispatch<React.SetStateAction<Request>>;
}

const CaAPITable: React.FC<CaAPITableProps> = ({ request, setRequest }) => {
  const inputs = get(request, "config.inputs", []).map((input, index) => ({
    ...input,
    key: index,
  }));

  const addInput = () => {
    const newPair: InputConfig = {
      name: "",
      type: "text",
      array: false,
      description: "",
    };

    setRequest((prev) => ({
      ...prev,
      config: {
        ...prev.config,
        inputs: [...(prev.config?.inputs || []), newPair],
      },
    }));
  };

  const updateInput = (index: number, field: keyof InputConfig, value: any) => {
    setRequest((prev) => ({
      ...prev,
      config: {
        ...prev.config,
        inputs: prev.config.inputs.map((input, i) =>
          i === index ? { ...input, [field]: value } : input
        ),
      },
    }));
  };

  const deleteInput = (index: number) => {
    setRequest((prev) => ({
      ...prev,
      config: {
        ...prev.config,
        inputs: prev.config.inputs.filter((_, i) => i !== index),
      },
    }));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: InputConfig & { key: number }) => (
        <Input
          placeholder="Name"
          value={record.name}
          onChange={(e) => updateInput(record.key, "name", e.target.value)}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_: any, record: InputConfig & { key: number }) => (
        <Select
          value={record.type}
          onChange={(value) => updateInput(record.key, "type", value)}
          style={{ width: "100%" }}
        >
          <Option value="text">Text</Option>
        </Select>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_: any, record: InputConfig & { key: number }) => (
        <Input
          placeholder="Description"
          value={record.description}
          onChange={(e) =>
            updateInput(record.key, "description", e.target.value)
          }
        />
      ),
    },
    {
      title: "Array",
      dataIndex: "array",
      key: "array",
      render: (_: any, record: InputConfig & { key: number }) => (
        <Checkbox
          checked={record.array}
          onChange={(e) => updateInput(record.key, "array", e.target.checked)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: InputConfig & { key: number }) => (
        <Button type="link" onClick={() => deleteInput(record.key)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Table dataSource={inputs} columns={columns} pagination={false} />
      <Button type="dashed" onClick={addInput} style={{ marginTop: "20px" }}>
        + Add data input
      </Button>
    </div>
  );
};

export default CaAPITable;
