import { getColorCode, getTextColor } from "@common/colors";
import { convertCurrentDate } from "@common/date-helpers";
import {
  capitalizeFirstLetter,
  getOnlyFirstLetterOfWord,
} from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Badge, Dropdown, MenuProps, Select } from "antd";
import {
  ChevronDown,
  Ellipsis,
  Forward,
  MoreVertical,
  Reply,
} from "lucide-react";

const TicketAllDetailsViewEdit = (props: any) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item (disabled)
        </a>
      ),
      disabled: true,
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item (disabled)
        </a>
      ),
      disabled: true,
    },
    {
      key: "4",
      danger: true,
      label: "a danger item",
    },
  ];

  return (
    <>
      <div className="">
        <div className="ticket-detail-time-wrapper">
          <p className="ticket-detail-time-container">
            {convertCurrentDate(getValue(props, "data.created_at", ""))}
          </p>
        </div>
        <div className="ticket-detail-message-wrapper my-3">
          <div
            className="image-container"
            style={{
              background: getColorCode(
                getOnlyFirstLetterOfWord(
                  getValue(props, `data.owner.first_name`, "")
                )
              ),
              color: getTextColor(
                getColorCode(
                  getOnlyFirstLetterOfWord(
                    getValue(props, `data.owner.first_name`, "")
                  )
                )
              ),
            }}
          >
            <p className="header-text">
              {getOnlyFirstLetterOfWord(
                getValue(props, `data.owner.first_name`, "")
              )}
            </p>
          </div>
          <div className="w-100">
            <div className="d-flex align-items-center gap-3 justify-content-between">
              <div className="d-flex align-items-center gap-3 ">
                <h6 className="header_text__18">
                  {capitalizeFirstLetter(
                    getValue(props, "data.ticket_name", "")
                  )}
                </h6>
                <Badge status="default" />
                <Select
                  defaultValue="lucy"
                  style={{ width: 120 }}
                  variant="borderless"
                  //   onChange={handleChange}
                  options={[
                    { value: "jack", label: "Email" },
                    { value: "lucy", label: "Whatsapp" },
                    { value: "Yiminghe", label: "Facebook" },
                    { value: "disabled", label: "Instagram", disabled: true },
                  ]}
                />
              </div>

              <div className="d-flex align-items-center gap-3">
                <p className="small_text__13">06.47 </p>
                <Forward size={16} className="cursor-pointer" />
                <Dropdown menu={{ items }} className="cursor-pointer">
                  <MoreVertical size={16} />
                </Dropdown>
              </div>
            </div>
            <p className="py-2 small_text__14">
              {getValue(props, "data.ticket_description", "")}
            </p>
            <div className="d-flex align-items-center gap-1 justify-content-center header_blue_text__14 cursor-pointer">
              See more <ChevronDown size={16} />
            </div>
          </div>
        </div>
      </div>
      <p className="small_text__14 text-center py-1">
        Conversation created from email via <b>test@gmail.com</b>
      </p>
      <p className="small_text__14 text-center pb-2">
        Coke Orellana was automatically sent the email notification{" "}
        <b>Your ticket 'testing ticket 1' has been recieved</b> on 29 Oct 2026
        09:16
      </p>
      <p className="small_text__14 text-center pb-2">
        Coke Orellana was automatically sent the email notification{" "}
        <b>Your ticket 'testing ticket 1' has been recieved</b> on 29 Oct 2026
        09:22
      </p>
      <div className="mt-3">
        <div>
          <div className="ticket-detail-time-wrapper">
            <p className="ticket-detail-time-container">
              {convertCurrentDate(getValue(props, "data.created_at", ""))}
            </p>
          </div>
          <div className="ticket-detail-message-wrapper mt-3 mb-1">
            <div>
              <div
                className="image-container"
                style={{
                  background: getColorCode(
                    getOnlyFirstLetterOfWord(
                      getValue(props, `data.owner.first_name`, "")
                    )
                  ),
                  color: getTextColor(
                    getColorCode(
                      getOnlyFirstLetterOfWord(
                        getValue(props, `data.owner.first_name`, "")
                      )
                    )
                  ),
                }}
              >
                <p className="header-text">
                  {getOnlyFirstLetterOfWord(
                    getValue(props, `data.owner.first_name`, "")
                  )}
                </p>
              </div>
            </div>
            <div className="w-100">
              <div className="d-flex align-items-center gap-3 justify-content-between">
                <h6 className="header_text__18">Coke Orellana</h6>
                <div className="d-flex align-items-center gap-3">
                  <p className="small_text__13">06.47 </p>
                  <div className="d-flex align-items-center gap-2">
                    <Reply size={16} className="cursor-pointer" />
                    <Forward size={16} className="cursor-pointer" />
                  </div>
                  <Dropdown menu={{ items }} className="cursor-pointer">
                    <MoreVertical size={16} />
                  </Dropdown>
                </div>
              </div>
              <p className="py-2 small_text__14">
                Hello, how may i assist today?
              </p>
              <Ellipsis size={18} className="mt-3 cursor-pointer" />
            </div>
          </div>
          <p className="small_text__13">Sent at 11:47</p>
        </div>
      </div>
    </>
  );
};

export default TicketAllDetailsViewEdit;
