import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getCategories = (queryRequest: string) =>
  get(`${config.API_URL}/solutions/categories?${queryRequest}`);

export const getCategoryFolders = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/solutions/categories/${id}/folders?${queryRequest}`);

export const getSpecificCategory = (id: string) =>
  get(`${config.API_URL}/solutions/categories/${id}`);

export const getSpecificCategoryFolder = (id: string) =>
  get(`${config.API_URL}/solutions/categories/folders/${id}`);

export const getSpecificArticle = (id: string) =>
  get(`${config.API_URL}/solutions/folders/articles/${id}`);

export const getArticleVersions = (id: string) =>
  get(`${config.API_URL}/solutions/articles/${id}/versions`);

export const createCategory = (payload: object) =>
  post(`${config.API_URL}/solutions/categories`, payload);

export const createCategoryFolder = (payload: object) =>
  post(`${config.API_URL}/solutions/categories/folders`, payload);

export const createArticle = (payload: object) =>
  post(`${config.API_URL}/solutions/folders/articles`, payload);

export const updateCategory = (id: string, payload: object) =>
  patch(`${config.API_URL}/solutions/categories/${id}`, payload);

export const updateCategoryFolder = (id: string, payload: object) =>
  patch(`${config.API_URL}/solutions/categories/folders/${id}`, payload);

export const updateArticle = (id: string, payload: object) =>
  patch(`${config.API_URL}/solutions/folders/articles/${id}`, payload);

export const updateArticlesStatus = (payload: object) =>
  patch(`${config.API_URL}/solutions/articles/bulk/active-inactive`, payload);

export const deleteCategory = (id: string) =>
  Delete(`${config.API_URL}/solutions/categories/${id}`);

export const deleteCategoryFolder = (id: string) =>
  Delete(`${config.API_URL}/solutions/categories/folders/${id}`);

export const deleteArticle = (id: string) =>
  Delete(`${config.API_URL}/solutions/folders/articles/${id}`);
