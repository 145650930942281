import SettingsSkills from "@pages/Private/Settings/Skills";
import SettingSkillDetails from "@pages/Private/Settings/Skills/SkillDetails";

export const SettingsSkillsRoutes = [
  {
    path: "/:orgId/settings/helpdesk/assignment-rules",
    name: "settings_templates_business_hours",
    component: SettingsSkills,
  },
  {
    path: "/:orgId/settings/helpdesk/assignment-rules/create",
    name: "settings_templates_business_hours",
    component: SettingSkillDetails,
  },
  {
    path: "/:orgId/settings/helpdesk/assignment-rules/edit/:id",
    name: "settings_templates_business_hours",
    component: SettingSkillDetails,
  },
];
