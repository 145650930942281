import EditSvgComponent from "@assets/svg/edit";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import ButtonComponent from "@components/Form/Button/Button";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import PropertySettingsLayout from "@layouts/PropertyLayout/property-settings-layout";
import React, { useEffect, useState } from "react";
import AddTaxGroupCategory from "./components/tax-group-category";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  createTaxCategory,
  getAllTaxCategory,
  getAllTaxRate,
  updateTaxCategory,
} from "@services/properties/settings/tax.service";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { toast } from "sonner";
import Loader from "@components/common/Loader/loading";

const PropertyGroupTaxSettings = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
    getTaxData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTaxCategory(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.taxCategories`, []));
        setIsLoading(false);
        if (editId) {
          let filtered = getValue(resp, `data.taxCategories`, []).find(
            (item: object) => getValue(item, `id`, "") === editId
          );
          setSelectedIds(
            getValue(filtered, `tax_rate`, []).map((item: object) =>
              getValue(item, `id`, "")
            )
          );
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [taxList, setTaxList] = useState([]);
  const getTaxData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTaxRate(queryRequest);
      if (resp) {
        setTaxList(
          getValue(resp, `data.taxRates`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `tax_name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Edit Section                                 */
  /* -------------------------------------------------------------------------- */
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [editId, setEditId] = useState("");
  const handleEdit = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    setRequest({
      ...request,
      category_name: getValue(obj, `category_name`, ""),
    });
    toggleTaxGroupCategory();
    setSelectedIds(
      getValue(obj, `tax_rate`, []).map((item: object) =>
        getValue(item, `id`, "")
      )
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                          Create/Edit Section                               */
  /* -------------------------------------------------------------------------- */
  const [openTaxGroupCategory, setopenTaxGroupCategory] = useState(false);
  const toggleTaxGroupCategory = () => {
    setopenTaxGroupCategory(!openTaxGroupCategory);
  };
  const intialState = {
    category_name: "",
    property_id: getValue(params, `id`, ""),
    tax_rate_ids: [],
  };
  const [request, setRequest] = useState(intialState);

  const reset = () => {
    setRequest(intialState);
    setEditId("");
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let requestPayload = JSON.parse(JSON.stringify(request));
      requestPayload["tax_rate_ids"] = getValue(
        request,
        `tax_rate_ids`,
        []
      ).map((item: object) => getValue(item, `id`, ""));
      let resp;
      if (!editId) {
        resp = await createTaxCategory(
          removeNullOrUndefinedProperties(requestPayload)
        );
      } else {
        resp = await updateTaxCategory(
          editId,
          removeNullOrUndefinedProperties(requestPayload)
        );
      }
      if (resp) {
        toast.success(`${editId ? "Updated" : "Created"} successfully`);
        reset();
        setSubmitLoading(false);
        getData();
        toggleTaxGroupCategory();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      console.error(error);
      setSubmitLoading(false);
    }
  };

  return (
    <PropertyDetailsLayout>
      <PropertySettingsLayout>
        <div className=" w-100 overflow-y-auto">
          <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
            <h3 className="header_text__20">Group tax category</h3>
            <ButtonComponent
              buttonType="primary"
              buttonText="Add tax group category"
              onClickHandler={toggleTaxGroupCategory}
            />
          </div>
          <p className="my-3">
            Use this page to create and edit hotel group tax categories. You can
            combine two or more Tax Categories to create a Group Tax Category
          </p>
          {isLoading ? (
            <Loader />
          ) : getValue(list, `length`, 0) > 0 ? (
            list.map((item: object) => {
              return (
                <div className=" mt-4 mb-4  p-3 rounded-3 cursor-pointer room-box-shadow mx-3">
                  <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
                    <h3 className="header_text__16">
                      {getValue(item, `category_name`, "")}
                    </h3>
                    <div onClick={() => handleEdit(item)}>
                      <EditSvgComponent />
                    </div>
                  </div>
                  <div className="">
                    <div className="row mt-3">
                      <p className="col-2 small_text__14">Name:</p>
                      <p className=" col-9 small_text__16">
                        {getValue(item, `category_name`, "")}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : null}
        </div>
      </PropertySettingsLayout>
      <CommonRightPopup
        isPopupVisible={openTaxGroupCategory}
        toggle={() => {
          toggleTaxGroupCategory();
          setEditId("");
          editId && reset();
          editId && setSelectedIds([]);
        }}
        width="500px"
        title="Add tax rate"
        hideNew
        second_title={
          submitLoading ? "Please wait..." : editId ? "Update" : "Save"
        }
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        permissions={getValue(props, `permissions`, [])}
      >
        <AddTaxGroupCategory
          request={request}
          setRequest={setRequest}
          taxList={taxList}
          selectedIds={selectedIds}
          editId={editId}
          getData={getData}
        />
      </CommonRightPopup>
    </PropertyDetailsLayout>
  );
};

export default PropertyGroupTaxSettings;
