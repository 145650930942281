import { Link, useLocation } from "react-router-dom";
import { ConfigProvider, Menu } from "antd";
import { useState } from "react";
import SearchSvgComponent from "@assets/svg/search";
import { useStateContext } from "context/profileProvider";
import CompanyNavigationList from "./left-menu-tabs";
import { renderIcons } from "@common/Data/icons-data";

export default function CompanyProfileNavigation() {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const { themeColor } = useStateContext();

  let navigationItems = CompanyNavigationList();

  const filteredNavigation = navigationItems
    .map((category) => ({
      ...category,
      linkArray: category.linkArray.filter((link: any) =>
        link.linkTitle.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    }))
    .filter((category) => category.linkArray.length > 0);

  const menuItems = filteredNavigation.map((category) => ({
    key: category.title,
    label: category.title,
    children: category.linkArray.map((linkObj: any) => {
      const isActive = location.pathname.includes(linkObj.linkUrl);
      const dynamicColor = isActive ? themeColor : "#414141";
      return {
        key: linkObj.linkUrl,
        label: (
          <Link to={linkObj.linkUrl} className=" antmenu-sub-selected">
            {linkObj.linkTitle}
          </Link>
        ),
        icon: renderIcons(linkObj.path, dynamicColor, 16),
      };
    }),
  }));

  return (
    <div className="company-profile-details-wrapper__navigation">
      <div className="settings-search-wrapper">
        <div className="settings_search">
          <input
            type="search"
            name="search-settings"
            id="search-settings"
            className="settings-search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search settings"
          />
          <div className="search-icon">
            <SearchSvgComponent color="#8696a0" size={16} />
          </div>
        </div>
      </div>

      <ConfigProvider
        theme={{
          token: {
            // Valid token names based on Ant Design's `AliasToken`
            colorBgContainer: "#ffffff", // Background color for containers (equivalent to itemBg)
            colorPrimary: "#fff", // Primary color (used for selected items)
            colorText: "rgba(0, 0, 0, 0.88)", // Text color (equivalent to itemColor)
            // colorTextHover: 'rgba(0, 0, 0, 0.88)', // Hover text color
            colorBgTextHover: "rgba(0, 0, 0, 0.06)", // Hover background color (equivalent to itemHoverBg)
            colorBgTextActive: "#e6f4ff", // Active background color (equivalent to itemSelectedBg)
            // colorTextActive: '#1677ff', // Active text color (equivalent to itemSelectedColor)
            controlHeight: 40, // Control height (equivalent to itemHeight)
            colorError: "#ff4d4f", // Danger item color (equivalent to dangerItemColor)
            colorErrorBgActive: "#fff2f0", // Danger item selected background
          },
        }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          defaultOpenKeys={[
            "General",
            "Customization",
            "Organisation",
            "Inbox",
            "Marketing",
            "Automation",
            "Reports",
            "Lead Capture",
            "Channel",
            "Team",
            "Templates",
            "Configuration",
          ]}
          items={menuItems}
          className="settings-left-menu"
        />
      </ConfigProvider>
    </div>
  );
}
