import CopySvgComponents from "@assets/svg/copy";
import { CopyToClipboard } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import PreviewScreen from "./preview";
import { CopyBlock, dracula } from "react-code-blocks";
import QRCode from "react-qr-code";
import InputRuleForm from "@components/InputRuleForm/form";
import { Tooltip } from "react-tooltip";
import { getPublicGlobalFormInfo } from "@services/web-forms.service";
import FormBuilder from "@components/Builder/FormBuilder";
import {
  FormRequestHelper,
  FormRequestPayloadObj,
} from "@components/helpers/request-helper";
import { config } from "../../../../../env";

function ManageFormStep3(props: any) {
  const { params, request, info } = props;
  const [activeTab, setActiveTab] = useState(0);
  const toggle = (index: any) => {
    setActiveTab(index);
  };

  const views = [
    {
      id: "1",
      name: "Iframe",
    },
    {
      id: "2",
      name: "Script",
    },
    {
      id: "3",
      name: "HTML Code",
    },
  ];
  const apiViews = [
    {
      id: "2",
      name: "API Documentation",
    },
  ];

  const generateIframe = (url: any) => {
    const cleanedUrl = url.replace(/%20/g, ""); // Remove all occurrences of %20 in the URL
    return `<iframe
        src={${cleanedUrl}}
        width="100%"
        height="600px"
        frameBorder="0"
        title="Embedded Page"
      />`;
  };

  const generateScriptTag = (src: any, id: string) => {
    const script = `<script src="${src}"${
      id ? ` id="${id}"` : ""
    } async></script>`;
    return script;
  };

  const generateHTMLCode = () => {
    const htmlStr = ReactDOMServer.renderToStaticMarkup(
      <PreviewScreen formFields={props.droppedFormFields} />
    );

    return `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Form Preview</title>
        <!-- Add any CSS stylesheets or other meta tags as needed -->
    </head>
    <body>${htmlStr}</body>
    </html>`;
  };
  const qrData = `${config.API_URL}/public/route/web-form?form_id=${getValue(
    info,
    `form_id`,
    ""
  )}`;

  const shareTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(qrData)}`,
      "_blank"
    );
  };

  const shareFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        qrData
      )}`,
      "_blank"
    );
  };

  const shareWhatsApp = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(qrData)}`,
      "_blank"
    );
  };

  useEffect(() => {
    getFormData();
  }, []);
  const [fields, setFields] = useState([]);
  const getFormData = async () => {
    try {
      let resp = await getPublicGlobalFormInfo(
        getValue(info, `org_id`, ""),
        getValue(info, `route_name`, "")
      );
      if (resp) {
        let fields = getValue(resp, `data.form_fields`, []).map(
          (item: object) => ({
            label: getValue(item, `display_label`, ""),
            api_name: getValue(item, `module_field.api_name`, ""),
            input_type: getValue(item, `module_field.input_type`, ""),
            placeholder: getValue(item, `module_field.placeholder`, ""),
            required: getValue(item, `required`, false),
            custom: getValue(item, `module_field.custom`, false),
            system_required: getValue(item, `module_field.system_required`, ""),
          })
        );
        generateObject(fields);
        setFields(fields);
      }
    } catch (error) {}
  };

  const generateObject = (formData: any) => {
    return JSON.stringify(FormRequestPayloadObj(formData));
  };
  return (
    <div className="manageFieldRightSettings  grayed-background add-overflow-y">
      <div className="d-flex justify-content-center">
        <div className="w-75 bg-white p-5">
          <div className="d-flex gap-3 justify-content-between">
            <div>
              <h6 className="header_text__18">Share a link to your form</h6>
              <p className="small_text__14 mt-2">
                Your form has been published in the below link. Copy this secure
                link and share it to anyone online.
              </p>
              <div className="d-flex align-items-center">
                <div className="w-100 mb-2">
                  <InputRuleForm
                    inputType="TEXT"
                    value={`${window.location.origin}/${getValue(
                      info,
                      `org_id`,
                      ""
                    )}/settings/web-forms/${getValue(info, `route_name`, "")}`}
                    disabled
                  />
                </div>
                <div
                  className="ms-3 cursor-pointer"
                  onClick={() =>
                    CopyToClipboard(
                      `${window.location.origin}/${getValue(
                        info,
                        `org_id`,
                        ""
                      )}/settings/web-forms/${getValue(info, `route_name`, "")}`
                    )
                  }
                >
                  <CopySvgComponents size={18} />
                </div>
              </div>
              <div className="mt-4">
                <h6 className="header_text__16">Share via Social media</h6>
                <div className="d-flex gap-2 align-items-center mt-1">
                  <button
                    onClick={shareTwitter}
                    className="form-social-share p-1"
                  >
                    <img src="/images/brand/twitter.png" />
                  </button>
                  <button
                    onClick={shareFacebook}
                    className="form-social-share p-1"
                  >
                    <img src="/images/brand/facebook.png" />
                  </button>
                  <button onClick={shareWhatsApp} className="form-social-share">
                    <img src="/images/whatsapp.png" />
                  </button>
                </div>
              </div>
            </div>
            <div className="px-5 qr-border-left d-flex flex-column align-items-center justify-content-center">
              <h6 className="header_text__18 mb-2 qr-code">
                QR Code
                <Tooltip className="tooltip" anchorSelect={`.qr-code`}>
                  /{getValue(info, `org_id`, "")}/settings/web-forms/
                  {getValue(info, `route_name`, "")}
                </Tooltip>
              </h6>
              <QRCode value={qrData} size={100} />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center small_text__14 my-4">
            ----------------------- OR -----------------------
          </div>
          {/* <div className="mb-4">
            <h6 className="header_text__18">Embed this form in a web page</h6>
            <p className="small_text__14 mt-2">
              Please use the below code to embed the form within your web page
              or app.
            </p>
          </div> */}
          <ul
            className="d-flex align-items-center justify-content-between mt-3"
            style={{ borderBottom: "1px solid #efefef" }}
          >
            <div className="d-flex align-items-center justify-content-start ms-4">
              {apiViews.map((tab, index) => {
                return (
                  <li
                    key={`pending-${index}`}
                    className={`${
                      activeTab === index
                        ? "activebar__main-body-list_active"
                        : "activebar__main-body-list"
                    }`}
                    onClick={() => toggle(index)}
                  >
                    <a className={"activebar__main-body-link p-3"}>
                      {tab.name}
                    </a>
                  </li>
                );
              })}
            </div>
          </ul>
          {activeTab === 0 && (
            <div className="mt-4">
              <div>
                <h6 className="header_text__16 mt-3">
                  Connect your website contact form using below API
                </h6>
                <div className="p-3">
                  <p className="small_text__14 mt-3 color_primary">
                    Method : POST
                  </p>
                  <div className="d-flex  align-items-center">
                    <p className="small_text__14 mt-2">
                      {`${
                        config.API_URL
                      }/public/route/web-form?form_id=${getValue(
                        info,
                        `form_id`,
                        ""
                      )}`}
                    </p>
                    <div
                      className="cursor-pointer ms-3"
                      onClick={() =>
                        CopyToClipboard(
                          `${
                            config.API_URL
                          }/public/route/web-form?form_id=${getValue(
                            info,
                            `form_id`,
                            ""
                          )}`
                        )
                      }
                    >
                      <CopySvgComponents size={18} />
                    </div>
                  </div>

                  <div className="mt-4">
                    <p className="small_text__14 mt-3 color_primary">Payload</p>
                    <p className="small_text__14 mt-2">
                      {generateObject(fields)}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <h6 className="header_text__16 mt-3">
                  To know about your form
                </h6>
                <div className="p-3">
                  <p className="small_text__14 mt-3 color_primary">
                    Method : GET
                  </p>
                  <div className="d-flex  align-items-center">
                    <p className="small_text__14 mt-2">{`${
                      config.API_URL
                    }/public/route/${getValue(
                      info,
                      `org_id`,
                      ""
                    )}/web-form/${getValue(info, `route_name`, "")}`}</p>
                    <div
                      className="cursor-pointer ms-3"
                      onClick={() =>
                        CopyToClipboard(
                          `${config.API_URL}/public/route/${getValue(
                            info,
                            `org_id`,
                            ""
                          )}/web-form/${getValue(info, `route_name`, "")}`
                        )
                      }
                    >
                      <CopySvgComponents size={18} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <ul
            className="d-flex align-items-center justify-content-between mt-3"
            style={{ borderBottom: "1px solid #efefef" }}
          >
            <div className="d-flex align-items-center justify-content-start ms-4">
              {views.map((tab, index) => {
                return (
                  <li
                    key={`pending-${index}`}
                    className={`${
                      activeTab === index
                        ? "activebar__main-body-list_active"
                        : "activebar__main-body-list"
                    }`}
                    onClick={() => toggle(index)}
                  >
                    <a className={"activebar__main-body-link p-3"}>
                      {tab.name}
                    </a>
                  </li>
                );
              })}
            </div>
          </ul>
          {activeTab === 0 && (
            <div className="">
              <h6 className="header_text__16 mt-3 mb-1">Embeded IFrame code</h6>
              <CopyBlock
                text={generateIframe(`${window.location.origin}/${getValue(
                  info,
                  `org_id`,
                  ""
                )}
              /settings/web-forms/
              ${getValue(request, `route_name`, "")}`)}
                language="jsx"
                showLineNumbers={true}
                theme={dracula}
                codeBlock
                // wrapLines
              />
            </div>
          )}
          {activeTab === 1 && (
            <div className="">
              <h6 className="header_text__16 mt-3 mb-1">Embeded Source code</h6>
              <CopyBlock
                text={generateScriptTag(
                  `${window.location.origin}/${getValue(info, `org_id`, "")}
              /settings/web-forms/
              ${getValue(request, `route_name`, "")}`,
                  ""
                )}
                language="jsx"
                showLineNumbers={true}
                theme={dracula}
                codeBlock
                // wrapLines
              />
            </div>
          )}
          {activeTab === 2 && (
            <div className="">
              <h6 className="header_text__16 mt-3 mb-1">Embeded HTML code</h6>
              <CopyBlock
                text={generateHTMLCode()}
                language="jsx"
                showLineNumbers={true}
                theme={dracula}
                codeBlock
                // wrapLines
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default ManageFormStep3;
