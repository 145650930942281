import React from "react";

const TextTab = () => {
  return (
    <div className="training-data-cotnent-container">
      <h6 className="header_text__18 mb-3">Text</h6>
      <textarea
        className="form-control"
        id="exampleFormControlTextarea1"
        rows={12}
        value="Build a custom GPT for your business, connect to your WhatsApp or website, and let it handle customer support, lead gen, user engagement, and more."
      />
      <p className="small_text__14 pt-3 text-center">146 characters</p>
    </div>
  );
};

export default TextTab;
