import React, { useEffect, useMemo, useState } from "react";
import CommonSendEmailInfoPopup from "./popup/send-email-info";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import {
  getDraftEmailList,
  getEmailConfig,
  getEmailList,
  getSpecificEmailThread,
  saveDraftEmail,
  sendEmail,
  updateDraftEmail,
} from "@services/email-config.service";
import { useStateContext } from "@context/profileProvider";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { getAvailableEmails } from "@services/workflow.service";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { mediaUpload } from "@services/upload.service";
import _ from "lodash";
import CommonSendEmailPopup from "./popup/Send-Email";
import MainBodyEmails from "./email-body";
import DraftConfirmModal from "./popup/draft-confirm-popup";

function CommonDetailEmailPage(props: any) {
  const { selectedModuleId, userId, userInfo } = useStateContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                                Email Section                               */
  /* -------------------------------------------------------------------------- */
  const { isPopupVisible, setPopupVisibility, handleSendEmail } = props;

  const [availableEmailInfo, setAvailableEmailInfo] = useState({});
  const [fromMails, setFromMails] = useState([]);
  const [orgEmails, setOrgEmails] = useState([]);
  const getAvailableEmailsList = async () => {
    try {
      let resp = await getAvailableEmails();
      if (resp) {
        setAvailableEmailInfo(getValue(resp, `data`, {}));
        const obj = getValue(resp, `data`, {});
        const userFrom = getValue(obj, `user.from`, "");
        const orgUserFrom = getValue(obj, `orgUser.from`, "");

        const transformedArray: any = [
          { label: userFrom, id: userFrom, name: userFrom, value: userFrom },
          {
            label: orgUserFrom,
            id: orgUserFrom,
            name: orgUserFrom,
            value: orgUserFrom,
          },
          ...getValue(obj, `orgEmails`, []).map((item: object) => ({
            label: getValue(item, `from`, ""),
            id: getValue(item, `from`, ""),
            name: getValue(item, `from`, ""),
            value: getValue(item, `from`, ""),
          })),
        ];

        const transformedArray1 = getValue(obj, `orgEmails`, [])
          .filter(
            (item: object) => getValue(item, `orgEmailId`, "") !== "default"
          )
          .map((item: object) => ({
            label: getValue(item, `from`, ""),
            id: getValue(item, `from`, ""),
            name: getValue(item, `from`, ""),
            value: getValue(item, `from`, ""),
          }));
        setFromMails(transformedArray);
        setOrgEmails(transformedArray1);
        setEmailRequest({
          ...emailRequest,
          from: orgUserFrom,
          to: [getValue(props, `data.email`, "")],
          replyTo: orgUserFrom ? [orgUserFrom] : [],
        });
      }
    } catch (error) {}
  };
  /* ------------------------  Send Email Section   ---------------------------- */

  const [textActive, setTextActive] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const emailInitalState = {
    subject: "",
    html: "",
    replyTo: "",
    text: "",
    to: [],
    cc: [],
    bcc: [],
    from: [],
  };
  const emailResetInitalState = {
    subject: "",
    html: "",
    text: "",
    replyTo: "",
    to: [getValue(props, `data.email`, "")],
    cc: [],
    bcc: [],
  };
  const [emailRequest, setEmailRequest] = useState<any>(emailInitalState);
  const resetEmail = () => {
    setEmailRequest({
      ...emailRequest,
      ...emailResetInitalState,
    });
    setDocumentList([]);
  };
  const handleCloseEmail = () => {
    setEmailRequest({
      ...emailRequest,
      ...emailResetInitalState,
    });
    setDocumentList([]);
    setPopupVisibility(false);
  };
  const handleChangeEditor = (name: string, value: string) => {
    let mainValue = value === "<p></p>" || value === "<p></p>\n" ? "" : value;
    setEmailRequest({
      ...emailRequest,
      [name]: value,
    });
  };
  const handleSubmitEmail = async () => {
    try {
      setEmailLoading(true);
      // if (textActive) {
      //   emailRequest["html"] = "";
      // } else {
      //   emailRequest["text"] = "";
      // }
      let emailReq: any = JSON.parse(JSON.stringify(emailRequest));
      emailReq["from"] = getValue(emailReq, `from`, "").includes("<")
        ? ""
        : getValue(emailReq, `from`, "");
      emailReq["replyTo"] =
        getValue(emailReq, `replyTo[${0}].length`, 0) > 0
          ? getValue(emailReq, `replyTo[${0}]`, [])
          : "";
      emailReq["subject"] =
        getValue(emailReq, `subject.length`, 0) > 0
          ? getValue(emailReq, `subject`, [])
          : "";
      emailReq["moduleId"] = await selectedModuleId(props.module);

      emailReq["integrationId"] = getValue(emailReq, `integrationId`, "")
        ? getValue(emailReq, `integrationId`, "")
        : getValue(configInfo, `integrationId`, "");

      emailReq["identityId"] = getValue(emailReq, `identityId`, "")
        ? getValue(emailReq, `identityId`, "")
        : getValue(props, `params.id`, "");
      emailReq["attachments"] =
        getValue(documentList, `length`, 0) > 0
          ? documentList.map((item: object) => getValue(item, `id`, ""))
          : [];
      let resp = await sendEmail(
        getValue(configInfo, `providerType`, ""),
        removeNullOrUndefinedProperties(emailReq)
      );
      if (resp) {
        toast.success("Email sent successfully");
        setEmailLoading(false);
        if (getValue(urlParams, `activeEmailSubTab`, "") == 2) {
          let payload = {
            ...urlParams,
            activeEmailSubTab: 1,
          };
          let queryRequest = QueryRequestHelper(payload);
          navigate(`${window.location.pathname}?${queryRequest}`);
          getEmails(false);
        } else {
          getEmails(false);
        }
        resetEmail();
        handleSendEmail();
      } else {
        setEmailLoading(false);
      }
    } catch (error) {
      setEmailLoading(false);
    }
  };

  /* ------------------------  Email info Section   ---------------------------- */

  const [isPopupInfoVisible, setPopupInfoVisibility] = useState(false);
  const [emailInfo, setEmailInfo] = useState([]);
  const handleCloseInfo = () => {
    setPopupInfoVisibility(!isPopupInfoVisible);
    setEmailInfo([]);
  };
  const getSpecificEmailInfo = async (threadId: string) => {
    try {
      let payload = {
        moduleId: await selectedModuleId(props.module),
        identityId: getValue(props, `params.id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSpecificEmailThread(threadId, queryRequest);
      if (resp) {
        setEmailInfo(getValue(resp, `data`, []));
        setPopupInfoVisibility(true);
      }
    } catch (error) {}
  };
  const handleReply = (val: string, index: number) => {
    handleCloseInfo();
    handleAppendState(val, index);
    setPopupVisibility(true);
  };
  const handleAppendState = (val: string, index: number) => {
    let from_email = getValue(emailInfo, `[${index}].from`, "")
      ? getValue(emailInfo, `[${index}].from`, "")
      : "";
    let to_email = getValue(emailInfo, `[${index}].to`, "")
      ? getValue(emailInfo, `[${index}].to`, "").split(",")
      : "";
    let cc_email = getValue(emailInfo, `[${index}].cc`, "")
      ? getValue(emailInfo, `[${index}].cc`, "").split(",")
      : "";
    let bcc_email = getValue(emailInfo, `[${index}].bcc`, "")
      ? getValue(emailInfo, `[${index}].bcc`, "").split(",")
      : "";
    let reply_to = getValue(emailInfo, `[${index}].replyTo`, "")
      ? getValue(emailInfo, `[${index}].replyTo`, "").split(",")
      : "";
    if (val === "reply") {
      setEmailRequest({
        ...emailRequest,
        from: from_email,
        to: to_email,
        replyTo: reply_to,
        // cc: cc_email,
        // bcc: bcc_email,
        subject: `Re:${getValue(emailInfo, `[${index}].subject`, "")}`,
        messageId: getValue(emailInfo, `[${index}].message_id`, ""),
        threadId: getValue(emailInfo, `[${index}].thread_id`, ""),
        html: `<p style="text-align:start;"><br><br><br></p>
      <p style="text-align:start;"><br><br><span style="color: rgb(0,0,0);font-size: small;font-family: Times;">---- On ${convertCurrentDateWithTime(
        getValue(emailInfo, `[${index}].created_at`, "")
      )}&lt;${getValue(
          emailInfo,
          `[${index}].from`,
          ""
        )}&gt;</span> <span style="color: rgb(0,0,0);font-size: small;font-family: Times;">wrote ----</span></p>
      `,
      });
    } else if (val === "reply_all") {
      setEmailRequest({
        ...emailRequest,
        from: from_email,
        to: to_email,
        cc: cc_email,
        bcc: bcc_email,
        replyTo: reply_to,
        subject: `Re:${getValue(emailInfo, `[${index}].subject`, "")}`,
        messageId: getValue(emailInfo, `[${index}].message_id`, ""),
        threadId: getValue(emailInfo, `[${index}].thread_id`, ""),
        html: `<p style="text-align:start;"><br><br><br></p>
        <p style="text-align:start;"><br><br><span style="color: rgb(0,0,0);font-size: small;font-family: Times;">---- On ${convertCurrentDateWithTime(
          getValue(emailInfo, `[${index}].created_at`, "")
        )}&lt;${getValue(
          emailInfo,
          `[${index}].from`,
          ""
        )}&gt;</span> <span style="color: rgb(0,0,0);font-size: small;font-family: Times;">wrote ----</span></p>
        `,
      });
    } else {
      setEmailRequest({
        ...emailRequest,
        from: from_email,
        to: to_email,
        // cc: cc_email,
        // bcc: bcc_email,
        replyTo: reply_to,
        subject: `Fwd:${getValue(emailInfo, `[${index}].subject`, "")}`,
        messageId: getValue(emailInfo, `[${index}].message_id`, ""),
        threadId: getValue(emailInfo, `[${index}].thread_id`, ""),
        html: `<p style="text-align:start;"><br><br></p>
        <p style="text-align:start;"></p>
        <p style="text-align:start;"><br></p>
        <p style="text-align:start;"><span style="color: rgb(0,0,0);font-size: small;font-family: Times;">========== Forwarded message ==========</span><br><span style="color: rgb(0,0,0);font-size: small;font-family: Times;">From:${getValue(
          emailInfo,
          `[${index}].from`,
          ""
        )}</span><br><span style="color: rgb(0,0,0);font-size: small;font-family: Times;">Date: ${convertCurrentDateWithTime(
          getValue(emailInfo, `[${index}].created_at`, "")
        )}</span><br><span style="color: rgb(0,0,0);font-size: small;font-family: Times;">Subject: ${getValue(
          emailInfo,
          `[${index}].subject`,
          ""
        )}</span><br><br><span style="color: rgb(0,0,0);font-size: small;font-family: Times;">To:&lt;${getValue(
          emailInfo,
          `[${index}].to`,
          ""
        )}&gt;</span></p>
        <p><span style="color: rgb(0,0,0);font-size: small;font-family: Times;">${decodeURIComponent(
          getValue(emailInfo, `[${index}].html`, "")
        )}</span></p>
        `,
      });
    }
  };

  /* ------------------------    Email Section   ---------------------------- */
  useEffect(() => {
    getAvailableEmailsList();
  }, []);
  const [activeEmailTab, setActiveEmailTab] = useState(1);
  useEffect(() => {
    if (
      getValue(urlParams, `activeTab`, "") == 4 &&
      (!getValue(urlParams, `activeEmailSubTab`, "") ||
        getValue(urlParams, `activeEmailSubTab`, "") == 1)
    ) {
      getEmails(true);
    }
    if (
      getValue(urlParams, `activeTab`, "") == 4 &&
      getValue(urlParams, `activeEmailSubTab`, "") == 2
    ) {
      getDraftsList(true);
    }
  }, [
    getValue(urlParams, `activeTab`, "") == 4,
    getValue(urlParams, `activeEmailSubTab`, ""),
  ]);
  //Emails
  const [loading, setIsLoading] = useState(false);
  const [emailList, setEmailList] = useState([]);
  //** API Section */
  const [configInfo, setConfigInfo] = useState({});
  const getEmails = async (loading: boolean) => {
    try {
      let payload = {
        page_size: 100,
        page_no: 1,
        moduleId: await selectedModuleId(props.module),
      };
      setIsLoading(loading);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getEmailList(
        getValue(props, `params.id`, ""),
        queryRequest
      );
      let config = await getEmailConfig();
      if (config) {
        setConfigInfo(getValue(config, `data`, {}));
      }

      if (resp) {
        setEmailList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getFromEmail = (email: string) => {
    const regex = /<([^>]+)>/;
    const match = email.match(regex);

    if (match && match.length > 1) {
      return match[1];
    } else {
      // Handle other cases if needed
      return email;
    }
  };

  /* ------------------------    Draft Section   ---------------------------- */
  const [isDraftOpen, setIsDraftOpen] = useState(false);
  const handleModalDraft = () => {
    setIsDraftOpen(!isDraftOpen);
  };
  const handleCancelDraft = () => {
    handleModalDraft();
    resetEmail();
  };
  const getDraftsList = async (loading: boolean) => {
    try {
      setIsLoading(loading);
      let payload = {
        page_size: 100,
        page_no: 1,
        // moduleFor: getValue(props, `module`, ""),
        moduleId: await selectedModuleId(props.module),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getDraftEmailList(
        getValue(props, `params.id`, ""),
        queryRequest
      );
      if (resp) {
        setEmailList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [draftId, setDraftId] = useState("");
  const getSpecificDraftInfo = async (item: object) => {
    setDraftId(getValue(item, `id`, ""));
    let from_email = getValue(item, `from`, "");
    let to_email = getValue(item, `to`, "")
      ? getValue(item, `to`, "").split(",")
      : "";
    let cc_email = getValue(item, `cc`, "")
      ? getValue(item, `cc`, "").split(",")
      : "";
    let bcc_email = getValue(item, `bcc`, "")
      ? getValue(item, `bcc`, "").split(",")
      : "";
    let reply_to = getValue(item, `replyTo`, "")
      ? getValue(item, `replyTo`, "").split(",")
      : "";
    setEmailRequest({
      ...emailRequest,
      from: from_email,
      to: to_email,
      cc: cc_email,
      bcc: bcc_email,
      replyTo: reply_to,
      identityId: getValue(item, `identity_id`, ""),
      integrationId: getValue(item, `email_integration_id`, ""),
      subject: getValue(item, `subject`, ""),
      html: decodeURIComponent(getValue(item, `html`, "")),
    });
    setPopupVisibility(true);
  };
  const handleSubmitDraft = async () => {
    try {
      let emailReq: any = JSON.parse(JSON.stringify(emailRequest));
      emailReq["from"] = getValue(emailReq, `from`, "").includes("<")
        ? ""
        : getValue(emailReq, `from`, "");
      emailReq["subject"] = getValue(emailReq, `subject`, []);
      emailReq["moduleId"] = await selectedModuleId(props.module);
      emailReq["integrationId"] = getValue(emailReq, `integrationId`, "")
        ? getValue(emailReq, `integrationId`, "")
        : getValue(availableEmailInfo, `orgUser.integrationId`, "");
      emailReq["identityId"] = getValue(emailReq, `identityId`, "")
        ? getValue(emailReq, `identityId`, "")
        : getValue(props, `params.id`, "");
      emailReq["attachments"] =
        getValue(documentList, `length`, 0) > 0
          ? documentList.map((item: object) => getValue(item, `id`, ""))
          : [];
      let resp;
      if (draftId) {
        resp = await updateDraftEmail(
          draftId,
          removeNullOrUndefinedProperties(emailReq)
        );
      } else {
        resp = await saveDraftEmail(removeNullOrUndefinedProperties(emailReq));
      }
      if (resp) {
        let payload = {
          ...urlParams,
          activeEmailSubTab: 2,
        };
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
        toast.success(`Draft updated successfully`);
        getDraftsList(false);
        setDraftId("");
        setPopupVisibility(false);
        // handleModalDraft();
      }
    } catch (error) {}
  };
  const isEqual = useMemo(
    () =>
      _.isEqual(_.omit(emailRequest, "from"), _.omit(emailInitalState, "from")),
    [emailRequest, emailInitalState]
  );
  /* ------------------------    Email Document Section   ---------------------------- */

  const [documentList, setDocumentList] = useState<any>([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocumentList((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };
  const handleRemoveDocuments = async (i: number) => {
    let list = documentList.filter((item: any, index: number) => i !== index);
    setDocumentList(list);
  };

  return (
    <div>
      {getValue(urlParams, `activeTab`, "") == 4 && (
        <MainBodyEmails
          activeEmailTab={activeEmailTab}
          setActiveEmailTab={setActiveEmailTab}
          list={emailList}
          loading={loading}
          getSpecificEmailInfo={getSpecificEmailInfo}
          getSpecificDraftInfo={getSpecificDraftInfo}
        />
      )}
      {/* ----------------------------------- Email Module ----------------------------------- */}
      <CommonSendEmailPopup
        isPopupVisible={isPopupVisible}
        setPopupVisibility={setPopupVisibility}
        request={emailRequest}
        setRequest={setEmailRequest}
        handleChangeEditor={handleChangeEditor}
        handleSubmit={handleSubmitEmail}
        isLoading={emailLoading}
        // handleClose={isEqual ? handleCloseEmail : handleModalDraftMain}
        handleClose={handleCloseEmail}
        textActive={textActive}
        setTextActive={setTextActive}
        handleSubmitDraft={handleSubmitDraft}
        //upload section
        uploadLoading={uploadLoading}
        documentList={documentList}
        handleUploadDocuments={handleUploadDocuments}
        handleRemoveDocuments={handleRemoveDocuments}
        //from email dropdown
        fromMails={fromMails}
      />
      <CommonSendEmailInfoPopup
        isPopupVisible={isPopupInfoVisible}
        setPopupVisibility={setPopupInfoVisibility}
        info={emailInfo}
        handleClose={handleCloseInfo}
        handleReply={handleReply}
      />
      <DraftConfirmModal
        isOpen={isDraftOpen}
        handleModal={handleModalDraft}
        handleSubmit={handleSubmitDraft}
        handleCancel={handleCancelDraft}
      />
    </div>
  );
}

export default CommonDetailEmailPage;
