export const SettingsRequest = {
  name: "",
  description: "",
  enable_credits_limit: true,
  credits_limit: 0,
  model: "",
  instructions_type: "custom_prompt",
  instructions: "",
  temp: 1,
  retraining_interval: "never",
  initial_messages: [""],
  suggested_messages: [""],
  message_placeholder: "",
  collect_user_feedback: true,
  regenerate_messages: true,
  footer: "",
  theme: "",
  display_name: "",
  user_message_color: "",
  header_color: "",
  button_color: "",
  align_chat_button: "left",
  auto_open_chat_window_after: 0,
  profile_picture_id: "",
  chat_icon_id: "",
  visibility: "public",
  only_allow_on_added_domains: true,
  allowed_domains: [""],
  ip_limit: 0,
  ip_limit_timeframe: 0,
  ip_limit_message: "",
  daily_conversations_active: true,
  daily_conversations_emails: [""],
  daily_leads_collected_active: true,
  daily_leads_collected_emails: [""],
};
