import AddSvgComponent from "@assets/svg/add";
import BackSvgComponent from "@assets/svg/back-link";
import MoreVerticalSvgComponent from "@assets/svg/more-vertical";
import PrinterSvgComponent from "@assets/svg/printer";
import ButtonComponent from "@components/Form/Button/Button";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import {
  getPropertyBookingFolio,
  getPropertyBookingUsers,
  getSpecificPropertyBookingUsers,
  propertyBookingCheckin,
  propertyBookingCheckout,
} from "@services/properties/property-booking.service";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { allParams } from "@common/url-params-helper";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import BookingInformation from "../settings/components/booking-information";
import { QueryRequestHelper } from "@common/query-request-helper";
import SettlePayment from "../settings/components/settle-payment";
import { toast } from "sonner";
import BookingInfoForm from "../ReservationCalender/Bookings/WalkIn/forms/booking-info-form";
import currencies from "@common/json/currency.json";
import { formatString } from "@common/text-helpers";

const Bookingfolio = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();

  const [updatingFlag, setUpdatingFlag] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
    getbookingUsers();
  }, [updatingFlag]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = useState(false);
  const [bookingInfo, setBookingInfo] = useState({});
  const [activeTab, setActiveTab] = useState<any>("1");
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getPropertyBookingFolio(
        getValue(params, `bookingId`, "")
      );
      if (resp) {
        setBookingInfo(getValue(resp, `data`, {}));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Guest details section                        */
  /* -------------------------------------------------------------------------- */

  const [selectedUserId, setSelectedUserId] = useState("");

  const [isLoading1, setIsLoading1] = useState(false);
  const [bookingUsersInfo, setBookingUsersInfo] = useState([]);
  const getbookingUsers = async () => {
    try {
      setIsLoading1(true);
      let payload = {
        property_booking_id: getValue(params, `bookingId`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getPropertyBookingUsers(queryRequest);
      if (resp) {
        setIsLoading1(false);
        setBookingUsersInfo(getValue(resp, "data.pbUser", ""));
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  const [isLoading2, setIsLoading2] = useState(false);
  const [openGuestDetails, setOpenGuestDetails] = useState(false);
  const toggleGuestDetails = () => {
    setOpenGuestDetails(!openGuestDetails);
  };

  const handleSelectGuest = async (selectedId: string) => {
    setSelectedUserId(selectedId);
    // await getSpecificUser(selectedId); // Fetch the specific user details
    toggleGuestDetails(); // Open the popup after fetching the data
  };

  /* -------------------------------------------------------------------------- */
  /*                               Booking info section                         */
  /* -------------------------------------------------------------------------- */

  const [openBookingInfo, setOpenBookignInfo] = useState(false);
  const toggleBookingInfo = () => {
    setOpenBookignInfo(!openBookingInfo);
  };

  const items = [
    {
      tooltipId: "more",
      tooltipContent: "More",
      SvgComponent: MoreVerticalSvgComponent,
      svgProps: { color: "#1a73e8", size: 24 },
    },
    {
      tooltipId: "printer",
      tooltipContent: "Print",
      SvgComponent: PrinterSvgComponent,
      svgProps: { color: "#444f60", size: 22 },
    },
  ];

  const bookingDetails = [
    {
      heading: "Address",
      info: getValue(bookingInfo, `property_booking_user[0].address`, "")
        ? getValue(bookingInfo, `property_booking_user[0].address`, "")
        : "NA",
    },
    {
      heading: "Booking made on",
      info: convertCurrentDateWithTime(getValue(bookingInfo, `created_at`, "")),
    },
    {
      heading: "Email",
      info: getValue(bookingInfo, `property_booking_user[0].email`, "")
        ? getValue(bookingInfo, `property_booking_user[0].email`, "")
        : "NA",
    },
    {
      heading: "Checkin",
      info: convertCurrentDateWithTime(getValue(bookingInfo, `checkin`, "")),
    },
    // {
    //   heading: "Customer identification",
    //   info: "NA",
    // },
    {
      heading: "Nights",
      info: "1",
    },
    {
      heading: "Checkout",
      info: convertCurrentDateWithTime(getValue(bookingInfo, `checkout`, "")),
    },
    // {
    //   heading: "Booking reference id",
    //   info: "28869_12357",
    // },
    {
      heading: "Room ID",
      info: getValue(bookingInfo, "room_id", ""),
    },
    {
      heading: "Room type",
      info: getValue(bookingInfo, "room_type", ""),
    },

    {
      heading: "Rate plans",
      info: getValue(
        bookingInfo,
        "property_booking_rate_plan[0].plan_display_name",
        ""
      ),
    },
    {
      heading: "Adults / Children",
      info:
        getValue(bookingInfo, "adults", "") +
        "/" +
        getValue(bookingInfo, "children", ""),
    },
    // {
    //   heading: "Access code",
    //   info: "254 - NA , 2345 - NA",
    // },
    // {
    //   heading: "Customer Notes",
    //   info: "NA",
    // },
    // {
    //   heading: "Booking Notes",
    //   info: "NA",
    // },
  ];

  // const accordionItems = [
  //   {
  //     title: (
  //       <div className="d-flex align-items-center gap-2 w-100">
  //         <h6 className={`header_text__16 text-black`}>
  //           Bill to:{" "}
  //           {getValue(bookingInfo, `property_booking_user[0].full_name`, "")}{" "}
  //           <span className="checkedin-tag">
  //             {getValue(bookingInfo, `booking_status`, "") === "enquiry"
  //               ? "Enquiry"
  //               : getValue(bookingInfo, `booking_status`, "") === "checked_in"
  //               ? "Checked in "
  //               : "Checked out"}
  //           </span>
  //         </h6>
  //       </div>
  //     ),
  //     content: (
  //       <>
  //         <div className="d-flex align-items-center gap-2 w-100 mb-3">
  //           <div className="w-25">
  //             <SearchToolTip
  //               placeholder="Search guest list"
  //               onChange={(selectedItem: any) =>
  //                 handleSelectGuest(selectedItem.id)
  //               }
  //               label={`full_name`}
  //               data={bookingUsersInfo}
  //               value={"full_name"}
  //               selectKey={"value"}
  //               name={"full_name"}
  //               hideLabel
  //             />
  //           </div>
  //           <AddSvgComponent color="#3c4043" size="20" />
  //           <div className="w-25">
  //             <SearchToolTip placeholder="Change booking segment" />
  //           </div>
  //           <div className="w-25">
  //             <SearchToolTip placeholder="Change booking source" />
  //           </div>
  //         </div>
  //         <div className="grid-container row-gap-3 column-gap-4">
  //           {bookingDetails.map((details, index) => (
  //             <div
  //               key={index}
  //               className="d-flex align-items-center gap-2 justify-content-between"
  //             >
  //               <p className="small_text__14">{details.heading}:</p>
  //               <p className="small_text__16 text-black">{details.info}</p>
  //             </div>
  //           ))}
  //         </div>
  //       </>
  //     ),
  //   },
  // ];

  const [openSettleDue, setOpenSettleDue] = useState(false);
  const toggleSettleDue = () => {
    setOpenSettleDue(!openSettleDue);
  };

  const handleCheckin = async () => {
    try {
      let resp = await propertyBookingCheckin(
        getValue(bookingInfo, `room_booking_info[0].id`, "")
      );
      if (resp) {
        toast.success("Checked in successfully");
        navigate(
          `/${getValue(params, "orgId", "")}/property/details/${getValue(
            params,
            "id",
            ""
          )}/reservations`
        );
      }
    } catch (error) {}
  };

  const handleCheckout = async () => {
    try {
      let resp = await propertyBookingCheckout(
        getValue(bookingInfo, `room_booking_info[0].id`, "")
      );
      if (resp) {
        toast.success("Checked out successfully");
        navigate(
          `/${getValue(params, "orgId", "")}/property/details/${getValue(
            params,
            "id",
            ""
          )}/reservations`
        );
      }
    } catch (error) {}
  };

  const findCurrencySymbol = (currencyCode: any) => {
    const currency = currencies.find(
      (item) => item.cc.toLowerCase() === currencyCode.toLowerCase()
    );
    return currency ? currency.symbol : "";
  };

  return (
    <div>
      <HomeHeader />
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center gap-3">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/property/details/${getValue(
                    params,
                    `id`,
                    ""
                  )}/reservations?${allParams()}`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__14">
              Folio#:{" "}
              <span className="small_text__15">
                {getValue(bookingInfo, `booking_id`, "")}
              </span>
            </p>
          </div>
          <div className="d-flex gap-4 align-items-center">
            <ButtonComponent
              buttonType="primary"
              buttonText="View booking info"
              onClickHandler={toggleBookingInfo}
            />
            {/* {items.map((item, index) => (
              <div
                key={index}
                className="cursor-pointer"
                data-tooltip-id={item.tooltipId}
                data-tooltip-content={item.tooltipContent}
              >
                <item.SvgComponent {...item.svgProps} />
                <Tooltip id={item.tooltipId} className="tooltip" place="left" />
              </div>
            ))} */}
          </div>
        </div>
      </div>
      <div className="manageFieldRight w-100 overflow-y-auto  p-4">
        <div className="row mx-auto d-flex justify-content-center">
          <div className="col-10">
            {/* <div>
              {accordionItems.map((item, index) => (
                <div
                  className="accordion-item mb-3 border-bottom pb-2"
                  key={index}
                >
                  <div
                    className="accordion-title d-flex justify-content-between cursor-pointer"
                    onClick={() => toggleAccordion(index)}
                  >
                    {item.title}
                    <img
                      src="/images/icons/arrow-down.svg"
                      width={18}
                      height={18}
                      className={`${
                        openIndex === index ? "accordion-rotate" : ""
                      } accordion-arrow`}
                      alt="Toggle icon"
                    />
                  </div>
                  {openIndex === index && (
                    <motion.div
                      initial={{ y: 0, opacity: 0 }}
                      animate={{ y: "auto", opacity: 1 }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                      <div className=" mt-2 px-2 py-1">{item.content}</div>
                    </motion.div>
                  )}
                </div>
              ))}
            </div> */}
            <div className="d-flex align-items-center gap-2 w-100 justify-content-between">
              <h6 className={`header_text__16 text-black`}>
                Bill to:{" "}
                {getValue(bookingInfo, `property_booking_user.full_name`, "")}{" "}
                <span className="checkedin-tag">
                  {formatString(
                    getValue(
                      bookingInfo,
                      `room_booking_info[0].booking_status`,
                      ""
                    )
                  )}
                </span>
              </h6>
              <p>
                <span className="small_text__13">All amount in -</span>
                {findCurrencySymbol(
                  getValue(bookingInfo, "property.currency", "")
                )}
              </p>
            </div>
            <table className="datatable_table w-100 mt-4">
              <thead className="datatable_table_thead position-relative">
                <tr className="datatable_table_tr">
                  {/* <th className="datatable_table_th ">
                    <CustomCheckbox />
                  </th> */}
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">DATE</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">ITEM & DESCRIPTION</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">QUANTITY</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">PAYMENT TYPE</h6>
                  </th>
                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">RATE</h6>
                  </th>

                  {/* <th className="datatable_table_th ">
                    <h6 className="header_text__12">SUBTOTAL</h6>
                  </th> */}
                  {Array.isArray(
                    getValue(bookingInfo, "room_booking_info[0].taxes", [])
                  ) &&
                    getValue(bookingInfo, "room_booking_info[0].taxes", []).map(
                      (item: any, index: number) => (
                        <th className="datatable_table_th" key={index}>
                          {Object.keys(item).map((taxType) => (
                            <h6 className="header_text__12" key={taxType}>
                              {taxType.toUpperCase()}
                            </h6>
                          ))}
                        </th>
                      )
                    )}

                  <th className="datatable_table_th ">
                    <h6 className="header_text__12">TOTAL </h6>
                  </th>
                </tr>
              </thead>
              <tbody className="datatable_table_tbody">
                <tr className="datatable_table_tr">
                  {/* <td
                    className="datatable_table_td "
                    style={{ display: "table-cell" }}
                  >
                    <div className="d-flex justify-content-center">
                      <CustomCheckbox />
                    </div>
                  </td> */}
                  <td
                    className="datatable_table_td "
                    style={{ display: "table-cell" }}
                  >
                    {convertCurrentDateWithTime(
                      getValue(bookingInfo, `created_at`, "")
                    )}
                  </td>
                  <td className="datatable_table_td ">
                    {getValue(
                      bookingInfo,
                      `room_booking_info[0].room_type`,
                      ""
                    )}
                  </td>
                  <td className="datatable_table_td ">
                    {getValue(
                      bookingInfo,
                      `room_booking_info[0].no_of_nights`,
                      ""
                    )}{" "}
                    night
                  </td>
                  <td className="datatable_table_td ">
                    {getValue(
                      bookingInfo,
                      `room_booking_info[0].payment_method`,
                      ""
                    )}
                  </td>
                  {/* <td className="datatable_table_td ">
                    {getValue(
                      bookingInfo,
                      `room_booking_info[0].sub_total`,
                      ""
                    )}
                    .00
                  </td> */}

                  <td className="datatable_table_td ">
                    {getValue(
                      bookingInfo,
                      `room_booking_info[0].total_before_tax`,
                      ""
                    )}
                    .00
                  </td>
                  {/* <td className="datatable_table_td ">
                    <InputRuleForm
                      inputType="NUMBER"
                      name="number"
                      value="648.67"
                      placeholder="Enter total with tax"
                      marginZero
                      hideFormLabel
                    />
                  </td> */}
                  {Array.isArray(
                    getValue(bookingInfo, "room_booking_info[0].taxes", [])
                  ) &&
                    getValue(bookingInfo, "room_booking_info[0].taxes", []).map(
                      (item: any, index: number) => (
                        <td className="datatable_table_td" key={index}>
                          {Object.keys(item).map((taxType) => (
                            <div key={taxType}>
                              <p className="">{item[taxType]?.tax_amount}.00</p>
                              <p className="small_text__12">
                                {item[taxType]?.tax_percentage}%
                              </p>
                            </div>
                          ))}
                        </td>
                      )
                    )}

                  {/* <td className="datatable_table_td ">
                    {getValue(
                      bookingInfo,
                      `room_booking_info[0].total_tax`,
                      ""
                    )}
                    .00
                  </td> */}
                  <td className="datatable_table_td ">
                    {getValue(bookingInfo, `room_booking_info[0].total`, "")}.00
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="px-3 py-2">
                    <h6 className="header_text__16">Sub-total</h6>
                  </td>
                  <td className="px-3 py-2">
                    {getValue(
                      bookingInfo,
                      `room_booking_info[0].total_before_tax`,
                      ""
                    )}
                    .00
                  </td>
                  {Array.isArray(
                    getValue(bookingInfo, "room_booking_info[0].taxes", [])
                  ) &&
                    getValue(bookingInfo, "room_booking_info[0].taxes", []).map(
                      (item: any, index: number) => (
                        <td className="px-3 py-2" key={index}>
                          {Object.keys(item).map((taxType) => (
                            <div key={taxType}>
                              <p className="">{item[taxType]?.tax_amount}.00</p>
                            </div>
                          ))}
                        </td>
                      )
                    )}
                  <td className="px-3 py-2">
                    {getValue(bookingInfo, `room_booking_info[0].total`, "")}.00
                  </td>
                </tr>
              </tbody>
            </table>
            <hr className="mt-0" />
            <div className="d-flex justify-content-end">
              <div className="d-flex flex-column gap-2">
                <p className="text-end">Total Taxable Amount</p>
                <p className="text-end">Total</p>
                <p className="text-end">Payment Made</p>
                <h6 className="text-end ">Balance Due</h6>
              </div>
              <div className="col-2 d-flex flex-column gap-2">
                <p className="text-end">
                  {getValue(bookingInfo, `room_booking_info[0].total_tax`, "")}
                  .00
                </p>
                <p className="text-end">
                  {getValue(bookingInfo, `room_booking_info[0].total`, "")}
                  .00
                </p>
                <p className="text-end">
                  {getValue(
                    bookingInfo,
                    `room_booking_info[0].booking_amount`,
                    ""
                  )}
                  .00
                </p>

                <h6 className="text-end header_blue_text__18">
                  {getValue(
                    bookingInfo,
                    `room_booking_info[0].balance_due`,
                    ""
                  )}
                  .00
                </h6>
              </div>
            </div>
          </div>
          {/* <div className="col-3 position-sticky top-0">
            <div className="room-box-shadow rounded-3  border">
              <div className="add-more-rolls-wrapper__header d-flex align-items-center justify-content-between rounded-top-3">
                <h3 className="header_text__18 text-center text-white">
                  Folio summary
                </h3>
              </div>
              <div className="p-3">
                <div className="border-bottom">
                  <p className="text-center header_text__16">Fee Breakdown</p>
                  <table className="w-100 mt-3 mb-3 small_text__16">
                    <tr>
                      <td className="text-start p-1">Total without taxes</td>
                      <td className="text-end p-1">
                        ₹{" "}
                        {getValue(
                          bookingInfo,
                          "room_booking_info[0].total_before_tax",
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start p-1"> Total tax amount</td>
                      <td className="text-end p-1">
                        ₹{" "}
                        {getValue(
                          bookingInfo,
                          "room_booking_info[0].total_tax",
                          ""
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start p-1">
                        {" "}
                        Total with taxes and fees
                      </td>
                      <td className="text-end p-1">
                        ₹{" "}
                        {getValue(
                          bookingInfo,
                          "room_booking_info[0].total",
                          ""
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="border-bottom mt-3">
                  <p className="text-center header_text__16">
                    Room Taxes Breakdown
                  </p>
                  <table className="w-100 mt-3 mb-3 small_text__16">
                    <tr>
                      <td className="text-start p-1">custom tax 1</td>
                      <td className="text-end p-1">₹ 18.00</td>
                    </tr>
                  </table>
                </div>
                <div className="border-bottom mt-3">
                  <table className="w-100 mt-3 mb-3 small_text__16">
                    <tr>
                      <td className="text-start p-1"> Service taxes</td>
                      <td className="text-end p-1">₹ 0.00</td>
                    </tr>
                  </table>
                </div>
                <div className="border-bottom mt-3">
                  <table className="w-100 mt-3 mb-3 small_text__16 text-black">
                    <tr>
                      <td className="text-start p-1"> Payment made</td>
                      <td className="text-end p-1">₹ 0.00</td>
                    </tr>
                    <tr>
                      <td className="text-start p-1"> Balance due</td>
                      <td className="text-end p-1">
                        ₹{" "}
                        {getValue(
                          bookingInfo,
                          "room_booking_info[0].balance_due",
                          ""
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="mt-3 d-flex align-items-center justify-content-between">
                  <ButtonComponent
                    buttonType="secondary"
                    buttonText="Settle dues"
                    onClickHandler={toggleSettleDue}
                    disabled={
                      getValue(
                        bookingInfo,
                        "room_booking_info[0].booking_status",
                        ""
                      ) === "checked_out"
                    }
                  />
                  {getValue(
                    bookingInfo,
                    "room_booking_info[0].booking_status",
                    ""
                  ) === "checked_in" ? (
                    <ButtonComponent
                      buttonType="primary"
                      buttonText="Check out"
                      onClickHandler={handleCheckout}
                    />
                  ) : (
                    <ButtonComponent
                      buttonType="primary"
                      buttonText="Check in"
                      onClickHandler={handleCheckin}
                      disabled={
                        getValue(
                          bookingInfo,
                          "room_booking_info[0].booking_status",
                          ""
                        ) === "checked_out"
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div> */}
        </div>

        {getValue(bookingInfo, "room_booking_info[0].booking_status", "") !==
          "enquiry" && (
          <div className="mt-3 d-flex align-items-center justify-content-center gap-3 w-100">
            <ButtonComponent
              buttonType="secondary"
              buttonText="Settle dues"
              onClickHandler={toggleSettleDue}
              disabled={
                getValue(
                  bookingInfo,
                  "room_booking_info[0].booking_status",
                  ""
                ) === "checked_out" ||
                getValue(
                  bookingInfo,
                  "room_booking_info[0].balance_due",
                  ""
                ) === 0
              }
            />
            {getValue(
              bookingInfo,
              "room_booking_info[0].booking_status",
              ""
            ) === "checked_in" ? (
              <ButtonComponent
                buttonType="primary"
                buttonText="Check out"
                onClickHandler={handleCheckout}
              />
            ) : (
              <ButtonComponent
                buttonType="primary"
                buttonText="Check in"
                onClickHandler={handleCheckin}
                disabled={
                  getValue(
                    bookingInfo,
                    "room_booking_info[0].booking_status",
                    ""
                  ) === "checked_out"
                }
              />
            )}
          </div>
        )}
      </div>
      {openBookingInfo && (
        <CommonRightPopup
          isPopupVisible={openBookingInfo}
          toggle={toggleBookingInfo}
          width="840px"
          title="Booking information"
          hideNew
          second_title="Save"
          handleSubmit={toggleBookingInfo}
        >
          <BookingInformation
            reservationInfo={bookingInfo}
            passingInfo
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </CommonRightPopup>
      )}
      <SettlePayment
        openCheckin={openSettleDue}
        toggleCheckin={toggleSettleDue}
        bookingInfo={bookingInfo}
        updatingFlag={updatingFlag}
        setUpdatingFlag={setUpdatingFlag}
        permissions={getValue(props, `permissions`, [])}
      />

      <CommonRightPopup
        isPopupVisible={openGuestDetails}
        toggle={toggleGuestDetails}
        // handleSubmit={() => toggleGuestDetails(index)}
        width="820px"
        title={`Add guest details`}
        hideNew
        second_title="Save"
        permissions={getValue(props, `permissions`, [])}
      >
        <div className="subpipeline-wrapper py-2 w-100">
          <div className="d-flex flex-column gap-3">
            <BookingInfoForm
              index={0}
              // item={item}
              // setRequest={setRequest}
              // handleUploadDocuments={handleUploadDocuments}
              key_name={"user_booking_info"}
              adultArray
            />
          </div>
        </div>
      </CommonRightPopup>
    </div>
  );
};

export default Bookingfolio;
