import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React, { useEffect, useRef, useState } from "react";
import WhatsappIconViewPage from "../components/common/whatsapp-icon";
import { getValue } from "@utils/lodash";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import {
  createWhatsappTemplate,
  getSpecificWhatsappTemplate,
  getSpecificWhatsappTemplateFromBackend,
} from "@services/fb.service";
import { toast } from "sonner";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useStateContext } from "@context/profileProvider";
import { QueryRequestHelper } from "@common/query-request-helper";
import { listAllPipelines } from "@services/pipeline.service";
import { getAllAvailableTemplateTags } from "@services/email-config.service";
import { mediaUpload } from "@services/upload.service";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import convertMarkdownToHtml from "../../../../common/markdown/useMarkdown";
import draftToHtmlPuri from "draftjs-to-html";
import AddWhatsappVariable from "../components/template-messages/add-variable-popup";
import BackSvgComponent from "@assets/svg/back-link";
import parse from "html-to-md";
import { Button, MenuProps, message, Modal } from "antd";
import { LinkOutlined, PhoneOutlined, SendOutlined } from "@ant-design/icons";
import TemplateCarousel from "./components/template-carousel";
import TemplateFooter from "./components/template-footer";
import TemplateBody from "./components/template-body";
import TemplateHeader from "./components/template-header";
import { createAIRephrase } from "@services/ai.service";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import TemplateCarouselModal from "./components/template-carousel-modal";
import { getStringContentLength } from "@common/markdown/html-to-text-parser";
import { aiRephraseMethods } from "@components/AI/AiRephraseMethods";
import _ from "lodash";
import { formatMarkdownText } from "@components/MarkdownEditor/helpers/textFormatter";
import RNRephraseModal from "@components/AI/RNRephraseModal";

const CreateTemplateMessage = (props: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const [editId, setEditId] = useState(getValue(params, `id`, ""));
  const [templateFromLibrary, setTemplateFromLibrary] = useState(false);
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const intialState = {
    id: "",
    moduleId: "",
    pipelineId: "",
    name: "",
    category: "MARKETING",
    language: "en",
    header: {
      type: "",
      text: "",
      link: "",
    },
    body: "",
    footer: "",
    buttonType: "",
    customTags: [],
    buttons: [],
    status: "",
    isActive: true,
    carousel: [
      // {
      //   header: {
      //     type: "IMAGE",
      //     link: "",
      //   },
      //   body: "<p></p>\n",
      //   buttons: [{ action_type: "" }, { action_type: "" }],
      // },
    ],
  };
  const initialAiText = {
    key: "",
    text: "",
  };
  const [aiRequest, setAiRequest] = useState(initialAiText);
  const [isRephraseLoading, setIsRephraseLoading] = useState(false);
  const [aiRephrasedText, setAiRephrasedText] = useState("");
  const [previousEditorState, setPreviousEditorState] = useState("");
  const [openAiModal, setOpenAiModal] = useState(false);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState<any>(intialState);
  const resetState = () => {
    setEditId("");
    setErrorMessage("");
    setRequest(intialState);
    setAiRequest(initialAiText);
    // setRequest({ ...request });
  };
  const [templateData, settemplateData] = useState<any>([]);
  /* -------------------------------------------------------------------------- */
  /*                             UseEffect Section                              */
  /* -------------------------------------------------------------------------- */

  const { selectedModuleId } = useStateContext();
  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (!editId) {
      resetState();
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      getData();
    }
  }, [editId]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp =
        getValue(UrlParams, `activeTab`, "") != "4"
          ? await getSpecificWhatsappTemplate(getValue(params, `id`, ""))
          : await getSpecificWhatsappTemplateFromBackend(
              getValue(params, `id`, "")
            );
      if (resp) {
        let buttons =
          getValue(resp, `data.buttons.length`, 0) > 0
            ? getValue(resp, `data.buttons`, []).map((item: object) => ({
                ...item,
                type: getValue(item, `action_type`, ""),
                action_type: getValue(item, `type`, ""),
              }))
            : [];
        let customTagsList = getValue(resp, `data.tags`, []).map(
          (item: object) => ({
            type: getValue(item, `type`, ""),
            sample: getValue(item, `sample`, ""),
            tag: getValue(item, `tag`, ""),
            fieldId: getValue(item, `module_field_id`, ""),
            // placeholder: `[[${getValue(item, `module_field.api_name`, "")}]]`,
            placeholder: `[[${getValue(item, `tag`, "")}]]`,
          })
        );
        setErrorMessage(getValue(resp, `data.error_reason_message`, ""));
        settemplateData(getValue(resp, `data`, ""));
        setRequest({
          ...request,
          id: getValue(resp, `data.id`, ""),
          moduleId: getValue(resp, `data.module_id`, ""),
          pipelineId: getValue(resp, `data.pipeline_id`, ""),
          name: getValue(resp, `data.name`, ""),
          category:
            getValue(resp, `data.carousels.length`, 0) > 0
              ? "CAROUSEL"
              : getValue(resp, `data.category`, ""),
          language: getValue(resp, `data.language`, ""),
          header: {
            type: getValue(resp, `data.header_type`, ""),
            text: getValue(resp, `data.header_link`, ""),
            link: getValue(resp, `data.header_file`, ""),
          },
          body: replaceDefaultPlaceholders(
            getValue(resp, `data.body`, ""),
            allTags
          ),
          footer: getValue(resp, `data.footer`, ""),
          customTags: customTagsList,
          buttons: buttons,
          // isActive: true,
          status: getValue(resp, `data.status`, ""),
          carousel: getCarouselPreview(getValue(resp, `data.carousels`, "")),
        });
        setIsLoading(false);
        // handlePreview(editId);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [allTags, setAllTags] = useState<any[]>([]);
  const [moduleId, setModuleId] = useState("");
  const [pipelineId, setPipelineId] = useState("");
  const [allPipelinesList, setAllPipelineList] = useState([]);

  const getTags = async () => {
    try {
      let allPipelines = await listAllPipelines(
        await selectedModuleId("contacts"),
        ""
      );
      setModuleId(await selectedModuleId("contacts"));
      setPipelineId(getValue(allPipelines, `data.[${0}].id`, ""));
      let list = getValue(allPipelines, `data`, []).map((item: object) => ({
        ...item,
        value: getValue(item, `id`, ""),
        label: getValue(item, `label`, ""),
      }));
      setAllPipelineList(list);
      tagsByPipelineID(getValue(allPipelines, `data.[${0}].id`, ""));
    } catch (error) {}
  };
  const tagsByPipelineID = async (id: string) => {
    setPipelineId(id);
    try {
      let payload = {
        pipelineId: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAvailableTemplateTags(
        await selectedModuleId("contacts"),
        queryRequest
      );
      if (resp) {
        let list = getValue(resp, `data`, []).map((item: object) => ({
          ...item,
          placeHolderOld: getValue(item, `placeHolder`, ""),
          placeHolder: `[[${getValue(item, `name`, "")}]]`,
        }));
        const uniqueData: any = _.uniqBy(list, "fieldId");
        setAllTags(uniqueData);
        // setAllTags(list);
      }
    } catch (error) {}
  };

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", getValue(file, `name`, ""));
    formData.append("is_public", "true");
    formData.append("collection", "public-images");
    let resp = await mediaUpload(formData);
    if (resp) {
      setRequest({
        ...request,
        header: {
          ...request.header,
          link: {
            ...getValue(resp, `data`, {}),
            ke_main: getValue(resp, `data.key`, ""),
            key: getValue(resp, `data.key`, ""),
          },
        },
      });
    }

    if (!file) {
      console.error("No file selected");
      return;
    }
  };

  const ConvertedComponent = (data: any) => {
    if (getValue(data, `length`, 0) > 0) {
      const convertedData = data.map((item: any, index: number) => {
        if (item.action_type === "CALL_TO_ACTION") {
          return {
            type: "CALL_TO_ACTION",
            callAction: [
              {
                type: item.type === "url" ? "url" : "call",
                text: item.text,
                url: item.url,
                phone: item.phone,
                index: index,
              },
            ],
          };
        } else if (item.action_type === "URL") {
          return {
            type: "CALL_TO_ACTION",
            callAction: [
              {
                type: item.type === "url" ? "url" : "call",
                text: item.text,
                url: item.url,
                phone: item.phone,
                index: index,
              },
            ],
          };
        } else if (item.action_type === "QUICK_REPLY") {
          return {
            type: "QUICK_REPLY",
            quickReply: [
              {
                text: item.text,
                index: index,
              },
            ],
          };
        }
      });
      return convertedData.filter((item: object) => item);
    } else {
      return [];
    }
  };

  const handleNavigateHome = async () => {
    let payload: any = {
      ...UrlParams,
      page_no: 1,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const newfun = (arr1: any, arr2: any, arr3: any) => {
    const arr3Dict = arr3.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});
    const newResult = arr1
      .map((item: any) => {
        const placeholderName = item.placeholder
          .replace("[[", "")
          .replace("]]", "");

        if (arr3Dict[placeholderName]) {
          const arr2Item = arr2.find(
            (x: any) => x.placeholder === item.placeholder
          );
          const fieldId = arr2Item ? arr2Item.fieldId : "";
          const sample = arr2Item ? arr2Item.sample : "";
          const sampleInfo = getValue(findInfo, `tags`, []).find(
            (item: object) => getValue(item, `module_field_id`, "") === fieldId
          );
          return {
            type: item.type,
            label: arr3Dict[placeholderName].label,
            name: arr3Dict[placeholderName].name,
            sample: sample ? sample : getValue(sampleInfo, `sample`, ""),
            placeholder: item.placeholder,
            fieldId: fieldId
              ? fieldId
              : arr3Dict[placeholderName].fieldId
              ? arr3Dict[placeholderName].fieldId
              : getValue(sampleInfo, `module_field_id`, ""),
          };
        }
        return null;
      })
      .filter((item: any) => item !== null);

    return newResult;
  };

  const handleAddTypeButton = (name: string, type: String) => {
    const buttonArray = getValue(request, `buttons`, []);
    if (buttonArray.length < 10) {
      if (name === "QUICK_REPLY") {
        let obj = { text: "", action_type: name };
        buttonArray.push(obj);
        setRequest({ ...request });
      } else {
        let obj = {
          action_type: name,
          type: type,
          text: "",
          url: "",
          phone: "",
        };
        buttonArray.push(obj);
        setRequest({ ...request });
      }
    } else {
      toast.error("You have reached maximum limits");
    }
  };

  const handleRemoveTypeButton = (index: number) => {
    let filtered = getValue(request, `buttons`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      buttons: filtered,
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                          Template Modal section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenTemplate, setIsOpenTemplate] = useState(false);
  const handleOpenTempalte = () => {
    setIsOpenTemplate(!isOpenTemplate);
  };
  /* -------------------------------------------------------------------------- */
  /*                            Variable Modal section                          */
  /* -------------------------------------------------------------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const editorRef = useRef<any>();

  const handleAddTags = (item: any) => {
    setParentChage(true);
    const name = getValue(item, `name`, "");
    let text: any = insertText(`[[${name}]]`);
    let finalText = text;
    setRequest({
      ...request,
      body: finalText,
    });
    request.customTags.push({
      type: "body",
      sample: "",
      placeholder: `[[${getValue(item, `name`, "")}]]`,
      fieldId: getValue(item, `fieldId`, ""),
    });
  };

  const insertText = (value: any) => {
    const newEditorState =
      getValue(request, `body`, "").slice(0, start) +
      value +
      getValue(request, `body`, "").slice(end);
    return newEditorState;
  };

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [parentChage, setParentChage] = useState(false);
  const handleCursorMovement = (textarea: any) => {
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    setEnd(end);
    setStart(start);
    // Add logic to handle cursor movement
  };

  const handleChangeEditor = (text: any) => {
    let value = formatMarkdownText(text);
    // Assuming you're using a state variable called "editor" to store the editor state
    if (getStringContentLength(value) <= 1024) {
      setRequest({
        ...request,
        body: value,
      });
      // Extract custom tags from the editor text
      const regex = /\[\[(.*?)\]\]/g;
      const matches = value.match(regex);
      if (matches) {
        const newCustomTags = matches.map((match: any) => ({
          type: "body",
          sample: "",
          placeholder: match,
          fieldId: "",
        }));
        let tags = newfun(newCustomTags, request.customTags, allTags);
        setRequest({
          ...request,
          body: value,
          customTags: tags,
        });
      } else {
        setRequest({
          ...request,
          body: value,
          customTags: [],
        });
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Helper section                             */
  /* -------------------------------------------------------------------------- */

  const replacePlaceholders = (text: any) => {
    if (text) {
      let replacedText = text;
      let escapedMatch = String(replacedText).replace(/\\/g, "");
      return escapedMatch;
    } else {
      return null;
    }
  };
  const replaceDefaultPlaceholders = (text: any, data: any) => {
    let replacedText = text;
    data.forEach((item: any) => {
      replacedText = replacedText.replace(item.placeHolder, `[[${item.name}]]`);
    });
    return replacedText;
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Preview section                            */
  /* -------------------------------------------------------------------------- */

  const [errorMessage, setErrorMessage] = useState("");
  const [findInfo, setFindInfo] = useState({});
  const getCarouselPreview = (carousels: any) => {
    let carousel =
      getValue(carousels, `length`, 0) > 0
        ? carousels.map((item: object) => ({
            header: {
              type: getValue(item, `header_type`, ""),
              link: getValue(item, `header_file.key`, ""),
            },
            body: getValue(item, `body`, ""),
            buttons: convertCarouselButtons(getValue(item, `buttons`, [])),
          }))
        : [];
    return carousel;
  };

  const convertCarouselButtons = (buttons: any) => {
    let button =
      getValue(buttons, `length`, 0) > 0
        ? buttons.map((item: object) => ({
            ...item,
            action_type: getValue(item, `type`, ""),
            type: getValue(item, `phone`, "") ? "call" : "url",
          }))
        : [];
    return button;
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Submit section                             */
  /* -------------------------------------------------------------------------- */
  const getCarouselPayload = () => {
    let carousel =
      getValue(request, `carousel.length`, 0) > 0
        ? getValue(request, `carousel`, []).map((item: object) => ({
            header: {
              type: getValue(item, `header.type`, ""),
              text: getValue(item, `header.text`, ""),
              link: getValue(item, `header.link.id`, "")
                ? getValue(item, `header.link.id`, "")
                : getValue(item, `header.link`, ""),
            },
            body: getValue(item, `body`, ""),
            buttons: ConvertedComponent(getValue(item, `buttons`, [])),
          }))
        : [];
    return carousel;
  };
  const convertPayload = () => {
    let buttons = ConvertedComponent(getValue(request, `buttons`, []));
    let payload = JSON.parse(JSON.stringify(request));
    let obj;
    if (getValue(request, `category`, "") === "CAROUSEL") {
      obj = {
        id: getValue(payload, `id`, ""),
        // moduleId: moduleId,
        // pipelineId: pipelineId,
        category: "MARKETING",
        name: getValue(payload, `name`, ""),
        language: getValue(payload, `language`, ""),
        body: replacePlaceholders(getValue(payload, `body`, "")),
        customTags:
          getValue(request, `customTags.length`, 0) > 0
            ? getValue(request, `customTags`, []).map((item: object) => ({
                type: getValue(item, `type`, ""),
                sample: getValue(item, `sample`, ""),
                tag: getValue(item, `placeholder`, ""),
                fieldId: getValue(item, `fieldId`, ""),
              }))
            : [],
        isActive: true,
        carousel: getCarouselPayload(),
      };
    } else {
      obj = {
        id: getValue(payload, `id`, ""),
        moduleId: moduleId,
        pipelineId: pipelineId,
        category: getValue(payload, `category`, ""),
        name: getValue(payload, `name`, ""),
        language: getValue(payload, `language`, ""),
        body: replacePlaceholders(getValue(payload, `body`, "")),
        customTags:
          getValue(request, `customTags.length`, 0) > 0
            ? getValue(request, `customTags`, []).map((item: object) => ({
                type: getValue(item, `type`, ""),
                sample: getValue(item, `sample`, ""),
                tag: getValue(item, `placeholder`, ""),
                fieldId: getValue(item, `fieldId`, ""),
              }))
            : [],
        isActive: true,
        header: {
          type: getValue(payload, `header.type`, ""),
          text: getValue(payload, `header.text`, ""),
          link: getValue(payload, `header.link.id`, "")
            ? getValue(payload, `header.link.id`, "")
            : getValue(payload, `header.link`, ""),
        },
        footer: getValue(payload, `footer`, ""),
        buttons: buttons,
      };
    }
    return removeNullOrUndefinedProperties(obj);
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      messageApi.open({
        type: "error",
        content: "Please fill all the missing details",
      });
    } else {
      try {
        setSubmitLoading(true);
        let resp = await createWhatsappTemplate(
          removeNullOrUndefinedProperties(convertPayload())
        );
        if (resp) {
          handleOpenTempalte();
          resetState();
          getData();
          toast.success("Submitted successfully");
          handleNavigateHome();
          navigate(
            `/${getValue(params, `orgId`, "")}/broadcast/?activeTab=3&page_no=1`
          );

          hideValidations();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const hideValidations = () => {
    simpleValidator.current.fields = {};
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handlChangeType = (
    i: number,
    childIndex: number,
    e: any,
    key: string,
    name: string,
    subName: string,
    setObject: any,
    type: string
  ) => {
    setObject((object: any) => {
      return {
        ...object,
        [key]: object[key].map((x: any, index: number) => {
          if (index !== i) return x;
          return {
            ...x,
            [name]: x[name].map((y: any, p_index: number) => {
              if (childIndex !== p_index) return y;
              return {
                ...y,
                [subName]: type,
              };
            }),
          };
        }),
      };
    });
  };

  const [activeCarouselTab, setActiveCarouselTab] = useState(1);
  const handleCarouselTab = (tab: number) => {
    setActiveCarouselTab(tab);
  };

  /* -------------------------------------------------------------------------- */
  /*                        Carousel Template Section                           */
  /* -------------------------------------------------------------------------- */
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddCarousel = () => {
    let buttons = [
      {
        action_type: button1Type,
        type:
          buttonTypeEnum.find((item) => item.value === button1Type)?.type || "",
        text: "",
      },
    ];

    if (button2Type) {
      buttons.push({
        action_type: button2Type,
        type:
          buttonTypeEnum.find((item) => item.value === button2Type)?.type || "",
        text: "",
      });
    }

    let obj = {
      header: {
        type: carouselType,
        link: "",
      },
      body: "",
      buttons: buttons,
    };

    const updatedCarousel = [...getValue(request, `carousel`, []), obj];

    setRequest((prevRequest: any) => ({
      ...prevRequest,
      carousel: updatedCarousel,
    }));
    setSelectedCarousel(getValue(request, "carousel.length", ""));
    toggleModal();
  };

  const handleRemoveCarousel = (index: number) => {
    let filtered = getValue(request, `carousel`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      carousel: filtered,
    });
  };

  const [carouselFileLists, setCarouselFileLists] = useState<
    Record<number, any[]>
  >({});

  const handleCarouseFileChange = async (info: any, i: number) => {
    // Always keep only the last file in the fileList
    const fileList = info.fileList.slice(-1);
    const file = fileList.length > 0 ? fileList[0].originFileObj : null;

    if (!file) {
      console.error("No file selected");
      return;
    }

    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", getValue(file, `name`, ""));
    formData.append("is_public", "true");
    formData.append("collection", "public-images");

    try {
      let resp = await mediaUpload(formData);
      if (resp) {
        setRequest((prevRequest: any) => ({
          ...prevRequest,
          carousel: prevRequest.carousel.map((item: any, index: number) => {
            if (index === i) {
              return {
                ...item,
                header: {
                  ...item.header,
                  link: getValue(resp, "data", {}),
                },
              };
            }
            return item;
          }),
        }));

        // Update fileList for the current carousel item, ensuring only one file
        setCarouselFileLists((prevLists) => ({
          ...prevLists,
          [i]: fileList, // Replace with the new fileList (only one file)
        }));
      }
    } catch (error) {
      console.error("File upload failed", error);
    }
  };

  const handleFileRemove = (file: any, i: number) => {
    setRequest((object: any) => {
      return {
        ...object,
        ["carousel"]: object["carousel"].map((x: any, index: number) => {
          if (index !== i) return x;
          return {
            ...x,
            header: {
              ...x.header,
              link: "",
            },
          };
        }),
      };
    });
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div className="d-grid gap-0">
          <div>Quick Reply</div>
          <small style={{ color: "#888" }}>Custom reply</small>
        </div>
      ),
      key: "QUICK_REPLY",
      icon: <SendOutlined />,
    },
    {
      label: (
        <div className="d-grid gap-0">
          <div>Call to Action</div>
          <small style={{ color: "#888" }}>1 button maximum</small>
        </div>
      ),
      key: "CALL_TO_ACTION",
      icon: <PhoneOutlined />,
    },
    {
      label: (
        <div className="d-grid gap-0">
          <div>URL</div>
          <small style={{ color: "#888" }}>2 button maximum</small>
        </div>
      ),
      key: "URL",
      icon: <LinkOutlined />,
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const selectedType = e.key;
    const buttons = getValue(request, "buttons", []);

    const callToActionCount = buttons.filter(
      (button: any) => button.action_type === "CALL_TO_ACTION"
    ).length;

    const urlCount = buttons.filter(
      (button: any) => button.action_type === "URL"
    ).length;

    if (selectedType === "CALL_TO_ACTION" && callToActionCount >= 1) {
      messageApi.open({
        type: "warning",
        content: "Cannot add more than 1 Call to Action button",
      });
      return;
    }

    if (selectedType === "URL" && urlCount >= 2) {
      messageApi.open({
        type: "warning",
        content: "Cannot add more than 2 URL buttons",
      });
      return;
    }

    // if (buttons.length < 3) {
    handleAddTypeButton(selectedType, selectedType === "URL" ? "url" : "call");
    // } else {
    // }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(getValue(request, "buttons", []));
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    setRequest((prev: any) => ({
      ...prev,
      buttons: newItems,
    }));
  };

  const handleAIRephraseSubmit = async (method: string) => {
    try {
      setIsRephraseLoading(true);
      let payload = {
        key: method,
        text: getValue(request, `body`, ""),
      };
      let resp = await createAIRephrase(payload);
      if (resp) {
        setAiRequest({
          ...aiRequest,
          text: resp.data,
        });
        setAiRephrasedText(resp.data);
      }
    } catch (error) {
      setIsRephraseLoading(false);
    }
    setIsRephraseLoading(false);
  };

  const toggleAiModal = (method: any) => {
    setOpenAiModal(!openAiModal);
    handleAIRephraseSubmit(method);
  };
  const applyAIContent = () => {
    if (aiRephrasedText) {
      setPreviousEditorState(getValue(request, "body", ""));
      setEditorState(editorState);
      setRequest({
        ...request,
        body: aiRephrasedText,
      });
      setAiRequest({
        ...aiRequest,
        text: "",
      });
    } else {
      toast.error("No content to apply");
    }
  };

  const revertToPreviousState = () => {
    if (previousEditorState) {
      setRequest({
        ...request,
        body: previousEditorState,
      });
    }
  };

  const aIItems: MenuProps["items"] = aiRephraseMethods.map(
    (method: any, index: any) => ({
      key: `${index + 1}`,
      label: method,
      onClick: () => handleAIRephraseSubmit(method),
      disabled: isRephraseLoading === true,
    })
  );

  const [carouselType, setCarouselType] = useState(
    getValue(request, `carousel[0].header.type`, "IMAGE")
  );
  const handleCarouselTypeChange = (value: any) => {
    setCarouselType(value);

    const updatedCarousel = request.carousel.map((item: any) => ({
      ...item,
      header: {
        ...item.header,
        type: value,
        link: {},
      },
    }));

    setRequest((prevRequest: any) => ({
      ...prevRequest,
      carousel: updatedCarousel,
    }));
  };

  const [button1Type, setButton1Type] = useState<string>("QUICK_REPLY");
  const [button2Type, setButton2Type] = useState<string>("");

  const handleButton1TypeChange = (value: any) => {
    setButton1Type(value);

    const selectedType =
      buttonTypeEnum.find((item) => item.value === value)?.type || "";

    const updatedCarousel = request.carousel.map((item: any) => ({
      ...item,
      buttons: item.buttons.map((button: any, index: number) => ({
        ...button,
        action_type: index === 0 ? value : button.action_type,
        type: index === 0 ? selectedType : button.type,
      })),
    }));

    setRequest((prevRequest: any) => ({
      ...prevRequest,
      carousel: updatedCarousel,
    }));
  };

  const handleButton2TypeChange = (value: any) => {
    setButton2Type(value);

    const selectedType =
      buttonTypeEnum.find((item) => item.value === value)?.type || "";

    const updatedCarousel = request.carousel.map((item: any) => {
      let updatedButtons = [...item.buttons]; // Clone the current buttons

      // If button 2 exists, update it. If not, add it to the array.
      if (updatedButtons[1]) {
        updatedButtons[1] = {
          ...updatedButtons[1],
          action_type: value,
          type: selectedType,
          text: "", // You can modify or keep text as needed
        };
      } else {
        // Add button 2 to the array
        updatedButtons.push({
          action_type: value,
          type: selectedType,
          text: "", // You can modify or keep text as needed
        });
      }

      return {
        ...item,
        buttons: updatedButtons, // Update the buttons array
      };
    });

    setRequest((prevRequest: any) => ({
      ...prevRequest,
      carousel: updatedCarousel,
    }));
  };

  const [buttonTypeEnum] = useState([
    { value: "QUICK_REPLY", label: "Quick Reply" },
    { value: "CALL_TO_ACTION", label: "Call to Action", type: "call" },
    { value: "URL", label: "URL", type: "url" },
  ]);

  const handleCancel = (index: any) => {
    if (!getValue(request, `carousel.${index}.body`, "")) {
      handleRemoveCarousel(index);
    }
    setIsModalOpen(false);
  };

  const showModal = (index: any) => {
    setSelectedCarousel(index);
    toggleModal();
  };

  const [selectedCarousel, setSelectedCarousel] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();

  const handleClone = (index: number) => {
    if (getValue(request, "carousel.length", "") < 10) {
      const itemToClone = request.carousel[index];
      const clonedItem = { ...itemToClone };

      setRequest((prevRequest: any) => ({
        ...prevRequest,
        carousel: [...prevRequest.carousel, clonedItem],
      }));
    } else {
      messageApi.open({
        type: "error",
        content: "Maximum 10 carousel items are allowed",
      });
    }
  };
  return (
    <MainLayout {...props}>
      {contextHolder}
      <div className="px-3 py-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div onClick={() => navigate(-1)}>
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">
              {templateFromLibrary ? "Create" : editId ? "Edit" : "Create"}{" "}
              template message
            </p>
          </div>
          {getValue(props, `permissions`, []).includes("create") && (
            <div>
              {getValue(request, `status`, "") === "APPROVED" ||
              getValue(request, `status`, "") === "REJECTED" ||
              getValue(request, `status`, "") === "PAUSED" ? (
                <div className="d-flex justify-content-end">
                  <Button
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                    loading={submitLoading}
                  >
                    Submit
                  </Button>
                </div>
              ) : !editId || templateFromLibrary ? (
                <div className="d-flex justify-content-end">
                  <Button
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                    loading={submitLoading}
                  >
                    Submit for approval
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="template-wrapper">
        <div className="template-container">
          <div className="template-form-container">
            <div className="template-editor-wrapper">
              <TemplateHeader
                request={request}
                setRequest={setRequest}
                editId={editId}
                templateFromLibrary={templateFromLibrary}
                errorMessage={errorMessage}
                simpleValidator={simpleValidator}
                handleFileChange={handleFileChange}
              />
              <TemplateBody
                request={request}
                setRequest={setRequest}
                handleModal={handleModal}
                handleChangeEditor={handleChangeEditor}
                toggleAiModal={toggleAiModal}
                aiRephraseMethods={aiRephraseMethods}
                isRephraseLoading={isRephraseLoading}
                handleCursorMovement={handleCursorMovement}
                parentChage={parentChage}
                setParentChage={setParentChage}
                // handleAddCustomTags={handleAddCustomTags}
              />
              {getValue(request, `category`, "") === "CAROUSEL" ? (
                <TemplateCarousel
                  request={request}
                  setRequest={setRequest}
                  templateData={templateData}
                  editId={editId}
                  carouselType={carouselType}
                  handleCarouselTypeChange={handleCarouselTypeChange}
                  button1Type={button1Type}
                  handleButton1TypeChange={handleButton1TypeChange}
                  button2Type={button2Type}
                  handleButton2TypeChange={handleButton2TypeChange}
                  activeCarouselTab={activeCarouselTab}
                  setActiveCarouselTab={setActiveCarouselTab}
                  handlChangeType={handlChangeType}
                  handleAddCarousel={handleAddCarousel}
                  handleCarouselTab={handleCarouselTab}
                  showModal={showModal}
                  handleClone={handleClone}
                  handleRemoveCarousel={handleRemoveCarousel}
                  handleCarouseFileChange={handleCarouseFileChange}
                />
              ) : (
                <TemplateFooter
                  params={params}
                  request={request}
                  setRequest={setRequest}
                  editId={editId}
                  menuProps={menuProps}
                  handleDragEnd={handleDragEnd}
                  handleRemoveTypeButton={handleRemoveTypeButton}
                />
              )}
            </div>
          </div>
          <WhatsappIconViewPage request={request} />
        </div>
      </div>
      <AddWhatsappVariable
        isOpen={isOpen}
        handleModal={handleModal}
        handleAddTags={handleAddTags}
        allTags={allTags}
        tagsByPipelineID={tagsByPipelineID}
        allPipelinesList={allPipelinesList}
        pipelineId={pipelineId}
      />
      <RNRephraseModal
        openAiModal={openAiModal}
        setOpenAiModal={setOpenAiModal}
        revertToPreviousState={revertToPreviousState}
        applyAIContent={applyAIContent}
        isRephraseLoading={isRephraseLoading}
        aIItems={aIItems}
        content={getValue(request, "body", "")}
        aiRequest={aiRequest}
        handleChangeEditor={handleChangeEditor}
        placeHolder={`Hi [[name]]!🖐\n\nThe awesome products 😍 from [[brand_name]] are waiting to be yours!\n Get your products here👇`}
      />
      <TemplateCarouselModal
        isModalOpen={isModalOpen}
        handleOk={toggleModal}
        handleCancel={handleCancel}
        request={request}
        setRequest={setRequest}
        activeCarouselTab={selectedCarousel}
        carouselFileLists={carouselFileLists}
        handleCarouseFileChange={handleCarouseFileChange}
        handleFileRemove={handleFileRemove}
        simpleValidator={simpleValidator}
      />
    </MainLayout>
  );
};

export default CreateTemplateMessage;
