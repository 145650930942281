import { config } from "../../env";
import { Delete, get, patch, post } from "../helpers/http-handler";

export const getAllReservation = (queryRequest: string) =>
  get(`${config.API_URL}/reservation?${queryRequest}`);

export const createReservation = (payload: object) =>
  post(`${config.API_URL}/reservation`, payload);

export const releaseReservationInventory = (id: string) =>
  get(`${config.API_URL}/reservation/${id}/release/inventory`);

export const getReservationsExports = (queryRequest: string) =>
  get(`${config.API_URL}/reservation/export?${queryRequest}`);
