import { useState } from "react";
import MoreBookingDetails from "./more-booking-details";
import { getValue } from "@utils/lodash";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { capitalizeFirstLetter, formatString } from "@common/text-helpers";
import { Steps } from "antd";

type StepStatus = "process" | "finish" | "wait" | "error" | undefined;

interface Step {
  status: StepStatus;
  title: string;
}

const AllBookingInformation = (props: any) => {
  const [showAll, setShowAll] = useState(false);
  const {
    bookingInfo,
    updatingFlag,
    setUpdatingFlag,
    toggleBookingInfo,
    getAllRoomList,
  } = props;

  const handleReadMore = () => {
    setShowAll(!showAll);
  };

  const getStepsStatus = (bookingInfo: any): Step[] => {
    const bookingStatus = getValue(
      bookingInfo,
      "room_booking_info[0].booking_status",
      ""
    );
    const balanceDue = getValue(
      bookingInfo,
      "room_booking_info[0].balance_due",
      ""
    );

    return [
      {
        status: bookingStatus === "enquiry" ? "process" : "finish",
        title: "Enquiry",
      },
      {
        status:
          bookingStatus === "confirmed"
            ? "process"
            : bookingStatus === "enquiry"
            ? "wait"
            : "finish",
        title: "Confirmed",
      },
      {
        status:
          // bookingStatus === "checked_in"
          //   ? "process"
          bookingStatus === "enquiry"
            ? "wait"
            : bookingStatus === "confirmed"
            ? "wait"
            : "finish",
        title: "Checked in",
      },
      {
        status:
          bookingStatus === "checked_in"
            ? "process"
            : balanceDue === 0
            ? "finish"
            : "wait",
        title: "Settle due",
      },
      {
        status: bookingStatus === "checked_out" ? "finish" : "wait",
        title: "Checked out",
      },
    ];
  };

  console.log("bookingInfo", bookingInfo);
  return (
    <div>
      <Steps
        type="navigation"
        className="booking-info-navigation-steps"
        size="small"
        items={getStepsStatus(bookingInfo)}
      />

      <hr />
      <div className="grid-container row-gap-3 column-gap-4 mt-4">
        {/* {detailsToShow.map((details, index) => ( */}
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Booking Id:</p>
          <p className="small_text__16 text-black">
            {getValue(bookingInfo, `booking_id`, "")}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Booking Source:</p>
          <p className="small_text__16 text-black">
            {formatString(getValue(bookingInfo, `sub_segment`, ""))}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14"> Guest Name:</p>
          <p className="small_text__16 text-black">
            {capitalizeFirstLetter(
              getValue(bookingInfo, `property_booking_user.full_name`, "")
            )}
          </p>
        </div>
        {/* <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Booking Status:</p>
          <p className="small_text__16 text-black">
            {formatString(
              getValue(bookingInfo, `room_booking_info[0].booking_status`, "")
            )}
          </p>
        </div> */}
        {/* <div
            className="d-flex align-items-center gap-2 justify-content-between"
          >
            <p className="small_text__14">Guest name:</p>
            <p className="small_text__16 text-black">{getValue(bookingInfo,`customer_name`,'')}</p>
          </div>
          <div
            className="d-flex align-items-center gap-2 justify-content-between"
          >
            <p className="small_text__14">Guest phone:</p>
            <p className="small_text__16 text-black">{getValue(bookingInfo,`res_id`,'')}</p>
          </div> */}
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Room type:</p>
          <p className="small_text__16 text-black">
            {capitalizeFirstLetter(
              getValue(bookingInfo, `room_booking_info[0].room_type`, "")
            )}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Rate plan:</p>
          <p className="small_text__16 text-black">
            {capitalizeFirstLetter(
              getValue(
                bookingInfo,
                `room_booking_info[0].room_booking_info_rate_plan[0].plan_display_name`,
                ""
              )
            )}
          </p>
        </div>

        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Check-In:</p>
          <p className="small_text__16 text-black">
            {convertCurrentDateWithTime(
              getValue(bookingInfo, `room_booking_info[0].actual_checkin`, "")
                ? getValue(
                    bookingInfo,
                    `room_booking_info[0].actual_checkin`,
                    ""
                  )
                : getValue(bookingInfo, `checkin`, "")
            )}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Check-out:</p>
          <p className="small_text__16 text-black">
            {convertCurrentDateWithTime(
              getValue(bookingInfo, `room_booking_info[0].actual_checkout`, "")
                ? getValue(
                    bookingInfo,
                    `room_booking_info[0].actual_checkout`,
                    ""
                  )
                : getValue(bookingInfo, `checkout`, "")
            )}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Booking made on:</p>
          <p className="small_text__16 text-black">
            {convertCurrentDateWithTime(
              getValue(bookingInfo, `created_at`, "")
            )}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Adults / Children :</p>
          <p className="small_text__16 text-black">
            {getValue(bookingInfo, `room_booking_info[0].adults`, "")}/
            {getValue(bookingInfo, `room_booking_info[0].children`, "")}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Booked Room No.(s):</p>
          <p className="small_text__16 text-black">
            {getValue(bookingInfo, `room_booking_info[0].room_id`, "")}
          </p>
        </div>
        <div className="d-flex align-items-center gap-3 justify-content-between">
          <p className="small_text__14">Comments</p>
          <p className="small_text__16 text-black">
            {getValue(bookingInfo, `room_booking_info[0].comments`, "")}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Booked By</p>
          <p className="small_text__16 text-black">
            {capitalizeFirstLetter(
              getValue(
                bookingInfo,
                `property_booking_user.created_by.first_name`,
                ""
              )
            ) +
              " " +
              capitalizeFirstLetter(
                getValue(
                  bookingInfo,
                  `property_booking_user.created_by.last_name`,
                  ""
                )
              )}
          </p>
        </div>
        {/* <div
            className="d-flex align-items-center gap-2 justify-content-between"
          >
            <p className="small_text__14">Room Booking Amount:</p>
            <p className="small_text__16 text-black">{getValue(bookingInfo,`res_id`,'')}</p>
          </div>
          <div
            className="d-flex align-items-center gap-2 justify-content-between"
          >
            <p className="small_text__14">Room Booking Tax:</p>
            <p className="small_text__16 text-black">{getValue(bookingInfo,`res_id`,'')}</p>
          </div> */}
        {/* <div
            className="d-flex align-items-center gap-2 justify-content-between"
          >
            <p className="small_text__14">Addons Amount:</p>
            <p className="small_text__16 text-black">{getValue(bookingInfo,`res_id`,'')}</p>
          </div>
          <div
            className="d-flex align-items-center gap-2 justify-content-between"
          >
            <p className="small_text__14">Addons Tax:</p>
            <p className="small_text__16 text-black">{getValue(bookingInfo,`res_id`,'')}</p>
          </div> */}
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Total Tax:</p>
          <p className="small_text__16 text-black">
            {getValue(bookingInfo, `total_tax`, "")}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Final amount with tax:</p>
          <p className="small_text__16 text-black">
            {getValue(bookingInfo, `room_booking_info[0].total_after_tax`, "")}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Payment made:</p>
          <p className="small_text__16 text-black">
            {getValue(bookingInfo, `room_booking_info[0].booking_amount`, "")}
          </p>
        </div>
        <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Balance Due:</p>
          <p className="small_text__16 text-black">
            {getValue(bookingInfo, `room_booking_info[0].balance_due`, "")}
          </p>
        </div>
        {/* <div className="d-flex align-items-center gap-2 justify-content-between">
          <p className="small_text__14">Booking Amount:</p>
          <p className="small_text__16 text-black">
            Rs. {getValue(bookingInfo, `booking_amount`, "")}
          </p>
        </div> */}
        {/* <div
            className="d-flex align-items-center gap-2 justify-content-between"
          >
            <p className="small_text__14">Access code:</p>
            <p className="small_text__16 text-black">{getValue(bookingInfo,`res_id`,'')}</p>
          </div>
          <div
            className="d-flex align-items-center gap-2 justify-content-between"
          >
            <p className="small_text__14">OTA Booking Id:</p>
            <p className="small_text__16 text-black">{getValue(bookingInfo,`res_id`,'')}</p>
          </div> */}

        {/* ))} */}
      </div>
      <MoreBookingDetails
        bookingInfo={bookingInfo}
        updatingFlag={updatingFlag}
        setUpdatingFlag={setUpdatingFlag}
        permissions={getValue(props, `permissions`, [])}
        toggleBookingInfo={toggleBookingInfo}
        getAllRoomList={getAllRoomList}
      />
    </div>
  );
};

export default AllBookingInformation;
