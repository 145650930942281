import { DownOutlined } from "@ant-design/icons";
import { QueryRequestHelper } from "@common/query-request-helper";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import ListLoader from "@components/common/Loader";
import SolutionsNoDataPage from "@components/common/NoData/solutions-nodata";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import {
  createCategory,
  createCategoryFolder,
  deleteCategory,
  getCategories,
  updateCategory,
} from "@services/solutions.service";
import { getValue } from "@utils/lodash";
import { Button, Dropdown, MenuProps, Modal, Pagination } from "antd";
import { FolderKanban, MoreVertical, Plus, PlusCircle } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import SolutionsCategoryModal from "./components/SolutionsCategoryModal";
import SolutionsFolderModal from "./components/SolutionsFolderModal";
import SimpleReactValidator from "simple-react-validator";
import "./solutions.scss";
import { capitalizeFirstLetter } from "@common/text-helpers";
import SolutionsList from "./components/SolutionsList";

const Solutions = () => {
  const params = useParams();
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  //Categories
  const [categoriesRequest, setCategoriesRequest] = useState({
    name: "",
    description: "",
  });
  const [seletedCategory, setSelectedCategory] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesDropdownList, setCategoriesDropdownList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);
  const [openCreateCategories, setOpenCreateCategories] = useState(false);

  //Folders
  const [foldersRequest, setFoldersRequest] = useState({
    name: "",
    description: "",
    category_id: "",
    parent_folder: "",
    article_order: "alphabetically",
    visibility: "all_users",
  });
  const [selectedFolder, setSelectedFolder] = useState("");
  const [foldersList, setFoldersList] = useState([]);
  const [totalCountFolders, setTotalCountFolders] = useState(0);
  const [limitFolders, setLimitFolders] = useState(10);
  const [page_no_folders, setPage_no_folders] = useState(1);
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  //loading
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getCategoriesList(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getCategoriesList(true);
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                              Categories API Section                        */
  /* -------------------------------------------------------------------------- */

  const handleCategoriesSubmit = async () => {
    setIsLoading(true);
    try {
      let payload = JSON.parse(JSON.stringify(categoriesRequest));
      const resp = seletedCategory
        ? await updateCategory(seletedCategory, payload)
        : await createCategory(payload);
      if (resp) {
        toast.success(
          `${seletedCategory ? "Updated" : "Created"} Successfully`
        );
        setSelectedCategory("");
        setCategoriesRequest({
          name: "",
          description: "",
        });
        setIsLoading(false);
        setOpenCreateCategories(false);
        getCategoriesList(true);
      } else {
        toast.error(
          `Failed ${seletedCategory ? "updating" : "creating"} Category`
        );
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(
        `Error ${seletedCategory ? "updating" : "creating"} Category`
      );
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getCategoriesList = async (status: boolean) => {
    try {
      setIsLoading1(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getCategories(queryRequest);
      if (resp) {
        const modifiedResp = getValue(resp, "data.categories", []).map(
          (item: any) => ({
            value: getValue(item, "id", ""),
            label: getValue(item, "name", ""),
          })
        );
        setCategoriesDropdownList(modifiedResp);
        setCategoriesList(getValue(resp, "data.categories", []));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setIsLoading1(false);
      }
    } catch (error) {
      toast.error("Failed to load categories.");
      setIsLoading1(false);
    } finally {
      setIsLoading1(false);
    }
  };

  const handleDeleteCategory = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete category - ${name}?`,
      content: "Once deleted, this category cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteCategory(id);
          toast.success("Category deleted successfully");
          getCategoriesList(true);
        } catch (error) {
          toast.error("Error deleting category");
        }
      },
    });
  };

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };
  /* -------------------------------------------------------------------------- */
  /*                              Categories API Section                        */
  /* -------------------------------------------------------------------------- */
  const handleFolderSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      // toast.error(`Please fill all the mandatory fields`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading2(true);
      try {
        let payload = JSON.parse(JSON.stringify(foldersRequest));
        const resp = await createCategoryFolder(payload);
        if (resp) {
          toast.success(
            `${seletedCategory ? "Updated" : "Created"} Successfully`
          );
          setFoldersRequest({
            name: "",
            description: "",
            category_id: "",
            parent_folder: "",
            article_order: "alphabetically",
            visibility: "all_users",
          });
          forceUpdate(0);
          simpleValidator.current.hideMessages();
          setOpenCreateFolder(false);
          setIsLoading2(false);
          getCategoriesList(true);
        } else {
          toast.error(
            `Failed ${seletedCategory ? "updating" : "creating"} Folder`
          );
          setIsLoading2(false);
        }
      } catch (error) {
        toast.error(
          `Error ${seletedCategory ? "updating" : "creating"} Folder`
        );
        setIsLoading2(false);
      } finally {
        setIsLoading2(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                           Category  Modal Section                          */
  /* -------------------------------------------------------------------------- */

  const showCategoryModal = (
    id?: string,
    name?: string,
    description?: string
  ) => {
    if (id) {
      setSelectedCategory(id);
      setCategoriesRequest({
        name: name ? name : "",
        description: description ? description : "",
      });
    }
    setOpenCreateCategories(true);
  };

  const handleCancelCategories = () => {
    setOpenCreateCategories(false);
    setCategoriesRequest({
      name: "",
      description: "",
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                           Folder  Modal Section                          */
  /* -------------------------------------------------------------------------- */

  const showFolderModal = (
    id?: string,
    name?: string,
    description?: string
  ) => {
    setOpenCreateFolder(true);
  };

  const handleCancelFolder = () => {
    setOpenCreateFolder(false);
    setFoldersRequest({
      name: "",
      description: "",
      category_id: "",
      parent_folder: "",
      article_order: "alphabetically",
      visibility: "all_users",
    });
    forceUpdate(0);
    simpleValidator.current.hideMessages();
  };

  const items: MenuProps["items"] = [
    {
      label: "New Folder",
      key: "1",
      icon: <PlusCircle size={16} />,
      onClick: () => showFolderModal(),
      disabled: categoriesList.length === 0,
    },
    {
      label: "New Category",
      key: "2",
      icon: <PlusCircle size={16} />,
      onClick: () => showCategoryModal(),
    },
  ];

  const subMenuArray = [
    {
      linkText: `Categories (${categoriesList.length})`,
    },
  ];

  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle={`Knowledge Base (Support)`}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        subMenuArray={subMenuArray}
        addButton={
          <Dropdown.Button
            icon={<DownOutlined />}
            menu={{ items }}
            onClick={() =>
              navigate(
                `/${getValue(params, "orgId", "")}/solutions/article/create`
              )
            }
            size="large"
            type="primary"
            className="justify-content-end"
          >
            New Article
          </Dropdown.Button>
        }
      />
      <div className={`d-flex w-100`}>
        <div className="manageFieldRight w-100">
          <div className="solutions-wrapper">
            {isLoading1 ? (
              <ListLoader />
            ) : categoriesList.length === 0 ? (
              <SolutionsNoDataPage />
            ) : (
              <SolutionsList
                navigate={navigate}
                params={params}
                categoriesList={categoriesList}
                showCategoryModal={showCategoryModal}
                handleDeleteCategory={handleDeleteCategory}
                foldersRequest={foldersRequest}
                setFoldersRequest={setFoldersRequest}
                showFolderModal={showFolderModal}
              />
            )}
          </div>
          <Pagination
            align="center"
            className="pt-3 border-top"
            total={totalCount}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            defaultPageSize={limit}
            defaultCurrent={page_no}
            onChange={handleChangePagination}
          />
        </div>
      </div>
      {/* Category */}
      <SolutionsCategoryModal
        openCreateCategories={openCreateCategories}
        request={categoriesRequest}
        setRequest={setCategoriesRequest}
        seletedCategory={seletedCategory}
        isLoading={isLoading}
        handleCategorySubmit={handleCategoriesSubmit}
        handleCancelCategories={handleCancelCategories}
      />
      {/* Folder */}
      <SolutionsFolderModal
        openCreateFolder={openCreateFolder}
        request={foldersRequest}
        setRequest={setFoldersRequest}
        isLoading={isLoading2}
        handleCancelFolder={handleCancelFolder}
        handleFolderSubmit={handleFolderSubmit}
        categoriesDropdownList={categoriesDropdownList}
        validator={simpleValidator}
      />
    </div>
  );
};

export default Solutions;
