import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Table } from "antd";
import React from "react";

const GroupsAgents = (props: any) => {
  const { isLoading2, groupDetails, setSelectedAgentIds } = props;

  const columns = [
    {
      title: "NAME",
      dataIndex: "agent",
      key: "agent",
      render: (agent: any) => (
        <>
          <h6 className="header_text__14">
            {capitalizeFirstLetter(getValue(agent, "first_name", "")) +
              " " +
              capitalizeFirstLetter(getValue(agent, "last_name", ""))}
          </h6>
        </>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "agent",
      key: "email",
      render: (agent: any) => getValue(agent, "email", "-"),
    },
  ];

  const handleRowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      const updatedAgents = selectedRowKeys.map((key) => ({
        id: key as string,
      }));
      setSelectedAgentIds(updatedAgents);
    },
  };

  return (
    <Table
      size="middle"
      bordered
      columns={columns}
      dataSource={getValue(groupDetails, "agents", [])}
      rowKey={(record: any) => record.agent_id}
      loading={isLoading2}
      className="mt-3"
      rowSelection={{
        type: "checkbox",
        ...handleRowSelection,
      }}
      pagination={{
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
    />
  );
};

export default GroupsAgents;
