import React from "react";
import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  className?: string;
  route?: string;
  handleBack?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({
  className = "",
  route,
  handleBack,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (handleBack) {
      handleBack();
    } else if (route) {
      navigate(route);
    } else {
      window.history.back();
    }
  };

  return (
    <button
      onClick={handleGoBack}
      className={`${className} dashboard-button p-0`}
    >
      <img
        src="/images/icons/back-icon.svg"
        width={24}
        height={24}
        alt="back"
      />
    </button>
  );
};

export default BackButton;
