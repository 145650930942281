import { getValue } from "@utils/lodash";
import React from "react";
import DropDown from "@components/CustomSelect/CustomSelect";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import InfoSvgComponent from "@assets/svg/info";
import { Checkbox } from "antd";

const CheckDatebox = ({
  label,
  value,
  dateTypeOne,
  dateTypeTwo,
  dateTypeThree,
  onChange,
  checked,
  selectedOption,
}: any) => {
  const DateId = `importOption_${value}`;

  return (
    <div className="radio-wrapper">
      <input
        type="radio"
        id={DateId}
        name="importDateType"
        value={value}
        checked={selectedOption === value}
        onChange={() => onChange(value)}
      />
      <label className="radio-label" htmlFor={DateId}>
        <p className="span">{label}</p>
        <span className="span">{dateTypeOne}</span>
        <span className="span">{dateTypeTwo}</span>
        <span className="span">{dateTypeThree}</span>
      </label>
    </div>
  );
};
function ImportStep5(props: any) {
  const handleChange = (value: string) => {
    props.setRequest({
      ...props.request,
      date_format: value,
    });
  };
  const howToImportContacts = [
    { label: "Create new contact", value: "add" },
    {
      label: "Create new contacts and update existing one",
      value: "add_update",
    },
    { label: "Update only existing one", value: "update" },
  ];
  const getMatchedFieldValidation = () => {
    let columnMapping = getValue(
      props,
      `info.column_mapping`,
      []
    ).filter((item: object) => getValue(item, `mapped_field_name`, ""));
    let list =
      getValue(columnMapping, `length`, 0) > 0
        ? columnMapping.map((item: object) =>
            getValue(item, `mapped_field_name`, "")
          )
        : [];
    if (list.includes(getValue(props, `request.match_field`, ""))) {
      return true;
    } else {
      return false;
    }
  };
  const getMatchedFieldName = () => {
    let list = getValue(props, `info.match_fields`, []).filter(
      (item: object) =>
        getValue(item, `value`, "") ===
        getValue(props, `request.match_field`, "")
    );

    return getValue(list, `[${0}].label`, "");
  };

  return (
    <div className="contact-type w-100">
      <div>
        <h3>Your file is ready to be imported</h3>
        <p className="mapping-description">
          Take a quick look at these settings to make sure nothing is missed out
        </p>
        <div className="more-settings">
          <div>
            <span className="sub-heading">
              Select the date format used in your file
            </span>
            <div className="import-date-format">
              <CheckDatebox
                value={"DDMMYYYY"}
                label="DD MM YYYY"
                onChange={() => handleChange("DDMMYYYY")}
                selectedOption={getValue(props, `request.date_format`, "")}
                dateTypeOne="25/05/2023"
                dateTypeTwo="25-05-2023"
                dateTypeThree="25.05.2023"
              />
              <CheckDatebox
                value={"MMDDYYYY"}
                label="MM DD YYYY"
                onChange={() => handleChange("MMDDYYYY")}
                selectedOption={getValue(props, `request.date_format`, "")}
                dateTypeOne="05/25/2023"
                dateTypeTwo="05-25-2023"
                dateTypeThree="05.25.2023"
              />
              <CheckDatebox
                value={"YYYYMMDD"}
                label="YYYY MM DD"
                onChange={() => handleChange("YYYYMMDD")}
                selectedOption={getValue(props, `request.date_format`, "")}
                dateTypeOne="2023/05/25"
                dateTypeTwo="2023-05-25"
                dateTypeThree="2023.05.25"
              />
            </div>
          </div>

          <div className="mb-4">
            {/* <span className="sub-heading">
              How do you want to import contacts?
            </span> */}
            {/* <DropDown
              options={howToImportContacts}
              request={props.request}
              setRequest={props.setRequest}
              value={"import_type"}
            /> */}

            <SearchToolTip
              label="label"
              data={howToImportContacts}
              selectKey={"value"}
              value={getValue(props, `request.import_type`, "")}
              onChange={(e: object) =>
                props.setRequest({
                  ...props.request,
                  import_type: getValue(e, `value`, ""),
                  match_field: getValue(
                    props,
                    `info.match_fields[${0}].value`,
                    ""
                  ),
                })
              }
              editInline
              name="How do you want to import contacts?"
              required
              errorLabel={"Import Type"}
              validator={props.validator}
              concatLength={60}
            />
          </div>
          <div className="mb-3">
            <div className="d-flex align-items-center">
              <div className="email-checkbox">
                <label className="checkbox-container">
                  <Checkbox
                    type="checkbox"
                    checked={
                      getValue(props, `request.match_field`, "") ? true : false
                    }
                    onChange={(e) => {
                      props.setRequest({
                        ...props.request,
                        match_field: "",
                      });
                    }}
                    disabled={!getValue(props, `request.match_field`, "")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <p className="ms-2"> Skip duplicate contacts if field matches with</p>
            </div>
            <div className="mt-2">
              <SearchToolTip
                label="label"
                data={getValue(props, `info.match_fields`, [])}
                selectKey={"value"}
                value={getValue(props, `request.match_field`, "")}
                onChange={(e: object) =>
                  props.setRequest({
                    ...props.request,
                    match_field: getValue(e, `value`, ""),
                  })
                }
                editInline
              />
            </div>
            {getValue(props, `request.match_field`, "") &&
              !getMatchedFieldValidation() && (
                <div className="d-flex align-items-center p-2 import_error_container mt-3">
                  <InfoSvgComponent color={"#ffd0d6"} />
                  <p className="text ms-3 ">
                    <b>{getMatchedFieldName()}</b> is not mapped to any column
                    in your file. To make sure your existing contacts are
                    updated, go back and map it to a column.
                  </p>
                </div>
              )}
          </div>
          {getValue(props, `module`, "") !== "calls" &&
            getValue(props, `module`, "") !== "meetings" &&
            getValue(props, `module`, "") !== "tasks" && (
              <div>
                <SearchToolTip
                  label="label"
                  data={getValue(props, `stages`, [])}
                  selectKey={"value"}
                  value={getValue(props, `request.pipeline_stage_id`, "")}
                  onChange={(e: object) =>
                    props.setRequest({
                      ...props.request,
                      pipeline_stage_id: getValue(e, `value`, ""),
                    })
                  }
                  editInline
                  name={"Status"}
                  // required
                  // validator={props.validator}
                />
              </div>
            )}
          <SearchToolTip
            label="label"
            selectKey={"id"}
            value={getValue(props, `request.owner_email`, "")}
            onChange={(e: object) =>
              props.setRequest({
                ...props.request,
                owner_email: getValue(e, `id`, ""),
                owner_id: getValue(e, `main_id`, ""),
              })
            }
            editInline
            name={"Owner"}
            lookup_api="org_user_mail"
            // required
            // validator={props.validator}
          />
          {/* <DropDown
              options={getValue(props, `stages`, [])}
              request={props.request}
              setRequest={props.setRequest}
              value={"pipeline_stage_id"}
            /> */}
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default ImportStep5;
