import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllTasks = (queryRequest: string) =>
  get(`${config.API_URL}/records/tasks?${queryRequest}`);

export const getSpecificTask = (id: string) =>
  get(`${config.API_URL}/records/tasks/${id}`);

export const createTask = (payload: object) =>
  post(`${config.API_URL}/records/tasks`, payload);

export const updateTask = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/tasks/${id}`, payload);

export const deleteTask = (id: string) =>
  Delete(`${config.API_URL}/records/tasks/${id}`);

export const tasksBulkUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/tasks/bulk-update`, payload);

export const tasksSheetUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/tasks/sheet-view`, payload);

//task notes @ comments
export const getSpecificTaskComment = (id: string) =>
  get(`${config.API_URL}/records/tasks/${id}/notes`);

export const createTaskComment = (payload: object, id: string) =>
  post(`${config.API_URL}/records/tasks/${id}/notes`, payload);

export const updateTaskComment = (
  id: string,
  payload: object,
  comment_id: string
) =>
  patch(`${config.API_URL}/records/tasks/${id}/notes/${comment_id}`, payload);

export const deleteTaskComment = (id: string, comment_id: string) =>
  Delete(`${config.API_URL}/records/tasks/${id}/notes/${comment_id}`);
