import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Button, Dropdown, Empty, MenuProps } from "antd";
import { FolderKanban, MoreVertical } from "lucide-react";
import React from "react";

const SolutionsList = (props: any) => {
  const {
    categoriesList,
    showCategoryModal,
    handleDeleteCategory,
    foldersRequest,
    setFoldersRequest,
    showFolderModal,
    navigate,
    params,
  } = props;
  return (
    <div className="solutions-card-wrapper">
      {categoriesList.map((category: any, index: any) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: "Edit",
            onClick: () =>
              showCategoryModal(
                getValue(category, "id", ""),
                getValue(category, "name", ""),
                getValue(category, "description", "")
              ),
          },
          {
            key: "2",
            label: "Delete",
            onClick: () =>
              handleDeleteCategory(
                getValue(category, "id", ""),
                getValue(category, "name", "")
              ),
          },
        ];
        return (
          <div key={index} className="solutions-card-container border">
            <div className="d-flex justify-content-between align-items-center gap-3 mb-2">
              <h6 className="header_text__18 d-flex align-items-center gap-2">
                <FolderKanban />{" "}
                {capitalizeFirstLetter(getValue(category, "name", ""))}
              </h6>
              <Dropdown menu={{ items }} className="solution-edit-dropdown">
                <MoreVertical size={18} />
              </Dropdown>
            </div>
            {getValue(category, "description", "") && (
              <p className="small_text__14">
                {getValue(category, "description", "")}
              </p>
            )}
            {getValue(category, "folders.length", 0) !== 0 ? (
              <div className="mt-3">
                {getValue(category, "folders", []).map(
                  (folder: any, i: number) => (
                    <div
                      key={i}
                      className={`folder-card cursor-pointer p-2 ${
                        i === getValue(category, "folders.length", 0) - 1
                          ? ""
                          : "border-bottom"
                      }`}
                      onClick={() =>
                        navigate(
                          `/${getValue(
                            params,
                            "orgId",
                            ""
                          )}/solutions/${getValue(
                            category,
                            "id",
                            ""
                          )}/folders/${getValue(folder, "id", "")}`
                        )
                      }
                    >
                      <h6 className="header_text__16">
                        {capitalizeFirstLetter(getValue(folder, "name", ""))}
                      </h6>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="w-100 mt-4">
                <Empty>
                  <Button
                    className=""
                    onClick={() => {
                      setFoldersRequest({
                        ...foldersRequest,
                        category_id: getValue(category, "id", ""),
                      });
                      showFolderModal();
                    }}
                  >
                    Add Folder
                  </Button>
                </Empty>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SolutionsList;
