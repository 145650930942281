import SettingsGroups from "@pages/Private/Settings/Groups";
import CreateGroup from "@pages/Private/Settings/Groups/EditGroupSettings";
import HelpDeskTicketFields from "@pages/Private/Settings/TicketFields";

export const SettingsGroupsRoutes = [
  {
    path: "/:orgId/settings/helpdesk/groups",
    name: "settings_templates_business_hours",
    component: SettingsGroups,
  },
  {
    path: "/:orgId/settings/helpdesk/groups/edit/:id",
    name: "settings_templates_business_hours",
    component: CreateGroup,
  },
  {
    path: "/:orgId/settings/helpdesk/ticket-fields",
    name: "settings_reports_bulk_download",
    component: HelpDeskTicketFields,
  },
];
