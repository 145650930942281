import { useEffect, useState } from "react";
import ModuleSettingNavViews from "../components/nav-views";
import {
  getInboxDomains,
  validateInboxDomain,
} from "@services/email-config.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import SettingsLayout from "@layouts/Layout/settings-layout";
import { useNavigate, useParams } from "react-router-dom";
import ListLoader from "@components/common/Loader";
import ConfigPopup from "./config-popup";
import { toast } from "sonner";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import EmailssNoDataPage from "@components/common/NoData/email-nodata";
import { Button, Table, Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
function SettingsEmailDeliverability(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [domainList, setDomainList] = useState([]);
  const [configInfo, setConfigInfo] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let payload = {};
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getInboxDomains(queryRequest);
      if (resp) {
        setDomainList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getItemInfo = (item: object) => {
    let config = getValue(item, `config`, "");
    if (config) {
      setConfigInfo({
        ...item,
        config: JSON.parse(config),
      });
      handleModalOpen();
    } else {
      toast.error("No config generated");
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const handleNavigate = () => {
    navigate(
      `/${getValue(
        params,
        `orgId`,
        ""
      )}/settings/general/emails/deliverability/add`
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [validateLoading, setValidateLoading] = useState(false);
  const handleValidate = async () => {
    try {
      setValidateLoading(true);
      let resp = await validateInboxDomain(getValue(configInfo, `id`, ""));
      if (resp) {
        setValidateLoading(false);
        if (getValue(resp, `data.isVerified`, false)) {
          toast.success("Verified successfully");
        } else {
          toast.error("Not verified");
        }
      } else {
        setValidateLoading(false);
      }
    } catch (error) {
      setValidateLoading(false);
    }
  };

  const columns = [
    {
      title: "DOMAIN",
      dataIndex: "domain",
      key: "domain",
      render: (domain: any, record: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() => getItemInfo(record)}
        >
          {domain}
        </div>
      ),
    },
    {
      title: "SUB DOMAIN",
      dataIndex: "subDomain",
      key: "subDomain",
      // render: (text: any) => text.label,
    },
    {
      title: "VERIFIED",
      dataIndex: "isVerified",
      key: "isVerified",
      render: (isVerified: any, record: any) => (
        <div className="d-flex align-items-center gap-2">
          {isVerified === true ? (
            <CheckCircleOutlined
              style={{ color: "#3fd1a9", fontSize: "18px" }}
            />
          ) : (
            <>
              <CloseCircleOutlined
                style={{ color: "#f54d4d", fontSize: "18px" }}
              />
              <p
                className="small_text__14 color_primary cursor-pointer"
                onClick={() => getItemInfo(record)}
              >
                Verify
              </p>
            </>
          )}
        </div>
      ),
    },
    {
      title: "DEFAULT",
      dataIndex: "isDefault",
      key: "isDefault",
      render: (isDefault: boolean) => (
        <Tag color={isDefault === true ? "success" : "error"}>
          {isDefault === true ? "Yes" : "No"}
        </Tag>
      ),
    },
    // {
    //   title: "ACTION",
    //   dataIndex: "is_active",
    //   key: "is_active",
    //   render: (is_active: any, record: any) => (
    //     <div className="d-flex align-items-center ">
    //       <div className="image_hover_background">
    //         <EditSvgComponent />
    //       </div>
    //       <div
    //         className="image_hover_background"
    //         onClick={(e: any) => e.stopPropagation()}
    //       >
    //         <DeleteSvgComponent />
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        title="Emails"
        icon="emails"
        addChildren={
          <Button size="large" type="primary" onClick={handleNavigate}>
            New Sender
          </Button>
        }
      />
      <div className={`manageFieldRightSettings add-overflow-y p-4`}>
        <ModuleSettingNavViews />
        <div className="mt-4 d-flex align-items-center gap-2">
          <p className="small_text__14 w-50">
            When you send messages using Appzo, the Sender is the "from" email
            address that is displayed. You can add your own email address(es) as
            the sender.
          </p>
        </div>
        {isLoading ? (
          <ListLoader />
        ) : getValue(domainList, `length`, 0) > 0 ? (
          // <table className={`common_table w-100 mt-4`}>
          //   <thead>
          //     <tr>
          //       <td>Domain</td>
          //       <td>Sub Domain</td>
          //       <td>Verified</td>
          //       <td>Default</td>
          //       <td>Action</td>
          //     </tr>
          //   </thead>
          //   <tbody>
          //     {domainList.map((item: object) => {
          //       return (
          //         <tr onClick={() => getItemInfo(item)}>
          //           <td>{getValue(item, `domain`, "")}</td>
          //           <td>{getValue(item, `subDomain`, "")}</td>
          //           <td>
          //             <div className="d-flex align-items-center">
          //               <p className="small_text__14">
          //                 {getValue(item, `isVerified`, false)
          //                   ? "Verified"
          //                   : "Unverified"}
          //               </p>
          //               <p className="small_text__14 color_primary ms-2">
          //                 Verify
          //               </p>
          //             </div>
          //           </td>
          //           <td>
          //             {getValue(item, `isDefault`, false) ? "Yes" : "No"}
          //           </td>
          //           <td>
          //             <div className="d-flex align-items-center ">
          //               <div className="image_hover_background">
          //                 <EditSvgComponent />
          //               </div>
          //               <div
          //                 className="image_hover_background"
          //                 onClick={(e: any) => e.stopPropagation()}
          //               >
          //                 <DeleteSvgComponent />
          //               </div>
          //             </div>
          //           </td>
          //         </tr>
          //       );
          //     })}
          //   </tbody>
          // </table>
          <Table
            size="middle"
            className="mt-3"
            bordered
            columns={columns}
            dataSource={domainList}
            rowKey="id"
            pagination={{
              showTotal: (total, range) =>
                `${total} ${total >= 2 ? "items" : "item"}`,
            }}
            scroll={{
              y: `calc(100vh - 300px)`,
              // x: 1400,
            }}
          />
        ) : (
          <div className="mt-3">
            <EmailssNoDataPage />
          </div>
        )}
      </div>
      <ConfigPopup
        isOpen={isModalOpen}
        handleModal={handleModalOpen}
        info={getValue(configInfo, `config`, {})}
        configInfo={configInfo}
        handleSubmit={handleValidate}
        isLoading={validateLoading}
      />
    </CompanyProfileDetailsLayout>
  );
}

export default SettingsEmailDeliverability;
