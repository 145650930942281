import DownSvgComponent from "@assets/svg/down";
import UpSvgComponent from "@assets/svg/up";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import DashboardMoreOptions from "../../components/dashboard-more-options";
import { Eye } from "lucide-react";

function KPIStandardComonent(props: any) {
  return (
    <div
      className={`${
        getValue(props, "isMaximized", "") ? "" : "dashboard_border"
      }`}
    >
      <p
        className="dashboard-card-heading"
        onClick={() => props.toggleChartDetails(props)}
      >
        {capitalizeFirstLetter(getValue(props, `name`, ""))}
        <Eye size={16} className="dashboard-card-heading-eye" />
      </p>
      <div className="d-flex align-items-center cursor-pointer">
        <h6 className="standard-highlight">
          {getValue(props, `graph.current`, "")}
        </h6>
        <div className="d-flex align-items-center ms-3 ">
          {getValue(props, `compare_objective`, "") === "positive" ? (
            <UpSvgComponent size="20" />
          ) : (
            <DownSvgComponent size="20" />
          )}

          <p
            className={`${
              getValue(props, `compare_objective`, "") === "positive"
                ? "db_color_success"
                : "db_color_negative"
            }`}
          >
            {getValue(props, `graph.percentage`, 0) > 0
              ? parseInt(getValue(props, `graph.percentage`, 0))
              : 0}
            %
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center mt-2 ">
        <p className="small_text__14">
          {capitalizeFirstLetter(getValue(props, `graph.previous_label`, ""))} :{" "}
          {getValue(props, `graph.previous`, "")}
        </p>
      </div>
      <DashboardMoreOptions
        props={props}
        id={getValue(props, "id", "")}
        confirmDeleteAnalyticsComponent={props.confirmDeleteAnalyticsComponent}
        handleEditChart={props.handleEditChart}
      />
    </div>
  );
}

export default KPIStandardComonent;
