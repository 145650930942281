import { getColorCode, getTextColor } from "@common/colors";
import {
  capitalizeFirstLetter,
  formatString,
  getOnlyFirstLetterOfWord,
} from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Badge, Checkbox, Tag } from "antd";
import "./card.scss";
import { daysSinceDate } from "@common/date-helpers";
import { Link } from "react-router-dom";
import ListLoader from "@components/common/Loader";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import TicketsNoDataPage from "@components/common/NoData/tickets-nodata";

const CustomCardView = ({
  params,
  data,
  module,
  selectedIds,
  setSelectedIds,
  isLoading,
}: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const handleChangeCheckbox = (id: any) => {
    let checkIfExist =
      getValue(selectedIds, `length`, 0) > 0
        ? selectedIds.filter((item: any) => item === id)
        : [];
    if (getValue(checkIfExist, `length`, 0) > 0) {
      let filtered =
        getValue(selectedIds, `length`, 0) > 0
          ? selectedIds.filter((item: any) => item !== id)
          : [];
      setSelectedIds(filtered);
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds]);
    }
  };

  return isLoading && !getValue(UrlParams, `search`, "") ? (
    <ListLoader />
  ) : (getValue(Object.keys(UrlParams), `length`, 0) && data.length === 0) ||
    getValue(localStorage, `sf:tickets`, "") ? (
    <FiltersNoDataPage module={module} />
  ) : data.length > 0 ? (
    <>
      {/* {isLoading ? (
        <ListLoader />
      ) : ( */}
      <div className="d-grid gap-2 mb-3 mt-1 ticket-card-wrapper">
        {data.map((item: any, index: any) => (
          <div key={index} className="ticket-card-container row mx-auto">
            <div className="d-flex align-items-center gap-2 col-8">
              <Checkbox
                className="pe-3"
                checked={
                  selectedIds.includes(getValue(item, `id`, "")) ? true : false
                }
                onChange={() => handleChangeCheckbox(getValue(item, `id`, ""))}
              />
              <div
                className="image-container"
                style={{
                  background: getColorCode(
                    getOnlyFirstLetterOfWord(getValue(item, `ticket_name`, ""))
                  ),
                  color: getTextColor(
                    getColorCode(
                      getOnlyFirstLetterOfWord(
                        getValue(item, `ticket_name`, "")
                      )
                    )
                  ),
                }}
              >
                <p className="header-text">
                  {getOnlyFirstLetterOfWord(getValue(item, `ticket_name`, ""))}
                </p>
              </div>
              <div className="d-grid">
                {/* {getValue(item, "resolution", "") && ( */}
                <Tag color="magenta" className="w-fit-content">
                  {formatString(getValue(item, "resolution", ""))}
                </Tag>
                {/* )} */}
                <Link
                  to={`/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/tickets/detail/${getValue(item, "id", "")}`}
                  className="py-2"
                >
                  {capitalizeFirstLetter(getValue(item, "ticket_name", ""))}{" "}
                  <span className="small_text__12">
                    {getValue(item, "number_of_times_contacted", "") && (
                      <># {getValue(item, "record_num", "")}</>
                    )}
                  </span>
                </Link>
                <div className="d-flex align-items-center gap-3">
                  <p className="small_text__13">
                    Ticket ID: {getValue(item, "ticket_id", "")}
                  </p>
                  <Badge status="default" />
                  <p className="small_text__13">
                    Created: {daysSinceDate(getValue(item, "created_at", ""))}
                  </p>
                  {/* <Badge status="default" />
                <p className="small_text__13">
                  Overdue by
                  {daysSinceDate(getValue(item, "created_at", "")) === 0
                    ? "today"
                    : daysSinceDate(getValue(item, "created_at", "")) === 1
                    ? "yesterday"
                    : `${daysSinceDate(
                        getValue(item, "created_at", "")
                      )} days ago`}
                </p> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* )} */}
    </>
  ) : (
    <TicketsNoDataPage />
  );
};

export default CustomCardView;
