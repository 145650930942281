import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import { Link } from "react-router-dom";
import "./whatsapp.scss";
import { getValue } from "@utils/lodash";
import { Modal } from "antd";
import QRCode from "react-qr-code";
import { config } from "../../../../env";
import CloseMainSvgComponent from "@assets/svg/close-main";
import { CopyToClipboard } from "@common/text-helpers";
import { toast } from "sonner";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import { saveAs } from "file-saver";

function GenerateWhatsappLink(props: any) {
  const [request, setRequest] = useState({
    phone_number: "",
    message: "",
  });

  const [generatedLink, setGeneratedLink] = useState("");
  const handleSubmit = () => {
    if (
      getValue(request, `message`, "") &&
      getValue(request, `phone_number`, "")
    ) {
      setGeneratedLink(
        `https://api.whatsapp.com/send?text=${getValue(
          request,
          `message`,
          ""
        )}&phone=${getValue(request, `phone_number`, "")}`
      );
      handleModal();
    } else {
      toast.error("Please input Phone Number / Message");
    }
  };

  const handleDownloadOnClick = (qrCodeDataUrl: string, filename: string) => {
    const link = document.createElement("a");
    link.href = qrCodeDataUrl;
    link.download = `${filename}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const downloadQRCode = () => {
    const svg: any = document.getElementById("qrcode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      saveAs(pngFile, "qrcode.png");
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <>
      <header
        className={`login-header-wrapper d-flex justify-content-between align-items-center`}
      >
        <div className="container-fluid">
          <Link to="/">
            <a
              className={`login-header-wrapper__logo cursor-pointer d-inline-block d-flex`}
            >
              <img
                src={"/appzo-logo.svg"}
                className={`login-header-wrapper__logo-image img-fluid`}
              />
            </a>
          </Link>
        </div>
      </header>
      <div className="generate_whatspp-main_wrapper">
        <div className="generate_whatspp-main_container">
          <div className="generate_whatspp-header_container">
            <h6 className="header">Create WhatsApp Link</h6>
            <p className="desc">
              Enter the Whatsapp Mobile Number and Pre-filled text and generate
              your Whatsapp Link Instantly
            </p>
          </div>
          <div className="d-flex align-items-start mt-5">
            <div className="w-50">
              <div className="generate_whatspp-phone_container">
                <h6 className="header">WhatsApp Phone Number</h6>
                <p className="desc">
                  Select your country code & Type your WhatsApp phone number
                </p>
                <div className="w-100 mt-4">
                  <PhoneInput
                    defaultCountry="in"
                    value={getValue(request, `phone_number`, "")}
                    onChange={(e: any) => {
                      setRequest({
                        ...request,
                        phone_number: e,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="generate_whatspp-message_container mt-4">
                <h6 className="header">Custom Message</h6>
                <p className="desc">
                  Type your custom message with emojis & WhatsApp text
                  formatting
                </p>
                <div className="w-100">
                  <InputRuleForm
                    inputType="TEXTAREA"
                    placeholder="Add your customer message that user will send to you"
                    value={getValue(request, `message`, "")}
                    onChange={(e: any) => {
                      setRequest({
                        ...request,
                        message: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <ButtonComponent
                  buttonType={"green"}
                  buttonText={"Generate Link"}
                  onClickHandler={() => handleSubmit()}
                />
              </div>
            </div>
            <div className="w-50 d-flex justify-content-center">
              <div className="mobile-template">
                <div className="mobile-border whatsapp-link-border">
                  <div className="dark-green-header whatsapp-link-header"></div>
                  <div className="green-header d-flex align-items-center px-3 text-white">
                    <img
                      src="/images/account_circle.svg"
                      className="account-circle me-3"
                    />
                    {request.phone_number}
                  </div>
                  <div className="mobile-screen whatsapp-link-screen d-flex justify-content-end align-items-end flex-column">
                    {request.message && (
                      <div className="whatsapp-user-message">
                        {request.message}
                      </div>
                    )}
                    <div className="bottom-user-message">
                      <div className="message-create-container">
                        <p className="message-placeholder">
                          {request.message.length > 0 ? (
                            <>
                              {request.message.length > 20
                                ? request.message.slice(0, 30) + ".."
                                : request.message}
                            </>
                          ) : (
                            "Message"
                          )}
                        </p>
                      </div>
                      <img
                        src="/images/send.svg"
                        className="whatsapp-send-button"
                      />
                    </div>
                  </div>
                </div>
                {/* Message Preview <div>{request.phone_number}</div>
              <div>{request.message}</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={isOpen}>
        <div className="p-5">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="header_text__18">
              Here is your WhatsApp short link
            </h6>
            <div onClick={handleModal}>
              <CloseMainSvgComponent />
            </div>
          </div>
          <p className="small_text__14 mt-3">
            Copy and share it on your social media, website, emails or anywhere
            you want to be contacted instantly by your customers.
          </p>
          <div className="text-center mt-4 mb-4">
            <QRCode id="qrcode" value={generatedLink} size={170} />
          </div>
          <p className="text-center small_text__14">
            FREE !!- Track links & much more,{" "}
            <a
              className="header_blue_text cursor-pointer "
              target="blank"
              href={config.MAIN_URL}
            >
              Sign Up
            </a>
            now
          </p>
          <div className="mt-4 d-flex justify-content-center">
            <ButtonComponent
              buttonType={"green"}
              buttonText={"Copy URL"}
              onClickHandler={() => CopyToClipboard(generatedLink)}
            />
            <ButtonComponent
              buttonType={"green_outline"}
              buttonText={"Download QR"}
              onClickHandler={() => downloadQRCode()}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default GenerateWhatsappLink;
