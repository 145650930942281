import { emojisList } from "@common/emojis";
import MarkdownEditor from "@components/MarkdownEditor/Editor";
import AIStars from "@components/common/Loader/ai-stars";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import { getValue } from "@utils/lodash";
import { Alert, Button, Dropdown, Modal } from "antd";
import React from "react";

const RNRephraseModal = (props: any) => {
  const {
    openAiModal,
    setOpenAiModal,
    revertToPreviousState,
    applyAIContent,
    isRephraseLoading,
    aIItems,
    content,
    aiRequest,
    handleChangeEditor,
    placeHolder,
  } = props;

  return (
    <Modal
      title="AI Rewrite"
      centered
      open={openAiModal}
      onOk={() => setOpenAiModal(false)}
      onCancel={() => setOpenAiModal(false)}
      width={1000}
      footer={[
        <Button key="revert" onClick={() => revertToPreviousState()}>
          Revert content
        </Button>,

        <Button
          key="submit"
          type="primary"
          loading={isRephraseLoading}
          onClick={async () => {
            await applyAIContent();
            setOpenAiModal(false);
          }}
        >
          {isRephraseLoading ? "Generating" : "Apply AI content"}
        </Button>,
      ]}
    >
      <p>
        AI Rewrite helps you create engaging and more meaningful message
        templates.
      </p>
      <div className="d-flex justify-content-end">
        <Dropdown menu={{ items: aIItems }}>
          <a onClick={(e) => e.preventDefault()}>
            <div className="ai-button-wrapper">
              <button className="ai-button" type="button">
                <AIStars />
                Rewrite Again
              </button>
              <div className="ai-rewrite-button-bg"></div>
            </div>
          </a>
        </Dropdown>
      </div>

      <div className="d-flex align-items-center gap-3 w-100 mt-4">
        <div className="w-100">
          <h6 className="mb-2 header_text__16">Your content</h6>
          <MarkdownEditor
            value={content}
            handleChangeEditor={handleChangeEditor}
          />
        </div>
        <div className="w-100">
          <h6 className="mb-2 header_text__16">AI content</h6>
          {isRephraseLoading ? (
            "Loading..."
          ) : (
            <MarkdownEditor
              value={getValue(aiRequest, "text", "")}
              handleChangeEditor={() => {}}
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Alert
          message="AI will be rewriting from your content"
          type="info"
          showIcon
        />
      </div>
    </Modal>
  );
};

export default RNRephraseModal;
