import ButtonComponent from "@components/Form/Button/Button";
import ".././index.scss";
import PreviewFormBuilder from "./preview-buileder";

function PreviewScreen(props: any) {
  return (
    <div className="manageFieldRightSettings d-flex justify-content-start flex-column align-items-center overflow-y-auto">
      <div
        style={{ width: `${props.backgroundWidth}%` }}
        className="responsive-animation"
      >
        <div
          style={{ background: "#eaeef2" }}
          className="w-100 d-flex justify-content-center"
        >
          <div
            className="pb-4 d-flex flex-column pt-4"
            style={{ width: `${props.width}%` }}
          >
            <PreviewFormBuilder
              fields={props.formFields}
              setFields={props.setFormFields}
            />
            <div className="preview-b-radius  pb-5 pt-3 bg-white d-flex justify-content-center">
              <div className="desktop-size">
                <ButtonComponent
                  buttonType={"primary"}
                  buttonText={"Save"}
                  // onClickHandler={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewScreen;
