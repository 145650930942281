import { config } from "../env";
import {
  Delete,
  get,
  patch,
  post,
  postFileUpload,
  postWithoutBody,
} from "./helpers/http-handler";

export const getCombinedInboxChatList = (queryRequest: string) =>
  get(`${config.API_URL}/common/route/combine/list?${queryRequest}`);

export const getCombinedInboxChatListCount = (queryRequest: string) =>
  get(`${config.API_URL}/common/route/combine/list/count?${queryRequest}`);

export const createWebchatMessage = (payload: object) =>
  post(`${config.API_URL}/webchat/message`, payload);

export const updateWebchatInfo = (id: string, payload: object) =>
  patch(`${config.API_URL}/webchat/${id}`, payload);

export const getSpecificWebChatInfo = (queryRequest: string) =>
  get(`${config.API_URL}/webchat/list/conversation?${queryRequest}`);

export const readWebchatStatus = (id: string) =>
  get(`${config.API_URL}/webchat/read/status/${id}`);
