import { useEffect, useState } from "react";
// import { Nav, NavItem, NavLink } from "antd";
import AllBookingInformation from "./all-booking-information";
import CusotmerInformation from "./customer-information";
import PropertyNotes from "./property-notes";
import {
  getPropertyBookingDetail,
  getPropertyBookingUsers,
  getSpecificPropertyBookingUsers,
  updatePropertyBookingUsers,
} from "@services/properties/property-booking.service";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { mediaUpload } from "@services/upload.service";
import { toast } from "sonner";
import { config } from "env";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";

interface Payload {
  property_booking_id: string;
}

interface User {
  id: string;
  type: string;
  // Add other properties of the User object if needed
}

const BookingInformation = (props: any) => {
  const { activeTab, setActiveTab } = props;
  const [bookingInfo, setBookingInfo] = useState([]);
  const [users, setUsers] = useState({});
  const [selectedUserId, setSelectedUserId] = useState("");
  const [bookingUsersInfo, setBookingUsersInfo] = useState<User[]>([]);
  const [adultUsersInfo, setAdultUsersInfo] = useState<User[]>([]);
  const [childUsersInfo, setChildUsersInfo] = useState<User[]>([]);
  const [specificUser, setSpecificUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [activeCarouselTab, setActiveCarouselTab] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [uploadLoading, setUploadLoading] = useState({
    front: false,
    back: false,
  });
  const [uploadedFileInfo, setUploadedFileInfo] = useState({});
  const [updatingFlag, setUpdatingFlag] = useState(false);

  const tabs = [
    {
      id: "1",
      name: "Booking info",
    },
    {
      id: "2",
      name: "Adult details",
    },
    {
      id: "3",
      name: "Child details",
    },
  ];

  const [request, setRequest] = useState({
    full_name: "",
    mobile: "",
    email: "",
    pincode: 0,
    address: "",
    city: "",
    state: "",
    country: "",
    identity_type: "passport",
    identity_value: "",
    identity_upload_type: "device_upload",
    identity_front_img: "",
    identity_back_img: "",
    is_primary: false,
    dob: "",
    gender: "male",
    type: "adult",
    relationship: "self",
    nationality: "",
    occupation: "",
    emergency_contact: "",
    emergency_contact_name: "",
  });

  const { reservationInfo, passingInfo } = props;

  /* -------------------------------------------------------------------------- */
  /*                        Hooks Section                           */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (reservationInfo) {
      setBookingInfo(reservationInfo);
      getData();
    } else if (bookingInfo) {
      setBookingInfo(reservationInfo);
      getData();
    }
  }, [passingInfo, reservationInfo, updatingFlag]);

  useEffect(() => {
    if (selectedUserId) {
      getSpecificUser(selectedUserId);
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (getValue(bookingInfo, "room_booking_info[0].property_booking_id", "")) {
      getBookingUsers();
    }
  }, [bookingInfo]);

  /* -------------------------------------------------------------------------- */
  /*                                   Api Section                              */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    if (passingInfo) {
      setBookingInfo(reservationInfo);
    } else {
      try {
        setIsLoading(true);
        let payload = {
          room_booking_info_id: getValue(
            reservationInfo,
            `room_booking_info_id`,
            ""
          ),
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getPropertyBookingDetail(
          getValue(reservationInfo, `booking_id`, ""),
          queryRequest
        );
        if (resp) {
          setBookingInfo(getValue(resp, `data`, {}));
          setUsers({
            id: "1",
            users: getValue(resp, `data.property_booking_user`, []).map(
              (item: any) => ({
                ...item,
                label: getValue(item, `full_name`, ""),
                value: getValue(item, `id`, ""),
              })
            ),
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                            All users Section                               */
  /* -------------------------------------------------------------------------- */

  const getBookingUsers = async () => {
    try {
      setIsLoading1(true);
      const payload: Payload = {
        property_booking_id: getValue(
          passingInfo ? reservationInfo : bookingInfo,
          `room_booking_info[0].property_booking_id`,
          ""
        ),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getPropertyBookingUsers(queryRequest);
      if (resp) {
        const users: User[] = getValue(resp, "data.pbUser", []);

        // Separate users based on type
        const adultUsers = users.filter((user: User) => user.type === "adult");
        const childUsers = users.filter((user: User) => user.type === "child");

        // Update states with the filtered users
        setBookingUsersInfo(users); // All users
        setAdultUsersInfo(adultUsers); // Adult users
        setChildUsersInfo(childUsers); // Child users

        if (users.length > 0) {
          if (activeCarouselTab === currentTab) {
            setSelectedUserId(users[activeCarouselTab].id);
          } else {
            setSelectedUserId(users[0].id);
          }
        }
      }
      setIsLoading1(false);
    } catch (error) {
      setIsLoading1(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                            Specific User Section                           */
  /* -------------------------------------------------------------------------- */

  const getSpecificUser = async (userId: string) => {
    try {
      setIsLoading3(true);
      let resp = await getSpecificPropertyBookingUsers(userId);

      if (resp) {
        setSpecificUser(getValue(resp, `data`, {}));
        setRequest(getValue(resp, `data`, {}));
      }
      setIsLoading3(false);
    } catch (error) {
      setIsLoading3(false);
    }
  };

  const handleCarouselTab = (tab: number, id: string) => {
    setActiveCarouselTab(tab);
    setSelectedUserId(id);
    setRequest({
      full_name: "",
      mobile: "",
      email: "",
      pincode: 0,
      address: "",
      city: "",
      state: "",
      country: "",
      identity_type: "passport",
      identity_value: "",
      identity_upload_type: "device_upload",
      identity_front_img: "",
      identity_back_img: "",
      is_primary: false,
      dob: "",
      gender: "male",
      type: "adult",
      relationship: "self",
      nationality: "",
      occupation: "",
      emergency_contact: "",
      emergency_contact_name: "",
    });
    setCurrentTab(tab);
  };

  /* -------------------------------------------------------------------------- */
  /*                        Upload Document Section                             */
  /* -------------------------------------------------------------------------- */

  const updateSpecificUser = async (selectedUserId: string) => {
    try {
      setIsLoading4(true);
      let payload = {
        full_name: getValue(request, "full_name", ""),
        mobile: getValue(request, "mobile", ""),
        email: getValue(request, "email", ""),
        pincode: getValue(request, "pincode", 0),
        address: getValue(request, "address", ""),
        city: getValue(request, "city", ""),
        state: getValue(request, "state", ""),
        country: getValue(request, "country", ""),
        identity_type: getValue(request, "identity_type", ""),
        identity_value: getValue(request, "identity_value", ""),
        identity_upload_type: getValue(
          request,
          "identity_upload_type",
          "device_upload"
        ),
        identity_front_img: getValue(request, "identity_front_img", ""),
        identity_back_img: getValue(request, "identity_back_img", ""),
        is_primary: getValue(request, "is_primary", ""),
        dob: getValue(request, "dob", ""),
        gender: getValue(request, "gender", ""),
        type: getValue(request, "type", ""),
        relationship: getValue(request, "relationship", "self"),
        nationality: getValue(request, "nationality", ""),
        occupation: getValue(request, "occupation", ""),
        emergency_contact: getValue(request, "emergency_contact", ""),
        emergency_contact_name: getValue(request, "emergency_contact_name", ""),
      };

      let resp = await updatePropertyBookingUsers(selectedUserId, payload);
      if (resp) {
        toast.success("Successfully Updated");
        setIsLoading4(false);
      }
    } catch (error) {
      setIsLoading4(false);
    }
  };

  const handleSave = () => {
    updateSpecificUser(selectedUserId);
    getBookingUsers();
  };

  /* -------------------------------------------------------------------------- */
  /*                        Upload Document Section                             */
  /* -------------------------------------------------------------------------- */
  const handleUploadId = async (e: any, type: "front" | "back") => {
    const file = e.target.files[0];

    if (!file) return;

    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append("is_public", "true");
    formData.append("collection", "public-images");

    try {
      // Set the loading state for the specific type
      setUploadLoading((prevLoading: any) => ({
        ...prevLoading,
        [type]: true,
      }));

      const resp = await mediaUpload(formData);
      if (resp && resp.data && resp.data.key) {
        const imageUrl = `https://${config.ASSETS_CDN_ENDPOINT}/${resp.data.key}`;
        setUploadedFileInfo(resp.data);
        setRequest((prevRequest) => ({
          ...prevRequest,
          [type === "front" ? "identity_front_img" : "identity_back_img"]:
            imageUrl,
        }));
        e.target.value = "";
      } else {
        console.error("Upload failed: No valid response received");
      }
    } catch (error) {
      console.error("Upload error:", error);
    } finally {
      // Clear the loading state for the specific type
      setUploadLoading((prevLoading: any) => ({
        ...prevLoading,
        [type]: false,
      }));
    }
  };

  const handleChangeTab = (key: any) => {
    setActiveTab(key);
  };

  return (
    <div className="subpipeline-wrapper p-0">
      <div
        className="position-sticky top-0 bg-white pb-3"
        style={{ zIndex: "9" }}
      >
        <Tabs
          activeKey={activeTab}
          onChange={handleChangeTab}
          type="card"
          className="booking-tabs p-4"
        >
          {tabs.map((item) => (
            <TabPane tab={item.name} key={item.id}>
              {item.id === "1" && (
                <AllBookingInformation
                  bookingInfo={passingInfo ? reservationInfo : bookingInfo}
                  updatingFlag={updatingFlag}
                  setUpdatingFlag={setUpdatingFlag}
                  permissions={getValue(props, `permissions`, [])}
                  toggleBookingInfo={props.toggleBookingInfo}
                  getAllRoomList={props.getAllRoomList}
                />
              )}
              {item.id === "2" && (
                <CusotmerInformation
                  users={adultUsersInfo}
                  request={request}
                  setRequest={setRequest}
                  specificUser={specificUser}
                  activeCarouselTab={activeCarouselTab}
                  handleCarouselTab={handleCarouselTab}
                  handleSave={handleSave}
                  handleUploadId={handleUploadId}
                  uploadLoading={uploadLoading}
                />
              )}
              {item.id === "3" && (
                <CusotmerInformation
                  users={childUsersInfo}
                  request={request}
                  setRequest={setRequest}
                  specificUser={specificUser}
                  activeCarouselTab={activeCarouselTab}
                  handleCarouselTab={handleCarouselTab}
                  handleSave={handleSave}
                  handleUploadId={handleUploadId}
                  uploadLoading={uploadLoading}
                />
              )}
            </TabPane>
          ))}
        </Tabs>
      </div>
      {/*
        {activeTab === "3" && <PropertyNotes />}
       */}
    </div>
  );
};

export default BookingInformation;
