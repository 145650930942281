export function formatMarkdownText(text: string): string {
  if (text) {
    return text
      .replace(/\*(.*?)\*/g, "<strong>$1</strong>")
      .replace(/_(.*?)_/g, "<em>$1</em>")
      .replace(/~(.*?)~/g, "<del>$1</del>")
      .replace(/```(.*?)```/g, "<code>$1</code>");
  } else {
    return text;
  }
}

export function stripFormatting(text: string): string {
  if (text) {
    // Convert WhatsApp markdown to WhatsApp's format
    return text
      .replace(/\*(.*?)\*/g, "*$1*") // Bold stays as is
      .replace(/_(.*?)_/g, "_$1_") // Italic stays as is
      .replace(/~(.*?)~/g, "~$1~") // Strikethrough stays as is
      .replace(/```(.*?)```/g, "```$1```"); // Monospace stays as is
  } else {
    return text;
  }
}
