import { Button } from "antd";
import { Plus, Trash } from "lucide-react";

const QandATab = (props: any) => {
  const { request, setRequest } = props;
  return (
    <div className="training-data-cotnent-container">
      <h6 className="header_text__18 mb-3">Q&A</h6>
      <div className="d-flex gap-3 justify-content-end mb-3">
        <Button
          icon={<Plus />}
          onClick={() =>
            setRequest((prevRequest: any) => [...prevRequest, { input: "" }])
          }
        ></Button>
      </div>
      {request.map((item: any, index: number) => (
        <div key={index} className="d-grid  mb-2 border p-4">
          <div>
            <label className="form-label">Question</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={3}
              value=""
            />
          </div>
          <div>
            <label className="form-label">Answer</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={12}
              value=""
            />
          </div>
          <Trash
            onClick={() => {
              const updatedRequest = request.filter(
                (item: any, i: number) => i !== index
              );
              setRequest(updatedRequest);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default QandATab;
