import { Badge, Select, Switch, Table, TableProps, Tooltip } from "antd";
import { getValue } from "@utils/lodash";
import CustomTimeInputToggle from "./CustomTimeInputToggle";
import { Info } from "lucide-react";
import { capitalizeFirstLetter } from "@common/text-helpers";

const SLATargetTable = (props: any) => {
  const handleChangeOperationalHours = (index: number, value: boolean) => {
    props.setRequest((prev: any) => {
      const updated = [...prev];
      updated[index].business_hours = value;
      return updated;
    });
  };

  const handleChangeEscalation = (index: number, checked: boolean) => {
    props.setRequest((prev: any) => {
      const updated = [...prev];
      updated[index].escalation_enabled = checked;
      return updated;
    });
  };

  const handleTimeChange = (index: number, time: string, timeKey: string) => {
    props.setRequest((prev: any) => {
      const updated = [...prev];
      updated[index][timeKey] = time;
      return updated;
    });
  };

  const columns: TableProps["columns"] = [
    {
      title: "PRIORITY",
      dataIndex: "priority_name",
      key: "priority_name",
      render: (text: string, record: any) => (
        <div
          className="d-flex align-items-center gap-2 small_text__16"
          style={{ height: "60px" }}
        >
          <Badge status={record.color} />
          {capitalizeFirstLetter(text)}
        </div>
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          FIRST RESPONSE TIME
          <Tooltip title="Set how quickly you respond to a customer’s request">
            <Info size={16} />
          </Tooltip>
        </div>
      ),
      dataIndex: "first_response_time",
      key: "first_response_time",
      render: (_, record, index) => (
        <CustomTimeInputToggle
          onTimeChange={(time: string) =>
            handleTimeChange(index, time, "first_response_time")
          }
          request={props.request}
          current={index.toString()}
          timeKey="first_response_time"
        />
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          EVERY RESPONSE TIME
          <Tooltip title="Set how frequently you’ll keep customers updated">
            <Info size={16} />
          </Tooltip>
        </div>
      ),
      dataIndex: "every_response_time",
      key: "every_response_time",
      render: (_, record, index) => (
        <CustomTimeInputToggle
          onTimeChange={(time: string) =>
            handleTimeChange(index, time, "every_response_time")
          }
          request={props.request}
          current={index.toString()}
          timeKey="every_response_time"
        />
      ),
    },
    {
      title: (
        <div className="d-flex align-items-center gap-2">
          RESOLUTION TIME
          <Tooltip title="Set how long you’ll take to solve a request">
            <Info size={16} />
          </Tooltip>
        </div>
      ),
      key: "resolution_due_time",
      dataIndex: "resolution_due_time",
      render: (_, record, index) => (
        <CustomTimeInputToggle
          onTimeChange={(time: string) =>
            handleTimeChange(index, time, "resolution_due_time")
          }
          request={props.request}
          current={index.toString()}
          timeKey="resolution_due_time"
        />
      ),
    },
    {
      title: "OPERATIONAL HOURS",
      key: "hours",
      render: (_, record, index) => (
        <Select
          onChange={(value) => handleChangeOperationalHours(index, value)}
          value={getValue(props.request[index], "business_hours", true)}
          options={[
            { value: true, label: "Business Hours" },
            { value: false, label: "Calendar Hours" },
          ]}
        />
      ),
    },
    {
      title: "ESCALATION",
      key: "escalation",
      render: (_, record, index) => (
        <Switch
          size="small"
          checked={getValue(props.request[index], "escalation_enabled", true)}
          onChange={(checked) => handleChangeEscalation(index, checked)}
        />
      ),
    },
  ];

  const tableData = props.request.map((item: any, index: number) => ({
    key: index,
    priority_name: item.priority_name,
    color: item.priority_name
      ? item.priority_name.toLowerCase() === "urgent"
        ? "error"
        : item.priority_name.toLowerCase() === "high"
        ? "warning"
        : item.priority_name.toLowerCase() === "medium"
        ? "processing"
        : "success"
      : "",
  }));

  return (
    <div className="mt-5">
      <h6 className="header_text__18">Set SLA target as:</h6>
      <Table
        columns={columns}
        dataSource={tableData}
        className="mt-2"
        pagination={false}
      />
    </div>
  );
};

export default SLATargetTable;
