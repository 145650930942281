import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllAgents = (queryRequest: string) =>
  get(`${config.API_URL}/settings/agent-actions?${queryRequest}`);

export const getSpecificAgent = (id: string) =>
  get(`${config.API_URL}/settings/agent-actions/${id}`);

export const createAgent = (payload: object) =>
  post(`${config.API_URL}/settings/agent-actions`, payload);

export const updateAgent = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/agent-actions/${id}`, payload);

export const deleteAgent = (id: string) =>
  Delete(`${config.API_URL}/settings/agent-actions/${id}`);

export const updateStatusAgent = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/agent-actions/${id}/status`, payload);

export const testAgentResponse = (payload: object) =>
  post(
    `${config.API_URL}/settings/agent-actions/custom-action/response-check`,
    payload
  );

//Form Agent Action

export const getFormAgentAction = (id: string) =>
  get(`${config.API_URL}/settings/agent-actions/${id}/form`);

export const updateFormAgentAction = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/agent-actions/${id}/form`, payload);

//Web Search Agent Action

export const getWebSearchAgentAction = (id: string) =>
  get(`${config.API_URL}/settings/agent-actions/${id}/web-search`);

export const updateWebSearchAgentAction = (id: string, payload: object) =>
  patch(`${config.API_URL}/settings/agent-actions/${id}/web-search`, payload);
