import { SearchOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { SlidersHorizontal } from "lucide-react";
import React from "react";

function ChatSearchHeader(props: any) {
  const {
    handleChangeSearch,
    toggleFilterView,
    filterCount,
    handleApplyUnReadFilter,
    filterRequest,
  } = props;
  return (
    <div>
      <div className="message_search">
        <input
          type="search"
          name="search-form"
          id="search-form"
          className="contact-search"
          onChange={(e) => handleChangeSearch(e)}
          placeholder="Search user"
        />
        <div className="search-icon">
          <SearchOutlined />
        </div>
        <Tooltip title={"Filter"}>
          <div className="position-relative">
            <div className="cursor-pointer " onClick={toggleFilterView}>
              <SlidersHorizontal size={20} />
            </div>
            {filterCount > 0 && (
              <div className="filter_count__container">{filterCount}</div>
            )}
          </div>
        </Tooltip>
      </div>
      <div className="d-flex gap-2 px-4 pb-1">
        <button
          onClick={() => handleApplyUnReadFilter(false)}
          className={`wa-filter-button ${
            filterRequest.onlyUnread === false ? "wa-filter-selected" : ""
          }`}
        >
          All
        </button>
        <button
          onClick={() => handleApplyUnReadFilter(true)}
          className={`wa-filter-button ${
            filterRequest.onlyUnread === true ? "wa-filter-selected" : ""
          }`}
        >
          Unread
        </button>
      </div>
    </div>
  );
}

export default ChatSearchHeader;
