import AddSvgComponent from "@assets/svg/add";
import ResendSvgComponent from "@assets/svg/resend";
import { emojisList } from "@common/emojis";
import { capitalizeFirstLetter, sanitizeHTML } from "@common/text-helpers";
import { aiRephraseMethods } from "@components/AI/AiRephraseMethods";
import MarkdownEditor from "@components/MarkdownEditor/Editor";
import AIStars from "@components/common/Loader/ai-stars";
import WhatsappAddHover from "@components/common/Tooltip/whats-app-add";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import { getValue } from "@utils/lodash";
import {
  Avatar,
  Button,
  Dropdown,
  List,
  Popover,
  Skeleton,
  Spin,
  Tooltip,
} from "antd";
import EmojiPicker from "emoji-picker-react";
import { FolderClosed, FolderLock } from "lucide-react";
import { memo, useRef } from "react";

const ChatEditor = (props) => {
  const {
    contentLength,
    showEmojiPicker,
    handleEmojiClick,
    handleUploadDocument,
    message,
    toggleAiModal,
    isRephraseLoading,
    handleKeyDown,
    editorRef,
    handleChangeEditor,
    handleSubmit,
    placeHolder,
    openCannedResponse,
    handleCannedOpen,
    handleCannedEditorButton,
    cannedPopupPosition,
    cannedList,
    isCannedLoading,
    handleCannedResponse,
    handleCannedScroll,
    cannedLoadMore,
    parentChage,
    setParentChage,
    showCannedResponse,
  } = props;
  const items = aiRephraseMethods.map((method, index) => ({
    key: `${index + 1}`,
    label: method,
    onClick: () => toggleAiModal(method),
    disabled: isRephraseLoading === true,
  }));

  const loadMore = !isCannedLoading ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button onClick={cannedLoadMore}>Load more</Button>
    </div>
  ) : null;

  const fileInputRef = useRef(null);

  return (
    <div className="position-relative">
      <div
        style={{
          position: "absolute",
          top: "60%",
          right: "156px",
          // right: "80px",
          zIndex: "99",
        }}
      >
        <Tooltip
          title={contentLength < 12 ? "Add message to let AI help you" : ""}
        >
          <Dropdown menu={{ items }} disabled={contentLength < 12}>
            <a onClick={(e) => e.preventDefault()} className="w-auto">
              <AIStars />
            </a>
          </Dropdown>
        </Tooltip>
      </div>
      <div className="message-bottom-section p-2">
        <div
          style={{
            position: "absolute",
            bottom: "60px",
            left: "60px",
          }}
        >
          {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
        </div>

        <div className="d-flex align-items-center">
          {/* <div className="cursor-pointer mx-2">
            <WhatsappAddHover
              header={<AddSvgComponent color={"#54656f"} size={32} />}
              text={"Please add all required information"}
              width={"200px"}
              height={"200px"}
              hover
              handleUploadDocument={handleUploadDocument}
              message={message}
              toggleAiModal={toggleAiModal}
              contentLength={contentLength}
              isRephraseLoading={isRephraseLoading}
            />
          </div> */}
          <div className="w-100">
            <div
              onKeyDown={handleKeyDown}
              style={{
                outline: "none",
              }}
              ref={editorRef}
              className="position-relative"
            >
              {/* <DraftJSRefEditor
                editorState={message}
                handleChangeEditor={handleChangeEditor}
                placeholder={placeHolder ? placeHolder : "Enter here"}
                editorStyle={{
                  background: "white",
                  paddingLeft: "20px",
                  paddingRight: "110px",
                  paddingTop: "20px",
                  // height: "10vh",
                }}
                // ref={props.reference}
                focusColor={"#1a73e8"}
                toolbar={{
                  options: ["inline", "emoji"],
                  inline: {
                    options: ["bold", "italic"],
                  },
                  emoji: {
                    emojis: emojisList,
                  },
                }}
                // hideFocus
                autoFocus
                focus
              /> */}
              <MarkdownEditor
                value={message}
                handleChangeEditor={handleChangeEditor}
                parentChage={parentChage}
                setParentChage={setParentChage}
                openCannedResponse={openCannedResponse}
                handleCannedEditorButton={handleCannedEditorButton}
                showCanned={showCannedResponse}
                handleUploadDocument={handleUploadDocument}
                showUpload
              />

              {cannedList && (
                <Popover
                  width="400px"
                  content={
                    <div
                      className="inbox-canned-response-container"
                      onScroll={handleCannedScroll}
                    >
                      <List
                        width="400px"
                        size="small"
                        className=""
                        loading={isCannedLoading}
                        itemLayout="horizontal"
                        // loadMore={loadMore}
                        dataSource={cannedList}
                        renderItem={(item) => (
                          <List.Item>
                            <Skeleton
                              avatar
                              title={false}
                              loading={item.loading}
                              active
                            >
                              <List.Item.Meta
                                avatar={
                                  getValue(item, "visibility", "") ===
                                    "myself" ? (
                                    <FolderLock size={16} />
                                  ) : (
                                    <FolderClosed size={16} />
                                  )
                                }
                                title={
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleCannedResponse(
                                        getValue(item, "content", "")
                                      )
                                    }
                                  >
                                    {capitalizeFirstLetter(
                                      getValue(item, "title", "  ")
                                    )}
                                  </div>
                                }
                                description={sanitizeHTML(
                                  getValue(item, "content", "  ")
                                )}
                              />
                              <div>{getValue(item, "folder.name", "  ")}</div>
                            </Skeleton>
                          </List.Item>
                        )}
                      />
                    </div>
                  }
                  open={openCannedResponse}
                  onOpenChange={handleCannedOpen}
                  trigger="click"
                >
                  <div
                    style={{
                      position: "absolute",
                      top: cannedPopupPosition.top,
                      left: "200px",
                      zIndex: 1000,
                    }}
                  ></div>
                </Popover>
              )}
            </div>
          </div>
          <Button
            type="primary"
            onClick={!message ? null : handleSubmit}
            className="cursor-pointer mx-2 ms-3"
            disabled={!message ? true : false}
          >
            <ResendSvgComponent color={!message ? "#fff" : "#fff"} size="18" />
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(ChatEditor);
