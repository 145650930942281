import { getValue } from "@utils/lodash";
import { Select } from "antd";

interface AntDropDownProps {
  label?: string;
  width?: string;
  required?: boolean;
  validator?: any;
  [key: string]: any;
}

const AntDropDown = ({
  label,
  width = "100%",
  required = false,
  validator,
  ...rest
}: AntDropDownProps) => {
  return (
    <div>
      {label && (
        <p className="form-label">
          {label} {required && <span className="text-danger">*</span>}{" "}
        </p>
      )}
      <Select
        size="large"
        className={`w-100 ${required ? "ant-select-required" : ""}`}
        style={{ width }}
        showSearch
        optionFilterProp="label"
        {...rest}
      />
      {validator && (
        <p className={`error-text`}>
          {validator.current.message(
            label,
            getValue(rest, "value", ""),
            "required"
          )}
        </p>
      )}
    </div>
  );
};

export default AntDropDown;
