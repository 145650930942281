import InputRuleForm from "@components/InputRuleForm/form";
import { Button, Divider } from "antd";
import { Plus, Trash } from "lucide-react";
import React from "react";

const WebsiteTab = (props: any) => {
  const { request, setRequest } = props;
  return (
    <div className="training-data-cotnent-container">
      <h6 className="header_text__18 mb-3">Website</h6>
      <div>
        <div className="d-flex align-items-center gap-3">
          <InputRuleForm
            inputType="TEXT"
            name="name"
            // value={getValue(request, "name", "")}
            placeholder="https//www.example.com"
            required
            label="Crawl"
            // onChange={(e: any) => handleTextChange(e, request, setRequest)}
            validLeft
          />
          <Button>Fetch {request.length > 0 && "more"} links</Button>
        </div>
        <p className="small_text__14">
          This will crawl all the links starting with the URL (not including
          files on the website).
        </p>
      </div>
      <Divider plain>OR</Divider>
      <div>
        <div className="d-flex align-items-center gap-3">
          <InputRuleForm
            inputType="TEXT"
            name="name"
            // value={getValue(request, "name", "")}
            placeholder="https//www.example.com/sitemap.xml"
            required
            label="Submit Sitemap"
            // onChange={(e: any) => handleTextChange(e, request, setRequest)}
            validLeft
          />
          <Button>Load {request.length > 0 && "Additional"} Sitemap</Button>
        </div>
      </div>
      <Divider plain className="mt-4">
        Included Links
      </Divider>
      <div className="d-flex gap-3 justify-content-end">
        <Button
          icon={<Plus />}
          onClick={() =>
            setRequest((prevRequest: any) => [...prevRequest, { input: "" }])
          }
        ></Button>
      </div>
      {request.map((item: any, index: number) => (
        <div key={index} className="d-flex gap-3 mb-2">
          <InputRuleForm
            inputType="TEXT"
            name="name"
            // value={getValue(request, "name", "")}
            placeholder="https//www.example.com/"
            required
            label="Submit Sitemap"
            // onChange={(e: any) => handleTextChange(e, request, setRequest)}
            validLeft
            hideLabel
          />
          <Trash
            onClick={() => {
              const updatedRequest = request.filter(
                (item: any, i: number) => i !== index
              );
              setRequest(updatedRequest);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default WebsiteTab;
