import { config } from "../../env";
import { Delete, get, patch, post } from "../helpers/http-handler";

export const getAllAIChatbots = (queryRequest: string) =>
  get(`${config.API_URL}/ai-chatbot?${queryRequest}`);

export const getSpecificAIChatbot = (id: string) =>
  get(`${config.API_URL}/ai-chatbot/${id}`);

export const createAIChatbot = (payload: object) =>
  post(`${config.API_URL}/ai-chatbot`, payload);

export const updateAIChatbot = (id: string, payload: object) =>
  patch(`${config.API_URL}/ai-chatbot/${id}`, payload);

export const deleteAIChatbot = (id: string) =>
  Delete(`${config.API_URL}/ai-chatbot/${id}`);

export const showAIChatbotInstructions = (queryRequest: string) =>
  get(`${config.API_URL}/ai-chatbot/show/instructions?${queryRequest}`);
