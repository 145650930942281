import { formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Button, Table, Tag } from "antd";
import { useNavigate, useParams } from "react-router-dom";

function FacebookPageList(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();

  const columns = [
    {
      title: "PAGE ID",
      dataIndex: "page_id",
      key: "page_id",
      render: (page_id: any, text: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() =>
            getValue(text, `status`, "") === "SUBSCRIBED" &&
            navigate(
              `/${getValue(
                params,
                `orgId`,
                ""
              )}/settings/crm/ads/lead-forms/${getValue(text, `id`, "")}`
            )
          }
        >
          {page_id}
        </div>
      ),
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any) => (
        <Tag color={status === "SUBSCRIBED" ? "success" : "error"}>
          {formatString(status)}
        </Tag>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "status",
      key: "status",
      render: (status: any, record: any) => (
        <div className="d-flex justify-content-center">
          <Button
            type="primary"
            onClick={() => props.handleSubscribePage(record)}
            disabled={status === "SUBSCRIBED"}
          >
            Subscribe
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Table
      size="middle"
      className="p-4"
      bordered
      columns={columns}
      dataSource={list}
      loading={isLoading}
      rowKey="id"
      pagination={{
        showTotal: (total, range) =>
          `${total} ${total >= 2 ? "items" : "item"}`,
      }}
      scroll={{
        y: `calc(100vh - 190px)`,
      }}
    />
  );
}

export default FacebookPageList;
