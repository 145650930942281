import { handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import LoaderMain from "@components/common/Loader/loading1";
import {
  createAgent,
  updateAgent,
  updateFormAgentAction,
} from "@services/agent-actions.service";
import { getValue } from "@utils/lodash";
import { Button, Radio, Switch } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import FaFieldComponent from "./components/field";

const FAFields = (props: any) => {
  const { request, setRequest, isLoading, getActionData } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const params = useParams();

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSave = async () => {
    try {
      setSubmitLoading(true);
      let payload = {
        ...request,
      };
      let resp;
      if (getValue(UrlParams, `action_id`, "")) {
        resp = await updateFormAgentAction(
          getValue(UrlParams, `action_id`, ""),
          payload
        );
      }
      if (resp) {
        toast.success(`Action updated successfully`);
        // navigate(
        //   `/${getValue(params, "orgId", "")}/chatbot/${getValue(
        //     params,
        //     "chatbotId",
        //     ""
        //   )}/ai/detail/form-action/create?action_id=${getValue(
        //     resp,
        //     `data.id`,
        //     ""
        //   )}`
        // );
        getActionData();
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  const snakeCase = (str: any) => {
    if (str) return str.replace(/\s+/g, "_").toLowerCase();
  };
  return isLoading ? (
    <LoaderMain />
  ) : (
    <>
      <div className="px-4 pb-4 d-grid gap-3">
        <FaFieldComponent
          title={"Name"}
          request={request}
          setRequest={setRequest}
          key_value={"name"}
        />
        <FaFieldComponent
          title={"Email"}
          request={request}
          setRequest={setRequest}
          key_value={"email"}
        />
        <FaFieldComponent
          title={"Phone"}
          request={request}
          setRequest={setRequest}
          key_value={"phone_number"}
        />
      </div>
      <Button
        onClick={() => handleSave()}
        size="large"
        type="primary"
        disabled={submitLoading}
      >
        Save and Continue
      </Button>
    </>
  );
};

export default FAFields;
