import MainLayout from "@layouts/HomeLayout/NewLayout";
import React, { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { Button, Modal, Select, Tabs, TabsProps } from "antd";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "@components/custom/BrowserBack";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import {
  deleteGroupsAgents,
  getSpecificGroups,
  getSpecificGroupsAgents,
  updateGroupsAgent,
  updateSpecificGroups,
} from "@services/groups.service";
import GroupsGeneralSettings from "./components/GroupsGeneralSettings";
import GroupsAgents from "./components/common/GroupsAgents";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllBusinessHours } from "@services/business-hours.service";
import GroupProperties from "./components/GroupProperties";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { DeleteOutlined } from "@ant-design/icons";

const EditGroupSettings = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [editId, seteditId] = useState("");
  const [groupDetails, setGroupDetails] = useState([]);
  const [currentTab, setCurrentTab] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [isLoading5, setIsLoading5] = useState(false);
  const [isLoading6, setIsLoading6] = useState(false);
  const [request, setRequest] = useState({
    enable_automatic_ticket_assignment: false,
    assignment_type: "",
    // enable_agent_availability_status: false,
    // allow_agents_to_change_availability: false,
    // unassigned_for: "30m",
    // escalate_to: "",
    name: "",
    description: "",
    business_hour: "",
  });
  const [agents, setAgents] = useState([]);
  const [agentsRequest, setAgentsRequest] = useState({
    agents: [],
  });
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  const [businessHour, setBusinessHour] = useState([]);
  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                            useEffect Section                               */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (params) {
      seteditId(getValue(params, "id", ""));
    }
  }, [params]);

  useEffect(() => {
    if (UrlParams.addAgent) {
      setIsModalOpen(true);
    }
  }, [UrlParams]);

  useEffect(() => {
    if (editId) {
      getGroupDetails(true);
      getBusinessHoursList(true);
      getAllAgents(editId);
    }
  }, [editId]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getGroupDetails = async (status: boolean) => {
    try {
      setIsLoading(status);
      const resp = await getSpecificGroups(editId);
      if (resp) {
        setRequest({
          ...request,
          name: getValue(resp, "data.name", ""),
          description: getValue(resp, "data.description", ""),
          business_hour: getValue(resp, "data.business_hour_id", ""),
          assignment_type: getValue(resp, "data.assignment_type", ""),
          enable_automatic_ticket_assignment: getValue(
            resp,
            "data.enable_automatic_ticket_assignment",
            false
          ),
        });
        setGroupDetails(getValue(resp, "data", []));
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to load group.");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getBusinessHoursList = async (status: boolean) => {
    try {
      setIsLoading1(status);
      const payload = {
        page_size: 10,
        page_no: 1,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllBusinessHours(queryRequest);
      if (resp) {
        const modifiedResp = getValue(resp, "data.business_hours", []).map(
          (item: any) => ({
            value: getValue(item, "id", ""),
            label: getValue(item, "name", ""),
          })
        );
        setBusinessHour(modifiedResp);
        setIsLoading1(false);
      }
    } catch (error) {
      toast.error("Failed to load business hours.");
      setIsLoading1(false);
    } finally {
      setIsLoading1(false);
    }
  };

  const getAllAgents = async (id: string) => {
    try {
      setIsLoading2(true);
      const resp = await getSpecificGroupsAgents(id);
      if (resp) {
        const modifiedUsers = getValue(resp, "data.agents", "").map(
          (user: any) => ({
            value: getValue(user, "id", ""),
            label:
              capitalizeFirstLetter(getValue(user, "first_name", "")) +
              " " +
              capitalizeFirstLetter(getValue(user, "last_name", "")),
          })
        );
        setAgents(modifiedUsers);
        setIsLoading2(false);
      }
    } catch (error) {
      toast.error("Failed to load Agents.");
      setIsLoading2(false);
    } finally {
      setIsLoading2(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading4(true);
    try {
      let payload = JSON.parse(JSON.stringify(request));
      const resp = await updateSpecificGroups(editId, payload);

      if (resp) {
        toast.success("Updated Successfully");
        setRequest({
          name: "",
          description: "",
          business_hour: "",
          assignment_type: "",
          enable_automatic_ticket_assignment: false,
        });
        navigate(`/${getValue(params, "orgId", "")}/settings/helpdesk/groups`);
        setIsLoading4(false);
      } else {
        toast.error(`Failed editing group`);
        setIsLoading4(false);
      }
    } catch (error) {
      toast.error(`Error editing group`);
      setIsLoading4(false);
    } finally {
      setIsLoading4(false);
    }
  };

  const handleAddAgent = async () => {
    setIsLoading6(true);
    try {
      let payload = JSON.parse(JSON.stringify(agentsRequest));
      const resp = await updateGroupsAgent(editId, payload);
      if (resp) {
        toast.success("Updated Agents Successfully");
        setAgentsRequest({
          agents: [],
        });
        setIsModalOpen(false);
        getGroupDetails(true);
        getAllAgents(editId);
        setIsLoading6(false);
        navigate(
          `/${getValue(
            params,
            "orgId",
            ""
          )}/settings/helpdesk/groups/edit/${editId}`
        );
      } else {
        toast.error(`Failed editing agents`);
        setIsLoading6(false);
      }
    } catch (error) {
      toast.error(`Error editing agents`);
      setIsLoading6(false);
    } finally {
      setIsLoading6(false);
    }
  };

  const handleDeleteAgent = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this agent from the group?`,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          const payload = {
            agents: [...selectedAgentIds],
          };
          let queryRequest = JSON.parse(JSON.stringify(payload));
          await deleteGroupsAgents(editId, queryRequest);
          toast.success(
            `Agent${
              selectedAgentIds.length > 1 ? "s" : ""
            } deleted successfully`
          );
          setSelectedAgentIds([]);
          getGroupDetails(true);
          getAllAgents(editId);
        } catch (error) {
          toast.error("Error deleting agent");
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "General Settings",
      children: (
        <GroupsGeneralSettings
          request={request}
          setRequest={setRequest}
          businessHour={businessHour}
        />
      ),
    },
    {
      key: "2",
      label: "Group Properties",
      children: <GroupProperties request={request} setRequest={setRequest} />,
    },
    {
      key: "3",
      label: "Agents",
      children: (
        <GroupsAgents
          agents={agents}
          isLoading2={isLoading2}
          groupDetails={groupDetails}
          setSelectedAgentIds={setSelectedAgentIds}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    setCurrentTab(key);
  };

  //Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    if (UrlParams.addAgent) {
      navigate(
        `/${getValue(
          params,
          "orgId",
          ""
        )}/settings/helpdesk/groups/edit/${editId}`
      );
    }
  };

  const handleUsers = (selectedAgents: string[]) => {
    setAgentsRequest((prevRequest: any) => ({
      ...prevRequest,
      agents: selectedAgents.map((id) => ({ id })),
    }));
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        backButton
        title="Edit Group"
        titleLoading={isLoading}
        addChildren={
          <div className="d-flex gap-2 align-items-center">
            {selectedAgentIds.length < 1 ? (
              <Button
                // type=""
                size="large"
                onClick={() =>
                  navigate(
                    `/${getValue(params, "orgId", "")}/settings/helpdesk/groups`
                  )
                }
              >
                Cancel
              </Button>
            ) : (
              <Button
                danger
                size="large"
                type="primary"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteAgent()}
              >
                Delete Agent{selectedAgentIds.length > 1 ? "'s" : ""}
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              onClick={() =>
                currentTab === "1" || currentTab === "2"
                  ? handleSubmit()
                  : showModal()
              }
              loading={
                currentTab === "1" || currentTab === "2"
                  ? isLoading4
                  : isLoading6
              }
            >
              {currentTab === "1" || currentTab === "2"
                ? "Update Group"
                : "Add Agent"}
            </Button>
          </div>
        }
      />
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        onChange={onChange}
        className="manageFieldRightSettings px-4 pt-2 pb-4 overflow-y-auto"
      />
      <Modal
        title="Add Agents"
        okText="Add to Group"
        open={isModalOpen}
        onOk={handleAddAgent}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: getValue(agentsRequest, "agents.length", 0) === 0,
        }}
      >
        <Select
          size="large"
          mode="multiple"
          placeholder="Select one or more agents"
          options={agents}
          className="w-100 mb-4"
          value={agentsRequest.agents.map((agent: { id: string }) => agent.id)}
          onChange={(selectedValues: string[]) => {
            handleUsers(selectedValues);
          }}
          maxTagCount="responsive"
        />
      </Modal>
    </CompanyProfileDetailsLayout>
  );
};

export default EditGroupSettings;
