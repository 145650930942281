import { useEffect } from "react";
import { handleTextArrayChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";

const CustomPaymentDetails = ({ request, setRequest }: any) => {
  const handleNestedArrayChange = (
    index: number,
    e: any,
    key1: string,
    key2: string,
    setObject: any
  ) => {
    const { name, value } = e.target ? e.target : e;

    setObject((prev: any) => {
      let updatedObject = { ...prev };

      if (updatedObject[key1]) {
        const data = updatedObject[key1];

        if (Array.isArray(data) && data[index]) {
          const nestedData = data[index][key2];

          if (nestedData) {
            data[index][key2] = {
              ...nestedData,
              [name]: isNaN(value) ? value : Number(value),
            };
          } else {
            data[index][key2] = {
              [name]: isNaN(value) ? value : Number(value),
            };
          }
        }
      }

      return updatedObject;
    });
  };

  useEffect(() => {
    const roomData = getValue(request, "room_data", []);
    if (Array.isArray(roomData) && roomData[0]?.room_price_info) {
      const { rate_plan_price = 0, total_tax = 0 } =
        roomData[0].room_price_info;

      const total = Number(rate_plan_price) + Number(total_tax);
      setRequest((prev: any) => {
        let updatedRequest = { ...prev };

        if (updatedRequest.room_data && updatedRequest.room_data[0]) {
          updatedRequest.room_data[0].room_price_info = {
            ...updatedRequest.room_data[0].room_price_info,
            total,
          };
        }

        return updatedRequest;
      });
    }
  }, [
    getValue(request, "room_data[0].room_price_info.rate_plan_price", 0),
    getValue(request, "room_data[0].room_price_info.total_tax", 0),
  ]);

  return (
    <div className="d-flex flex-column gap-3 my-3">
      <InputRuleForm
        inputType="NUMBER"
        name="rate_plan_price"
        value={getValue(
          request,
          `room_data[0].room_price_info.rate_plan_price`,
          ""
        )}
        placeholder="Enter Agreed Price"
        label="Agreed Price"
        onChange={(e: any) =>
          handleNestedArrayChange(
            0,
            e,
            "room_data",
            "room_price_info",
            setRequest
          )
        }
      />
      <InputRuleForm
        inputType="NUMBER"
        name="total_tax"
        value={getValue(request, `room_data[0].room_price_info.total_tax`, "")}
        placeholder="Enter Total Tax"
        label="Total Tax"
        onChange={(e: any) =>
          handleNestedArrayChange(
            0,
            e,
            "room_data",
            "room_price_info",
            setRequest
          )
        }
      />
      <InputRuleForm
        inputType="NUMBER"
        name="total"
        value={getValue(request, `room_data[0].room_price_info.total`, "")}
        placeholder="Enter Total"
        label="Total"
        onChange={(e: any) => {}}
        disabled
      />
      <InputRuleForm
        inputType="TEXT"
        name="payment_remarks"
        value={getValue(request, `room_data[0].payment_remarks`, "")}
        placeholder="Enter Remarks"
        label="Payment Remarks"
        onChange={(e: any) =>
          handleTextArrayChange(0, e, "room_data", setRequest)
        }
      />
    </div>
  );
};

export default CustomPaymentDetails;
