import HomeHeader from "@components/common/Header/HomeHeader/Header";
import NoAccessComponent from "./noaccess";

export default function MainLayout({ children, ...restProps }: any) {
  return (
    <>
      <HomeHeader />
      {restProps.isLoading || !restProps.permissionAPITriggered ? (
        // <ListLoader />
        <div className="flex-1">{children}</div>
      ) : restProps.permissions.includes("read") ? (
        <div className="flex-1">{children}</div>
      ) : (
        <NoAccessComponent />
      )}
    </>
  );
}
