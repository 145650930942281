export const caCustomActionRequest = {
  name: "",
  description: "",
  chatbot_id: "",
  account_id: "",
  instruction: "",
  type: "custom-action",

  config: {
    inputs: [
      {
        name: "12312",
        type: "text",
        array: true,
        description: "",
      },
    ],
    headers: [],
    parameters: [],
    return_fields: [],
    body: "",
    wait_for_response: true,
    is_client: true,
    url: "",
    // static states
    method: "get",
    is_live_response: true,
  },
};

export const caFormActionRequest = {
  name: "",
  description: "",
  instruction: "",
  status: "enabled",
  config: {
    ignore_message: "",
    success_message: {
      title: "",
      description: "",
    },
    name: {
      name: "",
      type: "text",
      enabled: true,
      required: true,
      display_name: "",
      placeholder: "",
    },
    email: {
      name: "",
      type: "text",
      enabled: true,
      required: true,
      display_name: "",
      placeholder: "",
    },
    phone_number: {
      name: "",
      type: "text",
      enabled: true,
      required: true,
      display_name: "",
      placeholder: "",
    },
  },
};

export const caWebSearchActionRequest = {
  name: "",
  description: "",
  instruction: "",
  status: "enabled",
  config: {
    images_enabled: true,
    included_domains: [""],
  },
};
