import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";

import { QueryRequestHelper } from "@common/query-request-helper";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import { getSharedListData } from "@services/common.service";
import { getValue } from "@utils/lodash";
import ListLoader from "@components/common/Loader";
import { concatString } from "@common/text-helpers";
import "./summit.scss";
import { WhatsAppOutlined } from "@ant-design/icons";
import { Download, Eye } from "lucide-react";

// Define interface for lead data
interface LeadData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
  website_url: string;
  message: string;
}

const SharedImportData: React.FC = () => {
  // Parse URL search params
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [list, setList] = useState<LeadData[]>([]);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  // Memoized data fetching function
  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const payload = {
        page_size: 100,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getSharedListData(queryRequest);

      if (resp) {
        setList(getValue(resp, `data.leads`, []));
        setTotal(getValue(resp, `data.leads.length`, 0));
      }
    } catch (error) {
      console.error("Error fetching shared list data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch data on component mount
  useEffect(() => {
    getData();
  }, [getData]);

  // Define columns with TypeScript type
  const invitedUsersColumns: ColumnsType<LeadData> = [
    {
      title: "FIRST NAME",
      dataIndex: "first_name",
      key: "first_name",
      width: 150,
      render: (text) => (
        <div style={{ userSelect: "none", WebkitUserSelect: "none" }}>
          {text}
        </div>
      ),
    },
    {
      title: "LAST NAME",
      dataIndex: "last_name",
      key: "last_name",
      width: 150,
      render: (text) => (
        <div style={{ userSelect: "none", WebkitUserSelect: "none" }}>
          {text}
        </div>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: 250,
      render: (text) => (
        <div style={{ userSelect: "none", WebkitUserSelect: "none" }}>
          {text}
        </div>
      ),
    },
    {
      title: "MOBILE NUMBER",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: 200,
      render: (text) => (
        <div style={{ userSelect: "none", WebkitUserSelect: "none" }}>
          {text}
        </div>
      ),
    },
    {
      title: "COMPANY NAME",
      dataIndex: "company_name",
      key: "company_name",
      width: 200,
      render: (text) => (
        <div style={{ userSelect: "none", WebkitUserSelect: "none" }}>
          {text ? (
            <Tooltip title={text}>{concatString(text, 20)}</Tooltip>
          ) : (
            "N/A"
          )}
        </div>
      ),
    },
    {
      title: "WEBSITE URL",
      dataIndex: "website_url",
      key: "website_url",
      width: 250,
      render: (text) => (
        <div style={{ userSelect: "none", WebkitUserSelect: "none" }}>
          {text || "N/A"}
        </div>
      ),
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      width: 300,
      render: (text) => (
        <div style={{ userSelect: "none", WebkitUserSelect: "none" }}>
          {text ? (
            <Tooltip title={text}>{concatString(text, 30)}</Tooltip>
          ) : (
            "No message"
          )}
        </div>
      ),
    },
  ];

  // Redirect handlers with unified logic
  const handleRedirect = useCallback(
    (path: string) => {
      const accessToken = getValue(localStorage, `accessToken`, "");
      const orgId = getValue(urlParams, `orgId`, "");
      if (accessToken) {
        if (orgId) {
          window.location.href = `/${orgId}/crm/leads?path=${path}`;
        } else {
          window.location.href = `/organisations?path=${path}`;
        }
      } else {
        navigate(`/signup?path=${path}`);
      }
    },
    [navigate, urlParams]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="px-4 py-3 border-bottom">
        <img src="/appzo-logo.svg" alt="Appzo-logo" height={38} />
      </div>
      {isLoading ? (
        <ListLoader />
      ) : (
        <div className="mt-3 px-4">
          <div className="d-flex flex-column flex-lg-row align-items-center gap-3 justify-content-between mb-3 mt-2">
            <h6 className="header_text__20">
              Bengaluru Tech Summit 2024- Exhibitor Directory
            </h6>
            <div className="gap-2 d-flex flex-wrap">
              <Button
                type="primary"
                onClick={() => showModal()}
                icon={<Eye size={16} />}
              >
                {/* <Button onClick={() => handleRedirect("shared")}> */}
                View in CRM
              </Button>
              <Button
                type="primary"
                onClick={() => showModal()}
                icon={<Download size={16} />}
              >
                Download
              </Button>
              {/* <Button onClick={() => showModal()}>Duplicate</Button> */}
              <button
                onClick={() => showModal()}
                style={{
                  background: "#25D366",
                  outline: "none",
                  color: "#fff",
                  borderRadius: "20px",
                  fontWeight: 400,
                  border: "none",
                  fontSize: "14px",
                  boxShadow: "0 2px 0 rgba(5, 145, 255, 0.1)",
                }}
                className="d-flex align-items-center gap-2 px-3 py-2"
              >
                <WhatsAppOutlined /> Broadcast on WhatsApp
              </button>
            </div>
          </div>
          {list.length > 0 ? (
            <Table
              bordered
              columns={invitedUsersColumns}
              dataSource={list}
              loading={isLoading}
              rowKey="id"
              scroll={{ x: 1500, y: `calc(100vh - 348px)!important` }}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                pageSize: 100,
                size: "small",
                showSizeChanger: false,
              }}
              onRow={() => ({
                onCopy: (e) => e.preventDefault(),
              })}
              className="summit-data"
            />
          ) : (
            <FiltersNoDataPage module="users" />
          )}
        </div>
      )}
      <Modal
        // title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="px-4 py-3 d-flex justify-content-center">
          <img src="/appzo-logo.svg" alt="Appzo-logo" height={38} />
        </div>
        <h6 className="text-center header_text__30 mt-3 mb-2">
          Build real relationships to close winning deals
        </h6>
        <p className="text-center px-5">
          Login or create a free account to duplicate Bengaluru Tech Summit -
          Exhibitor Directory and collaborate on it.
        </p>
        <div className="d-flex justify-content-center">
          <Button
            type="primary"
            className="mx-auto mt-4"
            size="large"
            onClick={() => handleRedirect("shared")}
          >
            Continue with Email to Download
          </Button>
        </div>
        <p className="text-center px-5 mt-5 mx-4">
          By signing up you agree to the appzo{" "}
          <a href="https://www.appzo.ai/policy" target="_blank">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="https://www.appzo.ai/terms" target="_blank">
            Terms of Use
          </a>
        </p>
      </Modal>
    </div>
  );
};

export default SharedImportData;
