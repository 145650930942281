import ModulesAndFieldsSvgComponent from "@assets/svg/modules-fields";
import PenSvgComponent from "@assets/svg/pen";
import WorkflowSvgComponent from "@assets/svg/workflow";
import { formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Badge, Switch, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";

function WorkflowTable(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      width: "60px",
      render: () => <WorkflowSvgComponent color={"lightgray"} />,
    },
    {
      title: "WORKFLOW NAME",
      dataIndex: "name",
      key: "name",
      render: (name: any, record: any) => (
        <div
          className="header_blue_text__14 cursor-pointer"
          onClick={() =>
            navigate(
              `/${getValue(
                params,
                `orgId`,
                ""
              )}/settings/crm/workflows/edit/${getValue(record, `id`, "")}?${
                props.mainQueryRequest
              }`
            )
          }
        >
          {formatString(name)}
        </div>
      ),
    },
    {
      title: "MODULE NAME",
      dataIndex: "moduleName",
      key: "moduleName",
      render: (moduleName: any) => formatString(moduleName),
    },
    {
      title: "EXECUTION TYPE",
      dataIndex: "executionType",
      key: "executionType",
      render: (executionType: any) => formatString(executionType),
    },

    {
      title: "STATUS",
      dataIndex: "isActive",
      key: "isActive",
      render: (is_active: any) => (
        <div className="d-flex align-items-center gap-2">
          <Badge status={is_active === true ? "success" : "error"} />
          {is_active === true ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: any, record: any) => (
        <Switch
          size="small"
          checked={isActive}
          onChange={() =>
            props.handleChangeWorkflowStatus(
              getValue(record, `id`, ""),
              !isActive
            )
          }
        />
      ),
    },
  ];

  return (
    <Table
      size="middle"
      className="p-4"
      bordered
      columns={columns}
      dataSource={getValue(props, `list`, [])}
      rowKey="id"
      pagination={{
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
    />
  );
}

export default WorkflowTable;
