import { handleTextChange } from "@common/handleChange";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Modal } from "antd";

const SolutionsCategoryModal = (props: any) => {
  const {
    openCreateCategories,
    request,
    setRequest,
    seletedCategory,
    isLoading,
    handleCategorySubmit,
    handleCancelCategories,
  } = props;
  return (
    <Modal
      title="New Category"
      open={openCreateCategories}
      onOk={handleCategorySubmit}
      okText={seletedCategory ? "Update" : "Create"}
      confirmLoading={isLoading}
      onCancel={handleCancelCategories}
      okButtonProps={{
        disabled: !request.name,
      }}
    >
      <InputRuleForm
        inputType="TEXT"
        name="name"
        value={getValue(request, "name", "")}
        placeholder="Enter Category Name"
        required
        label="Category Name (For Own Reference)"
        onChange={(e: any) => handleTextChange(e, request, setRequest)}
      />
      <FormDescription
        request={request}
        textClassname="mb-2 mt-3"
        inputClassname=""
        input={
          <InputRuleForm
            inputType="TEXTAREA"
            name="description"
            value={getValue(request, `description`, "")}
            placeholder="Enter Description"
            label="Description"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
          />
        }
      />
    </Modal>
  );
};

export default SolutionsCategoryModal;
