import EditSvgComponent from "@assets/svg/edit";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Badge, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";

function FacebookLeadList(props: any) {
  const { isLoading, list } = props;
  const navigate = useNavigate();
  const params = useParams();

  const columns = [
    {
      title: "CREATED ON",
      dataIndex: "form_id",
      key: "form_id",
      render: (form_id: any, text: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() =>
            navigate(
              `/${getValue(
                params,
                `orgId`,
                ""
              )}/settings/crm/ads/lead-forms/${getValue(
                params,
                `id`,
                ""
              )}/edit/${form_id}`
            )
          }
        >
          {convertCurrentDateWithTime(getValue(text, `created_time`, ""))}
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (status: any) => (
        <div className="d-flex align-items-center gap-2">
          <Badge color={status === "ACTIVE" ? "green" : "red"} />
          {formatString(status)}
        </div>
      ),
    },
    {
      title: "FORM NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "CRM INTEGRATION",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (id ? "Yes" : "No"),
    },

    {
      title: "ACTION",
      dataIndex: "status",
      key: "status",
      width: 80,
      render: (status: any, record: any) => <EditSvgComponent />,
    },
  ];
  return (
    <Table
      size="middle"
      className="p-4"
      bordered
      columns={columns}
      dataSource={list}
      loading={isLoading}
      rowKey="id"
      pagination={{
        showTotal: (total, range) =>
          `${total} ${total >= 2 ? "items" : "item"}`,
      }}
      scroll={{
        y: `calc(100vh - 190px)`,
      }}
    />
  );
}

export default FacebookLeadList;
