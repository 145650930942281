import { handleBooleanChange } from "@common/handleChange";
import {
  testAgentResponse,
  updateAgent,
} from "@services/agent-actions.service";
import { getValue } from "@utils/lodash";
import { Button, Radio } from "antd";
import { toast } from "sonner";
import JSONEditor from "./jsoneditor";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";

const CaTestResponse = (props: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const { request, setRequest, getActionData } = props;
  const [jsonData, setJsonData] = useState({});

  const handleTriggerLiveResponse = async () => {
    if (getValue(UrlParams, `action_id`, "")) {
      try {
        let payload = {
          action_id: getValue(UrlParams, `action_id`, ""),
        };
        let resp = await testAgentResponse(payload);
        if (resp) {
          setJsonData(getValue(resp, `data`, {}));
          setRequest((prev: any) => ({
            ...prev,
            config: {
              ...prev.config,
              return_fields: transformData(getValue(resp, `data`, {})),
            },
          }));
          toast.success("Request sent successfully");
        }
      } catch (error) {}
    } else {
      toast.error("Please create action");
    }
  };

  // Transform the data
  // Function to transform the data
  const transformData = (data: any) => {
    const result: any = [];
    // Helper function to process nested objects
    const processObject = (obj: any, prefix = "") => {
      Object.entries(obj).forEach(([key, value]) => {
        const fullKey = prefix ? `${prefix}.${key}` : key;
        if (
          value !== null &&
          typeof value === "object" &&
          !Array.isArray(value)
        ) {
          processObject(value, fullKey);
        } else {
          result.push({
            name: fullKey,
            value: value?.toString() || "",
            chosen: false,
          });
        }
      });
    };

    processObject(data);
    return result;
  };

  const navigate = useNavigate();
  const params = useParams();
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSave = async () => {
    try {
      setSubmitLoading(true);
      let reqPayload: any = JSON.parse(JSON.stringify(request));
      delete reqPayload["config"]["is_live_response"];
      let payload = {
        ...reqPayload,
        config: removeNullOrUndefinedProperties(
          getValue(reqPayload, `config`, {})
        ),
      };
      let resp;
      if (getValue(UrlParams, `action_id`, "")) {
        resp = await updateAgent(getValue(UrlParams, `action_id`, ""), payload);
      } else {
      }
      if (resp) {
        toast.success(
          `Action ${
            getValue(UrlParams, `action_id`, "") ? "updated" : "created"
          } successfully`
        );
        getActionData();
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  return (
    <div>
      <Radio.Group
        value={!getValue(request, `is_live_response`, false)}
        onChange={(e: any) => {
          handleBooleanChange(
            "is_live_response",
            !getValue(request, `is_live_response`, false),
            request,
            setRequest
          );
        }}
        className="d-grid gap-2"
      >
        <Radio value={true}>
          <h6 className="header_text__16">Live response</h6>
          <p className="small_text__14">
            Test with live data from the API to make sure it is configured
            correctly.
          </p>
        </Radio>
        {/* <Radio value={false}>
          <h6 className="header_text__16">Example response</h6>
          <p className="small_text__14">
            Use example JSON data if the API is not ready.
          </p>
        </Radio> */}
      </Radio.Group>

      <JSONEditor initialJSON={jsonData} />
      {!getValue(request, `is_live_response`, false) && (
        <Button className="mt-3" onClick={() => handleTriggerLiveResponse()}>
          Test response
        </Button>
      )}

      <Button
        onClick={() => handleSave()}
        size="large"
        type="primary"
        disabled={submitLoading}
      >
        Save and Continue
      </Button>
    </div>
  );
};

export default CaTestResponse;
