import React from "react";
import "../pipeline.scss";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import FormBuilder from "@components/Builder/FormBuilder";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
function CreateSubPipeline(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const navigatePipelineFields = () => {
    window.open(
      `${window.location.origin}/${getValue(
        params,
        `orgId`,
        ""
      )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
        props,
        `selectedOption.module_id`,
        ""
      )}/${getValue(props, `selectedOption.module_name`, "")}`,
      "_blank"
    );
  };
  const { module } = props;
  return (
    <div className={"subpipeline-wrapper"}>
      <>
        <div className="form_wrapper_builder-pipeline">
          {module !== "tasks" &&
            module !== "calls" &&
            module !== "tickets" &&
            module !== "meetings" && (
              <SearchToolTip
                placeholder={`Choose Status`}
                label={`label`}
                validator={props.simpleValidator}
                // width={"480px"}
                data={getValue(props, `stageList`, [])}
                value={getValue(props, `staticFieldRequest.stage`, "")}
                onChange={(e: object) =>
                  props.setStaticFieldRequest({
                    ...props.staticFieldRequest,
                    stage: getValue(e, `id`, ""),
                  })
                }
                selectKey={"id"}
                name={"Status"}
                required={true}
              />
            )}
        </div>
        <FormBuilder
          fields={getValue(props, `fields`, [])}
          setFields={props.setFields}
          simpleValidator={props.simpleValidator}
          singular_label={props.singular_label}
          module={props.module}
          duplicateAPINames={props.duplicateAPINames}
          setDuplicateAPINames={props.setDuplicateAPINames}
          pipeline_id={props.pipeline_id}
          // hideValidation={props.hideValidation}
        />
        {getValue(props, `association`, "") && (
          <div className={`association_container mt-5`}>
            <hr />
            <h6 className="header_text mb-4">Associate deal with</h6>
            <div className="mb-3">
              <label className="form-label">Contact</label>
              <SearchToolTip
                placeholder={`Enter Contact`}
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                //   formHandleLookeupArrayChange(index, e, setFields)
                // }
                label={`label`}
                // width={"480px"}
                lookup_api="contacts"
              />
            </div>
            <div className="mb-5">
              <label className="form-label">Company</label>
              <SearchToolTip
                placeholder={`Enter Contact`}
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                //   formHandleLookeupArrayChange(index, e, setFields)
                // }
                label={`label`}
                // width={"480px"}
                lookup_api="companies"
              />
            </div>
          </div>
        )}
      </>
      {/* ) : (
        <ActivityLoader />
      )} */}
    </div>
  );
}

export default CreateSubPipeline;
