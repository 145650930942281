import { handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import Loader from "@components/common/Loader/loading";
import { getValue } from "@utils/lodash";
import { Select, Switch } from "antd";

function SettingsSecurity(props: any) {
  const { request, setRequest, isLoading } = props;

  const handleKeyDown = (e: any, name: any) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent space character
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      const currentValue = e.target.value;
      const cursorPosition = e.target.selectionStart;
      const textBeforeCursor = currentValue.substring(0, cursorPosition);
      const textAfterCursor = currentValue.substring(cursorPosition);

      setRequest({
        ...request,
        [name]: textBeforeCursor + "\n" + textAfterCursor,
      });

      // Move cursor to the new line
      setTimeout(() => {
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  const handleChange = (e: any, name: any) => {
    const value = e.target.value;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div style={{ height: "calc(100vh - 260px)", overflowY: "auto" }}>
      <div className="my-3">
        <label className="form-label">Visibilty</label>
        <Select
          value={getValue(request, `visibility`, "")}
          style={{ width: 120 }}
          options={[
            { value: "private", label: "Private" },
            { value: "public", label: "Public" },
          ]}
          onChange={(e: any) => {
            setRequest({
              ...request,
              visibility: e,
            });
          }}
        />
      </div>
      <div className="my-3">
        <label className="form-label">
          Only allow the iframe and widget on specific domains
        </label>
        <Switch
          size="small"
          className="ms-2"
          checked={getValue(request, `only_allow_on_added_domains`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              only_allow_on_added_domains: !getValue(
                request,
                `only_allow_on_added_domains`,
                false
              ),
            })
          }
        />
      </div>
      <div className="my-3">
        <InputRuleForm
          inputType="TEXTAREA"
          name="name"
          placeholder="Enter Allowed Domains"
          label="Allowed Domains"
          value={getValue(request, `allowed_domains`, "")}
          onChange={(e: any) => handleChange(e, "allowed_domains")}
          onKeyDown={(e: any) => handleKeyDown(e, "allowed_domains")}
        />
        <p className="small_text__14">Enter each domain in a new line.</p>
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <p className="mx-2">Limit to</p>
          <InputRuleForm
            inputType="NUMBER"
            name="ip_limit"
            label="IP Limit"
            placeholder={"Enter IP Limit"}
            value={getValue(request, `ip_limit`, 0)}
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
          />
        </div>
        <div className="d-flex align-items-center">
          <p className="mx-2">messages every</p>
          <InputRuleForm
            inputType="NUMBER"
            name="ip_limit_timeframe"
            label="IP Limit Timeframe"
            placeholder={"Enter IP Limit Timeframe"}
            value={getValue(request, `ip_limit_timeframe`, 0)}
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
          />
          <p className="mx-2">seconds.</p>
        </div>
      </div>

      <div className="my-3">
        <InputRuleForm
          inputType="TEXTAREA"
          name="ip_limit_message"
          label="IP Limit Message"
          placeholder={"Enter IP Limit Message"}
          value={getValue(request, `ip_limit_message`, "")}
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
        />
      </div>
    </div>
  );
}

export default SettingsSecurity;
