import { handleBooleanChange } from "@common/handleChange";
import { handleNumberChange, handleTextChange } from "@common/handleChange";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import InputRuleForm from "@components/InputRuleForm/form";
import Loader from "@components/common/Loader/loading";
import { deleteAIChatbot } from "@services/AIBot/settings.service";
import { deleteChatbot } from "@services/chatbot.service";
import { getValue } from "@utils/lodash";
import { Button, Switch } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

function SettingsGeneral(props: any) {
  const { request, setRequest, isLoading } = props;
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };

  const handleDelete = async () => {
    try {
      let resp = await deleteAIChatbot(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        setDeleteId("");
        navigate(`/${getValue(params, `orgId`, "")}/chatbot`);
      }
    } catch (error) {}
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div style={{ height: "calc(100vh - 260px)", overflowY: "auto" }}>
      <div className="border rounded-3 p-4">
        <h6 className="header_text__18">General</h6>

        <div className="my-3">
          <label className="form_label__14">Chatbot ID</label>
          <h6 className="header_text__18">
            {getValue(params, `chatbotId`, "")}
          </h6>
        </div>
        <div className="my-3">
          <label className="form_label__14">Number of characters</label>
          <h6 className="header_text__18">
            {getValue(request, `num_of_characters`, "")}
          </h6>
        </div>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter a Name"
          required
          label="Name"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
          validLeft
        />
        <div className="mt-3">
          <InputRuleForm
            inputType="NUMBER"
            name="credits_limit"
            value={getValue(request, "credits_limit", "")}
            placeholder="enable to add limit"
            required
            label="Credit Limit"
            onChange={(e: any) => handleNumberChange(e, request, setRequest)}
            validLeft
          />
          <Switch
            value={getValue(request, "enable_credits_limit", false)}
            onChange={(e: any) =>
              handleBooleanChange(
                "enable_credits_limit",
                !getValue(request, "enable_credits_limit", false),
                request,
                setRequest
              )
            }
          />
          <p className="small_text__14">
            Maximum credits to be used by this chatbot from the credits
            available on the team.
          </p>
        </div>
        {/* 
      <div className="mt-5">
        <h6>Delete All Conversations</h6>
        <p>
          Once you delete all your conversations, there is no going back. Please
          be certain. All the conversations on this chatbot will be deleted.
          This action is not reversible
        </p>
        <Button>Delete</Button>
      </div> */}
      </div>
      <div className="mt-5">
        <h6>Delete Chatbot</h6>
        <p>
          Once you delete your chatbot, there is no going back. Please be
          certain. All your uploaded data will be deleted. This action is not
          reversible
        </p>
        <Button
          onClick={() => handleDeleteView(getValue(params, `chatbotId`, ""))}
        >
          Delete
        </Button>
      </div>
      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDelete}
        deleteValue={deleteValue}
      />
    </div>
  );
}

export default SettingsGeneral;
