import React from "react";
import { getValue } from "@utils/lodash";
import { Badge, Tabs } from "antd";

const { TabPane } = Tabs;

function MainBodyNavViews(props: any) {
  const { pendingLinkArray, handleTab, urlParams } = props;

  return (
    <Tabs
      activeKey={getValue(urlParams, "activeTab", "1")}
      onChange={(key) => handleTab(Number(key))}
      centered
    >
      {pendingLinkArray.map((linkObj: object, index: number) => {
        // const key = `${index + 1}`;
        return (
          <TabPane
            tab={
              <span className="pending-wrapper__main-body-link">
                {getValue(linkObj, `title`, "")}
                {getValue(linkObj, `title`, "") === "Tasks" &&
                  getValue(props, `taskCount`, 0) > 0 && (
                    <Badge
                      count={getValue(props, `taskCount`, 0)}
                      offset={[10, 0]}
                    />
                  )}
              </span>
            }
            key={getValue(linkObj, `key`, "")}
          />
        );
      })}
    </Tabs>
  );
}

export default MainBodyNavViews;
