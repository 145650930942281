import DeleteModal from "@components/custom/Modal/DeleteModal";
import {
  deleteArticle,
  deleteCategoryFolder,
} from "@services/solutions.service";
import { getValue } from "@utils/lodash";
import { Checkbox, Tooltip } from "antd";
import { FolderClosed, FolderOpenDot, Pencil, PlusCircle } from "lucide-react";

const CategoryDetailsLeftSide = (props: any) => {
  const {
    params,
    navigate,
    categoryDetails,
    selectedFolder,
    setSelectedFolder,
    handleFolderEdit,
    selectedArticle,
    selectedCheckbox,
    handleCheckboxChange,
    handleGetSpecificCategory,
  } = props;
  return (
    <div className="w-30 articles-leftside">
      {/* <h6 className="small_text__14 px-4 pt-4">
      {getValue(categoryDetails, "name", "")}
    </h6> */}
      <div className="p-4">
        {getValue(categoryDetails, "folders.length", 0) > 0 &&
          getValue(categoryDetails, "folders", []).map(
            (folder: any, index: number) => (
              <>
                <div
                  key={index}
                  className={`solution-folder-card ${
                    selectedFolder === getValue(folder, "id", "")
                      ? "solution-folder-card--selected"
                      : ""
                  }`}
                >
                  <h6
                    className={`${
                      selectedFolder === getValue(folder, "id", "")
                        ? "text-white"
                        : "header_text__16"
                    } d-flex align-items-center gap-2`}
                    onClick={() =>
                      setSelectedFolder(getValue(folder, "id", ""))
                    }
                  >
                    {selectedFolder === getValue(folder, "id", "") ? (
                      <FolderOpenDot size={18} />
                    ) : (
                      <FolderClosed size={18} />
                    )}
                    {getValue(folder, "name", "")}
                  </h6>
                  <div className="d-flex align-items-center gap-2">
                    <Tooltip title="Add Article">
                      <PlusCircle
                        size={16}
                        color={
                          selectedFolder === getValue(folder, "id", "")
                            ? "#fff"
                            : "#202124"
                        }
                        className="cursor-pointer more-options"
                        onClick={() =>
                          navigate(
                            `/${getValue(
                              params,
                              "orgId",
                              ""
                            )}/solutions/article/create/${getValue(
                              folder,
                              "id",
                              ""
                            )}`
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Edit">
                      <Pencil
                        size={16}
                        color={
                          selectedFolder === getValue(folder, "id", "")
                            ? "#fff"
                            : "#202124"
                        }
                        className="cursor-pointer more-options"
                        onClick={() =>
                          handleFolderEdit(getValue(folder, "id", ""))
                        }
                      />
                    </Tooltip>
                    <DeleteModal
                      api={() =>
                        deleteCategoryFolder(getValue(folder, "id", ""))
                      }
                      getData={() => handleGetSpecificCategory(true)}
                      name={getValue(folder, "name", "")}
                      module="Folder"
                      color={
                        selectedFolder === getValue(folder, "id", "")
                          ? "#fff"
                          : "#202124"
                      }
                      classname="more-options"
                    />
                    <p
                      className={`${
                        selectedFolder === getValue(folder, "id", "")
                          ? "text-white"
                          : "small_text__16"
                      }`}
                    >
                      {getValue(folder, "solution_articles.length", 0)}
                    </p>
                  </div>
                </div>
                {getValue(folder, "solution_articles.length", 0) > 0 && (
                  <div
                    className={`d-grid solution-folder-card__articles ${
                      selectedFolder === getValue(folder, "id", "")
                        ? "solution-folder-card__articles_selected"
                        : ""
                    }`}
                  >
                    {getValue(folder, "solution_articles", []).map(
                      (article: any, i: number) => (
                        <div
                          className={`folder-articles ${
                            selectedArticle === getValue(article, "id", "")
                              ? "folder-articles--selected"
                              : ""
                          }`}
                          key={i}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <Checkbox
                              checked={selectedCheckbox.includes(
                                getValue(article, "id", "")
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  getValue(article, "id", ""),
                                  e.target.checked
                                )
                              }
                            />
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                navigate(
                                  `/${getValue(
                                    params,
                                    "orgId",
                                    ""
                                  )}/solutions/${getValue(
                                    params,
                                    "id",
                                    ""
                                  )}/folders/${getValue(
                                    folder,
                                    "id",
                                    ""
                                  )}/article/${getValue(article, "id", "")}`
                                );
                              }}
                            >
                              {/* <Tooltip
                              title={getValue(article, "title", "")}
                            > */}
                              {getValue(article, "title", "")}
                              {/* </Tooltip> */}
                            </span>
                          </div>
                          <div className="more-options">
                            <DeleteModal
                              api={() =>
                                deleteArticle(getValue(article, "id", ""))
                              }
                              getData={() => handleGetSpecificCategory(true)}
                              //   getData={() => handleGetArticle(selectedArticle)}
                              name={getValue(article, "title", "")}
                              module="Article"
                              disabled={getValue(article, "is_active", false)}
                              disabledMessage="Cannot delete active article"
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              </>
            )
          )}
      </div>
    </div>
  );
};

export default CategoryDetailsLeftSide;
