import ArrowDownSvgComponent from "@assets/svg/arrow-down";
import CloseMainSvgComponent from "@assets/svg/close-main";
import { convertCurrentDate } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import React, { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import CommonDocumentList from "@components/custom/FileUpload/document-list";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import FormBuilder from "@components/Builder/FormBuilder";
import ButtonComponent from "@components/Form/Button/Button";
import { generatePayload } from "@components/helpers/request-helper";
import Loader from "@components/common/Loader/loading";
import { createSubPipeline } from "@services/common.service";
import { toast } from "sonner";
import SimpleReactValidator from "simple-react-validator";
import { linkWhatsappWeb } from "@services/fb.service";
import { formatString } from "@common/text-helpers";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import BackSvgComponent from "@assets/svg/back-link";
import { Alert, Button, Select, Space } from "antd";
import { updateWebchatInfo } from "@services/inbox.service";

function ChatContactInfo(props: any) {
  const {
    handleToggleWidth,
    contactInfo,
    columnList,
    columnLabelInfo,
    allPipelines,
    stages,
    fields,
    setFields,
    getFormFields,
    isFormLoading,
    staticFieldRequest,
    setStaticFieldRequest,
    selectedConversation,
    selectedContact,
    userDetails,
    toggleUserDetails,
    getSpecificContactInfo,
    selectedType,
    getSpecificLeadInfo,
    isLeadShow,
    selectedConversationInfo,
  } = props;
  const params = useParams();
  const navigate = useNavigate();

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [openIndex, setOpenIndex] = useState(null);
  const toggleAccordion = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const accordionItems = [
    {
      title: "Profile details",
      content: (
        <>
          {getValue(columnList, "length", 0) > 0 ? (
            columnList.map((field: string, index: number) => {
              if (
                typeof contactInfo[field] === "string" &&
                field !== "owner_id" &&
                field !== "created_at" &&
                field !== "updated_at"
              ) {
                return (
                  <div className="conversation-wrapper bg-white" key={index}>
                    <div className="d-flex gap-3 align-items-center my-1">
                      <p className="small_text__14">
                        {columnLabelInfo[field]}:
                      </p>
                      <div className="d-flex gap-2 flex-wrap">
                        {contactInfo[field]}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })
          ) : (
            <div className="small_text__13 d-flex justify-content-center my-3 color_desc">
              <p>No data</p>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Company Details",
      content: (
        <>
          {contactInfo &&
          contactInfo.companies &&
          contactInfo.companies.length > 0 ? (
            <>
              {getValue(contactInfo, `companies`, []).map((item: object) => {
                return (
                  <div className="conversation-wrapper bg-white">
                    <h6 className="header_text__16">
                      {getValue(item, `name`, "")}
                    </h6>
                    <div className="tags-container mt-2">
                      <img
                        src="/images/icons/email-icon.svg"
                        className="tag-icon"
                      />
                      <p className="tags-heading ">Email:</p>
                      {getValue(item, `email`, "") ? (
                        <p className="text-normal">
                          {getValue(item, `email`, "")}
                        </p>
                      ) : (
                        <p className="small_text__12 ms-2">-</p>
                      )}
                    </div>
                    <div className="id-container mt-1">
                      <img
                        src="/images/icons/call-icon.svg"
                        className=""
                        width={16}
                      />
                      <p className="id-heading">Mobile:</p>
                      {getValue(item, `phone_number`, "") ? (
                        <Link to="#" className="message-blue-text">
                          {getValue(item, `phone_number`, "")}{" "}
                        </Link>
                      ) : (
                        <p className="small_text__12 ms-2">-</p>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="small_text__13 d-flex justify-content-center my-3 color_desc">
              <p>No data</p>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Deal Details",
      content: (
        <>
          {contactInfo && contactInfo.deals && contactInfo.deals.length > 0 ? (
            <>
              {getValue(contactInfo, `deals`, []).map((item: object) => {
                return (
                  <div className="conversation-wrapper bg-white">
                    <h6 className="header_text__16">
                      {getValue(item, `name`, "")}
                    </h6>
                    <div className="tags-container mt-2">
                      <p className="tags-heading">Amount:</p>
                      <p className="text-normal">
                        INR {getValue(item, `amount`, "")}
                      </p>
                    </div>
                    <div className="id-container mt-1">
                      <p className="id-heading">Closing Date:</p>
                      <Link to="#" className="message-blue-text">
                        {convertCurrentDate(getValue(item, `closing_date`, ""))}{" "}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="small_text__13 d-flex justify-content-center my-3 color_desc">
              <p>No data</p>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Tasks",
      content: (
        <>
          {contactInfo && contactInfo.tasks && contactInfo.tasks.length > 0 ? (
            <>
              {getValue(contactInfo, `tasks`, []).map((item: object) => {
                return (
                  <div className="conversation-wrapper bg-white">
                    <h6 className="header_text__16">
                      {getValue(item, `title`, "")}
                    </h6>
                    <div className="tags-container mt-2">
                      <p className="tags-heading">Description: </p>
                      <p className="text-normal">
                        {getValue(item, `description`, "")}
                      </p>
                    </div>
                    <div className="id-container mt-1">
                      <p className="id-heading">Due Date:</p>
                      <Link to="#" className="message-blue-text">
                        {convertCurrentDate(getValue(item, `due_datetime`, ""))}{" "}
                      </Link>
                    </div>
                    <div className="id-container mt-1">
                      <p className="id-heading">Status:</p>
                      <p
                        className={`small_text__14 ${
                          getValue(item, `is_completed`, "") === false
                            ? "color_danger"
                            : "color_success"
                        }`}
                      >
                        {getValue(item, `is_completed`, "") === false
                          ? "Open"
                          : "Completed"}
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="small_text__13 d-flex justify-content-center my-3 color_desc">
              <p>No data</p>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Documents",
      content: (
        <div>
          {contactInfo &&
          contactInfo.attachments &&
          contactInfo.attachments.length > 0 ? (
            <div className="">
              <CommonDocumentList
                documentList={getValue(contactInfo, `attachments`, []).map(
                  (contactInfo: object) => ({
                    id: getValue(contactInfo, `id`, ""),
                    extension: getValue(contactInfo, `file.extension`, ""),
                    filename: getValue(contactInfo, `file.filename`, ""),
                    key: getValue(contactInfo, `file.key`, ""),
                    size: getValue(contactInfo, `file.size`, ""),
                    description: getValue(contactInfo, `description`, ""),
                  })
                )}
                hideRemove={true}
              />
            </div>
          ) : (
            <div className="small_text__13 d-flex justify-content-center my-3 color_desc">
              <p>No data</p>
            </div>
          )}
        </div>
      ),
    },
  ];
  const hideValidation = () => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.fields = {};
    simpleValidator.current.errorMessages = {};
    forceUpdate(0);
  };
  const showValidation = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };
  const [listCreateLoading, setListCreateLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      showValidation();
    } else {
      try {
        let payload = generatePayload(selectedType, fields, staticFieldRequest);
        let resp = await createSubPipeline(payload, selectedType);
        if (resp) {
          toast.success("Created Successfully");
          let response;
          if (
            getValue(props, `selectedConversationInfo.source`, "") === "webchat"
          ) {
            response = await updateWebchatInfo(
              getValue(selectedConversationInfo, `id`, ""),
              {
                is_active: getValue(
                  selectedConversationInfo,
                  `is_active`,
                  false
                ),
                status: getValue(selectedConversationInfo, `status`, ""),
                chat_bot_id: getValue(
                  selectedConversationInfo,
                  `chat_bot_id`,
                  ""
                ),
                org_user_id: getValue(
                  selectedConversationInfo,
                  `org_user_id`,
                  ""
                ),
                contact_id: getValue(resp, `data.id`, ""),
                name: getValue(selectedConversationInfo, `name`, ""),
                phone: getValue(selectedConversationInfo, `phone`, ""),
                email: getValue(selectedConversationInfo, `email`, ""),
                is_guest: getValue(selectedConversationInfo, `is_guest`, false),
              }
            );
          } else {
            response = await linkWhatsappWeb({
              conversationId: selectedConversation,
              [selectedType === "leads" ? "leadId" : "contactId"]: getValue(
                resp,
                `data.id`,
                ""
              ),
            });
          }

          if (response) {
            // navigate(
            //   `/${getValue(params, `orgId`, "")}/crm/contacts/detail/${getValue(
            //     resp,
            //     `data.id`,
            //     ""
            //   )}`
            // );
            if (selectedType === "leads") {
              getSpecificLeadInfo(getValue(resp, `data.id`, ""));
            } else {
              getSpecificContactInfo(getValue(resp, `data.id`, ""));
            }
            toast.success("Created successfully");
            setListCreateLoading(false);
            hideValidation();
          }
        } else {
          setListCreateLoading(false);
          hideValidation();
        }
      } catch (error) {
        hideValidation();
      }
    }
  };
  return (
    <div>
      <div className="d-flex align-items-center border-bottom px-3 profile-details">
        <div className="" onClick={handleToggleWidth}>
          <CloseMainSvgComponent color={"gray"} size={24} />
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <h6 className="ms-4 header_text__16">
            {getValue(contactInfo, `id`, "") ? "Contact info" : "Add Contact"}
          </h6>
          {getValue(props, `permissions`, []).includes("update") && (
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={listCreateLoading}
              loading={listCreateLoading}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      <div className="p-3">
        {getValue(contactInfo, `id`, "") ? (
          accordionItems.map((item, index) => (
            <div className="accordion-item mb-3 border-bottom pb-2" key={index}>
              <div
                className="accordion-title d-flex justify-content-between cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <h6
                  className={`header_text__16 ${
                    openIndex === index ? "text-black" : "desc_text"
                  }`}
                >
                  {item.title}
                </h6>
                <img
                  src="/images/icons/arrow-down.svg"
                  width={18}
                  height={18}
                  className={`${
                    openIndex === index ? "accordion-rotate" : ""
                  } accordion-arrow`}
                  alt="Toggle icon"
                />
              </div>
              {openIndex === index && (
                <motion.div
                  initial={{ y: 0, opacity: 0 }}
                  animate={{ y: "auto", opacity: 1 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                  <div className="accordion-content mt-2 px-2 py-1">
                    {item.content}
                  </div>
                </motion.div>
              )}
            </div>
          ))
        ) : (
          <div>
            {!userDetails ? (
              <motion.div
                key="userDetails"
                initial={{ x: "-100%", opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0.5 }}
                transition={{ duration: 0.5 }}
                className="d-flex justify-content-center mb-2 flex-column align-items-center"
              >
                <AccountCircleSvgComponent color={"#dfe5e7"} size={100} />

                <div>
                  {getValue(selectedContact, `contact.first_name`, "") ||
                  getValue(selectedContact, `contact.last_name`, "") ? (
                    <p
                      className="small_text__16 color_black text-center"
                      style={{ textAlign: "left" }}
                    >
                      {formatString(
                        getValue(selectedContact, `contact.first_name`, "")
                      )}{" "}
                      {formatString(
                        getValue(selectedContact, `contact.last_name`, "")
                      )}
                    </p>
                  ) : (
                    <p
                      className="small_text__16 color_black text-center"
                      style={{ textAlign: "left" }}
                    >
                      {formatString(
                        getValue(selectedContact, `wa_profile_name`, "")
                      )}
                    </p>
                  )}
                </div>
                <p className="small_text__14 text-center mt-1">
                  {getValue(selectedContact, "wa_id", "")}
                </p>
                <p className="text-center small_text__14 mt-4 mx-4">
                  This is a new contact and not part of your current contact.
                  Would you like to add?
                </p>
                {getValue(props, `permissions`, []).includes("update") && (
                  <Space>
                    {isLeadShow() && (
                      <Button
                        size="middle"
                        type="primary"
                        onClick={() => {
                          toggleUserDetails("leads");
                        }}
                        className="mt-3"
                      >
                        Add to Leads
                      </Button>
                    )}
                    <Button
                      size="middle"
                      type="primary"
                      onClick={() => {
                        toggleUserDetails("contacts");
                      }}
                      className="mt-3"
                    >
                      Add to Contacts
                    </Button>
                  </Space>
                )}
              </motion.div>
            ) : (
              <motion.div
                key="otherComponent"
                initial={{ x: "100%", opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div>
                  <div className="mb-3 d-flex justify-content-between align-items-center px-3 mt-2">
                    <div onClick={toggleUserDetails}>
                      <BackSvgComponent size={16} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className=" mb-3">
                      {/* <SearchToolTip
                        label="label"
                        data={allPipelines}
                        name="Pipeline"
                        value={getValue(staticFieldRequest, `pipeline_id`, "")}
                        onChange={(e: object) => {
                          setStaticFieldRequest({
                            ...staticFieldRequest,
                            pipeline_id: getValue(e, `id`, ""),
                          });
                          getFormFields(getValue(e, `id`, ""));
                        }}
                      /> */}
                      <p className="form-label">Pipeline</p>
                      <Select
                        size="large"
                        showSearch
                        placeholder="Select Pipeline"
                        optionFilterProp="label"
                        value={getValue(staticFieldRequest, "pipeline_id", "")}
                        onChange={(value) => {
                          setStaticFieldRequest({
                            ...staticFieldRequest,
                            pipeline_id: value,
                          });
                          getFormFields(value);
                        }}
                        options={allPipelines.map((pipeline: any) => ({
                          value: pipeline.id,
                          label: pipeline.label,
                        }))}
                        className="w-100 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
                {isFormLoading ? (
                  <Loader />
                ) : getValue(fields, `length`, 0) > 0 ? (
                  <>
                    <div className="d-flex justify-content-center">
                      <div className="">
                        <SearchToolTip
                          label="label"
                          data={stages}
                          name="Status"
                          value={getValue(staticFieldRequest, `stage`, "")}
                          onChange={(e: object) => {
                            setStaticFieldRequest({
                              ...staticFieldRequest,
                              stage: getValue(e, `id`, ""),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <FormBuilder
                      fields={fields}
                      setFields={setFields}
                      phoneInline
                    />
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Alert
                      message="Please select a pipeline to continue"
                      type="info"
                    />
                  </div>
                )}
              </motion.div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatContactInfo;
