import { CopyOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Checkbox, TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "sonner";

const AgentShiftHours = ({ request, setRequest, days }: any) => {
  const handleWorkingDaysChange = (checkedValues: string[]) => {
    const updatedWorkDays = days
      .filter((day: any) => checkedValues.includes(day.value))
      .map((day: any) => {
        const existingDay = getValue(request, "work_days", []).find(
          (item: any) => item.day === day.value
        );
        return (
          existingDay || {
            day: day.value,
            start_time: "08:00",
            end_time: "17:00",
          }
        );
      });

    setRequest({
      ...request,
      work_days: updatedWorkDays,
    });
  };

  const handleTimeChange = (index: number, time: Dayjs | null, key: string) => {
    if (!time) {
      toast.error("Invalid time selected.");
      return;
    }

    const updatedWorkDays = [...request.work_days];
    updatedWorkDays[index][key] = time.format("HH:mm");

    setRequest({
      ...request,
      work_days: updatedWorkDays,
    });
  };

  const handleCopyToAll = () => {
    if (getValue(request, "work_days.length", 0) < 2) return;

    const { start_time, end_time } = request.work_days[0];

    const updatedWorkDays = request.work_days.map((day: any) => ({
      ...day,
      start_time,
      end_time,
    }));

    setRequest({
      ...request,
      work_days: updatedWorkDays,
    });
  };

  return (
    <div>
      <div className="my-3">
        <label className="form-label">Select the working days</label>
        <div className="d-flex align-items-center gap-3">
          <Checkbox.Group
            className="d-flex gap-3"
            onChange={handleWorkingDaysChange}
            value={request.work_days.map((day: any) => day.day)}
          >
            {days.map((item: any, index: number) => (
              <Checkbox
                key={index}
                value={item.value}
                disabled={
                  getValue(request, "work_days.length", 0) === 1 &&
                  request.work_days.some((day: any) => day.day === item.value)
                }
              >
                {getValue(item, "shortened", "")}
              </Checkbox>
            ))}
          </Checkbox.Group>
          <p className="small_text__14">
            ({getValue(request, "work_days.length", 0)}
            {getValue(request, "work_days.length", 0) === 1
              ? "day"
              : "days"}{" "}
            selected)
          </p>
        </div>
      </div>

      {/* Shift Timings */}
      <div className="my-3">
        <label className="form-label">Enter the shift timings</label>
        {request.work_days.map((day: any, index: number) => (
          <div className="row my-2" key={index}>
            <p className="col-2 small_text__14">
              {capitalizeFirstLetter(day.day)}
            </p>
            <div className="col-8 d-flex align-items-center gap-3">
              <TimePicker
                minuteStep={30}
                format="HH:mm"
                placeholder="Select Time"
                value={dayjs(day.start_time, "HH:mm")}
                onChange={(time) => handleTimeChange(index, time, "start_time")}
              />
              <p>to</p>
              <TimePicker
                minuteStep={30}
                format="HH:mm"
                placeholder="Select Time"
                value={dayjs(day.end_time, "HH:mm")}
                onChange={(time) => handleTimeChange(index, time, "end_time")}
              />
              {index === 0 && request.work_days.length > 1 && (
                <div
                  className="header_blue_text__14 d-flex align-items-center gap-1 cursor-pointer"
                  onClick={handleCopyToAll}
                >
                  <CopyOutlined />
                  Copy to all
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgentShiftHours;
