// import { useNavigate, useParams } from "react-router-dom";
// import { getValue } from "@utils/lodash";
// import PenSvgComponent from "assets/svg/pen";
// import ModulesAndFieldsSvgComponent from "@assets/svg/modules-fields";
// import { formatString, sortJSONObjectArray } from "@common/text-helpers";
// export default function ModuleAndFieldsTable(props: any) {
//   const navigate = useNavigate();
//   const params = useParams();

//   const handleNavigate = (route: string) => {
//     navigate(route);
//   };
//   return (
//     <div className={"admin-wrapper"}>
//       {/* {getValue(props, `isLoading`, false) ? (
//         <LoaderMain />
//       ) : ( */}
//       <table className={`${"admin-wrapper__table"} w-100`}>
//         <thead>
//           <tr>
//             <td style={{ width: "70px" }}>{/* <CustomCheckbox /> */}</td>
//             <td>
//               <div className="d-flex align-items-center w-100">
//                 {/* <div className={ClassNames["admin-wrapper__drag-icon"]}>
//                     <div>
//                       <span className={ClassNames["dot"]}></span>
//                       <span
//                         className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
//                       ></span>
//                       <span className={ClassNames["dot"]}></span>
//                     </div>
//                     <div>
//                       <span className={ClassNames["dot"]}></span>
//                       <span
//                         className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
//                       ></span>
//                       <span className={ClassNames["dot"]}></span>
//                     </div>
//                   </div> */}
//                 <h6 className="header_text__15 ms-4">Display Name</h6>
//               </div>
//             </td>
//             <td>
//               <div className="d-flex align-items-center w-100">
//                 {/* <div className={ClassNames["admin-wrapper__drag-icon"]}>
//                     <div>
//                       <span className={ClassNames["dot"]}></span>
//                       <span
//                         className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
//                       ></span>
//                       <span className={ClassNames["dot"]}></span>
//                     </div>
//                     <div>
//                       <span className={ClassNames["dot"]}></span>
//                       <span
//                         className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
//                       ></span>
//                       <span className={ClassNames["dot"]}></span>
//                     </div>
//                   </div> */}
//                 <h6 className="header_text__15 ms-4"> Type</h6>
//               </div>
//             </td>

//             <td>
//               <div className="d-flex align-items-center w-100">
//                 {/* <div className={ClassNames["admin-wrapper__drag-icon"]}>
//                     <div>
//                       <span className={ClassNames["dot"]}></span>
//                       <span
//                         className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
//                       ></span>
//                       <span className={ClassNames["dot"]}></span>
//                     </div>
//                     <div>
//                       <span className={ClassNames["dot"]}></span>
//                       <span
//                         className={`${ClassNames["dot"]} ${ClassNames["dot-margin"]}`}
//                       ></span>
//                       <span className={ClassNames["dot"]}></span>
//                     </div>
//                   </div> */}
//                 <h6 className="header_text__15 ms-4">Status</h6>
//               </div>
//             </td>
//           </tr>
//         </thead>

//         <tbody>
//           {sortJSONObjectArray(getValue(props, `modules`, []), "seq_num").map(
//             (item: object, index: number) => {
//               if (
//                 getValue(item, `api_name`, "") !== "calls" &&
//                 getValue(item, `api_name`, "") !== "meetings" &&
//                 getValue(item, `api_name`, "") !== "tickets"
//               )
//                 return (
//                   <tr key={index}>
//                     <td
//                       onClick={() =>
//                         navigate(
//                           `/${getValue(
//                             params,
//                             `orgId`,
//                             ""
//                           )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
//                             item,
//                             `id`,
//                             ""
//                           )}/${getValue(item, `api_name`, "")}`
//                         )
//                       }
//                     >
//                       {/* {getImages({
//                         name: getValue(item, `api_name`, ""),
//                         size: 24,
//                       })} */}
//                       {/* <DragIndicatorSvgComponent color={"lightgray"} /> */}
//                       <ModulesAndFieldsSvgComponent color={"lightgray"} />
//                     </td>
//                     <td
//                       className="d-flex align-items-center justify-content-between hide-hover"
//                       onClick={() =>
//                         navigate(
//                           `/${getValue(
//                             params,
//                             `orgId`,
//                             ""
//                           )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
//                             item,
//                             `id`,
//                             ""
//                           )}/${getValue(item, `api_name`, "")}`
//                         )
//                       }
//                     >
//                       <p className="small_text__14">
//                         {getValue(item, `label`, "")}
//                       </p>
//                       <div
//                         className=" hidden-button image_hover_background"
//                         onClick={(e: any) => {
//                           e.stopPropagation();
//                           props.handleEdit(item);
//                         }}
//                       >
//                         <PenSvgComponent />
//                       </div>
//                     </td>
//                     <td
//                       onClick={() =>
//                         navigate(
//                           `/${getValue(
//                             params,
//                             `orgId`,
//                             ""
//                           )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
//                             item,
//                             `id`,
//                             ""
//                           )}/${getValue(item, `api_name`, "")}`
//                         )
//                       }
//                     >
//                       <p className="small_text__14">
//                         {formatString(getValue(item, `type`, ""))}
//                       </p>
//                     </td>

//                     <td
//                       onClick={() =>
//                         navigate(
//                           `/${getValue(
//                             params,
//                             `orgId`,
//                             ""
//                           )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
//                             item,
//                             `id`,
//                             ""
//                           )}/${getValue(item, `api_name`, "")}`
//                         )
//                       }
//                     >
//                       <p className="small_text__14">
//                         {getValue(item, `is_active`, false)
//                           ? "Active"
//                           : "Inactive"}
//                       </p>
//                     </td>
//                   </tr>
//                 );
//             }
//           )}
//         </tbody>
//       </table>
//       {/* )} */}
//     </div>
//   );
// }

import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import PenSvgComponent from "assets/svg/pen";
import ModulesAndFieldsSvgComponent from "@assets/svg/modules-fields";
import { formatString, sortJSONObjectArray } from "@common/text-helpers";
import { Badge, Table } from "antd";

export default function ModuleAndFieldsTable(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = (route: any) => {
    navigate(route);
  };

  const columns = [
    {
      title: "",
      dataIndex: "icon",
      key: "icon",
      width: 70,
      render: (_: any, record: any) => (
        <div
          onClick={() =>
            handleNavigate(
              `/${getValue(
                params,
                "orgId",
                ""
              )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
                record,
                "id",
                ""
              )}/${getValue(record, "api_name", "")}`
            )
          }
        >
          <ModulesAndFieldsSvgComponent color={"lightgray"} />
        </div>
      ),
    },
    {
      title: "DISPLAY NAME",
      dataIndex: "label",
      key: "label",
      render: (label: any, record: any) => (
        <div
          className="d-flex align-items-center justify-content-between hide-hover"
          onClick={() =>
            handleNavigate(
              `/${getValue(
                params,
                "orgId",
                ""
              )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
                record,
                "id",
                ""
              )}/${getValue(record, "api_name", "")}`
            )
          }
        >
          <h6 className="header_blue_text__14">{label}</h6>
          <div
            className="hidden-button image_hover_background"
            onClick={(e) => {
              e.stopPropagation();
              props.handleEdit(record);
            }}
          >
            <PenSvgComponent />
          </div>
        </div>
      ),
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
      render: (type: any, record: any) => (
        <p
          className=""
          onClick={() =>
            handleNavigate(
              `/${getValue(
                params,
                "orgId",
                ""
              )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
                record,
                "id",
                ""
              )}/${getValue(record, "api_name", "")}`
            )
          }
        >
          {formatString(type)}
        </p>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "is_active",
      key: "is_active",
      render: (isActive: any, record: any) => (
        <p
          className="d-flex align-items-center gap-2"
          onClick={() =>
            handleNavigate(
              `/${getValue(
                params,
                "orgId",
                ""
              )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
                record,
                "id",
                ""
              )}/${getValue(record, "api_name", "")}`
            )
          }
        >
          <Badge color={isActive ? "green" : "red"} />
          {isActive ? "Active" : "Inactive"}
        </p>
      ),
    },
  ];

  const dataSource = sortJSONObjectArray(
    getValue(props, "modules", []),
    "seq_num"
  )
    .filter(
      (item: any) =>
        ["calls", "meetings", "tickets"].indexOf(
          getValue(item, "api_name", "")
        ) === -1
    )
    .map((item: any, index: any) => ({
      key: index,
      ...item,
    }));

  return (
    // <div className="admin-wrapper">
    <Table
      // className="admin-wrapper__table"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowClassName="cursor-pointer"
    />
    // </div>
  );
}
