import { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import DataAdminstrationNavViews from "../components/nav-views";
import SettingsLayout from "@layouts/Layout/settings-layout";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import { createDataExport } from "@services/imports-exports.service";
import { getDataExports } from "@services/imports-exports.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { listAllModules } from "@services/modules.service";
import {
  capitalizeFirstLetter,
  formatString,
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import SimpleReactValidator from "simple-react-validator";
import { listAllPipelines } from "@services/pipeline.service";
import ListLoader from "@components/common/Loader";
import { handleDownloadOnClick } from "@common/downloadfile";
import { convertCurrentDate } from "@common/date-helpers";
import useDynamicTitle from "@context/useDynamicTitle";
import { Button, Table, Tag } from "antd";
import { RefreshCw } from "lucide-react";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";

function DataAdministrationExports(props: any) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [type, setType] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [request, setRequest] = useState({
    api_name: "",
    module_id: "",
    pipeline_id: "",
  });
  const [allPiplelines, setAllPipelines] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getAllModules();
  }, []);
  useEffect(() => {
    getExports();
  }, [selectedModule]);

  useEffect(() => {
    getAllPipelines(selectedModule);
  }, [selectedModule]);

  useEffect(() => {
    if (request.api_name === "contacts" || request.api_name === "companies") {
      const defaultPipeline = allPiplelines.find(
        (pipeline: any) => pipeline.api_name === "default"
      );
      if (defaultPipeline) {
        setRequest((prevRequest) => ({
          ...prevRequest,
          pipeline_id: getValue(defaultPipeline, "id", ""),
        }));
      }
    }
  }, [allPiplelines, request.api_name]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [allModuls, setAllModules] = useState([]);
  const getAllModules = async () => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        let list = sortJSONObjectArray(getValue(resp, `data`, []), "seq_num");
        setAllModules(list);
        setSelectedModule(getValue(list, `[${0}].id`, ""));
        setRequest({
          ...request,
          module_id: getValue(resp, `data[0].id`, ""),
          api_name: getValue(resp, `data[0].api_name`, ""),
        });
      }
    } catch (error) {}
  };

  const getAllPipelines = async (id: string) => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await listAllPipelines(id, queryRequest);
      if (resp) {
        const pipelines = getValue(resp, `data`, []).map((item: object) => ({
          ...item,
          name: getValue(item, `api_name`, ""),
        }));
        setAllPipelines(pipelines);
        setRequest((prevRequest) => ({
          ...prevRequest,
          pipeline_id: getValue(pipelines, `[0].id`, ""),
        }));
      }
    } catch (error) {
      console.error("Error fetching pipelines:", error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [dataExports, setDataExports] = useState([]);
  const getExports = async () => {
    if (selectedModule) {
      try {
        setIsLoading(true);
        let payload = {
          module_id: selectedModule,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getDataExports(queryRequest);
        if (resp) {
          setDataExports(getValue(resp, `data.dataExports`, []));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp = await createDataExport(
          removeNullOrUndefinedProperties(request)
        );
        if (resp) {
          getExports();
          setSubmitLoading(false);
          setRequest({
            ...request,
            api_name: "",
            module_id: "",
            pipeline_id: "",
          });
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleDownload = (path: string) => {
    handleDownloadOnClick(path, "exported_file");
  };
  useDynamicTitle("DataAdministration");

  const columns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      render: (text: any, record: any) =>
        capitalizeFirstLetter(getValue(record, `module.api_name`, "")),
    },
    {
      title: "PIPELINE",
      dataIndex: "pipeline",
      key: "pipeline",
      render: (text: any, record: any) =>
        capitalizeFirstLetter(getValue(record, `pipeline.api_name`, "")),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any) => (
        <div>
          <Tag
            color={
              status === "UPLOADED" || status === "SUCCESSFUL"
                ? "blue"
                : status === "IN_PROGRESS"
                ? "purple"
                : status === "ERROR"
                ? "error"
                : "volcano"
            }
          >
            {formatString(status)}
          </Tag>
          {status === "IN_PROGRESS" && (
            <RefreshCw
              size={16}
              onClick={() => getExports()}
              className="cursor-pointer"
            />
          )}
        </div>
      ),
    },
    {
      title: "CREATED BY",
      dataIndex: "created_by",
      key: "created_by",
      render: (text: any, record: any) => {
        const firstName = capitalizeFirstLetter(
          getValue(record, `created_by.first_name`, "")
        );
        const lastName = capitalizeFirstLetter(
          getValue(record, `created_by.last_name`, "")
        );
        return `${firstName} ${lastName}`.trim();
      },
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      key: "created_at",
      render: (record: any, text: any) =>
        convertCurrentDate(getValue(text, `created_at`, "")),
    },
    {
      title: "LINK",
      dataIndex: "file_path",
      key: "file_path",
      render: (record: any, text: any) =>
        text.status !== "IN_PROGRESS" && (
          <div
            className="color_primary cursor-pointer"
            onClick={() => handleDownload(getValue(text, `file_path`, ""))}
          >
            Download
          </div>
        ),
    },
  ];

  return (
    <>
      <CompanyProfileDetailsLayout {...props}>
        <CompanyProfileSubHeader
          settings
          icon="data-administration-exports"
          title="Exports"
        />
        <div className={`manageFieldRightSettings overflow-y-auto p-4`}>
          {/* <DataAdminstrationNavViews /> */}
          {/* <div className={"module-list_wrapper"}>
            <div className={"module-tab-wrapper"}> */}
          <p className={`${"module-tab-wrapper__text"} mb-4`}>
            You can export records from a specific module in your Appzo account
            as a CSV file.
          </p>
          <div className="w-100 d-flex gap-2 align-items-end border-bottom pb-5">
            <SearchToolTip
              name={"Module"}
              label="label"
              required
              data={
                getValue(allModuls, `length`, 0) > 0
                  ? allModuls.filter(
                      (item: object) =>
                        getValue(item, `api_name`, "") !== "calls" &&
                        getValue(item, `api_name`, "") !== "meetings"
                    )
                  : []
              }
              selectKey={"id"}
              // onChange={(e: object) => {
              //   getAllPipelines(getValue(e, `id`, ""));
              //   setRequest({
              //     ...request,
              //     api_name: getValue(e, `api_name`, ""),
              //     module_id: getValue(e, `id`, ""),
              //     pipeline_id: "",
              //   });
              //   setType(getValue(e, `type`, ""));
              // }}
              onChange={(e: object) => {
                const selectedModuleApiName = getValue(e, `api_name`, "");
                const selectedModuleId = getValue(e, `id`, "");
                const typeValue = getValue(e, `type`, "");

                getAllPipelines(selectedModuleId);
                setRequest({
                  ...request,
                  api_name: selectedModuleApiName,
                  module_id: selectedModuleId,
                  pipeline_id: "",
                });
                setType(typeValue);
              }}
              value={getValue(request, `module_id`, "")}
              validator={simpleValidator}
            />
            {request.module_id &&
              (type === "" || !type || type !== "ACTIVITY") && (
                <SearchToolTip
                  name={"Pipeline"}
                  label="label"
                  required
                  data={allPiplelines}
                  selectKey={"id"}
                  onChange={(e: object) => {
                    setRequest({
                      ...request,
                      pipeline_id: getValue(e, `id`, ""),
                    });
                  }}
                  value={getValue(request, `pipeline_id`, "")}
                  // validator={type !== "ACTIVITY" && simpleValidator}
                />
              )}
            <Button
              size="large"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
              className="mb-1 ms-4"
            >
              Export
            </Button>
          </div>
          <div className="mt-4 mb-5 pb-3 mt-">
            <h6 className="header_text__18 mb-2">Exported Data</h6>
            <div className="w-25">
              <SearchToolTip
                name={"Module"}
                label="label"
                data={allModuls}
                selectKey={"id"}
                onChange={(e: object) => {
                  setSelectedModule(getValue(e, `id`, ""));
                }}
                value={selectedModule}
                validator={simpleValidator}
                required={false}
              />
            </div>
            {/* {isLoading ? (
              <ListLoader />
            ) : ( */}
            <Table
              size="middle"
              bordered
              columns={columns}
              dataSource={dataExports}
              rowKey="id"
              loading={isLoading}
              // rowSelection={{
              //   type: selectionType,
              //   ...rowSelection,
              // }}
              // pagination={{
              //   current: page_no,
              //   pageSize: 10,
              //   total: totalCount,
              //   showSizeChanger: true,
              //   pageSizeOptions: ["10", "20", "50"],
              //   onChange: (page_no) => {
              //     handleChangePagination(page_no);
              //   },
              //   showTotal: (total, range) =>
              //     `${range[0]}-${range[1]} of ${total} items`,
              // }}
            />
            {/* )} */}
          </div>
          {/* </div>
          </div> */}
        </div>
      </CompanyProfileDetailsLayout>
    </>
  );
}

export default DataAdministrationExports;
