import { MenuProps, Tabs } from "antd";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import ChatEditor from "./chat-editor";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getOrgLevelCannedResponse } from "@services/canned-response.service";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import _ from "lodash";
import { aiRephraseMethods } from "@components/AI/AiRephraseMethods";
import { EditorState, Modifier } from "draft-js";
import { createAIRephrase } from "@services/ai.service";
import { stateFromHTML } from "draft-js-import-html";
import RNRephraseModal from "@components/AI/RNRephraseModal";
interface CannedResponse {
  id: string;
  title: string;
  content: string;
  created_at: string;
  created_by: string | null;
  first_name: string;
  last_name: string;
  created_by_id: string;
  folder: {
    id: string;
    name: string;
  };
  folder_id: string;
  updated_at: string;
  updated_by_id: string;
  visibility: string;
}

function ChatFooter(props: any) {
  const {
    showEmojiPicker,
    handleEmojiClick,
    message,
    handleSendMessages,
    handleUploadDocument,
    handleChangeEditor,
    handleChangePrivateEditor,
    contentLength,
    privateContentLength,
    handleSendPrivateMessages,
    privateMessage,
    handlePrivateEmojiClick,
    openCannedResponse,
    handleCannedOpen,
    handleCannedEditorButton,
    cannedPopupPosition,
    cannedLoadMore,
    parentChage,
    setParentChage,
    setMessage,
    setOpenCannedResponse
  } = props;

  const editorRef = useRef(null);
  const privateEditorRef = useRef(null);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessages();
    }
  };
  const handlePrivateKeyDown = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendPrivateMessages();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Canned Response Section                      */
  /* -------------------------------------------------------------------------- */

  const [cannedList, setCannedList] = useState<CannedResponse[]>([]);
  const [personalCannedList, setPersonalCannedList] = useState<
    CannedResponse[]
  >([]);
  const [nonPersonalcannedList, setNonPersonalCannedList] = useState<
    CannedResponse[]
  >([]);
  const [isCannedLoading, setIsCannedLoading] = useState(false);
  const [cannedPageNo, setCannedPageNo] = useState(1);
  const [cannedPageCount, setCannedPageCount] = useState(1);
  const [cannedTotalCount, setCannedTotalCount] = useState(0);

  const getCannedResponseList = async (pageNo: number, status: boolean) => {
    if (cannedPageNo > cannedPageCount) {
      return;
    }
    try {
      setIsCannedLoading(status);

      const payload = { page_no: pageNo, page_size: 10 };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getOrgLevelCannedResponse(queryRequest);

      if (resp) {
        setCannedPageNo(getValue(resp, "data.pagination.page", 1));
        setCannedPageCount(getValue(resp, "data.pagination.page_count", 1));
        setCannedTotalCount(getValue(resp, "data.pagination.total", 0));

        const folders = getValue(resp, "data.canned_responses", []);

        const uniqueFolders = folders.filter((folder: any) => {
          return !cannedList.some(
            (existingFolder) => existingFolder.id === folder.id
          );
        });

        setCannedList((prevList: any) => [...prevList, ...uniqueFolders]);
      }
    } catch (error) {
      toast.error("Failed to load canned responses.");
    } finally {
      setIsCannedLoading(false);
    }
  };

  const handleCannedScroll = useCallback(
    _.debounce((event: React.UIEvent<HTMLDivElement>) => {
      const target = event.target as HTMLDivElement;
      const scrollHeight = target.scrollHeight;
      const scrollTop = target.scrollTop;
      const clientHeight = target.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (cannedPageNo < cannedPageCount) {
          getCannedResponseList(cannedPageNo + 1, true);
        } else if (cannedList.length === cannedTotalCount) {
          // toast.success("Fetched all responses");
        }
      }
    }, 300), // Debounce function with a delay of 500ms
    [cannedPageNo, cannedPageCount, cannedList, cannedTotalCount]
  );

  useEffect(() => {
    if (cannedList.length > 10 && cannedList.length === cannedTotalCount) {
      toast.success("Fetched more responses");
    }
  }, [cannedList.length]);

  useEffect(() => {
    if (props.triggerCannedResponseList) {
      getCannedResponseList(1, true);
      props.setTriggerCannedResponseList(false);
    }
  }, [props.triggerCannedResponseList]);

  /* -------------------------------------------------------------------------- */
  /*                              AI Section                                */
  /* -------------------------------------------------------------------------- */
  //Ai rephrase
  const initialAiText = {
    key: "",
    text: "",
  };
  const [aiRequest, setAiRequest] = useState(initialAiText);
  const [isRephraseLoading, setIsRephraseLoading] = useState(false);
  const [aiRephrasedText, setAiRephrasedText] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [previousEditorState, setPreviousEditorState] = useState("");
  const [openAiModal, setOpenAiModal] = useState(false);

  const aIItems: MenuProps["items"] = aiRephraseMethods.map(
    (method: any, index: any) => ({
      key: `${index + 1}`,
      label: method,
      onClick: () => {
        handleAIRephraseSubmit(method);
        toggleAiModal(method);
      },
      disabled: isRephraseLoading === true,
    })
  );

  const handleAIRephraseSubmit = async (method: string) => {
    try {
      setIsRephraseLoading(true);
      let payload = {
        key: method,
        text: message,
      };
      let resp = await createAIRephrase(payload);
      if (resp) {
        setAiRequest({
          ...aiRequest,
          text: resp.data,
        });
        setAiRephrasedText(resp.data);
      }
    } catch (error) {
      setIsRephraseLoading(false);
    }
    setIsRephraseLoading(false);
  };

  const toggleAiModal = (method: any) => {
    setOpenAiModal(!openAiModal);
    handleAIRephraseSubmit(method);
  };

  const revertToPreviousState = () => {
    if (previousEditorState) {
      setMessage({ previousEditorState });
    }
  };

  const applyAIContent = () => {
    if (aiRephrasedText) {
      setParentChage(true)
      // setPreviousEditorState(message);
      // setEditorState(editorState);
      setMessage(aiRephrasedText);
      setAiRequest({
        ...aiRequest,
        text: "",
      });
    } else {
      toast.error("No content to apply");
    }
  };

  const insertHTMLContent = (editorState: any, content: string) => {
    // Convert HTML to content state
    const newContentState = stateFromHTML(content);

    // Get the current content and selection
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    // Insert the new content at the current cursor position
    const newContent = Modifier.replaceWithFragment(
      currentContent,
      currentSelection,
      newContentState.getBlockMap()
    );

    // Create a new editor state with the updated content
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-fragment"
    );

    // Move cursor to the end of the inserted content
    const updatedEditorState = EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );

    return updatedEditorState;
  };

  const handleCannedResponse = (content: string) => {
    setParentChage(true)
    setMessage(content);
    setOpenCannedResponse(false);
    // const updatedState = insertHTMLContent(editorState, content);
    // let state = draftToHtmlPuri(convertToRaw(updatedState.getCurrentContent()));
  };
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        className="ant-inbox-tab"
        items={[
          {
            label: "Reply",
            key: "1",
            children: (
              <ChatEditor
                contentLength={contentLength}
                showEmojiPicker={showEmojiPicker}
                handleEmojiClick={handleEmojiClick}
                handleUploadDocument={handleUploadDocument}
                message={message}
                toggleAiModal={toggleAiModal}
                isRephraseLoading={isRephraseLoading}
                handleKeyDown={handleKeyDown}
                editorRef={editorRef}
                handleChangeEditor={handleChangeEditor}
                handleSubmit={handleSendMessages}
                openCannedResponse={openCannedResponse}
                setOpenCannedResponse={setOpenCannedResponse}
                handleCannedOpen={handleCannedOpen}
                handleCannedEditorButton={handleCannedEditorButton}
                cannedPopupPosition={cannedPopupPosition}
                cannedList={cannedList}
                personalCannedList={personalCannedList}
                nonPersonalcannedList={nonPersonalcannedList}
                isCannedLoading={isCannedLoading}
                handleCannedResponse={handleCannedResponse}
                handleCannedScroll={handleCannedScroll}
                cannedLoadMore={cannedLoadMore}
                parentChage={parentChage}
                setParentChage={setParentChage}
                showCannedResponse
              />
            ),
          },
          {
            label: "Private Note",
            key: "2",
            children: (
              <ChatEditor
                contentLength={privateContentLength}
                showEmojiPicker={showEmojiPicker}
                handleEmojiClick={handlePrivateEmojiClick}
                handleUploadDocument={handleUploadDocument}
                message={privateMessage}
                toggleAiModal={toggleAiModal}
                isRephraseLoading={isRephraseLoading}
                handleKeyDown={handlePrivateKeyDown}
                editorRef={privateEditorRef}
                handleChangeEditor={handleChangePrivateEditor}
                handleSubmit={handleSendPrivateMessages}
                placeHolder="Use '@' to mention people and '#' to mention Slack channels"
                parentChage={parentChage}
                setParentChage={setParentChage}
              />
            ),
          },
        ]}
      />

      <RNRephraseModal
        openAiModal={openAiModal}
        setOpenAiModal={setOpenAiModal}
        revertToPreviousState={revertToPreviousState}
        applyAIContent={applyAIContent}
        isRephraseLoading={isRephraseLoading}
        aIItems={aIItems}
        content={message}
        aiRequest={aiRequest}
        handleChangeEditor={handleChangeEditor}
        placeHolder={`Hi [[name]]!🖐\n\nThe awesome products 😍 from [[brand_name]] are waiting to be yours!\n Get your products here👇`}
      />
    </div>
  );
}

export default memo(ChatFooter);
