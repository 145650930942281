import React, { useEffect, useState } from "react";
import MessageLayout from "../components/message-layout";
import MessageNavViews from "../components/nav-views";
import { getValue } from "@utils/lodash";
import Loader from "@components/common/Loader/loading";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import ButtonComponent from "@components/Form/Button/Button";
import {
  getWhatsappConfigSettings,
  getWhatsappTemplate,
} from "@services/fb.service";
import InputFormField from "@pages/Public/Auth/common/inputForm";
import InputRuleForm from "@components/InputRuleForm/form";
import { toast } from "sonner";
import DeleteSvgComponent from "@assets/svg/delete";
import { addWhatsappConfigSettingOptOut } from "@services/fb.service";
import { handleTextArrayChange } from "@common/handleChange";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import MessageOptOutPopup from "../components/optout-popup";
import ListLoader from "@components/common/Loader";
import { Button } from "antd";
import WhatsappIconViewPage from "@pages/Private/Broadcast/components/common/whatsapp-icon";
import { DeleteOutlined } from "@ant-design/icons";
function OptInMessages(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const intialState = {
    isEnable: true,
    keywords: [],
    isAutoResponse: true,
    responseType: "template",
    autoResponseMsgType: "text",
    autoResponseMsgTxt: "",
    templateId: "",
    mediaId: "",
  };
  const [request, setRequest] = useState(intialState);
  const responseTypeEnum = [
    { value: "template", label: "Template" },
    { value: "message", label: "Message" },
  ];
  const messageTypeEnum = [
    { value: "text", label: "Text" },
    // { value: "image", label: "Image" },
    // { value: "audio", label: "Audio" },
    // { value: "document", label: "Document" },
    // { value: "video", label: "Video" },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getData();
    getTemplateList();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [info, setInfo] = useState({});
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappConfigSettings(queryRequest);
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setRequest({
          ...request,
          isEnable: getValue(resp, `data.feature.opt_out`, false),
          keywords: getValue(resp, `data.keywords`, []).map((item: object) => ({
            value: item,
            server: true,
          })),
          isAutoResponse: getValue(resp, `data.auto_response`, false),
          responseType: getValue(resp, `data.response_type`, ""),
          autoResponseMsgType: getValue(resp, `data.msg_type`, ""),
          autoResponseMsgTxt: getValue(resp, `data.msg_text`, ""),
          templateId: getValue(resp, `data.template_id`, ""),
          mediaId: getValue(resp, `data.msg_media_id`, ""),
        });
        setMessageType(
          getValue(resp, `data.response_type`, "") === "message"
            ? "regular"
            : "preapproved"
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    let keywords = getValue(request, `keywords`, []).map((item: object) =>
      getValue(item, `value`, "")
    );
    let payload = JSON.parse(JSON.stringify(request));
    payload["keywords"] =
      getValue(keywords, `length`, 0) > 0
        ? keywords.filter((item: object) => item)
        : [];
    // payload["responseType"] =
    //   messageType === "regular" ? "message" : "template";
    setSubmitLoading(true);
    try {
      let resp = await addWhatsappConfigSettingOptOut(
        // removeNullOrUndefinedProperties(payload)
        payload
      );
      if (resp) {
        toast.success("Saved successfully");
        setSubmitLoading(false);
        setIsOpen(false);
        getData();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleSubmitAutoResponse = async () => {
    let keywords = getValue(request, `keywords`, []).map((item: object) =>
      getValue(item, `value`, "")
    );
    let payload = JSON.parse(JSON.stringify(request));
    payload["keywords"] =
      getValue(keywords, `length`, 0) > 0
        ? keywords.filter((item: object) => item)
        : [];
    payload["isAutoResponse"] = false;
    setSubmitLoading(true);
    try {
      let resp = await addWhatsappConfigSettingOptOut(
        removeNullOrUndefinedProperties(payload)
      );
      if (resp) {
        toast.success("Saved successfully");
        setSubmitLoading(false);
        setIsOpen(false);
        getData();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                          Onchange Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleAddKeywords = () => {
    if (getValue(request, `keywords.length`, 0) < 5) {
      let obj = { value: "" };
      getValue(request, `keywords`, []).push(obj);
      setRequest({ ...request });
    } else {
      toast.error("You have reached max limits");
    }
  };

  const handleRemoveKeywords = (index: number) => {
    let keywords = getValue(request, `keywords`, []);
    let filtered = keywords.filter((item: object, i: number) => index !== i);
    setRequest({
      ...request,
      keywords: filtered,
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                             Modal Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const [messageType, setMessageType] = useState("preapproved");
  const [isLoading1, setIsLoading1] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const getTemplateList = async () => {
    try {
      setIsLoading1(true);
      let payload = {
        status: ["APPROVED"],
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappTemplate(queryRequest);
      if (resp) {
        setTemplateList(
          getValue(resp, `data.templates`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  return (
    <div className="optout_container">
      {isLoading ? (
        <ListLoader />
      ) : (
        <>
          <div className="optout-campaign_container">
            <div className="d-flex justify-content-between align-items-center px-4">
              <div>
                <h6 className="header_text__18">API Campaign Opt-out</h6>
                <p className="small_text__14 mt-3">
                  Enable this if you don't wish to send api campaign to
                  opted-out contacts
                </p>
              </div>
              <div>
                <label className={"switch"}>
                  <input
                    type="checkbox"
                    checked={getValue(request, `isAutoResponse`, false)}
                    onChange={() => {
                      const currentValue = getValue(
                        request,
                        `isAutoResponse`,
                        false
                      );
                      setRequest({
                        ...request,
                        isAutoResponse: !currentValue,
                      });
                      if (currentValue) {
                        handleSubmitAutoResponse();
                      }
                    }}
                  />
                  <span className={`${"slider"} ${`round`} d-flex`}></span>
                </label>
              </div>
            </div>
          </div>

          {getValue(request, `isAutoResponse`, false) && (
            <div className="optout-campaign_container">
              <div className="d-flex justify-content-between align-items-start px-4 w-100">
                <div className="w-50">
                  <h6 className="header_text__18">Opt-out Keywords</h6>
                  <p className="small_text__14 mt-3">
                    The user will have to type exactly one of these messages on
                    which they should be automatically opted-out
                  </p>
                  <div className="keywords_container w-50 mt-4">
                    {getValue(request, `keywords`, []).map(
                      (item: object, index: number) => {
                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center"
                          >
                            <InputRuleForm
                              inputType="TEXT"
                              placeholder="Enter keyword"
                              value={getValue(item, `value`, "")}
                              name={"value"}
                              onChange={(e: any) =>
                                handleTextArrayChange(
                                  index,
                                  e,
                                  "keywords",
                                  setRequest
                                )
                              }
                            />
                            {!getValue(item, `server`, false) && (
                              <DeleteOutlined
                                onClick={() => handleRemoveKeywords(index)}
                                className="ms-2 mt-2 cursor-pointer"
                              />
                            )}
                          </div>
                        );
                      }
                    )}
                    <div className="mt-4">
                      <Button
                        // size="large"
                        onClick={handleAddKeywords}
                        disabled={getValue(request, `keywords.length`, 0) > 4}
                      >
                        Add more
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="w-50">
                  <div className="d-flex align-items-center">
                    <h6 className="header_text__18">Opt-out Response</h6>
                    <div className="ms-3 mt-2">
                      <label className={"switch"}>
                        <input
                          type="checkbox"
                          checked={getValue(request, `isEnable`, false)}
                          onChange={() =>
                            setRequest({
                              ...request,
                              isEnable: !getValue(request, `isEnable`, false),
                            })
                          }
                        />
                        <span
                          className={`${"slider"} ${`round`} d-flex`}
                        ></span>
                      </label>
                    </div>
                    <div className="ms-2">
                      <Button onClick={handleModal}>Configure</Button>
                    </div>
                  </div>
                  <p className="small_text__14 mt-3">
                    Setup a response message for opt-out user keywords
                  </p>

                  {getValue(request, `responseType`, "") === "template" ? (
                    <div className="template-wrapper mt-2">
                      <div className=" template-container">
                        <WhatsappIconViewPage
                          request={templateList.find(
                            (item: object) =>
                              getValue(item, `id`, "") ===
                              getValue(request, `templateId`, "")
                          )}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="border border-radius__5 p-3 mt-5">
                      <p className=" small_text__16">
                        {getValue(request, `autoResponseMsgTxt`, "")}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4 mx-4">
                <Button
                  size="large"
                  type="primary"
                  onClick={handleSubmit}
                  loading={submitLoading}
                >
                  Save Settings
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      <MessageOptOutPopup
        isOpen={isOpen}
        handleModal={handleModal}
        request={request}
        setRequest={setRequest}
        messageType={messageType}
        setMessageType={setMessageType}
        templateList={templateList}
        messageTypeEnum={messageTypeEnum}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
      />
    </div>
  );
}

export default OptInMessages;
