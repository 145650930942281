import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getValue } from "@utils/lodash";

const AIAgentTabHeaders = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const orgId = getValue(params, "orgId", "");

  const items = [
    {
      key: "responses",
      label: "Responses",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/responses`,
    },
    {
      key: "training-data",
      label: "Training Data",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/training-data`,
    },
    {
      key: "actions",
      label: "Actions",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/actions`,
    },
    {
      key: "map-to-channel",
      label: "Map to Channel",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/map-to-channel`,
    },
    {
      key: "share",
      label: "Share",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/share`,
    },
    {
      key: "settings",
      label: "Settings",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/settings`,
    },
  ];

  const getActiveTab = () => {
    const path = location.pathname;
    const activeItem = items.find((item) => item.route === path);
    return activeItem?.key || "responses";
  };

  const handleTabChange = (key: any) => {
    const selectedTab = items.find((item) => item.key === key);
    if (selectedTab) {
      navigate(selectedTab.route);
    }
  };

  return (
    <div className={"tabWrapper"}>
      <div className={"tabContainer"}>
        <Tabs
          items={items.map((item) => ({ key: item.key, label: item.label }))}
          activeKey={getActiveTab()}
          onChange={handleTabChange}
          className={"tabs"}
          tabBarStyle={{
            margin: 0,
          }}
          tabBarGutter={32}
        />
      </div>
    </div>
  );
};

export default AIAgentTabHeaders;
