import { Button } from "antd";
import React from "react";

const SourcesCard = (props: any) => {
  const { request } = props;
  return (
    <div className="border rounded-3 p-4 h-fit-content">
      <h6 className="header_text__16 mb-3">Sources</h6>
      <p className="small_text__14">146 text input chars</p>
      {request.length > 0 && (
        <p className="small_text__14">
          {request.length} Links (0 detected chars)
        </p>
      )}
      {request.length > 0 && (
        <p className="small_text__14">
          {request.length} Q&A (0 detected chars)
        </p>
      )}
      <div>
        <h6 className="header_text__16">Total detected characters</h6>
        <p>146 / 400,000 limit</p>
      </div>
      <Button>Retrain Chatbot</Button>
    </div>
  );
};

export default SourcesCard;
