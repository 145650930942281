import HomeHeader from "@components/common/Header/HomeHeader/Header";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import {
  createCategoryFolder,
  getArticleVersions,
  getSpecificArticle,
  getSpecificCategory,
  getSpecificCategoryFolder,
  updateArticlesStatus,
  updateCategoryFolder,
} from "@services/solutions.service";
import { getValue } from "@utils/lodash";
import { Badge, Button, Dropdown, MenuProps } from "antd";
import { X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { DownOutlined } from "@ant-design/icons";
import SolutionsFolderModal from "../components/SolutionsFolderModal";
import SimpleReactValidator from "simple-react-validator";
import CategoryDetailsLeftSide from "./components/CategoryDetailsLeftSide";
import CategoryDetailsRightSide from "./components/CategoryDetailsRightSide";
import { motion, AnimatePresence } from "framer-motion";

const CategoryFolders = () => {
  const params = useParams();
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [categoryDetails, setCategoriesDetails] = useState({});
  const [articleDetails, setArtcileDetails] = useState({});
  //
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectedArticle, setSelectedArticle] = useState("");
  const [isDataFetched, setDataFetched] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState<any>([]);

  const [request, setRequest] = useState<any>({
    description: {},
  });

  //Folder
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [foldersRequest, setFoldersRequest] = useState<any>({
    name: "",
    description: "",
    category_id: "",
    parent_folder: "",
    article_order: "alphabetically",
    visibility: "all_users",
  });
  const [editFolderId, setEditFolderId] = useState("");
  const [isLoading4, setIsLoading4] = useState(false);
  const [isLoading5, setIsLoading5] = useState(false);
  const [categoriesDropdownList, setCategoriesDropdownList] = useState<any>([]);

  //versions
  const [openArticleVersion, setOpenArticleVersion] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      // getCategoriesList(true);
    }
  }, []);

  useEffect(() => {
    if (getValue(params, "id", "")) {
      setSelectedCategory(getValue(params, "id", ""));
      setSelectedFolder(getValue(params, "folderId", ""));
      setSelectedArticle(getValue(params, "articleId", ""));
      handleGetSpecificCategory(true);
    }
  }, [params]);

  useEffect(() => {
    if (selectedArticle) {
      handleGetArticle(selectedArticle);
    }
  }, [selectedArticle]);

  /* -------------------------------------------------------------------------- */
  /*                              Categories API Section                        */
  /* -------------------------------------------------------------------------- */

  const handleGetSpecificCategory = async (status: boolean) => {
    try {
      setIsLoading(status);
      const resp = await getSpecificCategory(getValue(params, "id", ""));
      if (resp) {
        setCategoriesDetails(getValue(resp, "data", {}));
        setCategoriesDropdownList([
          {
            label: getValue(resp, "data.name", ""),
            value: getValue(resp, "data.id", ""),
          },
        ]);
        setFoldersRequest((prev: any) => ({
          ...prev,
          category_id: getValue(resp, "data.id", ""),
        }));
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to load category.");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetSpecificFolder = async (id: string) => {
    try {
      setIsLoading5(true);
      const resp = await getSpecificCategoryFolder(id);
      if (resp) {
        setFoldersRequest(() => ({
          name: getValue(resp, "data.name", ""),
          description: getValue(resp, "data.description", ""),
          category_id: getValue(resp, "data.category_id", ""),
          parent_folder: getValue(resp, "data.id", ""),
          article_order: getValue(resp, "data.article_order", ""),
          visibility: getValue(resp, "data.visibility", ""),
        }));
        setIsLoading5(false);
      }
    } catch (error) {
      toast.error("Failed to load folder.");
      setIsLoading5(false);
    } finally {
      setIsLoading5(false);
    }
  };

  const handleGetArticle = async (id: string) => {
    try {
      setIsLoading1(true);
      const resp = await getSpecificArticle(id);
      if (resp) {
        setArtcileDetails(getValue(resp, "data", {}));
        setRequest({
          description: getValue(resp, "data.description", {}),
        });
        setIsLoading1(false);
        setDataFetched(!isDataFetched);
      }
    } catch (error) {
      toast.error("Failed to load article.");
      setIsLoading1(false);
    } finally {
      setIsLoading1(false);
    }
  };

  const handleGetArticleVersions = async (id: string) => {
    try {
      setIsLoading1(true);
      const resp = await getArticleVersions(id);
      if (resp) {
        // setArtcileDetails(getValue(resp, "data", {}));
        // setRequest({
        //   description: getValue(resp, "data.description", {}),
        // });
        // setIsLoading1(false);
        // setDataFetched(!isDataFetched);
      }
    } catch (error) {
      toast.error("Failed to load versions.");
      setIsLoading1(false);
    } finally {
      setIsLoading1(false);
    }
  };

  const handleStatusSubmit = async (status: any) => {
    setIsLoading3(true);
    try {
      const request = {
        ids: selectedCheckbox,
        is_active: status,
      };
      let payload = JSON.parse(JSON.stringify(request));
      const resp = await updateArticlesStatus(payload);
      if (resp) {
        toast.success(`Updated Successfully`);
        setSelectedCheckbox([]);
        setIsLoading3(false);
        handleGetSpecificCategory(true);
        handleGetArticle(selectedArticle);
      } else {
        toast.error(`Failed updating status`);
        setIsLoading3(false);
      }
    } catch (error) {
      toast.error(`Error updating status`);
      setIsLoading3(false);
    } finally {
      setIsLoading3(false);
    }
  };

  const handleCheckboxChange = (id: string, checked: boolean) => {
    setSelectedCheckbox((prev: any) =>
      checked
        ? [...prev, id]
        : prev.filter((checkboxId: any) => checkboxId !== id)
    );
  };

  const subMenuArray = [
    {
      linkText: `Categories`,
      linkUrl: "",
    },
    {
      linkText: getValue(categoryDetails, "name", ""),
      linkUrl: window.location.pathname,
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: "To Active",
      key: "1",
      icon: <Badge status="success" />,
      onClick: () => handleStatusSubmit(true),
    },
    {
      label: "To Inactive",
      key: "2",
      icon: <Badge status="error" />,
      onClick: () => handleStatusSubmit(false),
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                              Folder Section                        */
  /* -------------------------------------------------------------------------- */
  const handleFolderSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      // toast.error(`Please fill all the mandatory fields`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading4(true);
      try {
        let payload = JSON.parse(JSON.stringify(foldersRequest));
        const resp = editFolderId
          ? await updateCategoryFolder(editFolderId, payload)
          : await createCategoryFolder(payload);
        if (resp) {
          toast.success(`${editFolderId ? "Updated" : "Created"} Successfully`);
          setFoldersRequest({
            name: "",
            description: "",
            category_id: "",
            parent_folder: "",
            article_order: "alphabetically",
            visibility: "all_users",
          });
          forceUpdate(0);
          simpleValidator.current.hideMessages();
          setOpenCreateFolder(false);
          setIsLoading4(false);
          handleGetSpecificCategory(true);
          setEditFolderId("");
        } else {
          toast.error(
            `Failed ${editFolderId ? "updating" : "creating"} Folder`
          );
          setIsLoading4(false);
        }
      } catch (error) {
        toast.error(`Error ${editFolderId ? "updating" : "creating"} Folder`);
        setIsLoading4(false);
      }
    }
  };

  const showFolderModal = (
    id?: string,
    name?: string,
    description?: string
  ) => {
    setOpenCreateFolder(true);
  };

  const handleCancelFolder = () => {
    setOpenCreateFolder(false);
    setFoldersRequest({
      name: "",
      description: "",
      category_id: "",
      parent_folder: "",
      article_order: "alphabetically",
      visibility: "all_users",
    });
    forceUpdate(0);
    simpleValidator.current.hideMessages();
    setEditFolderId("");
  };

  const handleFolderEdit = (folderId: string) => {
    setEditFolderId(folderId);
    handleGetSpecificFolder(folderId);
    setOpenCreateFolder(true);
  };

  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle={`Knowledge Base (Support)`}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        subMenuArray={subMenuArray}
        showBack
        handleNavigateBack={() =>
          navigate(`/${getValue(params, "orgId", "")}/solutions`)
        }
        children={
          <div className="d-flex align-items-center gap-2">
            {selectedCheckbox.length > 0 && (
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="d-flex align-items-center gap-2"
              >
                <>
                  <h6
                    className="header_blue_text__16 text-nowrap px-2 cursor-pointer"
                    onClick={() => setSelectedCheckbox([])}
                  >
                    <X size={18} /> Clear Selection
                  </h6>
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    menu={{ items }}
                    size="large"
                    type="primary"
                    className="justify-content-end"
                    buttonsRender={([leftButton, rightButton]) => [
                      leftButton,
                      React.cloneElement(
                        rightButton as React.ReactElement<any, string>,
                        { loading: isLoading3 }
                      ),
                    ]}
                  >
                    Update Status
                  </Dropdown.Button>
                </>
              </motion.div>
            )}
            <Button
              type="primary"
              size="large"
              className=""
              onClick={() => showFolderModal()}
            >
              New Folder
            </Button>
          </div>
        }
      />
      <div className={`d-flex w-100`}>
        <div className="manageFieldRight w-100 d-flex">
          <CategoryDetailsLeftSide
            params={params}
            navigate={navigate}
            categoryDetails={categoryDetails}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            handleFolderEdit={handleFolderEdit}
            selectedArticle={selectedArticle}
            selectedCheckbox={selectedCheckbox}
            handleCheckboxChange={handleCheckboxChange}
            handleGetSpecificCategory={handleGetSpecificCategory}
          />
          <CategoryDetailsRightSide
            params={params}
            navigate={navigate}
            articleDetails={articleDetails}
            selectedArticle={selectedArticle}
            request={request}
            setRequest={setRequest}
            isDataFetched={isDataFetched}
            handleGetArticleVersions={handleGetArticleVersions}
          />
        </div>
      </div>
      {/* Folder */}
      <SolutionsFolderModal
        openCreateFolder={openCreateFolder}
        request={foldersRequest}
        setRequest={setFoldersRequest}
        isLoading={isLoading4}
        handleCancelFolder={handleCancelFolder}
        handleFolderSubmit={handleFolderSubmit}
        categoriesDropdownList={categoriesDropdownList}
        validator={simpleValidator}
        hideCategory
        editId={editFolderId}
      />
    </div>
  );
};

export default CategoryFolders;
