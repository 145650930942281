import Loader from "@components/common/Loader/loading";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ConversationMessages from "./conversation-messages";
import { getValue } from "@utils/lodash";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import { formatString } from "@common/text-helpers";
import { Button, Space } from "antd";

function ConversationScroll(props: any) {
  const {
    conversation,
    fetchMoreMessages,
    m_total_count,
    conversationLoading,
    setSelectedReplyMessage,
    selectedContact,
    contactInfo,
    handleAddContact,
    isLeadShow,
    selectedModule,
    messagesEndRef
  } = props;
  return (
    <>
      <div className="chat-section">
        <div
          id="scrollableDiv"
          style={{
            height: "calc(100vh - 350px)",
            // height: "calc(100vh - 256px)",
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <InfiniteScroll
            dataLength={conversation.length}
            next={fetchMoreMessages}
            hasMore={conversation.length < m_total_count}
            loader={conversationLoading ? <Loader small /> : null}
            style={{
              display: "flex",
              flexDirection: "column-reverse",
            }}
            inverse={true} // Scroll from bottom to top
            scrollableTarget="scrollableDiv"
          >
            <ConversationMessages
              conversation={conversation}
              setSelectedReplyMessage={setSelectedReplyMessage}
            />
          </InfiniteScroll>
          <div className="d-flex justify-content-center">
            <div className="new-contact-preview">
              <AccountCircleSvgComponent color={"#dfe5e7"} size={100} />
              <p className="small_text__18 color_black text-center mb-1 mt-2">
                {getValue(selectedContact, "wa_id", "")}
              </p>
              {getValue(contactInfo, `first_name`, "") ||
              getValue(contactInfo, `last_name`, "") ? (
                <p
                  className="small_text__14 text-center"
                  style={{ textAlign: "left" }}
                >
                  ~{formatString(getValue(contactInfo, `first_name`, ""))}{" "}
                  {formatString(getValue(contactInfo, `last_name`, ""))}
                </p>
              ) : (
                <p
                  className="small_text__14 text-center"
                  style={{ textAlign: "left" }}
                >
                  ~
                  {getValue(selectedContact, `wa_profile_name`, "")
                    ? formatString(
                        getValue(selectedContact, `wa_profile_name`, "")
                      )
                    : getValue(selectedContact, `first_name`, "") ||
                      getValue(selectedContact, `last_name`, "")
                    ? formatString(getValue(selectedContact, `first_name`, ""))
                    : ""}
                </p>
              )}
              {!getValue(contactInfo, `id`, "") ? (
                <>
                  <p className="small_text__13 text-center mb-1 mt-2">
                    Not a contact- No groups in common
                  </p>
                  {getValue(props, `permissions`, []).includes("update") && (
                    <Space>
                      <Button
                        size="middle"
                        type="primary"
                        onClick={() => handleAddContact()}
                        className="mt-2"
                      >
                        Add to {isLeadShow() ? "Leads/" : ""}
                        Contacts
                      </Button>
                    </Space>
                  )}
                </>
              ) : (
                <>
                  <p className="small_text__13 text-center mb-1 mt-2">
                    The contact already associated to {selectedModule}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div ref={messagesEndRef} />
      </div>
    </>
  );
}

export default ConversationScroll;
