import {
  handleSelectArrayChange,
  handleTextArrayChange,
} from "@common/handleChange";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";

const DefaultPaymentDetails = ({
  activeRoomIndex,
  rateplanList,
  ratePlanRequest,
  request,
  setRequest,
}: any) => {
  return (
    <div className="d-flex flex-column gap-3 my-3">
      <SearchToolTip
        label="label"
        data={rateplanList}
        selectKey={"value"}
        value={getValue(
          request,
          `room_data[${activeRoomIndex}].rate_plan_id`,
          ""
        )}
        onChange={(e: any) =>
          handleSelectArrayChange(
            activeRoomIndex,
            e,
            "rate_plan_id",
            "room_data",
            setRequest
          )
        }
        name="Rate Plan"
      />

      <div className="mx-1 mt-2">
        <InputRuleForm
          inputType="TEXT"
          name="rate_plan_price"
          value={getValue(ratePlanRequest, `rate_plan_price`, "")}
          placeholder="Enter Rate Plan Price"
          label="Rate Plan Price"
          disabled
        />
      </div>
      <div className="mx-1">
        <InputRuleForm
          inputType="TEXT"
          name="total_tax"
          value={getValue(ratePlanRequest, `total_tax`, "")}
          placeholder="Enter Total Tax"
          label="Total Tax"
          disabled
        />
      </div>
      <div className="mx-1">
        <InputRuleForm
          inputType="TEXT"
          name="total_after_tax"
          value={getValue(ratePlanRequest, `total_after_tax`, "")}
          placeholder="Enter Total After tax"
          label="Total After tax"
          disabled
        />
      </div>
      <div className="mx-1">
        <InputRuleForm
          inputType="TEXT"
          name="total_tax"
          value={getValue(ratePlanRequest, `total_tax`, "")}
          placeholder="Enter Total tax"
          label="Total tax"
          disabled
        />
      </div>
      <div className="mx-1">
        <InputRuleForm
          inputType="TEXT"
          name="sub_total"
          value={getValue(ratePlanRequest, `sub_total`, "")}
          placeholder="Enter Sub Total"
          label="Sub Total"
          disabled
        />
      </div>
      <div className="mx-1">
        <InputRuleForm
          inputType="TEXT"
          name="total"
          value={getValue(ratePlanRequest, `total`, "")}
          placeholder="Enter Total"
          label="Total"
          disabled
        />
      </div>
      <div className="mx-1">
        <InputRuleForm
          inputType="TEXT"
          name="payment_remarks"
          value={getValue(request, `room_data[0].payment_remarks`, "")}
          placeholder="Enter Remarks"
          label="Payment Remarks"
          onChange={(e: any) =>
            handleTextArrayChange(0, e, "room_data", setRequest)
          }
        />
      </div>
    </div>
  );
};

export default DefaultPaymentDetails;
