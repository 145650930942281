// import CompanyProfileSubHeader from "@components/v2/Settings/CompanyProfile/CompanyProfileSubHeader/CompanyProfileSubHeader";
// import ModuleAndFieldDetails from "@components/v2/Settings/ModuleAndField/ModuleAndFieldDetails/ModuleAndFieldDetails";
import { listAllModules } from "@services/modules.service";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
// import CompanyProfileNavigation from "@layouts/CompanyProfileDetailsLayout/CompanyProfileNavigation/CompanyProfileNavigation";
// import SettingsModuleEditInfoPopup from "@components/v2/Settings/ModuleAndField/EditInfoPopup";
import { UpdateModule } from "@services/modules.service";
import { toast } from "sonner";
import { useStateContext } from "context/profileProvider";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import "./index.scss";
import ModuleFieldDetails from "./components/ModuleAndFieldDetails";
import SettingsModuleEditInfoPopup from "./components/EditInfoPopup";
import useDynamicTitle from "@context/useDynamicTitle";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";

export default function ModuleAndField(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               Usestate Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [modules, setModules] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                               Useeffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await listAllModules("");
      if (resp) {
        let list = getValue(resp, `data`, []).filter(
          (item: object) => getValue(item, `name`, "") !== "tasks"
        );
        setModules(list);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [request, setRequest] = useState({
    label: "",
    id: "",
    seq_num: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = (item: object) => {
    setRequest({
      ...request,
      label: getValue(item, `label`, ""),
      id: getValue(item, `id`, ""),
      seq_num: getValue(item, `seq_num`, 0),
    });
    toggle();
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp = await UpdateModule(getValue(request, `id`, ""), {
        label: getValue(request, `label`, ""),
        seq_num: getValue(request, `seq_num`, 0),
      });
      if (resp) {
        toast.success("Updated successfully");
        toggle();
        getData();
        handleChangeModule();
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const { handleChangeModule } = useStateContext();
  useDynamicTitle("Modules & Fields");
  return (
    <CompanyProfileDetailsLayout {...props} isLoading={isLoading}>
      <CompanyProfileSubHeader
        settings
        icon="module-and-field"
        title="Modules"
      />
      <div className={`manageFieldRightSettings overfloe-y-auto p-4`}>
        <ModuleFieldDetails
          modules={modules}
          isLoading={isLoading}
          handleEdit={handleEdit}
        />
      </div>
      <SettingsModuleEditInfoPopup
        request={request}
        setRequest={setRequest}
        isOpen={isOpen}
        toggle={toggle}
        isLoading={submitLoading}
        handleSubmit={handleSubmit}
      />
    </CompanyProfileDetailsLayout>
  );
}
