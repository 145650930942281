import React, { useState } from "react";
import BackSvgComponent from "@assets/svg/back-link";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import { getColorStyle } from "@common/colors";
import { formatString } from "@common/text-helpers";
import { Button } from "antd";
function WorkflowDetailHeader(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const params = useParams();
  const { handleSubmit, submitLoading, pipelineInfo } = props;
  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center gap-3">
          <button
            onClick={() =>
              navigate(
                `/${getValue(params, `orgId`, "")}/settings/crm/workflows`
              )
            }
            className={`dashboard-button p-0`}
          >
            <img
              src="/images/icons/back-icon.svg"
              width={24}
              height={24}
              alt="back"
            />
          </button>
          {/* <h6
            className={`company-profile-subheader-wrapper__submenu-name mx-2`}
          >
            {getValue(params, `id`, "") ? "Update" : "Create"} Workflow
          </h6> */}
          <div>
            <InputRuleForm
              inputType="TEXT"
              label={"Name"}
              hideLabel
              placeholder="Enter Name"
              rows={4}
              name={"name"}
              autoFocus
              value={getValue(props, `request.name`, "")}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                props.setRequest({
                  ...props.request,
                  name: getValue(e, `target.value`, ""),
                })
              }

              //   validator={simpleValidator}
            />
          </div>
          <h6 style={getColorStyle("blue")} className="header_text__16">
            @
            {getValue(urlParams, `moduleName`, "")
              ? formatString(getValue(urlParams, `moduleName`, ""))
              : getValue(props, `info.module.label`, "")}
          </h6>
          <h6 style={getColorStyle("blue")} className="header_text__16">
            Pipeline :{" "}
            {getValue(pipelineInfo, `label`, "")
              ? formatString(getValue(pipelineInfo, `label`, ""))
              : getValue(props, `info.module.label`, "")}
          </h6>
        </div>
        <div className="d-flex align-items-center gap-2">
          <Button
            size="large"
            onClick={() =>
              navigate(
                `/${getValue(params, "orgId", "")}/settings/crm/workflows`
              )
            }
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => handleSubmit()}
            loading={submitLoading}
          >
            {getValue(props, `second_title`, "")
              ? getValue(props, `second_title`, "")
              : getValue(params, `id`, "")
              ? "Update"
              : "Create"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default WorkflowDetailHeader;
