import "./TicketsMainBody.scss";
import _ from "lodash";
import DetailsMiddleTicketList from "./common/ticket-list";
import TicketAllDetailsViewEdit from "./common/ticket-all-details-middle-page";
import { getValue } from "@utils/lodash";
import Loader from "@components/common/Loader/loading";

function TicketsDetailMiddlePage(props: any) {
  return (
    <div className={"ticket-wrapper__main-body row p-0 m-0"}>
      <div className="col-4 ticket-detail-ticket-list">
        <DetailsMiddleTicketList
          ticketList={props.ticketList}
          selectedTicket={props.selectedTicket}
        />
      </div>
      <div className="col-8 p-3">
        {getValue(props, `isLoading`, false) ? (
          <Loader />
        ) : (
          <TicketAllDetailsViewEdit data={props.data} />
        )}
      </div>
    </div>
  );
}

export default TicketsDetailMiddlePage;
