import React, { useRef } from "react";
import Popup from "reactjs-popup";
import { getValue } from "@utils/lodash";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";
import CloseMainSvgComponent from "@assets/svg/close-main";
import CloseSvgComponent from "@assets/svg/close";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";

function MP3PlayerComponent(props) {
  const POSITION_TYPES = [
    "bottom right",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "left center",
    "center center",
  ];

  const popupRef = useRef();
  const handleClose = (e) => {
    e.stopPropagation();
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };

  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      // className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      {props.header}
    </div>
  ));
  return (
    <Popup
      trigger={(open) => <CustomButtons open={open} header={props.header} />}
      position={POSITION_TYPES}
      closeOnDocumentClick
      on={"click"}
      contentStyle={{
        width: getValue(props, `width`, "")
          ? getValue(props, `width`, "")
          : "50%",
        minHeight: getValue(props, `height`, "")
          ? getValue(props, `height`, "")
          : "50px",
        zIndex: 9999,
        // background: "#33475b",
        // boxShadow:
        //   "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
      nested
      ref={popupRef}
      onOpen={handleOpen}
      onClick={handleClose}
      arrowStyle={
        {
          // color: "#33475b",
        }
      }
    >
      <div className="position-relative">
        <div className="d-flex justify-content-between">
          <div></div>
          <div onClick={handleClose} className="header_text__16 p-2">
            <DropdownCloseSvgComponent />
          </div>
        </div>
        <AudioPlayer
          autoPlay={props.autoPlay}
          src={getValue(props, `url`, "")}
          onPlay={(e) => {}}
          // other props here
        />
      </div>
    </Popup>
  );
}

export default MP3PlayerComponent;
