import { Table } from "antd";
import AIAgentLayout from "../../Layout";

const AIAgentResponses = () => {
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      // render: (name: any) => formatString(name),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      // render: (date: any) => convertCurrentDateWithTime(date),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      // render: (status: any) => (
      //   <Tag
      //     color={
      //       status === "COMPLETED"
      //         ? "success"
      //         : status === "DROPPED"
      //         ? "error"
      //         : "orange"
      //     }
      //   >
      //     {formatString(status)}
      //   </Tag>
      // ),
    },
    {
      title: "CREATED ON",
      dataIndex: "created_at",
      key: "created_at",
      // render: (created_at: any) => convertCurrentDateWithTime(created_at),
    },
    {
      title: "MORE INFO",
      dataIndex: "additional_info",
      key: "additional_info",
      // render: (additional_info: any) => (
      //   <div
      //     className="cursor-pointer d-flex justify-content-center"
      //     onClick={() => {
      //       handleModal();
      //       setBotInfo(additional_info);
      //     }}
      //   >
      //     {getValue(additional_info, `length`, 0) > 0 && (
      //       <EyeOutlined style={{ fontSize: "20px" }} />
      //     )}
      //   </div>
      // ),
    },
  ];

  return (
    <AIAgentLayout>
      <Table
        size="middle"
        className=""
        bordered
        columns={columns}
        // dataSource={list}
        rowKey="id"
        // pagination={{
        //   current: page_no,
        //   pageSize: limit,
        //   total: totalCount,
        //   showSizeChanger: true,
        //   pageSizeOptions: ["10", "20", "50"],
        //   onChange: (page_no, pageSize) => {
        //     handleChangePagination(page_no, pageSize);
        //   },
        //   showTotal: (total, range) =>
        //     `${range[0]}-${range[1]} of ${total} items`,
        // }}
        scroll={{
          y: `calc(100vh - 100px)`,
          x: 1400,
        }}
      />
    </AIAgentLayout>
  );
};

export default AIAgentResponses;
