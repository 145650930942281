import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import React from "react";
import { Button, Modal } from "antd";
import CommonPopupHeader from "@components/common/Header/CommonPopupHeader/CommonPopupHeader";
import InputRuleForm from "@components/InputRuleForm/form";
import ButtonComponent from "@components/Form/Button/Button";

function SettingsModuleEditInfoPopup(props: any) {
  const { request, setRequest, simpleValidator } = props;
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={props.handleSubmit}
      onCancel={props.toggle}
      footer={[
        <Button key="cancel" onClick={props.toggle} className="cancel__button">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      <h6 className="header_text__16">Update Pipeline Info</h6>

      <div className={"mb-4 mt-2 mx-2"}>
        <div className={`form_wrapper-pipeline`}>
          <InputRuleForm
            inputType="TEXT"
            label={"Module Display Label"}
            placeholder="Enter Label"
            name={"label"}
            value={getValue(request, `label`, "")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            validator={simpleValidator}
            validLeft
            mandatory
          />
        </div>
        {/* <div className={"popup-submit-container"}>
          <ButtonComponent
            buttonType={"scecondary"}
            buttonText="Cancel"
            onClickHandler={props.toggle}
          />
          <ButtonComponent
            buttonType={"primary"}
            buttonText={props.isLoading ? "Please wait..." : "Update"}
            onClickHandler={() => props.handleSubmit()}
            disabled={props.isLoading}
          />
        </div> */}
      </div>
    </Modal>
  );
}

export default SettingsModuleEditInfoPopup;
