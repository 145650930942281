import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./messages.scss";
import "reactjs-popup/dist/index.css";
import {
  SpecificWhatsappConversationRead,
  getAvailableConfigList,
  getSpecificWhatsappConversation,
  sendWhatsappConversation,
} from "@services/fb.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import Loader from "@components/common/Loader/loading";
import InboxContacts from "./components/ConversationList/contacts";
import ChatFooter from "./components/ConversationFooter/chat-footer";
import ChatRightHeader from "./components/ConversationHeader/chat-right-header";
import ChatDocumentPreview from "./components/ConversationFooter/chat-doc-preview";
import { mediaUpload } from "@services/upload.service";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { getSpecificContact } from "@services/contacts.service";
import { motion } from "framer-motion";
import {
  allPipelines,
  getSpecificPipeline,
  getSpecificPipelineColumns,
} from "@services/pipeline.service";
import { useStateContext } from "@context/profileProvider";
import {
  appendObjectValuesToArray,
  removeDuplicatesById,
} from "@components/helpers/request-helper";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import _ from "lodash";
import { replacePlaceholders } from "@common/markdown/markdown-helper";
import { useNavigate, useParams } from "react-router-dom";
import { getOrganisationUsers } from "@services/organisations.service";
import WaAdvancefilter from "./components/ConversationHeader/wa-advance-filter";
import ChatFilter from "./components/ConversationHeader/chat-filter";
import { getAllChatbots } from "@services/chatbot.service";
import InboxNoDataPage from "@components/common/NoData/inbox-nodata";
import useDynamicTitle from "@context/useDynamicTitle";
import ListLoader from "@components/common/Loader";
import { getSpecificLead } from "@services/leads.service";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import {
  createWebchatMessage,
  getCombinedInboxChatList,
  getSpecificWebChatInfo,
  readWebchatStatus,
} from "@services/inbox.service";
import { useSocketContext } from "@context/socketContext";
import ChatContactInfo from "./components/ConversationInfo/contact-info";
import ConversationScroll from "./components/ConversationInfo/conversation-scroll";
import ConversationReply from "./components/ConversationInfo/conversation-reply";
import ChatSearchHeader from "./components/ConversationHeader/chat-search-header";
import Pusher from "pusher-js";
import { config } from "../../../env";

function Messages(props: any) {
  useDynamicTitle("Inbox");
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [expanded, setExpanded] = useState(false);
  const [docExpanded, setDocExpanded] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isWAConfigured, setIsWAConfigured] = useState(false);

  const handleToggleWidth = () => {
    resetPipelineForm();
    setExpanded(!expanded);
  };
  const { selectedModuleId, userId, modules, userInfo, orgInfo, profileInfo } =
    useStateContext();

  const { statusCounts, getAllCount } = useSocketContext();
  const { unAssigned, assigned, assignedMe, resolved, assignedChatbot } =
    statusCounts;
  const [selectedConversation, setSelectedConversation] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                                 Socket Section                             */
  /* -------------------------------------------------------------------------- */
  const {
    newChatMessageInfo,
    newChatStatusInfo,
    newWebChatMessageInfo,
    setNewChatMessageInfo,
    setNewChatStatusInfo,
  } = useSocketContext();

  useEffect(() => {
    const processUnreadMessages = (
      conversationId: any,
      messageId: any,
      unreadCount: any
    ) => {
      if (unreadCount > 0) {
        handleReadWhatsappMessage(conversationId, messageId);
      }
    };
    // Process new message updates
    if (newChatMessageInfo) {
      const messageConversationId = getValue(
        newChatMessageInfo,
        "conversation.id",
        ""
      );
      const messageId = getValue(
        newChatMessageInfo,
        "conversation.last_msg.id",
        ""
      );

      // Update conversation if it's currently selected
      if (selectedConversation === messageConversationId && messageId) {
        addSocketMessageToChat(messageId);
        processUnreadMessages(
          messageConversationId,
          getValue(newChatMessageInfo, "last_msg.id", ""),
          getValue(newChatMessageInfo, "conversation.unread_count", 0)
        );
        let obj = {
          ...getValue(newChatMessageInfo, "conversation", {}),
          unreadCount: 0,
        };
        updateContactInfoFromSocket(obj);
      }
      // Update contact info if conversation exists
      if (messageConversationId) {
        updateContactInfoFromSocket(
          getValue(newChatMessageInfo, "conversation", {})
        );
      }
    }
    // Process status updates
    if (newChatStatusInfo) {
      const statusConversationId = getValue(
        newChatStatusInfo,
        "conversation.id",
        ""
      );
      const statusMessageId = getValue(newChatStatusInfo, "msg.id", "");
      const messageStatus = getValue(newChatStatusInfo, "msg.status", "");

      // Update status if it's currently selected conversation
      if (selectedConversation === statusConversationId && statusMessageId) {
        addSocketMessageStatusToChat(statusMessageId, messageStatus);
        // Handle delivered messages
        if (messageStatus === "is_delivered") {
          processUnreadMessages(
            statusConversationId,
            statusMessageId,
            getValue(newChatStatusInfo, "conversation.unread_count", 0)
          );
        }
      }

      // Update contact info if conversation exists
      if (statusConversationId) {
        updateContactInfoFromSocket(
          getValue(newChatStatusInfo, "conversation", {})
        );
      }
    }
  }, [
    newChatMessageInfo,
    newChatStatusInfo,
    newWebChatMessageInfo,
    selectedConversation,
  ]);

  const addSocketMessageToChat = (lastMessageId: string) => {
    if (!lastMessageId) return;
    // Check if message already exists in conversation
    const isDuplicate = conversation.some((msg) => msg.id === lastMessageId);
    if (isDuplicate) return;

    // Create new message object
    const newMessage = {
      ...getValue(newChatMessageInfo, "conversation", {}),
      ...getValue(newChatMessageInfo, "conversation.last_msg", {}),
    };
    // Update conversation state immutably
    setConversation((prevConversation) => [...prevConversation, newMessage]);
  };

  const addSocketMessageStatusToChat = (
    lastMessageId: string,
    newStatus: string
  ) => {
    setConversation((prevConversation) => {
      // Check if message exists
      const existingMessageIndex = prevConversation.findIndex(
        (msg) => msg.id === lastMessageId
      );
      // If we're just updating status and message exists
      if (newStatus && existingMessageIndex !== -1) {
        const updatedConversation = [...prevConversation];
        updatedConversation[existingMessageIndex] = {
          ...updatedConversation[existingMessageIndex],
          status: newStatus,
          is_read: newStatus === "is_read" ? true : false,
        };
        return updatedConversation;
      }

      // If message doesn't exist and we're adding new message
      if (existingMessageIndex === -1 && !newStatus) {
        const newMessage = {
          ...getValue(newChatMessageInfo, "conversation", {}),
          ...getValue(newChatMessageInfo, "conversation.last_msg", {}),
        };
        return [...prevConversation, newMessage];
      }

      // If neither condition is met, return unchanged
      return prevConversation;
    });
  };

  const updateContactInfoFromSocket = (newObject: any) => {
    setConversations((prevConversations) => {
      // Remove existing object with same ID if it exists
      const filteredArray = prevConversations.filter(
        (obj: any) => obj.id !== newObject.id
      );
      // Add new object to the beginning
      return [
        { ...newObject, unread_count: getValue(newObject, `unreadCount`, 0) },
        ...filteredArray,
      ];
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData(true, page_no, query, conversationStatus);
      getActiveUsersData();
    }
    getConnectedWhatsappList();
    getChatbotData();
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      setContactInfo({});
      setSelectedContact({});
      setFormFields([]);
      setExpanded(false);
      setUserDetails(false);
      getActiveUsersData();
      getData(true, page_no, query, conversationStatus);
      let waAccountIds: any = urlSearchParams.getAll("waAccountIds[]");
      if (
        getValue(waAccountIds, `length`, 0) > 0 ||
        getValue(UrlParams, `onlyUnread`, "")
      ) {
        let waAccountIds: any = urlSearchParams.getAll("waAccountIds[]");
        setFilterRequest({
          ...filterRequest,
          onlyUnread:
            getValue(UrlParams, `onlyUnread`, "") === "true" ? true : false,
          waAccountIds: waAccountIds,
          forOrgUser: getValue(UrlParams, `forOrgUser`, ""),
        });
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  /* ----------------------------  Whatsapp Contacts List  --------------------- */
  const [conversations, setConversations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page_no, setPageNo] = useState(1);
  const [page_size, setPageSize] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const handleChangePagination = (page_no: number) => {
    setPageNo(page_no);
    let payload = {
      ...UrlParams,
      page_no: page_no,
      conversationId: selectedConversation,
      searchText: query,
      convStatus: conversationStatus,
      userId: "",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const [conversationStatus, setConversationStatus] = useState("un-assigned");
  const [unreadCount, setUnreadCount] = useState(0);
  const getData = async (
    status: boolean,
    page_no: number,
    searchText: string,
    convStatus: string
  ) => {
    let waAccountIds: any = urlSearchParams.getAll("waAccountIds[]");
    try {
      setConversationStatus(convStatus);
      setIsLoading(status);
      let payload = {
        page_no: page_no ? page_no : getValue(UrlParams, `page_no`, ""),
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : page_size,
        search: getValue(UrlParams, `searchText`, "")
          ? getValue(UrlParams, `searchText`, "")
          : searchText,
        status: getValue(UrlParams, `convStatus`, "")
          ? getValue(UrlParams, `convStatus`, "")
          : convStatus
          ? convStatus
          : conversationStatus,
        org_user: getValue(UrlParams, `forOrgUser`, "")
          ? getValue(UrlParams, `forOrgUser`, "")
          : getValue(UrlParams, `userId`, ""),
        waAccountIds: waAccountIds,
        // onlyUnread: getValue(UrlParams, `onlyUnread`, ""),
        channel: "all",
      };
      let queryRequest = QueryRequestHelper(payload);
      // let resp = await getWhatsappConversations(queryRequest);
      let resp = await getCombinedInboxChatList(queryRequest);
      if (resp) {
        let result = getValue(resp, `data.conversations`, []);
        setUnreadCount(getValue(resp, `data.unread`, 0));
        setConversations(result);
        setTotalCount(getValue(resp, `data.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getDataStatusRefetch = async (
    status: boolean,
    id: string,
    newPayload: object
  ) => {
    let waAccountIds: any = urlSearchParams.getAll("waAccountIds[]");
    try {
      let conversationsInfo = conversations.find(
        (item: object) => getValue(item, `id`, "") === id
      );
      setIsLoading(status);
      let payload = {
        page_no: getValue(UrlParams, `page_no`, ""),
        page_size: getValue(UrlParams, `page_size`, ""),
        search: getValue(UrlParams, `searchText`, ""),
        status: getValue(UrlParams, `convStatus`, "")
          ? getValue(UrlParams, `convStatus`, "")
          : conversationStatus,
        org_user: getValue(UrlParams, `userId`, ""),
        waAccountIds: waAccountIds,
        onlyUnread: getValue(UrlParams, `onlyUnread`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getCombinedInboxChatList(queryRequest);
      if (resp) {
        let result = getValue(resp, `data.conversations`, []);
        setUnreadCount(getValue(resp, `data.unread`, 0));
        setConversations(result);
        let info = result.find(
          (item: object) =>
            getValue(item, `wa_profile_name`, "") ===
            getValue(conversationsInfo, `wa_profile_name`, "")
        );
        if (info) {
          setSelectedConversationInfo({
            ...info,
            last_name: getValue(info, `wa_profile_name`, ""),
            mobile_number: getValue(info, `wa_id`, ""),
          });
        }
        // delete UrlParams.convStatus
        setSelectedConversation(getValue(info, `id`, ""));
        let payload = {};
        if (
          getValue(newPayload, `orgUserId`, "") &&
          getValue(newPayload, `managedBy`, "") === "team"
        ) {
          payload = {
            page_no: 1,
            convStatus: "assigned",
          };
        } else if (
          getValue(newPayload, `chatBotId`, "") &&
          getValue(newPayload, `managedBy`, "") === "chat-bot"
        ) {
          payload = {
            page_no: 1,
            convStatus: "un-assigned",
          };
        } else {
          payload = {
            page_no: 1,
            convStatus: "un-assigned",
          };
        }
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* ----------------------------  Whatsapp Specific Contactinfo data  --------------------- */

  const [m_page_no, setMPageNo] = useState(1);
  const [m_page_size, setMPageSize] = useState(100);
  const [m_total_count, setMTotalCount] = useState(0);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [conversation, setConversation] = useState<any[]>([]);
  const [selectedContact, setSelectedContact] = useState<any>({});
  const getSpecificConversation = async (
    status: boolean,
    id: string,
    page_no: any,
    type: string
  ) => {
    try {
      setConversationLoading(status);
      let payload = {
        page_no: page_no ? page_no : m_page_no,
        page_size: m_page_size,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSpecificWhatsappConversation(id, queryRequest);
      if (resp) {
        const newMessages = getValue(resp, `data.msgs`, []);
        if (type === "new") {
          setConversation(newMessages);
        } else {
          setConversation((prevConvo) => [...newMessages, ...prevConvo]);
        }
        setMTotalCount(getValue(resp, `data.total`, 0));
        setConversationLoading(false);
      } else {
        setConversationLoading(false);
      }
    } catch (error) {
      setConversationLoading(false);
    }
  };
  const getSpecificWebchatConversation = async (
    status: boolean,
    id: string,
    page_no: any,
    type: string
  ) => {
    try {
      setConversationLoading(status);
      let payload = {
        page_no: page_no ? page_no : m_page_no,
        page_size: m_page_size,
        webchat_id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSpecificWebChatInfo(queryRequest);
      if (resp) {
        const newMessages = getValue(resp, `data.conversations`, []);
        if (type === "new") {
          setConversation(newMessages);
        } else {
          setConversation((prevConvo) => [...newMessages, ...prevConvo]);
        }
        setMTotalCount(getValue(resp, `data.pagination.total`, 0));
        setConversationLoading(false);
      } else {
        setConversationLoading(false);
      }
    } catch (error) {
      setConversationLoading(false);
    }
  };
  const fetchMoreMessages = () => {
    if (!conversationLoading && conversation.length < m_total_count) {
      setMPageNo(m_page_no + 1); // Increment page number
      getSpecificConversation(false, selectedConversation, m_page_no + 1, ""); // Fetch more data
    }
  };

  const [selectedConversationInfo, setSelectedConversationInfo] = useState({});

  const handleRemovePipelineInfo = () => {
    setMPageNo(1);
    setConversation([]);
    setSelectedContact({});
    setStaticFieldRequest({
      ...staticFieldRequest,
      pipeline_id: "",
      stage: "",
    });
    setFormFields([]);
    setExpanded(false);
    setUserDetails(false);
    setContactInfo({});
  };

  const [currentChannelName, setCurrentChannelName] = useState<string>("");

  // useEffect to listen for messages based on currentChannelName
  useEffect(() => {
    if (!currentChannelName) return;
    console.log(currentChannelName);
    // Get existing pusher instance
    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe(currentChannelName);
    console.log(channel);
    // Set up listeners
    channel.bind("chat-message", (data: any) => {
      console.log("Received message:", data);
      setNewChatMessageInfo(data);
      // Handle other message logic
    });

    channel.bind("inbox-notification", (data: any) => {
      console.log("Received message:", data);
    });

    channel.bind("chat", (data: any) => {
      console.log("Received message:", data);
    });

    // Cleanup function
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(currentChannelName);
    };
  }, [currentChannelName]); // Re-run when channel name changes

  /* -------------------------------------------------------------------------- */
  /*                             Send Message section                           */
  /* -------------------------------------------------------------------------- */
  const [selectedModule, setSelectedModule] = useState("");
  const handleSelectConvesation = (item: any, status: boolean) => {
    setNewChatMessageInfo({});
    setNewChatStatusInfo({});
    setMessage("");
    setParentChage(true);
    if (selectedConversation !== getValue(item, `id`, "")) {
      setSelectedConversation(getValue(item, `id`, ""));
      if (getValue(item, `source`, "") === "webchat") {
        console.log(getValue(item, `channel_name`, ""), "------channel");
        setCurrentChannelName(getValue(item, `channel_name`, ""));
        setSelectedConversationInfo({
          ...item,
          last_name: getValue(item, `name`, ""),
          mobile_number: getValue(item, `phone`, ""),
        });

        if (selectedConversation !== getValue(item, `id`, "")) {
          setSelectedConversation(getValue(item, `id`, ""));
          getSpecificWebchatConversation(true, getValue(item, `id`, ""), 1, "");

          if (getValue(item, `unread_count`, "") > 0) {
            handleReadWebchatMessage(getValue(item, `id`, ""));
            getAllCount();
          }

          if (getValue(item, `contact_id`, "")) {
            setSelectedModule("contacts");
            getSpecificContactInfo(getValue(item, `contact_id`, ""));
          } else if (getValue(item, `lead_id`, "")) {
            setSelectedModule("leads");
            getSpecificLeadInfo(getValue(item, `lead_id`, ""));
          }
        }
      } else {
        setSelectedConversationInfo({
          ...item,
          last_name: getValue(item, `wa_profile_name`, ""),
          mobile_number: getValue(item, `wa_id`, ""),
        });
        if (
          getValue(item, `unread_count`, "") > 0 &&
          getValue(item, `last_msg_id`, "")
        ) {
          getSpecificConversation(true, getValue(item, `id`, ""), 1, "");
          handleReadWhatsappMessage(
            getValue(item, `id`, ""),
            getValue(item, `last_msg_id`, "")
          );
          handleClickConversation(item);
        } else {
          getSpecificConversation(true, getValue(item, `id`, ""), 1, "");
          if (getValue(item, `contact_id`, "")) {
            setSelectedModule("contacts");
            getSpecificContactInfo(getValue(item, `contact_id`, ""));
          } else if (getValue(item, `lead_id`, "")) {
            setSelectedModule("leads");
            getSpecificLeadInfo(getValue(item, `lead_id`, ""));
          }
        }
        getData(false, 1, "", conversationStatus);
      }
    }
  };

  const [parentChage, setParentChage] = useState(false);

  const webchatPayload = (message: string) => {
    return {
      channel_name: getValue(selectedConversationInfo, `channel_name`, ""),
      name: getValue(selectedConversationInfo, `org_user_id`, "")
        ? `${getValue(
            selectedConversationInfo,
            `org_user.first_name`,
            ""
          )}${" "}${getValue(
            selectedConversationInfo,
            `org_user.last_name`,
            ""
          )}`
        : `${getValue(profileInfo, `first_name`, "")}${" "}${getValue(
            profileInfo,
            `last_name`,
            ""
          )}`,
      webchat_id: getValue(selectedConversationInfo, `id`, ""),
      org_user_id: getValue(selectedConversationInfo, `org_user_id`, "")
        ? getValue(selectedConversationInfo, `org_user_id`, "")
        : getValue(profileInfo, `id`, ""),
      message: message,
    };
  };

  const handleSendMessages = async () => {
    if (message) {
      try {
        let resp;
        if (getValue(selectedConversationInfo, `source`, "") === "webchat") {
          resp = await createWebchatMessage(webchatPayload(message));
        } else {
          resp = await sendWhatsappConversation(
            selectedConversation,
            removeNullOrUndefinedProperties({
              msgType: "text",
              textBody: message,
              replyMsgId: getValue(selectedReplyMessage, `id`, ""),
            })
          );
        }
        if (resp) {
          setMessage("");
          setParentChage(true);
          setSelectedConversation(selectedConversation);
          // getData(false, 1, "", conversationStatus);
          if (getValue(selectedConversationInfo, `source`, "") === "webchat") {
            getSpecificWebchatConversation(
              false,
              selectedConversation,
              1,
              "new"
            );
          } else {
            getSpecificConversation(false, selectedConversation, 1, "new");
          }
        }
      } catch (error) {}
    }
  };
  const handleSendPrivateMessages = async () => {
    if (privateMessage) {
      try {
        let resp;
        if (getValue(selectedConversationInfo, `source`, "") === "webchat") {
          resp = await createWebchatMessage({
            ...webchatPayload(privateMessage),
            is_private: true,
          });
        } else {
          resp = await sendWhatsappConversation(
            selectedConversation,
            removeNullOrUndefinedProperties({
              msgType: "text",
              textBody: replacePlaceholders(privateMessage),
              replyMsgId: getValue(selectedReplyMessage, `id`, ""),
              is_private: true,
            })
          );
        }
        if (resp) {
          setPrivateMessage("");
          setParentChage(true);
          setSelectedConversation(selectedConversation);
          getData(false, 1, "", conversationStatus);
          if (getValue(selectedConversationInfo, `source`, "") === "webchat") {
            getSpecificWebchatConversation(
              false,
              selectedConversation,
              1,
              "new"
            );
          } else {
            getSpecificConversation(false, selectedConversation, 1, "new");
          }
        }
      } catch (error) {}
    }
  };
  const findType = (type: string) => {
    switch (type) {
      case ".png":
      case ".jpg":
      case ".jpeg":
      case ".svg":
        return "image";
      case ".mp4":
        return "video";
      case ".mp3":
        return "audio";
      case ".docx":
      case ".pdf":
        return "document";
      default:
        return "image";
    }
  };
  const [isSendLoading, setIsSendLoading] = useState(false);

  const handleSendDocumentMessages = async () => {
    setIsSendLoading(true);
    try {
      await Promise.all(
        documentRequest.map((item: object, index: number) => {
          return triggerAPI(
            {
              msgType: findType(getValue(item, `fileId.extension`, "")),
              textBody: getValue(item, `textBody`, ""),
              fileId: getValue(item, `fileId.id`, ""),
            },
            index
          );
        })
      );
    } catch (error) {
      console.error("Error sending document messages:", error);
    } finally {
      setIsSendLoading(false);
    }
  };
  const triggerAPI = async (obj: object, index: number) => {
    try {
      let resp = await sendWhatsappConversation(selectedConversation, obj);
      if (resp) {
        if (index == 0) {
          setDocumentRequest([]);
          setDocExpanded(false);
          getSpecificConversation(false, selectedConversation, 1, "new");
          setSelectedReplyMessage(null);
        }
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Chat section                             */
  /* -------------------------------------------------------------------------- */
  const emojiPickerRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event: any) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };
    // Attach the event listener on mount
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerRef]);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const ref = useRef<any>();
  const [message, setMessage] = useState<any>("");
  const [privateMessage, setPrivateMessage] = useState<any>("");

  const handleEmojiClick = (event: any, emojiObject: any) => {
    if (ref.current) {
      const cursor = ref.current.selectionStart;
      const newText =
        message.slice(0, cursor) + event.emoji + message.slice(cursor);
      setMessage(newText);
      toggleEmojiPicker();
    }
  };
  const handlePrivateEmojiClick = (event: any, emojiObject: any) => {
    if (ref.current) {
      const cursor = ref.current.selectionStart;
      const newText =
        message.slice(0, cursor) + event.emoji + message.slice(cursor);
      setPrivateMessage(newText);
      toggleEmojiPicker();
    }
  };
  const [documentRequest, setDocumentRequest] = useState<any>([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocument = (e: any) => {
    Array.from(e.target.files).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocExpanded(true);
          documentRequest.push({
            fileId: getValue(resp, `data`, {}),
          });
          setDocumentRequest([...documentRequest]);
          setUploadLoading(false);
        } else {
          setUploadLoading(false);
        }
      } catch (error) {
        setUploadLoading(false);
      }
    });
  };
  const [selectedReplyMessage, setSelectedReplyMessage] = useState<any>(null);
  const handleReadWhatsappMessage = async (
    conversationId: string,
    messageId: string
  ) => {
    try {
      let resp = await SpecificWhatsappConversationRead(
        conversationId
        // messageId
      );
      if (resp) {
        addSocketMessageStatusToChat(messageId, "is_read");
      }
    } catch (error) {}
  };

  const handleReadWebchatMessage = async (conversationId: string) => {
    try {
      let resp = await readWebchatStatus(conversationId);
      if (resp) {
        getData(false, 1, "", conversationStatus);
      }
    } catch (error) {}
  };

  const handleClickConversation = (item: any) => {
    // getAllCount();
    setSelectedConversation(getValue(item, `id`, ""));
    if (getValue(item, `contact_id`, "")) {
      setSelectedModule("contacts");
      getSpecificContactInfo(getValue(item, `contact_id`, ""));
    } else if (getValue(item, `lead_id`, "")) {
      setSelectedModule("leads");
      getSpecificLeadInfo(getValue(item, `lead_id`, ""));
    } else {
      setContactInfo({});
      setColumnsList([]);
      setColumnLabelInfo({});
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contact Section                           */
  /* -------------------------------------------------------------------------- */

  const [contactInfo, setContactInfo] = useState({});
  const [columnList, setColumnsList] = useState([]);
  const [columnLabelInfo, setColumnLabelInfo] = useState({});
  const getSpecificContactInfo = async (id: string) => {
    if (id)
      try {
        let resp = await getSpecificContact(id);
        if (resp) {
          setContactInfo(getValue(resp, `data`, {}));
          if (getValue(resp, `data.pipeline_id`, "")) {
            let columnsList = await getSpecificPipelineColumns(
              await selectedModuleId("contacts"),
              getValue(resp, `data.pipeline_id`, "")
            );
            setColumnsList(
              getValue(columnsList, `data`, []).map((item: object) =>
                getValue(item, `api_name`, "")
              )
            );

            const obj: any = {};
            getValue(columnsList, `data`, []).forEach(
              (value: object, index: number) => {
                obj[getValue(value, `api_name`, "")] = getValue(
                  value,
                  `label`,
                  ""
                );
              }
            );
            setColumnLabelInfo(obj);
          }
        }
      } catch (error) {}
  };
  const getSpecificLeadInfo = async (id: string) => {
    if (id)
      try {
        let resp = await getSpecificLead(id);
        if (resp) {
          setContactInfo(getValue(resp, `data`, {}));
          if (getValue(resp, `data.pipeline_id`, "")) {
            let columnsList = await getSpecificPipelineColumns(
              await selectedModuleId("leads"),
              getValue(resp, `data.pipeline_id`, "")
            );
            setColumnsList(
              getValue(columnsList, `data`, []).map((item: object) =>
                getValue(item, `api_name`, "")
              )
            );

            const obj: any = {};
            getValue(columnsList, `data`, []).forEach(
              (value: object, index: number) => {
                obj[getValue(value, `api_name`, "")] = getValue(
                  value,
                  `label`,
                  ""
                );
              }
            );
            setColumnLabelInfo(obj);
          }
        }
      } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                            Form Creation Section                           */
  /* -------------------------------------------------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = useState<any>({
    pipeline_id: "",
    stage: "",
  });
  const [options, setOptions] = useState([]);
  const [optionLoading, setOptionLoading] = useState(false);
  const getAllPipelines = async (module: string) => {
    try {
      setOptionLoading(true);
      let payload = {
        module_name: module,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(
        await selectedModuleId(module),
        queryRequest
      );
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        setStaticFieldRequest((prevState: any) => ({
          ...prevState,
          pipeline_id: getValue(list, `[${0}].id`, ""),
          stage: getValue(list, `[${0}].stage[${0}].id`, ""),
        }));
        getFormFields(getValue(list, `[${0}].id`, ""));
        //**----------------------- creating pipeline options---------------- */
        setOptions(list);
        setOptionLoading(false);
      } else {
        setOptionLoading(false);
      }
    } catch (error) {
      setOptionLoading(false);
    }
  };
  const [stages, setStages] = useState([]);
  const [formFields, setFormFields] = useState<any>([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const getFormFields = async (id: string) => {
    try {
      setIsFormLoading(true);
      let resp = await getSpecificPipeline(
        await selectedModuleId("contacts"),
        id
      );
      if (resp) {
        let stages =
          getValue(resp, `data.stages.length`, 0) > 0
            ? getValue(resp, `data.stages`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        setStaticFieldRequest({
          ...staticFieldRequest,
          pipeline_id: getValue(resp, `data.id`, ""),
          stage: getValue(stages, `[${0}].id`, ""),
        });
        setStages(stages);

        let fields =
          getValue(resp, `data.form_fields.length`, 0) > 0
            ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                ...getValue(item, `module_field`, {}),
                name: getValue(item, `api_name`, ""),
                quick_add: getValue(item, `quick_add`, ""),
                required: getValue(item, `required`, ""),
                seq_num: getValue(item, `seq_num`, ""),
              }))
            : [];
        for (const item of fields) {
          if (item.api_name === "owner_id") {
            item.value = userId;
          }
        }
        let array: any = appendObjectValuesToArray(
          fields,
          selectedConversationInfo
        );

        setFormFields(removeDuplicatesById(array));

        setIsFormLoading(false);
      } else {
        setIsFormLoading(false);
      }
    } catch (error) {
      setIsFormLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                              bottom chat Scroll                            */
  /* -------------------------------------------------------------------------- */
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    messagesEndRef.current?.scrollIntoView({ behavior: "auto", block: "end" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [getValue(conversation, `msgs`, [])]);

  /* -------------------------------------------------------------------------- */
  /*                                   Chat section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e: any) => {
    setQuery(e.target.value);
    let payload = {
      ...UrlParams,
      status: false,
      page_no: page_no,
      conversationId: selectedConversation,
      searchText: e.target.value,
      convStatus: conversationStatus,
      userId: "",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const maxLength = 260;

  const [userDetails, setUserDetails] = useState(false);
  const toggleUserDetails = (module: string) => {
    resetPipelineForm();
    setSelectedType(module);
    getAllPipelines(module);
    setUserDetails(!userDetails);
  };

  const handleAddContact = () => {
    // getAllPipelines(module)
    resetPipelineForm();
    handleToggleWidth();
    setUserDetails(false);
  };

  const resetPipelineForm = () => {
    setSelectedType("");
    setStaticFieldRequest({
      pipeline_id: "",
      stage: "",
    });
    setFormFields([]);
  };
  /* -------------------------------------------------------------------------- */
  /*                               OrgUsers section                             */
  /* -------------------------------------------------------------------------- */
  const [orgUsers, setOrgUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const getActiveUsersData = async () => {
    if (getValue(orgUsers, `length`, 0) === 0) {
      let payload = {
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      try {
        setLoading(true);
        let resp = await getOrganisationUsers(queryRequest);
        if (resp) {
          setOrgUsers(
            getValue(resp, `data.length`, 0) > 0 &&
              getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                name:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
                label:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
              }))
          );
          setTotalCount(getValue(resp, `data.pagination.total`, 0));
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const navigate = useNavigate();
  const handleClickFilter = (type: string, id: string) => {
    setContactInfo({});
    setSelectedContact({});
    setSelectedConversation("");
    delete UrlParams.conversationId;
    delete UrlParams.searchText;
    let payload = {
      ...UrlParams,

      page_no: 1,
      conversationId: "",
      searchText: "",
      convStatus: type,
      userId: id,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Whatsapp Channel                             */
  /* -------------------------------------------------------------------------- */
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const toggleFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
  };

  const [filterRequest, setFilterRequest] = useState({
    onlyUnread: false,
    waAccountIds: [],
    forOrgUser: "",
  });
  const [allConnectionList, setAllConnectionList] = useState([]);
  const getConnectedWhatsappList = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAvailableConfigList(queryRequest);
      if (resp) {
        if (getValue(resp, `data.length`, 0) > 0) {
          setIsWAConfigured(true);
          setAllConnectionList(
            getValue(resp, `data`, []).map((item: object) => ({
              ...item,
              label:
                getValue(item, `verifiedName`, "") +
                " ( " +
                getValue(item, `displayPhoneNumber`, "") +
                " )",
              value: getValue(item, `id`, ""),
            }))
          );
        }
      }
    } catch (error) {}
  };

  const handleApplyFilter = () => {
    if (getValue(filterRequest, `onlyUnread`, false)) {
      delete UrlParams.onlyUnread;
    }
    delete UrlParams["waAccountIds[]"];
    let payload = {
      ...UrlParams,
      waAccountIds: getValue(filterRequest, `waAccountIds`, []),
      onlyUnread: getValue(filterRequest, `onlyUnread`, false),
      forOrgUser: getValue(filterRequest, `forOrgUser`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    toggleFilterView();
  };

  const handleApplyUnReadFilter = (isUnread: boolean) => {
    setFilterRequest((prevRequest) => {
      const updatedFilter = {
        ...prevRequest,
        onlyUnread: isUnread,
      };

      let payload = {
        ...UrlParams,
        waAccountIds: getValue(updatedFilter, `waAccountIds`, []),
        onlyUnread: getValue(updatedFilter, `onlyUnread`, false),
        forOrgUser: getValue(updatedFilter, `forOrgUser`, ""),
      };

      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);

      return updatedFilter;
    });
  };

  const handleResetFilter = () => {
    setFilterRequest({
      ...filterRequest,
      onlyUnread: false,
    });
    setFilterRequest((prevState: any) => ({
      ...prevState,
      waAccountIds: [],
    }));
    delete UrlParams.onlyUnread;
    delete UrlParams.waAccountIds;
    delete UrlParams.forOrgUser;
    getData(true, page_no, query, conversationStatus);
    navigate(`/${getValue(params, "orgId", "")}/live-chat`);
    toggleFilterView();
  };

  const [chatbotList, setChatbotList] = useState([]);
  const getChatbotData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: 100,
        page_no: 1,
        platform: "WHATSAPP",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbots(queryRequest);
      if (resp) {
        setChatbotList(
          getValue(resp, `data.chatbots`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [selectedType, setSelectedType] = useState("");

  const isLeadShow = () => {
    let info = modules.find(
      (item: object) => getValue(item, `api_name`, "") === "leads"
    );
    return getValue(info, `api_name`, "");
  };

  const filterCount = useMemo(() => {
    return Object.entries(filterRequest).filter(
      ([key, value]) =>
        key !== "onlyUnread" &&
        (value === true || (typeof value === "string" && value))
    ).length;
  }, [filterRequest]);

  const [openCannedResponse, setOpenCannedResponse] = useState(false);
  const [cannedPopupPosition, setCannedPopupPosition] = useState({
    top: 0,
    left: 0,
  });
  const [triggerCannedResponseList, setTriggerCannedResponseList] =
    useState(true);
  const handleChangeEditor = (value: any) => {
    setParentChage(true);
    setMessage(value);
    // Detect if the first character is `/`
    // const content = editorState.getCurrentContent().getPlainText();
    const content = value;
    if (content.trimStart() === "/") {
      setTriggerCannedResponseList(true);
      // Get cursor position for the popup
      const editorBounds = ref.current?.getBoundingClientRect();
      const calculatedPosition = getCursorPosition(editorBounds);
      setCannedPopupPosition(calculatedPosition);
      setOpenCannedResponse(true); // Show the popover
    } else {
      setOpenCannedResponse(false); // Hide the popover
    }
  };

  const handleCannedEditorButton = () => {
    setOpenCannedResponse((prevState) => {
      const newState = !prevState;
      if (newState) {
        setTriggerCannedResponseList(true);
      }
      return newState;
    });
  };

  const getCursorPosition = (editorBounds: any) => {
    const { top, left } = editorBounds;
    return {
      top: top + 20,
      left: left + 20,
    };
  };

  const handleCannedOpen = (newOpen: boolean) => {
    setOpenCannedResponse(newOpen);
  };

  const handleChangePrivateEditor = (value: string) => {
    setPrivateMessage(value);
  };

  return (
    <MainLayout {...props} isLoading={isLoading}>
      <>
        {isWAConfigured === false ? (
          <div className="d-flex flex-column manageFieldRight justify-content-center">
            {isLoading ? (
              <ListLoader />
            ) : (
              <InboxNoDataPage isLoading={isLoading} />
            )}
          </div>
        ) : (
          <div className="chatbox-wrapper">
            <div className="chatbox-container">
              <div className="chatbox-left-container">
                <div className="contact-search-wrapper">
                  <ChatSearchHeader
                    handleChangeSearch={handleChangeSearch}
                    toggleFilterView={toggleFilterView}
                    filterCount={filterCount}
                    handleApplyUnReadFilter={handleApplyUnReadFilter}
                    filterRequest={filterRequest}
                  />
                  <ChatFilter
                    handleClickFilter={handleClickFilter}
                    UrlParams={UrlParams}
                    userId={userId}
                    unreadCount={unreadCount}
                    unassignedCount={unAssigned}
                    assignedCount={assigned}
                    assignedMe={assignedMe}
                    resolvedCount={resolved}
                    botChatCount={assignedChatbot}
                  />
                </div>
                <InboxContacts
                  isLoading={isLoading}
                  conversations={conversations}
                  selectedConversation={selectedConversation}
                  handleSelectConvesation={handleSelectConvesation}
                  handleRemovePipelineInfo={handleRemovePipelineInfo}
                  query={query}
                />
                {totalCount > 100 && (
                  <Pagination
                    totalCount={totalCount}
                    limit={page_size}
                    page_no={page_no}
                    handleChangePagination={handleChangePagination}
                    showLimit={"false"}
                    // handleChangeLimit={handleChangeLimit}
                  />
                )}
              </div>
              <div className="chatbox-center-container">
                {selectedConversation ? (
                  <div className="conversation-wrapper">
                    <div className="conversation-section">
                      <ChatRightHeader
                        handleToggleWidth={handleToggleWidth}
                        selectedContact={selectedContact}
                        selectedConversationInfo={selectedConversationInfo}
                        orgUsers={orgUsers}
                        getData={getDataStatusRefetch}
                        handleClickFilter={handleClickFilter}
                        chatbotList={chatbotList}
                      />
                      <div className="message-section">
                        {conversationLoading ? (
                          <Loader small />
                        ) : (
                          <ConversationScroll
                            conversation={conversation}
                            fetchMoreMessages={fetchMoreMessages}
                            m_total_count={m_total_count}
                            conversationLoading={conversationLoading}
                            setSelectedReplyMessage={setSelectedReplyMessage}
                            selectedContact={selectedContact}
                            contactInfo={contactInfo}
                            handleAddContact={handleAddContact}
                            isLeadShow={isLeadShow}
                            selectedModule={selectedModule}
                            messagesEndRef={messagesEndRef}
                          />
                        )}
                        <ConversationReply
                          selectedReplyMessage={selectedReplyMessage}
                          maxLength={maxLength}
                          setSelectedReplyMessage={setSelectedReplyMessage}
                        />
                        <ChatFooter
                          showEmojiPicker={showEmojiPicker}
                          handleEmojiClick={handleEmojiClick}
                          toggleEmojiPicker={toggleEmojiPicker}
                          emojiPickerRef={emojiPickerRef}
                          message={message}
                          setMessage={setMessage}
                          privateMessage={privateMessage}
                          setPrivateMessage={setPrivateMessage}
                          handlePrivateEmojiClick={handlePrivateEmojiClick}
                          reference={ref}
                          parentChage={parentChage}
                          setParentChage={setParentChage}
                          handleSendMessages={handleSendMessages}
                          handleUploadDocument={handleUploadDocument}
                          selectedConversationInfo={selectedConversationInfo}
                          handleChangeEditor={handleChangeEditor}
                          handleChangePrivateEditor={handleChangePrivateEditor}
                          handleSendPrivateMessages={handleSendPrivateMessages}
                          //Canned response
                          openCannedResponse={openCannedResponse}
                          setOpenCannedResponse={setOpenCannedResponse}
                          handleCannedOpen={handleCannedOpen}
                          handleCannedEditorButton={handleCannedEditorButton}
                          cannedPopupPosition={cannedPopupPosition}
                          contentLength={getValue(message, "length", "")}
                          privateContentLength={getValue(
                            privateMessage,
                            "length",
                            ""
                          )}
                          triggerCannedResponseList={triggerCannedResponseList}
                          setTriggerCannedResponseList={
                            setTriggerCannedResponseList
                          }
                        />
                      </div>
                    </div>

                    <div
                      className={`document-review-section  ${
                        docExpanded ? "expanded" : ""
                      }`}
                    >
                      <div className={`${docExpanded ? "show" : "hidden"}`}>
                        <ChatDocumentPreview
                          showEmojiPicker={showEmojiPicker}
                          handleEmojiClick={handleEmojiClick}
                          toggleEmojiPicker={toggleEmojiPicker}
                          message={message}
                          setMessage={setMessage}
                          reference={ref}
                          handleSendMessages={handleSendMessages}
                          setDocExpanded={setDocExpanded}
                          docExpanded={docExpanded}
                          handleUploadDocument={handleUploadDocument}
                          documentRequest={documentRequest}
                          setDocumentRequest={setDocumentRequest}
                          handleSendDocumentMessages={
                            handleSendDocumentMessages
                          }
                          isSendLoading={isSendLoading}
                        />
                      </div>
                    </div>
                    <motion.div
                      key="otherComponent"
                      initial={{ x: "100%", opacity: 1 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: "-100%", opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      className={`profile-section ${
                        expanded ? "expanded" : ""
                      }`}
                    >
                      <div className={`${expanded ? "show" : "hidden"}`}>
                        <ChatContactInfo
                          handleToggleWidth={handleToggleWidth}
                          contactInfo={contactInfo}
                          columnList={columnList}
                          columnLabelInfo={columnLabelInfo}
                          allPipelines={options}
                          stages={stages}
                          fields={formFields}
                          selectedConversation={getValue(
                            selectedConversationInfo,
                            `id`,
                            ""
                          )}
                          setFields={setFormFields}
                          getFormFields={getFormFields}
                          isFormLoading={isFormLoading}
                          staticFieldRequest={staticFieldRequest}
                          setStaticFieldRequest={setStaticFieldRequest}
                          selectedContact={selectedContact}
                          userDetails={userDetails}
                          toggleUserDetails={toggleUserDetails}
                          setSelectedType={setSelectedType}
                          selectedType={selectedType}
                          selectedModule={selectedModule}
                          getSpecificLeadInfo={getSpecificLeadInfo}
                          getSpecificContactInfo={getSpecificContactInfo}
                          isLeadShow={isLeadShow}
                          permissions={getValue(props, `permissions`, [])}
                          selectedConversationInfo={selectedConversationInfo}
                        />
                      </div>
                    </motion.div>
                  </div>
                ) : (
                  <div className="no-conversation">
                    <img
                      src="/nodata/messages.svg"
                      className="whatsapp_no"
                      alt=""
                    />
                    <h4 className="mt-3">No Conversation Selected</h4>
                    <p>Select a conversation to read</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
      <WaAdvancefilter
        isOpenFilterView={isOpenFilterView}
        toggleFilterView={toggleFilterView}
        allConnectionList={allConnectionList}
        filterRequest={filterRequest}
        setFilterRequest={setFilterRequest}
        handleResetFilter={handleResetFilter}
        handleApplyFilter={handleApplyFilter}
        UrlParams={UrlParams}
      />
    </MainLayout>
  );
}

export default Messages;
