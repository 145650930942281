import React, { useEffect, useState } from "react";
import "./TaskContacts.scss";
import { getValue } from "@utils/lodash";
import TaskDeatilContactList from "./ContactList";
import SimpleReactValidator from "simple-react-validator";
import { useParams } from "react-router-dom";
import { createSubPipeline } from "@services/common.service";
import { toast } from "sonner";
import DescriptionInfo from "../common/DescInfo";
import { generatePipelineAssociationPayload } from "@components/helpers/request-helper";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { handleFormValidation } from "@components/custom/validation";
import PendingTaskCommonTitle from "../common/PendingTaskCommonTitle/PendingTaskCommonTitle";
import PendingTaskCommonDescription from "../common/PendingTaskCommonDescription/PendingTaskCommonDescription";
import AddInfoPopup from "../common/AddPopup/AddInfoPopup";
import TaskPendingLayout from "../../components/TaskPendingLayout/TaskPendingLayout";

export default function TaskContacts(props: any) {
  const [isActive1, setIsActive1] = useState(false);
  useEffect(() => {
    if (getValue(props, `data.contacts.length`, 0) > 0) {
      setIsActive1(true);
    }
  }, [getValue(props, `data.contacts`, [])]);
  const [isRollsPopupVisibile, setRollsPopupVisisbility] = useState(false);
  const handleOpenForm = () => {
    props.getForm("contacts");
    setRollsPopupVisisbility(true);
  };

  const [isRollsListPopupVisibile, setRollsListPopupVisisbility] =
    useState(false);
  //delete section
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDeleteView = (id: string, value: string) => {
    setDeleteId(id);
    setDeleteValue(value);
    handleModal();
  };

  return (
    <>
      <TaskPendingLayout>
        {/* <PendingTaskCommonTitle
          commonTitle="Contacts"
          count={
            getValue(props, `data.contacts.length`, 0) < 9 &&
            getValue(props, `data.contacts.length`, 0) !== 0
              ? `${getValue(props, `data.contacts.length`, 0)}`
              : getValue(props, `data.contacts.length`, 0)
          }
          isLinkVisible={true}
          handleToggle={() => {
            setIsActive1(!isActive1);
          }}
          triggerAddPopupSlidingFromRight={handleOpenForm}
        /> */}
        <div className={`showdiv`}>
          <PendingTaskCommonDescription description="See the people associated with this record." />
          <div>
            <ul>
              {getValue(props, `data.contacts`, []).map(
                (item: object, index: number) => {
                  if (index < 2)
                    return (
                      <DescriptionInfo
                        item={item}
                        index={index}
                        handleDeleteView={handleDeleteView}
                        module={"contacts"}
                      />
                    );
                }
              )}
            </ul>
            {getValue(props, `data.contacts.length`, 0) > 2 ? (
              <p
                className={"pending-task_more_info_text"}
                onClick={() => setRollsListPopupVisisbility(true)}
              >
                View More Contacts
              </p>
            ) : null}
          </div>
        </div>
      </TaskPendingLayout>
      {/* ----------------- Contact Form List ------------------ */}
      <TaskDeatilContactList
        isRollsListPopupVisibile={isRollsListPopupVisibile}
        setRollsListPopupVisisbility={setRollsListPopupVisisbility}
        contacts={getValue(props, `data.contacts`, [])}
        handleDeleteView={handleDeleteView}
      />
      {/* ----------------- Contact Form Popup ------------------ */}

      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={() => {
          props.handleDisAssociate(deleteId, "contacts");
          setTimeout(() => {
            handleModal();
          }, 300);
        }}
        deleteValue={deleteValue}
      />
    </>
  );
}
