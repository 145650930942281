import React, { memo, useEffect } from "react";
import { Input, Button, Table, Switch } from "antd";
import { getValue } from "@utils/lodash";

const CaAPIParameterPair = ({ request, setRequest }: any) => {
  const addPair = () => {
    const newPair = { key: "", value: "" };
    const currentInputs = getValue(request, "config.headers", []);
    setRequest((prev: any) => ({
      ...prev,
      config: {
        ...prev.config,
        headers: [...currentInputs, newPair],
      },
    }));
  };

  const updatePair = (index: any, field: any, value: any) => {
    const currentInputs = getValue(request, "config.headers", []);
    const updatedInputs = currentInputs.map((input: any, i: any) =>
      i === index ? { ...input, [field]: value } : input
    );

    setRequest((prev: any) => ({
      ...prev,
      config: {
        ...prev.config,
        headers: updatedInputs,
      },
    }));
  };

  const deletePair = (index: any) => {
    const currentInputs = getValue(request, "config.headers", []);
    const updatedInputs = currentInputs.filter((_: any, i: any) => i !== index);
    setRequest((prev: any) => ({
      ...prev,
      config: {
        ...prev.config,
        headers: updatedInputs,
      },
    }));
  };

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      render: (_text: any, record: any, index: any) => (
        <Input
          placeholder="Key"
          value={record.key}
          onChange={(e) => updatePair(index, "key", e.target.value)}
        />
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (_text: any, record: any, index: any) => (
        <Input
          placeholder="Value"
          value={record.value}
          onChange={(e) => updatePair(index, "value", e.target.value)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text: any, record: any, index: any) => (
        <Button type="link" onClick={() => deletePair(index)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className="p-4">
      <Table
        dataSource={getValue(request, "config.headers", [])}
        columns={columns}
        pagination={false}
        rowKey={(_: any, index: any) => index}
      />
      <Button type="dashed" onClick={addPair} className="mt-4">
        + Add header
      </Button>
    </div>
  );
};

export default memo(CaAPIParameterPair);
