import {
  companiesSearchableDropdown,
  contactsSearchableDropdown,
  emailTemplatesSearchableDropdown,
  groupsSearchableDropdown,
  prioritiesSearchableDropdown,
  productsSearchableDropdown,
  // productsSearchableDropdown,
  stageSearchableDropdown,
  tagsSearchableDropdown,
  usersEmailSearchableDropdown,
  usersSearchableDropdown,
  whatsappTemplatesSearchableDropdown,
} from "./lookup-apis-request";

export const getLookupAPIs = (value: string, queryRequest: string) => {
  let stageReq = value.includes("stage");
  switch (stageReq ? "stage" : value) {
    case "org_user":
    case "org_users":
      return usersSearchableDropdown(queryRequest);
    case "org_user_mail":
      return usersEmailSearchableDropdown(queryRequest);
    case "contacts":
      return contactsSearchableDropdown(queryRequest);
    case "companies":
      return companiesSearchableDropdown(queryRequest);
    case "email_templates":
      return emailTemplatesSearchableDropdown(queryRequest);
    case "templates":
      return whatsappTemplatesSearchableDropdown(queryRequest);
    case "stage":
      return stageSearchableDropdown(value, queryRequest);
    case "tags":
      return tagsSearchableDropdown(queryRequest);
    case "priority":
      return prioritiesSearchableDropdown(queryRequest);
    case "group":
      return groupsSearchableDropdown(queryRequest);
    case "product":
      return productsSearchableDropdown(queryRequest);

    default:
      return null;
  }
};
