export const businessHoursDetails = [
  {
    heading: "Business hours",
    description: `Business Hours give you more control over SLAs in your helpdesk, and when a ticket is due. For example, if your helpdesk works between 9am to 6pm Mon-Fri and a customer logs a ticket at 7pm on Tuesday, the "Due by" timers do not start ticking till Wednesday morning at 9.`,
  },
  {
    heading: "Holidays",
    description:
      "Holidays work exactly like business hours. If your helpdesk works between 9am to 6pm Mon-Fri and a customer sends a medium priority ticket at 8pm on Friday with a resolution time of 24 hours, then the ticket Due By time is set as 9am on Tuesday.(not 8pm on Sat)",
  },
  {
    heading: "Multiple Business Hours",
    description:
      "You can also create multiple sets of business hours and holidays, and apply them for specific groups. If you have one support team working on PST and another on GMT, you can let each group have its own set of business hours and holidays.",
  },
];

export const slaPolicyDetails = [
  {
    heading: "SLA policy",
    description: `A service level agreement (SLA) policy lets you set standards of performance for your support team. You can set SLA policies for the time within which agents should respond to, and resolve tickets based on ticket priorities. You can choose whether you want each SLA rule to be calculated over calendar hours or your business hours. Your SLA Policies will be used in Appzo to determine the “Due By” time for each ticket.`,
  },
  {
    heading: "Using Multiple SLA Policies",
    points: [
      `You can have multiple SLA policies, triggered by specific conditions like the customer who requested the ticket, the group it is assigned to, its source and/or product.`,
      `<b><i>The order of your SLA policies is important.</i></b>`,
      `The first SLA Policy that matches all conditions for a ticket will be applied to it, so remember to order your important rules closer to the top`,
    ],
  },
  {
    heading: "SLA reminders",
    points: [
      "You can set up reminders to make sure that agents are notified about the approaching due by time for tickets. The reminders can be for ticket response and resolution.",
      "The reminder email can contain contextual information like the URL of the ticket and ticket priority and can be configured in Admin > Email Notifications.",
    ],
  },
  {
    heading: "SLA violation notifications",
    points: [
      "You can also set up escalation rules that notify agents or managers when SLAs have been violated. You can set up multiple levels of escalation for resolution SLA.",
      "The violation emails can be configured in Admin > Email Notifications.",
    ],
  },
];

export const groupsDetails = [
  {
    heading: "Groups",
    description: `You can organize your agents into specific Groups like “Support” and “Sales”. This lets you easily assign tickets, create specific canned responses, manage workflows and generate group-level reports. Note that an agent can be a member of multiple groups.`,
  },
  {
    heading: "Business Hours",
    description: `You can associate different business hours to each of these groups based on their working hours. You can group agents by shifts and assign different business hours to each group, or create separate groups for each location that your agents work from.`,
  },
  {
    heading: "Automatic ticket assignment",
  },
  {
    subHeading: "To each group",
    description: `Once you’ve created your groups, you can set up rules in dispatch’r to automatically route tickets to each group`,
  },
  {
    subHeading: "To agents within group",
    description:
      "You can also auto-assign tickets to online agents within a group by three methods:",
    numberedPoints: [
      "Round robin - Assign tickets to agents in a circular fashion",
      "Load balanced - Set a limit on the number of tickets that are assigned to each agent",
      // "Skill based - Assign tickets to agents based on their expertise",
    ],
  },
];

export const agentShiftDetails = [
  {
    heading: "Agents",
    points: [
      `Full time agents are those in your support team who will login to your help desk every day.`,
      `Occasional agents are those who would need to use your Appzo infrequently. You will need to purchase a day pass for their access to Appzo.`,
    ],
  },
  {
    heading: "Collaborators",
    points: [
      `Collaborators can be people in your organization or third party contacts whose input is needed to resolve a customer ticket. They will have restricted access to Appzo.`,
      `Ticket collaborators can be assigned/tagged to tickets, and can add or edit private notes, update ticket status, modify their time entries, and view related customer information.`,
      `Analytics collaborators can view reports in the Analytics module.`,
    ],
  },
  {
    heading: "Roles and Scope",
    points: [
      `Roles determine the features an agent can access. Every role has a set of privileges that grant access to features in your Appzo. You can choose to create custom roles with different privileges.`,
      `Scope determines the tickets that an agent can view and edit. You can use 'advanced scope' to give an agent edit access to tickets in some groups and view access to tickets in other groups.`,
    ],
  },
  {
    heading: "Groups",
    description: `You can organize agents into specific groups such as Sales and Product Management. Segmenting agents into divisions lets you easily assign tickets, create specific canned responses, manage workflows and generate group-level reports. Note that an agent can be a member of multiple groups.`,
  },
];

export const productsDetails = [
  {
    heading: "Multiple Products",
    points: [
      `You can support multiple products with separate branded portals from a single Appzo account. Configure separate support emails for each product and automatically queue them in a specific group.`,
      `Configure portals for these products ​by clicking on the Admin tab and then 'Portals​'​`,
    ],
  },
  {
    heading: "Configuring a separate portal for products",
    description: `If you choose to configure a separate branded portal for each product, you can provide a unique set of Knowledge Base articles and Forums for each brand.`,
  },
];

export const skillsDetails = [
  {
    heading: "Skills",
    description: `With skill-based routing, tickets get automatically assigned to the agent that’s best skilled to handle them. In addition, you can ensure that requests with certain skills are always prioritized for routing over the rest.`,
  },
  {
    heading: "Creating Skills",
  },
  {
    subHeading: "Adding a skill to a request:",
    description: `Skill rules determine the skill that should be associated with a ticket. As an example, you can create a rule that assigns the skill “French” to requests from French-speaking customers. The first matching skill in the list is applied to the ticket.`,
  },
  {
    subHeading: "Associating agents to skills:",
    description: `Agents can be associated with a list of skills, in the right order, based on their proficiency. When a new ticket is created, agents with the first skill as that of the ticket are considered for assignment. If these agents are unavailable, agents with the second skill that matches that of the ticket are considered for assignment. This continues until the request is assigned to an agent with a matching skill.`,
  },
  {
    heading: "Prioritizing tickets",
    description: `Tickets with the first matching skill of an agent are always prioritized for routing over the rest of the unassigned tickets in the queue. For example, say an agent’s skill is ‘VIP customers’. Every time the agent resolves a request, requests with the skill ‘VIP customers’ are assigned, even if there are older tickets with different skills in the queue.`,
  },
];

// export const groupsDetails=[
//   {
//     heading: "",
//     description: ``
//   },
//   {
//     heading: "",
//     description: ``
//   }
// ]
