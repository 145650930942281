import PendingLoanComponent from "./components/PendingLoanComponent/PendingLoanComponent";
import PendingDealComponent from "./components/PendingDealComponent/PendingDealComponent";
import "./TicketsDetailLeft.scss";
import { Badge, Collapse, CollapseProps } from "antd";
import { getValue } from "@utils/lodash";
import TaskContacts from "../right-container/TaskContacts/TaskContacts";
import AddInfoPopup from "../right-container/common/AddPopup/AddInfoPopup";
import { createSubPipeline } from "@services/common.service";
import { toast } from "sonner";
import { generatePipelineAssociationPayload } from "@components/helpers/request-helper";
import SimpleReactValidator from "simple-react-validator";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { handleFormValidation } from "@components/custom/validation";
import { PlusCircle } from "lucide-react";

function TicketsDetailRightPage(props: any) {
  const [isRollsPopupVisibile, setRollsPopupVisisbility] = useState(false);

  const handleOpenForm = () => {
    props.getForm("contacts");
    setRollsPopupVisisbility(true);
  };

  /* -------------------------------------------------------------------------- */
  /*                     Generating Contact Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidatorContact = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const params = useParams();
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const handleSubmit = async (module: string) => {
    if (module) {
      // const formValid = simpleValidator.current.allValid();
      // if (!formValid) {
      //   simpleValidator.current.showMessages();
      //   forceUpdate(1);
      // } else {
      let fields = JSON.parse(JSON.stringify(props.form));
      let associations = [
        { api_name: props.module, record_id: getValue(params, `id`, "") },
      ];
      let payloadRequest = generatePipelineAssociationPayload(
        module,
        fields,
        props.staticFieldRequest,
        associations
      );
      try {
        setFormSubmitLoading(true);
        let resp = await createSubPipeline(payloadRequest, module);
        if (resp) {
          toast.success("Created & Associated Successfully");
          props.getData(false);
          simpleValidatorContact.current.hideMessages();
          forceUpdate(0);
          setRollsPopupVisisbility(false);
          setFormSubmitLoading(false);
        } else {
          setFormSubmitLoading(false);
        }
      } catch (error) {
        setFormSubmitLoading(false);
      }
    }
    // }
  };

  const errors = handleFormValidation(props.form);
  const hasErrors = errors.length > 0;
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "About this ticket",
      children: <PendingDealComponent {...props} />,
    },
    {
      key: "2",
      label: (
        <div className="d-flex justify-content-between gap-2 align-items-center">
          <div className="d-flex gap-2 align-items-center">
            Contacts
            <Badge
              count={
                getValue(props, `data.contacts.length`, 0) < 9 &&
                getValue(props, `data.contacts.length`, 0) !== 0
                  ? `${getValue(props, `data.contacts.length`, 0)}`
                  : getValue(props, `data.contacts.length`, 0)
              }
              className="ms-1"
            ></Badge>
          </div>
          <PlusCircle size={18} onClick={handleOpenForm} />
        </div>
      ),
      children: <TaskContacts {...props} create_module={"contacts"} />,
    },
  ];

  return (
    <div className={`pendingticket-left_container ${props.className}`}>
      <PendingLoanComponent {...props} />
      <Collapse
        items={items}
        // defaultActiveKey={["1"]}
        className="ticket-right-side-collapse"
      />

      <AddInfoPopup
        isOpen={isRollsPopupVisibile}
        setIsOpen={setRollsPopupVisisbility}
        title="Add Contacts"
        width={"590px"}
        form={props.form}
        setForm={props.setForm}
        formLoading={props.formLoading}
        //submit section
        handleSubmit={handleSubmit}
        formSubmitLoading={formSubmitLoading}
        simpleValidator={props.simpleValidator}
        handleAssociate={props.handleAssociate}
        handleDisAssociate={props.handleDisAssociate}
        //static List
        module={"contacts"}
        pipelineList={props.pipelineList}
        stageList={props.stageList}
        staticFieldRequest={props.staticFieldRequest}
        setStaticFieldRequest={props.setStaticFieldRequest}
        getSpecificPipelineInfo={props.getSpecificPipelineInfo}
        selectedList={
          getValue(props, `data.contacts.length`, 0) > 0
            ? getValue(props, `data.contacts`, []).map((item: object) =>
                getValue(item, `id`, "")
              )
            : []
        }
        disabled={hasErrors ? true : false}
        disableValidation
        permissions={getValue(props, `permissions`, [])}
      />
    </div>
  );
}

export default TicketsDetailRightPage;
