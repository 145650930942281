import React, { useState } from "react";
import { Input, Button, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";

const JsonEditor = ({ request, setRequest }: any) => {
  const [json, setJson] = useState(``);
  const [variables, setVariables] = useState<any>([]);

  const addVariable = () => {
    const newVariable = `{{new_variable_${variables.length + 1}}}`;
    setVariables([...variables, newVariable]);
    setJson((prevJson) => prevJson + `\n"${newVariable}": ""`);
  };

  const handleChange = (e: any) => {
    setRequest((prev: any) => ({
      ...prev,
      config: {
        ...prev.config,
        body: e.target.value,
      },
    }));
  };

  return (
    <div style={{ padding: "20px" }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Input.TextArea
          rows={10}
          value={getValue(request, `config.body`, "")}
          onChange={handleChange}
          placeholder={`{
            "name": "John Doe",
            "email": "{{email}}",
            "message": "Hello world!"
          }`}
          style={{ fontFamily: "monospace" }}
        />
        {/* <Button type="dashed" icon={<EditOutlined />} onClick={addVariable}>
          Add variable
        </Button> */}
      </Space>
    </div>
  );
};

export default JsonEditor;
