import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllPriorities = (queryRequest: string) =>
  get(`${config.API_URL}/priorities?${queryRequest}`);

export const getSpecificPriority = (id: string) =>
  get(`${config.API_URL}/priorities/${id}`);

export const getPrioritiesList = (queryRequest: string) =>
  get(`${config.API_URL}/priorities/list?${queryRequest}`);

export const createPriority = (payload: object) =>
  post(`${config.API_URL}/priorities`, payload);

export const updatePriority = (id: string, payload: object) =>
  patch(`${config.API_URL}/priorities/${id}`, payload);

export const deletePriority = (id: string) =>
  Delete(`${config.API_URL}/priorities/${id}`);
