import SLAPolicy from "@pages/Private/Settings/SLAPolicy";
import CreateSLAPolicy from "@pages/Private/Settings/SLAPolicy/CreateSLAPolicy";

export const SettingsSLAPolicyRoutes = [
  {
    path: "/:orgId/settings/helpdesk/sla-policy",
    name: "settings_templates_business_hours",
    component: SLAPolicy,
  },
  {
    path: "/:orgId/settings/helpdesk/sla-policy/create",
    name: "settings_templates_business_hours",
    component: CreateSLAPolicy,
  },
  {
    path: "/:orgId/settings/helpdesk/sla-policy/edit/:id",
    name: "settings_templates_business_hours",
    component: CreateSLAPolicy,
  },
];
