import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { getValue } from "@utils/lodash";
import { Alert, Button, Dropdown } from "antd";
import { Download } from "lucide-react";

const BroadcastContactsUpload = (props: any) => {
  return (
    <div>
      <div className="mb-3 mx-xxl-5 px-xxl-5 ">
        <h6 className="header_text__20 d-flex align-items-center gap-3">
          <button
            onClick={props.toggleContactUploadStep}
            className={` dashboard-button p-0`}
          >
            <img
              src="/images/icons/back-icon.svg"
              width={24}
              height={24}
              alt="Back"
            />
          </button>
          Upload Contacts from Excel
        </h6>
        <p className="small_text__14 ps-4 ms-3">
          Name and Phone numbers ( with country code, e.g; +91) are mandatory
          fields
        </p>
      </div>

      <div
        className="mx-auto mt-3"
        style={{
          maxWidth: "900px",
        }}
      >
        <div>
          <div className="d-flex justify-content-end mt-2">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: "With Country Code",
                    onClick: () => props.handleGenerateDownloadURL(true),
                  },
                  {
                    key: "2",
                    label: "Without Country Code",
                    onClick: () => props.handleGenerateDownloadURL(false),
                  },
                ],
              }}
            >
              <Button icon={<Download size={16} />}>Download Template</Button>
            </Dropdown>
          </div>
        </div>

        <div className="mt-3">
          <DragAndDropFileComponent
            uploadFile={props.handleUploadDocuments}
            name="file"
            classes="bulk-upload_drag-n-drop-wrapper position-absolute"
            fileName={
              getValue(props, `request.contactUploadId`, "")
                ? getValue(props, `uploadedFileInfo.filename`, "")
                : ""
            }
            setImportTedFile={() =>
              props.setRequest((prevState: any) => ({
                ...prevState,
                contactUploadId: "",
              }))
            }
            isLoading={props.uploadLoading}
            supportedFormats=".csv, max 5MB"
          />
          {getValue(props, "request.contactUploadId", "") && (
            <div className="d-flex justify-content-center mt-4">
              <Alert
                message="Map the uploaded file with the template in next step."
                type="info"
                // showIcon
              />
            </div>
          )}
          {getValue(
            props,
            `request.variableConfig.fileTags[${0}].numOfRows`,
            0
          ) > 0 && (
            <div className="d-flex justify-content-center mt-4">
              <Alert
                message={`${getValue(
                  props,
                  `request.variableConfig.fileTags[${0}].numOfRows`,
                  0
                )} Contacts Uploaded`}
                type="info"
                // showIcon
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BroadcastContactsUpload;
