import MainLayout from "@layouts/HomeLayout/NewLayout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { Alert, Button, Modal, Select, Tabs, TabsProps } from "antd";
import { getValue } from "@utils/lodash";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import BackButton from "@components/custom/BrowserBack";
import InputRuleForm from "@components/InputRuleForm/form";
import { handleSelectChange, handleTextChange } from "@common/handleChange";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import timezones from "@common/json/standardized-timezone-data.json";
import AgentShiftHours from "./components/AgentShiftHours";
import {
  createAgentShift,
  getSpecificShift,
  getSpecificShiftAgents,
  updateAgentShift,
} from "@services/agent-shifts.service";
import { toast } from "sonner";
import ShiftAgents from "./components/ShiftAgents";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { DeleteOutlined } from "@ant-design/icons";

const days = [
  { value: "sunday", label: "Sunday", shortened: "Sun" },
  { value: "monday", label: "Monday", shortened: "Mon" },
  { value: "tuesday", label: "Tuesday", shortened: "Tue" },
  { value: "wednesday", label: "Wednesday", shortened: "Wed" },
  { value: "thursday", label: "Thursday", shortened: "Thu" },
  { value: "friday", label: "Friday", shortened: "Fri" },
  { value: "saturday", label: "Saturday", shortened: "Sat" },
];

const CreateAgentShift = ({ props }: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const [request, setRequest] = useState({
    name: "",
    time_zone: "",
    work_days: [
      {
        day: "monday",
        start_time: "08:00",
        end_time: "17:00",
      },
      {
        day: "tuesday",
        start_time: "08:00",
        end_time: "17:00",
      },
      {
        day: "wednesday",
        start_time: "08:00",
        end_time: "17:00",
      },
      {
        day: "thursday",
        start_time: "08:00",
        end_time: "17:00",
      },
      {
        day: "friday",
        start_time: "08:00",
        end_time: "17:00",
      },
    ],
    agents: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [editId, seteditId] = useState("");
  const [agents, setAgents] = useState([]);
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  const [currentTab, setCurrentTab] = useState("1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agentsData, setAgentsData] = useState({
    agents: [],
  });

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(params, "id", "")) {
      seteditId(getValue(params, "id", ""));
    }
  }, [params]);

  useEffect(() => {
    if (editId) {
      getShift(true);
      getAllAgents(editId);
    }
  }, [editId]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = async (refresh?: any) => {
    setIsLoading(true);
    try {
      let payload = JSON.parse(JSON.stringify(request));
      const resp = editId
        ? await updateAgentShift(editId, payload)
        : await createAgentShift(payload);

      if (resp) {
        toast.success(
          `${refresh ? "Added" : editId ? "Updated" : "Created"} Successfully`
        );

        setRequest({
          name: "",
          time_zone: "",
          work_days: [
            {
              day: "monday",
              start_time: "08:00",
              end_time: "17:00",
            },
            {
              day: "tuesday",
              start_time: "08:00",
              end_time: "17:00",
            },
            {
              day: "wednesday",
              start_time: "08:00",
              end_time: "17:00",
            },
            {
              day: "thursday",
              start_time: "08:00",
              end_time: "17:00",
            },
            {
              day: "friday",
              start_time: "08:00",
              end_time: "17:00",
            },
          ],
          agents: [],
        });
        setAgentsData({ agents: [] });
        if (refresh) {
          getShift(true);
        } else {
          navigate(
            `/${getValue(params, "orgId", "")}/settings/helpdesk/agent-shifts`
          );
        }
      } else {
        toast.error(`Failed to ${editId ? "editing" : "creating"} Agent Shift`);
      }
    } catch (error) {
      toast.error(`Error ${editId ? "editing" : "creating"} Agent Shift`);
    } finally {
      setIsLoading(false);
    }
  };

  const getShift = async (status: boolean) => {
    try {
      setIsLoading1(status);
      const resp = await getSpecificShift(editId);
      if (resp) {
        setRequest({
          ...request,
          name: getValue(resp, "data.name", ""),
          time_zone: getValue(resp, "data.time_zone", ""),
          work_days: getValue(resp, "data.work_days", []),
          agents: getValue(resp, "data.agents", []),
        });
        setAgentsData({
          agents: getValue(resp, "data.agents", ""),
        });
      }
    } catch (error) {
      toast.error("Failed to load response.");
    } finally {
      setIsLoading1(false);
    }
  };

  const getAllAgents = async (id: string) => {
    try {
      setIsLoading2(true);
      const resp = await getSpecificShiftAgents(id);
      if (resp) {
        const modifiedUsers = getValue(resp, "data.agents", "").map(
          (user: any) => ({
            value: getValue(user, "id", ""),
            label:
              capitalizeFirstLetter(getValue(user, "first_name", "")) +
              " " +
              capitalizeFirstLetter(getValue(user, "last_name", "")),
          })
        );
        setAgents(modifiedUsers);
        setIsLoading2(false);
      }
    } catch (error) {
      toast.error("Failed to load Agents.");
      setIsLoading2(false);
    } finally {
      setIsLoading2(false);
    }
  };

  const handleAddAgent = async () => {
    setIsLoading3(true);
    handleSubmit(true);
    setIsModalOpen(false);
  };

  const handleDeleteAgent = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this agent from this shift?`,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          const updatedAgents = request.agents.filter(
            (agent: any) =>
              !selectedAgentIds.some(
                (selected: any) => selected.id === agent.id
              )
          );
          const updatedRequest = {
            ...request,
            agents: updatedAgents,
          };
          let payload = JSON.parse(JSON.stringify(updatedRequest));
          const resp = await updateAgentShift(editId, payload);

          if (resp) {
            toast.success("Updated Successfully");
            setRequest(updatedRequest);
            setSelectedAgentIds([]);
            setAgentsData({ agents: updatedAgents });
          } else {
            toast.error(`Failed to ${editId ? "edit" : "add"} Agent`);
          }
        } catch (error) {
          toast.error(`Error ${editId ? "editing" : "adding"} Agent`);
        } finally {
          setIsLoading(false);
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Other Section                                  */
  /* -------------------------------------------------------------------------- */
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General Settings",
      children: (
        <div>
          <div className="w-50">
            <InputRuleForm
              inputType="TEXT"
              name="name"
              value={getValue(request, "name", "")}
              placeholder="e.g. Chicago Shift"
              required
              label="Name (For Own Reference)"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          </div>
          <div className="mt-3 w-50">
            <SearchToolTip
              placeholder={`Enter Time Zone`}
              onChange={(e: object) =>
                handleSelectChange(e, "time_zone", request, setRequest)
              }
              label={`label`}
              data={timezones}
              value={getValue(request, `time_zone`, "")}
              selectKey={"value"}
              name={"Time Zone"}
              concatLength={100}
              required
            />
          </div>
          <AgentShiftHours
            request={request}
            setRequest={setRequest}
            days={days}
          />
        </div>
      ),
    },
    ...(editId
      ? [
          {
            key: "2",
            label: "Agents",
            children: (
              <ShiftAgents
                isLoading2={isLoading2}
                agentsData={agentsData}
                setSelectedAgentIds={setSelectedAgentIds}
                params={params}
              />
            ),
          },
        ]
      : []),
  ];

  const onChange = (key: string) => {
    setCurrentTab(key);
  };

  //Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUsers = (selectedAgents: string[]) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      agents: selectedAgents.map((id) => ({ id })),
    }));
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        backButton
        title={`${editId ? "Edit" : "New"} Agent Shift`}
        titleLoading={isLoading1}
        addChildren={
          <div className="d-flex align-items-center gap-2">
            <Button
              size="large"
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/settings/helpdesk/agent-shifts`
                )
              }
            >
              Cancel
            </Button>
            {currentTab === "2" &&
              (selectedAgentIds.length > 0 ? (
                <Button
                  danger
                  size="large"
                  type="primary"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteAgent()}
                >
                  Delete Agents
                </Button>
              ) : (
                <Button size="large" onClick={() => showModal()}>
                  Add Agents
                </Button>
              ))}
            <Button
              type="primary"
              size="large"
              onClick={() => handleSubmit()}
              loading={isLoading}
              disabled={
                !getValue(request, "name", "") ||
                !getValue(request, `time_zone`, "")
              }
            >
              {editId ? "Update" : "Create"} Agent Shift
            </Button>
          </div>
        }
      />
      <div className="manageFieldRightSettings overflow-y-auto p-4">
        {!editId && (
          <Alert
            message="Add Agents after creating the shift "
            type="info"
            showIcon
            className="w-fit-content"
          />
        )}
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
      <Modal
        title="Add Agents"
        okText="Add to Shift"
        open={isModalOpen}
        onOk={handleAddAgent}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: getValue(request, "agents.length", []) === 0,
        }}
      >
        <Select
          size="large"
          mode="multiple"
          placeholder="Select one or more agents"
          options={agents}
          className="w-100 mb-4"
          value={request.agents.map((agent: { id: string }) => agent.id)}
          onChange={(selectedValues: string[]) => {
            handleUsers(selectedValues);
          }}
          maxTagCount="responsive"
        />
        {/* <SearchToolTip
            placeholder={`Select Agent`}
            onChange={(e: any) => {}}
            label={`label`}
            data={agents}
            value={getValue(request, `agents.id`, "")}
            selectKey={"value"}
            name="label"
            hideLabel
          /> */}
      </Modal>
    </CompanyProfileDetailsLayout>
  );
};

export default CreateAgentShift;
