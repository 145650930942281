import { Button } from "antd";
import React from "react";

const KnowledgeBaseTab = () => {
  return (
    <div className="training-data-cotnent-container">
      <h6 className="header_text__18 mb-3">Knowledge base</h6>
      <div className="d-flex gap-3 justify-content-center mt-5 mb-3">
        <Button type="primary">Connect Knowledge base</Button>
      </div>
    </div>
  );
};

export default KnowledgeBaseTab;
