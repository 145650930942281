import BackSvgComponent from "@assets/svg/back-link";
import CalenderSvgComponent from "@assets/svg/calender";
import DashboardSvgComponent from "@assets/svg/dashboard";
import SettingsSvgComponents from "@assets/svg/settings";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { getValue } from "@utils/lodash";
import { Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const PropertyDetailsLayout = ({ children, setActiveTab }: any) => {
  const params = useParams();
  const navigate = useNavigate();

  const items = [
    // {
    //   changeHeader: () => {
    //     navigate(
    //       `/${getValue(params, "orgId", "")}/property/details/${getValue(
    //         params,
    //         "id",
    //         ""
    //       )}/summary`
    //     );
    //   },
    //   tooltipContent: "Property details",
    //   SvgComponent: DashboardSvgComponent,
    //   svgProps: { color: "#1a73e8", size: 24 },
    // },
    {
      changeHeader: () => {
        navigate(
          `/${getValue(params, "orgId", "")}/property/details/${getValue(
            params,
            "id",
            ""
          )}/reservations`
        );
      },
      tooltipContent: "Reservation calender",
      SvgComponent: CalenderSvgComponent,
      svgProps: { color: "#1a73e8", size: 24 },
    },
    {
      changeHeader: () => {
        navigate(
          `/${getValue(params, "orgId", "")}/property/details/${getValue(
            params,
            "id",
            ""
          )}/settings`
        );
      },
      tooltipContent: "Property configuration",
      SvgComponent: SettingsSvgComponents,
      svgProps: { color: "#1a73e8", size: 26 },
    },
  ];

  return (
    <div>
      <HomeHeader />
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(`/${getValue(params, "orgId", "")}/property`)
              }
            >
              <BackSvgComponent />
            </div>
          </div>
          <div className="d-flex gap-4 align-items-center">
            {items.map((item, index) => (
              <Tooltip title={item.tooltipContent} placement="bottom">
                <div
                  key={index}
                  onClick={item.changeHeader}
                  className="cursor-pointer"
                >
                  <item.SvgComponent {...item.svgProps} />
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default PropertyDetailsLayout;
