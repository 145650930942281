import "./nodata.scss";

function AgentShiftsNoDataPage() {
  return (
    <div className="n_container mt-3">
      <div className="nodata_container">
        <div className="d-flex justify-content-center align-items-center">
          <div className="nodata_container_main">
            <h6 className="nodata_container_header">
              Configure Agent Shifts in Appzo.
            </h6>
            <p className="nodata_container_desc mt-2 mb-3">
              Agent Shifts will appear here if you have configured through
              Appzo.
            </p>
          </div>
          <div className="w-10"></div>
          <img
            src={"/nodata/calls.svg"}
            alt="SVG"
            className="nodata_container_image"
          />
        </div>
      </div>
    </div>
  );
}

export default AgentShiftsNoDataPage;
